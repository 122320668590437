import { call, put, takeEvery } from "redux-saga/effects";
import { getAuthUserDetails } from "../../utils/Helpers";
import {
  FuelEfficiencySitesLoading,
  submitFuelEfficiencySitesActionDispatcher,
  submitFuelEfficiencySitesErrorDispatcher,
  SUBMIT_FUEL_EFFICIENCY_SITES_ACTION_REQUESTED,
} from "./Action"; // Path should come here
import axios from "axios";

const postFuelEfficiencySitesAPI = async () => {
  // console.log("postData: ", postData);
  const result = await axios.get(
    "https://volvodriverx.in/api/v1/sites/" + getAuthUserDetails().CustomerId
    //   ? postData.dates
    //   : "" //getAuthUserDetails().CustomerId
  );
  return result;
};

function* submitFuelEfficiencySitesSaga({ postData }) {
  try {
    yield put(FuelEfficiencySitesLoading(true));
    const data = yield call(postFuelEfficiencySitesAPI, postData);
    yield put(submitFuelEfficiencySitesActionDispatcher(data));
    yield put(submitFuelEfficiencySitesErrorDispatcher({}));
    yield put(FuelEfficiencySitesLoading(false));
  } catch (e) {
    yield put(submitFuelEfficiencySitesActionDispatcher({}));
    yield put(submitFuelEfficiencySitesErrorDispatcher(e.response?.data));
    yield put(FuelEfficiencySitesLoading(false));
  }
}

function* submitFuelEfficiencySitesWatcher() {
  yield takeEvery(
    SUBMIT_FUEL_EFFICIENCY_SITES_ACTION_REQUESTED,
    submitFuelEfficiencySitesSaga
  );
}
export default submitFuelEfficiencySitesWatcher;
