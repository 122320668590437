import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  modelsLoading,
  submitModelsActionDispatcher,
  submitModelsErrorDispatcher,
  SUBMIT_MODELS_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postModelsAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/ModelType?vehicleusermodel=12",
    {
      ...postData,
    }
  );
  return result;
};

function* submitModelsSaga({ postData }) {
  try {
    yield put(modelsLoading(true));
    const data = yield call(postModelsAPI, postData);
    yield put(submitModelsActionDispatcher(data));
    yield put(submitModelsErrorDispatcher({}));
    yield put(modelsLoading(false));
  } catch (e) {
    yield put(submitModelsActionDispatcher({}));
    yield put(
      submitModelsErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(modelsLoading(false));
  }
}

function* submitModelsWatcher() {
  yield takeEvery(SUBMIT_MODELS_ACTION_REQUESTED, submitModelsSaga);
}
export default submitModelsWatcher;
