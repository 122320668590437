import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  applicationsLoading,
  submitApplicationsActionDispatcher,
  submitApplicationsErrorDispatcher,
  SUBMIT_APPLICATIONS_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postApplicationsAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal?vehicletype=5",
    {
      ...postData,
    }
  );
  return result;
};

function* submitApplicationsSaga({ postData }) {
  try {
    yield put(applicationsLoading(true));
    const data = yield call(postApplicationsAPI, postData);
    yield put(submitApplicationsActionDispatcher(data));
    yield put(submitApplicationsErrorDispatcher({}));
    yield put(applicationsLoading(false));
  } catch (e) {
    yield put(submitApplicationsActionDispatcher({}));
    yield put(
      submitApplicationsErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(applicationsLoading(false));
  }
}

function* submitApplicationsWatcher() {
  yield takeEvery(SUBMIT_APPLICATIONS_ACTION_REQUESTED, submitApplicationsSaga);
}
export default submitApplicationsWatcher;
