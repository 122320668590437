import {
  getAuthUserDetails,
  setAuthUserDetails,
  setFleetFilters,
} from "../../utils/Helpers";
import {
  SITES_LOADING,
  SITES_SEARCH,
  CITY_BASED_SITES,
  SUBMIT_SITES_ACTION_DISPATCHER,
  SUBMIT_SITES_ACTION_REQUESTED,
  SUBMIT_SITES_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    sitesList: [],
    sites: [],
    excelData: [],
    siteIDs: [],
    myCities: [],
    mySites: [],
    filteredList: [],
    filteredMobList: [],
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  const siteList = [];
  const siteIDs = [];
  let authSiteIDs = [];
  getAuthUserDetails()?.lstSiteDetail?.forEach((e) => {
    authSiteIDs.push(e.SiteId);
  });
  // const siteIDsByCity = [];
  const cityList = [];
  let tempExcelArr = [
    [
      "Site ID",
      "Site Code",
      "Site Name",
      "Area",
      "Status",
      "Active Vehicle",
      "In-Active Vehicle",
      "Total Vehicle",
    ],
  ];
  let mobList = [];
  var siteListbyName = [];
  switch (action.type) {
    case SUBMIT_SITES_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_SITES_ACTION_DISPATCHER:
      for (let i = 0; i < action.filteredData.length; i += 5) {
        mobList.push(action.filteredData.slice(i, i + 5));
      }
      action.payload.forEach((e) => {
        siteIDs.push(e.SiteId);
        siteList.push({
          value: e.SiteCode + " - " + e.SiteName,
          name: e.SiteName,
          id: e.SiteId,
          city: e.SiteCity,
        });
        cityList.push({ value: e.SiteCity });
        tempExcelArr.push([
          e.SiteId,
          e.SiteCode,
          e.SiteName,
          e.SiteAddress,
          e.ActiveSite ? "Active" : "In-Active",
          e.ActiveVehicle,
          e.InActiveVehicle,
          e.TotalVehicle,
        ]);
      });
      siteIDs.sort();
      authSiteIDs.sort();
      siteList.sort(function (a, b) {
        var x = a.name.toLowerCase();
        var y = b.name.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      if (siteIDs.join() !== authSiteIDs.join()) {
        let authDetails = JSON.parse(localStorage.getItem("user_auth_details"));
        setFleetFilters(siteIDs);
        // console.log("authDetails: ", authDetails);
        // console.log("authDetails: ", authDetails.lstSiteDetail);
        authDetails.lstSiteDetail = action.payload;
        // console.log("newauthDetails: ", authDetails.lstSiteDetail);
        setAuthUserDetails(authDetails, authDetails.lstCustomerPermission);
        window.location.reload();
      }
      siteListbyName = siteList;
      // siteListbyName.sort(function (a, b) {
      //   var x = a.value.toLowerCase();
      //   var y = b.value.toLowerCase();
      //   return x < y ? -1 : x > y ? 1 : 0;
      // });
      siteListbyName.unshift({
        value: "All Sites",
        id: siteIDs.toString(),
        city: 0,
      });
      return {
        ...state,
        successData: {
          mySites: [
            ...new Map(
              siteListbyName.map((item) => [item["value"], item])
            ).values(),
          ],
          sites: siteList.slice(1),
          excelData: tempExcelArr,
          siteIDs: siteIDs,
          myCities: [
            ...new Map(cityList.map((item) => [item["value"], item])).values(),
          ],
          sitesList: action.payload,
          filteredList: action.filteredData,
          filteredMobList: mobList,
        },
        loading: false,
      };
    case SUBMIT_SITES_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case SITES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SITES_SEARCH:
      for (let i = 0; i < action.filteredData.length; i += 5) {
        mobList.push(action.filteredData.slice(i, i + 5));
      }
      action.payload.forEach((e) => {
        siteIDs.push(e.SiteId);
        siteList.push({
          value: e.SiteCode + " - " + e.SiteName,
          id: e.SiteId,
          city: e.SiteCity,
        });
        cityList.push({ value: e.SiteCity });
      });
      siteIDs.sort();
      // siteListbyName.sort(function (a, b) {
      //   var x = a.value.toLowerCase();
      //   var y = b.value.toLowerCase();
      //   return x < y ? -1 : x > y ? 1 : 0;
      // });
      // siteListbyName.unshift({
      //   value: "All Sites",
      //   id: siteIDs.toString(),
      //   city: 0,
      // });
      return {
        ...state,
        successData: {
          mySites: [
            ...new Map(siteList.map((item) => [item["value"], item])).values(),
          ],
          sites: siteList.slice(1),

          siteIDs: siteIDs,
          myCities: [
            ...new Map(cityList.map((item) => [item["value"], item])).values(),
          ],
          filteredList: action.filteredData,
          sitesList: action.payload,
          filteredMobList: mobList,
        },
        loading: false,
      };
    case CITY_BASED_SITES:
      for (let i = 0; i < action.filteredData.length; i += 5) {
        mobList.push(action.filteredData.slice(i, i + 5));
      }
      action.payload.forEach((e) => {
        siteIDs.push(e.SiteId);
        cityList.push({ value: e.SiteCity });
      });
      action.filteredData.forEach((e) => {
        siteList.push({
          value: e.SiteCode + " - " + e.SiteName,
          id: e.SiteId,
          city: e.SiteCity,
        });
        // siteIDsByCity.push(e.SiteId);
        tempExcelArr.push([
          e.SiteId,
          e.SiteCode,
          e.SiteName,
          e.SiteAddress,
          e.ActiveSite ? "Active" : "In-Active",
          e.ActiveVehicle,
          e.InActiveVehicle,
          e.TotalVehicle,
        ]);
      });
      siteIDs.sort();
      // siteIDsByCity.sort();
      siteListbyName = siteList;
      // siteListbyName.sort(function (a, b) {
      //   var x = a.value.toLowerCase();
      //   var y = b.value.toLowerCase();
      //   return x < y ? -1 : x > y ? 1 : 0;
      // });
      // if (action.payload !== action.filteredData) {
      //   siteListbyName.unshift({
      //     value: "All " + siteListbyName[2].city + " Sites",
      //     id: siteIDsByCity.toString(),
      //     city: 0,
      //   });
      // }
      siteListbyName.unshift({
        value: "All Sites",
        id: siteIDs.toString(),
        city: 0,
      });
      return {
        ...state,
        successData: {
          mySites: [
            ...new Map(siteList.map((item) => [item["value"], item])).values(),
          ],
          sites: siteList.slice(1),
          siteIDs: siteIDs,
          excelData: tempExcelArr,
          myCities: [
            ...new Map(cityList.map((item) => [item["value"], item])).values(),
          ],
          sitesList: action.payload,
          filteredList: action.filteredData,
          filteredMobList: mobList,
        },
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
