export const SUBMIT_USOB_OPEN_JOBS_ACTION_REQUESTED =
  "SUBMIT_USOB_OPEN_JOBS_ACTION_REQUESTED";
export const SUBMIT_USOB_OPEN_JOBS_ACTION_DISPATCHER =
  "SUBMIT_USOB_OPEN_JOBS_ACTION_DISPATCHER";
export const SUBMIT_USOB_OPEN_JOBS_ERROR_DISPATCHER =
  "SUBMIT_USOB_OPEN_JOBS_ERROR_DISPATCHER";
export const USOB_OPEN_JOBS_LOADING = "USOB_OPEN_JOBS_LOADING";

//Action Creator
export const submitUSOBOpenJobsAction = (data, page, filter) => ({
  type: SUBMIT_USOB_OPEN_JOBS_ACTION_REQUESTED,
  postData: data,
  page: page,
  filters: filter,
});

//Action Dispatcher
export const submitUSOBOpenJobsActionDispatcher = (data) => {
  let tempData = data.data?.success.data;
  let tempRegNo = [];
  let tempChassis = [];
  let tempServLoc = [];
  let tempConDesc = [];
  let tempCurrStat = [];
  let tempOpenSince = [];
  tempData.forEach((e) => {
    tempRegNo.push({
      value: e.registration_number,
      text: e.registration_number,
    });
    tempChassis.push({ value: e.chassis_id, text: e.chassis_id });
    tempServLoc.push({ value: e.workshop_name, text: e.workshop_name });
    tempConDesc.push({
      value: e.concern_description,
      text: e.concern_description,
    });
    tempCurrStat.push({
      value: e.repair_category_name,
      text: e.repair_category_name,
    });
    tempOpenSince.push({ value: e.open_since, text: e.open_since + " Days" });
  });
  return {
    type: SUBMIT_USOB_OPEN_JOBS_ACTION_DISPATCHER,
    payload: data,
    regNoFilter: [
      ...new Map(tempRegNo.map((item) => [item["value"], item])).values(),
    ],
    chassisFilter: [
      ...new Map(tempChassis.map((item) => [item["value"], item])).values(),
    ],
    servLocFilter: [
      ...new Map(tempServLoc.map((item) => [item["value"], item])).values(),
    ],
    conDescFilter: [
      ...new Map(tempConDesc.map((item) => [item["value"], item])).values(),
    ],
    currStatFilter: [
      ...new Map(tempCurrStat.map((item) => [item["value"], item])).values(),
    ],
    openSinceFilter: [
      ...new Map(tempOpenSince.map((item) => [item["value"], item])).values(),
    ],
  };
};

export const submitUSOBOpenJobsErrorDispatcher = (data) => ({
  type: SUBMIT_USOB_OPEN_JOBS_ERROR_DISPATCHER,
  payload: data,
});

export const USOBOpenJobsLoading = (loading) => ({
  type: USOB_OPEN_JOBS_LOADING,
  payload: loading,
});
