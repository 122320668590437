import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  carouselImgsLoading,
  submitCarouselImgsActionDispatcher,
  submitCarouselImgsErrorDispatcher,
  SUBMIT_CAROUSEL_IMGS_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBSegmentAPI = async (postData) => {
  // console.log("postData: ", postData);
  let apiUrl = "/carousel-image?" + postData;
  // }

  const result = await AXIOS_INSTANCE.get(apiUrl); //.slice(0, -1)
  // console.log("result: ", result);
  return result;
};

function* submitUSOBSegmentSaga({ postData }) {
  try {
    yield put(carouselImgsLoading(true));
    const data = yield call(postUSOBSegmentAPI, postData);
    yield put(submitCarouselImgsActionDispatcher(data));
    yield put(submitCarouselImgsErrorDispatcher({}));
    yield put(carouselImgsLoading(false));
  } catch (e) {
    yield put(submitCarouselImgsActionDispatcher({}));
    yield put(
      submitCarouselImgsErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(carouselImgsLoading(false));
  }
}

function* submitUSOBSegmentWatcher() {
  yield takeEvery(SUBMIT_CAROUSEL_IMGS_ACTION_REQUESTED, submitUSOBSegmentSaga);
}
export default submitUSOBSegmentWatcher;
