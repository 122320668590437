import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBDealerLocatorLoading,
  submitUSOBDealerLocatorActionDispatcher,
  submitUSOBDealerLocatorErrorDispatcher,
  SUBMIT_USOB_DEALER_LOCATOR_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBDealerLocatorAPI = async () => {
  let apiUrl = "/dealer-locator";
  const result = await AXIOS_INSTANCE.get(apiUrl);
  // console.log("result: ", result);
  return result;
};

function* submitUSOBDealerLocatorSaga({ latLng }) {
  try {
    yield put(USOBDealerLocatorLoading(true));
    const data = yield call(postUSOBDealerLocatorAPI);
    yield put(submitUSOBDealerLocatorActionDispatcher(data.data, latLng));
    yield put(submitUSOBDealerLocatorErrorDispatcher({}));
    yield put(USOBDealerLocatorLoading(false));
  } catch (e) {
    yield put(submitUSOBDealerLocatorActionDispatcher({}));
    yield put(submitUSOBDealerLocatorErrorDispatcher(e.response));
    yield put(USOBDealerLocatorLoading(false));
  }
}

function* submitUSOBDealerLocatorWatcher() {
  yield takeEvery(
    SUBMIT_USOB_DEALER_LOCATOR_ACTION_REQUESTED,
    submitUSOBDealerLocatorSaga
  );
}
export default submitUSOBDealerLocatorWatcher;
