import {
  FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_LOADING,
  SUBMIT_FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_ACTION_DISPATCHER,
  SUBMIT_FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_ACTION_REQUESTED,
  SUBMIT_FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    TotalVehicles: 0,
    InActiveVehicles: 0,
    TotalSites: 0,
    InActiveSites: 0,
    Aggregate_Repair: 0,
    Accident_Repair: 0,
    Preventive_Maintenance: 0,
    Running_Repair: 0,
    Predictive_Maintenance: 0,
    Super_Structure: 0,
    Acc_Insurance_Survey: 0,
    Acc_Repair_Estimate: 0,
    Customer_Approval_PO: 0,
    Customer_AR: 0,
    Customer_Man_power: 0,
    Customer_Tools_Equip: 0,
    Customer_Parts: 0,
    Customer_Repair: 0,
    Customer_Total: 0,
    VOLVO_Total_Count: 0,
    Volvo_Approval: 0,
    Volvo_Fault_diagnosis: 0,
    Volvo_Man_power: 0,
    Volvo_Parts_not_available: 0,
    Volvo_Repair_at_Workshop: 0,
    Volvo_Tools_Equipments: 0,
    Vendor_Approval: 0,
    Vendor_Parts: 0,
    Vendor_Manpower: 0,
    No_Delay_reson: 0,
    Unassigned: 0,
    apiData: {},
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_ACTION_DISPATCHER:
      let data = action.payload.data;
      // data.Customer_Approval_PO = parseInt(
      //   data.Customer_Approval_PO === "0" ? 5 : data.Customer_Approval_PO
      // );
      // data.Customer_AR = parseInt(
      //   data.Customer_AR === "0" ? 7 : data.Customer_AR
      // );
      // data.Customer_Man_power = parseInt(
      //   data.Customer_Man_power === "0" ? 3 : data.Customer_Man_power
      // );

      // data.Customer_Tools_Equip = parseInt(
      //   data.Customer_Tools_Equip === "0" ? 8 : data.Customer_Tools_Equip
      // );
      // data.Customer_Parts = parseInt(
      //   data.Customer_Parts === "0" ? 6 : data.Customer_Parts
      // );
      // data.CustomerRepair = parseInt(
      //   data.CustomerRepair === "0" ? 6 : data.CustomerRepair
      // );
      // // parseInt(data.CustomerRepair);

      // data.Acc_Insurance_Survey = parseInt(
      //   data.Acc_Insurance_Survey === "0" ? 2 : data.Acc_Insurance_Survey
      // );
      // data.Acc_Repair_Estimate = parseInt(
      //   data.Acc_Repair_Estimate === "0" ? 4 : data.Acc_Repair_Estimate
      // );

      // data.Preventive_Maintenance = parseInt(
      //   data.Preventive_Maintenance === "0" ? 6 : data.Preventive_Maintenance
      // );
      // data.Aggregate_Repair = parseInt(
      //   data.Aggregate_Repair === "0" ? 9 : data.Aggregate_Repair
      // );
      // data.Accident_Repair = parseInt(
      //   data.Accident_Repair === "0" ? 3 : data.Accident_Repair
      // );
      // data.Running_Repair = parseInt(
      //   data.Running_Repair === "0" ? 7 : data.Running_Repair
      // );
      // data.Predictive_Maintenance = parseInt(
      //   data.Predictive_Maintenance === "0" ? 5 : data.Predictive_Maintenance
      // );
      // data.Super_Structure = parseInt(
      //   data.Super_Structure === "0" ? 4 : data.Super_Structure
      // );
      // let customerTotal =
      //   parseInt(data.Customer_Approval_PO) +
      //   parseInt(data.Customer_AR) +
      //   parseInt(data.Customer_Man_power) +
      //   parseInt(data.Customer_Tools_Equip) +
      //   parseInt(data.Customer_Parts) +
      //   parseInt(data.CustomerRepair) +
      //   parseInt(data.Acc_Insurance_Survey) +
      //   parseInt(data.Acc_Repair_Estimate);
      let volvoTotal =
        parseInt(data.Preventive_Maintenance) +
        parseInt(data.Aggregate_Repair) +
        parseInt(data.Accident_Repair) +
        parseInt(data.Running_Repair) +
        parseInt(data.Predictive_Maintenance) +
        parseInt(data.Super_Structure);
      let volvoRepairs =
        0 +
        parseInt(data?.VOLVO_Total_Count) +
        parseInt(data?.Vendor_Approval) +
        parseInt(data?.Vendor_Parts) +
        parseInt(data?.Vendor_Manpower); // +parseInt(data?.No_Delay_reson) +parseInt(data?.Unassigned)
      return {
        ...state,
        successData: {
          // customerTotalCount: customerTotal,
          TotalVehicles: parseInt(data?.TotalVehicles),
          InActiveVehicles: parseInt(data?.InActiveVehicles),
          TotalSites: parseInt(data?.TotalSites),
          InActiveSites: parseInt(data?.InActiveSites),
          Aggregate_Repair: parseInt(data?.Aggregate_Repair),
          Accident_Repair: parseInt(data?.Accident_Repair),
          Preventive_Maintenance: parseInt(data?.Preventive_Maintenance),
          Running_Repair: parseInt(data?.Running_Repair),
          Predictive_Maintenance: parseInt(data?.Predictive_Maintenance),
          Super_Structure: parseInt(data?.Super_Structure),
          Acc_Insurance_Survey: parseInt(data?.Acc_Insurance_Survey),
          Acc_Repair_Estimate: parseInt(data?.Acc_Repair_Estimate),
          Customer_Approval_PO: parseInt(data?.Customer_Approval_PO),
          Customer_AR: parseInt(data?.Customer_AR),
          Customer_Man_power: parseInt(data?.Customer_Man_power),
          Customer_Tools_Equip: parseInt(data?.Customer_Tools_Equip),
          Customer_Parts: parseInt(data?.Customer_Parts),
          Customer_Repair: parseInt(data?.CustomerRepair),
          Customer_Total: parseInt(data?.Customer_Total_Count),
          VOLVO_Total_Count: volvoRepairs, //parseInt(data?.VOLVO_Total_Count),
          Volvo_Approval: parseInt(data?.Volvo_Approval),
          Volvo_Fault_diagnosis: parseInt(data?.Volvo_Fault_diagnosis),
          Volvo_Man_power: parseInt(data?.Volvo_Man_power),
          Volvo_Parts_not_available: parseInt(data?.Volvo_Parts_not_available),
          Volvo_Repair_at_Workshop: parseInt(data?.Volvo_Repair_at_Workshop),
          Volvo_Tools_Equipments: parseInt(data?.Volvo_Tools_Equipments),
          Vendor_Approval: parseInt(data?.Vendor_Approval),
          Vendor_Parts: parseInt(data?.Vendor_Parts),
          Vendor_Manpower: parseInt(data?.Vendor_Manpower),
          No_Delay_reson: parseInt(data?.No_Delay_reson),
          Unassigned: parseInt(data?.Unassigned),
          volvoTotalCount: volvoTotal,
          apiData: data,
        },
        loading: false,
      };
    case SUBMIT_FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
