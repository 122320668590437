//Action Types
export const SUBMIT_DELETE_VEHICLE_GROUP_ACTION_REQUESTED =
  "SUBMIT_DELETE_VEHICLE_GROUP_ACTION_REQUESTED";
export const SUBMIT_DELETE_VEHICLE_GROUP_ACTION_DISPATCHER =
  "SUBMIT_DELETE_VEHICLE_GROUP_ACTION_DISPATCHER";
export const SUBMIT_DELETE_VEHICLE_GROUP_ERROR_DISPATCHER =
  "SUBMIT_DELETE_VEHICLE_GROUP_ERROR_DISPATCHER";
export const SUBMIT_EMPTY_DELETE_VEHICLE_GROUP_STATE_ACTION =
  "SUBMIT_EMPTY_DELETE_VEHICLE_GROUP_STATE_ACTION";
export const DELETE_VEHICLE_GROUP_LOADING =
  "DELETE_VEHICLE_GROUP_LOADING";

//Action Creator
export const submitDeleteVehicleGroupAction = (data) => ({
  type: SUBMIT_DELETE_VEHICLE_GROUP_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitDeleteVehicleGroupActionDispatcher = (data) => ({
  type: SUBMIT_DELETE_VEHICLE_GROUP_ACTION_DISPATCHER,
  payload: data,
});

//Error Dispatcher
export const submitDeleteVehicleGroupErrorDispatcher = (data) => ({
  type: SUBMIT_DELETE_VEHICLE_GROUP_ERROR_DISPATCHER,
  payload: data,
});

//Loading Action
export const deleteVehicleGroupLoading = (loading) => ({
  type: DELETE_VEHICLE_GROUP_LOADING,
  payload: loading,
});

export const submitEmptyDeleteVehicleGroupStateAction = () => ({
  type: SUBMIT_EMPTY_DELETE_VEHICLE_GROUP_STATE_ACTION,
});
