export const SUBMIT_OPEN_JOBS_BY_CUSTOMER_COUNTS_ACTION_REQUESTED =
  "SUBMIT_OPEN_JOBS_BY_CUSTOMER_COUNTS_ACTION_REQUESTED";
export const SUBMIT_OPEN_JOBS_BY_CUSTOMER_COUNTS_ACTION_DISPATCHER =
  "SUBMIT_OPEN_JOBS_BY_CUSTOMER_COUNTS_ACTION_DISPATCHER";
export const SUBMIT_OPEN_JOBS_BY_CUSTOMER_COUNTS_ERROR_DISPATCHER =
  "SUBMIT_OPEN_JOBS_BY_CUSTOMER_COUNTS_ERROR_DISPATCHER";
export const OPEN_JOBS_BY_CUSTOMER_COUNTS_LOADING =
  "OPEN_JOBS_BY_CUSTOMER_COUNTS_LOADING";

//Action Creator
export const submitOpenJobsByCustomerCountsAction = (data) => ({
  type: SUBMIT_OPEN_JOBS_BY_CUSTOMER_COUNTS_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitOpenJobsByCustomerCountsActionDispatcher = (data) => ({
  type: SUBMIT_OPEN_JOBS_BY_CUSTOMER_COUNTS_ACTION_DISPATCHER,
  payload: data,
});

export const submitOpenJobsByCustomerCountsErrorDispatcher = (data) => ({
  type: SUBMIT_OPEN_JOBS_BY_CUSTOMER_COUNTS_ERROR_DISPATCHER,
  payload: data,
});

export const openJobsByCustomerCountsLoading = (loading) => ({
  type: OPEN_JOBS_BY_CUSTOMER_COUNTS_LOADING,
  payload: loading,
});
