import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  utilizationByMonthLoading,
  submitUtilizationByMonthActionDispatcher,
  submitUtilizationByMonthErrorDispatcher,
  SUBMIT_UTILIZATION_BY_MONTH_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUtilizationByMonthAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post("/CustomerPortal/Post?Uptime=avf&p1=xyz&Extrapram=4&Graph=5", {
    ...postData,
  });
  return result;
};

function* submitUtilizationByMonthSaga({ postData }) {
  try {
    yield put(utilizationByMonthLoading(true));
    const data = yield call(postUtilizationByMonthAPI, postData);
    yield put(submitUtilizationByMonthActionDispatcher(data));
    yield put(submitUtilizationByMonthErrorDispatcher({}));
    yield put(utilizationByMonthLoading(false));
  } catch (e) {
    yield put(submitUtilizationByMonthActionDispatcher({}));
    yield put(
      submitUtilizationByMonthErrorDispatcher(e.response.data)
    );
    yield put(utilizationByMonthLoading(false));
  }
}

function* submitUtilizationByMonthWatcher() {
  yield takeEvery(SUBMIT_UTILIZATION_BY_MONTH_ACTION_REQUESTED, submitUtilizationByMonthSaga);
}
export default submitUtilizationByMonthWatcher;
