export const SUBMIT_OPEN_JOBS_AWAITING_BY_CUSTOMER_ACTION_REQUESTED =
  "SUBMIT_OPEN_JOBS_AWAITING_BY_CUSTOMER_ACTION_REQUESTED";
export const SUBMIT_OPEN_JOBS_AWAITING_BY_CUSTOMER_ACTION_DISPATCHER =
  "SUBMIT_OPEN_JOBS_AWAITING_BY_CUSTOMER_ACTION_DISPATCHER";
export const SUBMIT_OPEN_JOBS_AWAITING_BY_CUSTOMER_ERROR_DISPATCHER =
  "SUBMIT_OPEN_JOBS_AWAITING_BY_CUSTOMER_ERROR_DISPATCHER";
export const OPEN_JOBS_AWAITING_BY_CUSTOMER_LOADING =
  "OPEN_JOBS_AWAITING_BY_CUSTOMER_LOADING";

//Action Creator
export const submitOpenJobsAwaitingByCustomerAction = (data) => ({
  type: SUBMIT_OPEN_JOBS_AWAITING_BY_CUSTOMER_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitOpenJobsAwaitingByCustomerActionDispatcher = (data) => {
  let tempChassis = [];
  let tempSite = [];
  let tempJobCategory = [];
  data.data?.OpenJobsDetailsList.forEach((e) => {
    tempSite.push({ value: e.SiteName, text: e.SiteName });
    tempChassis.push({ value: e.ChassisNumber, text: e.ChassisNumber });
    tempJobCategory.push({ value: e.StatusName, text: e.StatusName });
  });
  return {
    type: SUBMIT_OPEN_JOBS_AWAITING_BY_CUSTOMER_ACTION_DISPATCHER,
    payload: data?.data?.OpenJobsDetailsList,
    siteFilter: [...new Map(tempSite.map((item) => [item["value"], item])).values()],
    chassisFilter: [...new Map(tempChassis.map((item) => [item["value"], item])).values()],
    jobCategoryFilter: [...new Map(tempJobCategory.map((item) => [item["value"], item])).values()],
  };
};

export const submitOpenJobsAwaitingByCustomerErrorDispatcher = (data) => ({
  type: SUBMIT_OPEN_JOBS_AWAITING_BY_CUSTOMER_ERROR_DISPATCHER,
  payload: data,
});

export const openJobsAwaitingByCustomerLoading = (loading) => ({
  type: OPEN_JOBS_AWAITING_BY_CUSTOMER_LOADING,
  payload: loading,
});
