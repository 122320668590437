export const SUBMIT_APPLICATIONS_ACTION_REQUESTED = "SUBMIT_APPLICATIONS_ACTION_REQUESTED";
export const SUBMIT_APPLICATIONS_ACTION_DISPATCHER =
  "SUBMIT_APPLICATIONS_ACTION_DISPATCHER";
export const SUBMIT_APPLICATIONS_ERROR_DISPATCHER = "SUBMIT_APPLICATIONS_ERROR_DISPATCHER";
export const APPLICATIONS_LOADING = "APPLICATIONS_LOADING";

//Action Creator
export const submitApplicationsAction = (data) => ({
  type: SUBMIT_APPLICATIONS_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitApplicationsActionDispatcher = (data) => ({
  type: SUBMIT_APPLICATIONS_ACTION_DISPATCHER,
  payload: data,
});

export const submitApplicationsErrorDispatcher = (data) => ({
  type: SUBMIT_APPLICATIONS_ERROR_DISPATCHER,
  payload: data,
});

export const applicationsLoading = (loading) => ({
  type: APPLICATIONS_LOADING,
  payload: loading,
});
