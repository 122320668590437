export const SUBMIT_FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_ACTION_REQUESTED =
  "SUBMIT_FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_ACTION_REQUESTED";
export const SUBMIT_FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_ACTION_DISPATCHER =
  "SUBMIT_FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_ACTION_DISPATCHER";
export const SUBMIT_FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_ERROR_DISPATCHER =
  "SUBMIT_FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_ERROR_DISPATCHER";
export const FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_LOADING =
  "FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_LOADING";

//Action Creator
export const submitFleetSummaryAndRepairReportCountsAction = (data) => ({
  type: SUBMIT_FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitFleetSummaryAndRepairReportCountsActionDispatcher = (data) => ({
  type: SUBMIT_FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_ACTION_DISPATCHER,
  payload: data,
});

export const submitFleetSummaryAndRepairReportCountsErrorDispatcher = (data) => ({
  type: SUBMIT_FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_ERROR_DISPATCHER,
  payload: data,
});

export const fleetSummaryAndRepairReportCountsLoading = (loading) => ({
  type: FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_LOADING,
  payload: loading,
});
