import {
  USOB_WORKSHOPS_LOADING,
  SUBMIT_USOB_WORKSHOPS_ACTION_DISPATCHER,
  SUBMIT_USOB_WORKSHOPS_ACTION_REQUESTED,
  SUBMIT_USOB_WORKSHOPS_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { workshops: [], apiData: {} },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  const workshopList = [];
  switch (action.type) {
    case SUBMIT_USOB_WORKSHOPS_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_USOB_WORKSHOPS_ACTION_DISPATCHER:
      let data = action.payload.data;
      data.success?.workshops.forEach((e) => {
        workshopList.push({
          value: `(${e.workshop_code}) - ${e.workshop_name}`,
          id: e.workshop_id,
          key: e.workshop_code,
          workshop_name: e.workshop_name,
          workshop_code: e.workshop_code,
          owner: e.owner,
          contact_number: e.contact_number,
          region: e.region,
          area: e.area,
          added_user: e.added_user,
          added_time: e.added_time,
          modified_time: e.modified_time,
          modified_user: e.modified_user,
          active: e.active,
          created_at: e.created_at,
        });
      });
      return {
        ...state,
        successData: {
          workshops: workshopList,
          apiData: data.success,
        },
        loading: false,
      };
    case SUBMIT_USOB_WORKSHOPS_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case USOB_WORKSHOPS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
