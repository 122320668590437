import {
  SUBMIT_CHANGEPASSWORD_ACTION_REQUESTED,
  SUBMIT_CHANGEPASSWORD_ACTION_DISPATCHER,
  SUBMIT_CHANGEPASSWORD_ERROR_DISPATCHER,
  CHANGEPASSWORD_LOADING,
  SUBMIT_EMPTYCHANGEPASSWORDSTATE_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { message: "" },
  failureData: { message: "" },
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_CHANGEPASSWORD_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_CHANGEPASSWORD_ACTION_DISPATCHER:
      return {
        ...state,
        successData: {
          message: action.payload.data.Message,
        },
        loading: false,
      };
    case SUBMIT_CHANGEPASSWORD_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: {
          message: action.payload.data.Message,
        },
        loading: false,
      };
    case CHANGEPASSWORD_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SUBMIT_EMPTYCHANGEPASSWORDSTATE_ERROR_DISPATCHER:
      return {
        ...state,
        successData: {
          message: "",
        },
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
