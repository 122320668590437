import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  serviceHistoryLoading,
  submitServiceHistoryActionDispatcher,
  submitServiceHistoryErrorDispatcher,
  SUBMIT_SERVICE_HISTORY_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postServiceHistoryAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/ServiceHistoryVCP?closeticket=544",
    {
      ...postData,
    }
  );
  return result;
};

function* submitServiceHistorySaga({ postData, filterQuery }) {
  try {
    yield put(serviceHistoryLoading(true));
    const data = yield call(postServiceHistoryAPI, postData);
    yield put(submitServiceHistoryActionDispatcher(data.data, filterQuery));
    yield put(submitServiceHistoryErrorDispatcher({}));
    yield put(serviceHistoryLoading(false));
  } catch (e) {
    yield put(submitServiceHistoryActionDispatcher({}));
    yield put(
      submitServiceHistoryErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(serviceHistoryLoading(false));
  }
}

function* submitServiceHistoryWatcher() {
  yield takeEvery(
    SUBMIT_SERVICE_HISTORY_ACTION_REQUESTED,
    submitServiceHistorySaga
  );
}
export default submitServiceHistoryWatcher;
