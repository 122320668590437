import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  renameVehicleGroupLoading,
  submitRenameVehicleGroupActionDispatcher,
  submitRenameVehicleGroupErrorDispatcher,
  SUBMIT_RENAME_VEHICLE_GROUP_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postRenameVehicleGroupAPI = async (postData) => {
  // console.log('postData: ', postData);
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/updategroupmaster?Action=Update",
    { ...postData }
  );
  return result;
};

function* submitRenameVehicleGroupSaga({ postData }) {
  try {
    yield put(renameVehicleGroupLoading(true));
    const data = yield call(postRenameVehicleGroupAPI, postData);
    yield put(submitRenameVehicleGroupActionDispatcher(data));
    yield put(submitRenameVehicleGroupErrorDispatcher({}));
    yield put(renameVehicleGroupLoading(false));
  } catch (e) {
    yield put(submitRenameVehicleGroupActionDispatcher({}));
    yield put(submitRenameVehicleGroupErrorDispatcher(e));
    yield put(renameVehicleGroupLoading(false));
  }
}

function* submitRenameVehicleGroupWatcher() {
  yield takeEvery(
    SUBMIT_RENAME_VEHICLE_GROUP_ACTION_REQUESTED,
    submitRenameVehicleGroupSaga
  );
}
export default submitRenameVehicleGroupWatcher;
