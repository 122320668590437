import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBRegisterActionServiceLoading,
  submitUSOBRegisterActionServiceActionDispatcher,
  submitUSOBRegisterActionServiceErrorDispatcher,
  SUBMIT_USOB_REGISTER_ACTION_SERVICE_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBRegisterActionServiceAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post("/action-service/register", {
    ...postData,
  });
  return result;
};

function* submitUSOBRegisterActionServiceSaga({ postData }) {
  try {
    yield put(USOBRegisterActionServiceLoading(true));
    const data = yield call(postUSOBRegisterActionServiceAPI, postData);
    yield put(submitUSOBRegisterActionServiceActionDispatcher(data));
    yield put(submitUSOBRegisterActionServiceErrorDispatcher({}));
    yield put(USOBRegisterActionServiceLoading(false));
  } catch (e) {
    yield put(submitUSOBRegisterActionServiceActionDispatcher({}));
    yield put(submitUSOBRegisterActionServiceErrorDispatcher(e));
    yield put(USOBRegisterActionServiceLoading(false));
  }
}

function* submitUSOBRegisterActionServiceWatcher() {
  yield takeEvery(
    SUBMIT_USOB_REGISTER_ACTION_SERVICE_ACTION_REQUESTED,
    submitUSOBRegisterActionServiceSaga
  );
}
export default submitUSOBRegisterActionServiceWatcher;
