import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBCreateVehicleGroupLoading,
  submitUSOBCreateVehicleGroupActionDispatcher,
  submitUSOBCreateVehicleGroupErrorDispatcher,
  SUBMIT_USOB_CREATE_VEHICLE_GROUP_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBCreateVehicleGroupAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post("/groups", {
    ...postData,
  });
  return result;
};

function* submitUSOBCreateVehicleGroupSaga({ postData }) {
  try {
    yield put(USOBCreateVehicleGroupLoading(true));
    const data = yield call(postUSOBCreateVehicleGroupAPI, postData);
    yield put(submitUSOBCreateVehicleGroupActionDispatcher(data));
    yield put(submitUSOBCreateVehicleGroupErrorDispatcher({}));
    yield put(USOBCreateVehicleGroupLoading(false));
  } catch (e) {
    yield put(submitUSOBCreateVehicleGroupActionDispatcher({}));
    yield put(submitUSOBCreateVehicleGroupErrorDispatcher(e));
    yield put(USOBCreateVehicleGroupLoading(false));
  }
}

function* submitUSOBCreateVehicleGroupWatcher() {
  yield takeEvery(
    SUBMIT_USOB_CREATE_VEHICLE_GROUP_ACTION_REQUESTED,
    submitUSOBCreateVehicleGroupSaga
  );
}
export default submitUSOBCreateVehicleGroupWatcher;
