import {
  SUBMIT_FORGOTPASSWORD_ACTION_REQUESTED,
  SUBMIT_FORGOTPASSWORD_ACTION_DISPATCHER,
  SUBMIT_FORGOTPASSWORD_ERROR_DISPATCHER,
  SUBMIT_EMPTY_FORGOTPASSWORD_STATE_ACTION,
  FORGOTPASSWORD_LOADING,
} from "./Action";

const initialState = {
  successData: { message: "" },
  failureData: { message: "" },
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_FORGOTPASSWORD_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_FORGOTPASSWORD_ACTION_DISPATCHER:
      return {
        ...state,
        successData: {
          message: action.payload.data.Message,
        },
        loading: false,
      };
    case SUBMIT_FORGOTPASSWORD_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: {
          message: action.payload.data.Message,
        },
        loading: false,
      };
    case FORGOTPASSWORD_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SUBMIT_EMPTY_FORGOTPASSWORD_STATE_ACTION:
      return {
        ...state,
        successData: {
          message: "",
        },
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
