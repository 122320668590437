export const SUBMIT_MY_SHIFTS_ACTION_REQUESTED =
  "SUBMIT_MY_SHIFTS_ACTION_REQUESTED";
export const SUBMIT_MY_SHIFTS_ACTION_DISPATCHER =
  "SUBMIT_MY_SHIFTS_ACTION_DISPATCHER";
export const SUBMIT_MY_SHIFTS_ERROR_DISPATCHER =
  "SUBMIT_MY_SHIFTS_ERROR_DISPATCHER";
export const MY_SHIFTS_LOADING = "MY_SHIFTS_LOADING";

//Action Creator
export const submitMyShiftsAction = (data) => ({
  type: SUBMIT_MY_SHIFTS_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitMyShiftsActionDispatcher = (data) => ({
  type: SUBMIT_MY_SHIFTS_ACTION_DISPATCHER,
  payload: data,
});

export const submitMyShiftsErrorDispatcher = (data) => ({
  type: SUBMIT_MY_SHIFTS_ERROR_DISPATCHER,
  payload: data,
});

export const myShiftsLoading = (loading) => ({
  type: MY_SHIFTS_LOADING,
  payload: loading,
});
