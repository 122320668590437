//Action Types
export const SUBMIT_USOB_ADD_VEHICLES_IN_GROUP_ACTION_REQUESTED =
  "SUBMIT_USOB_ADD_VEHICLES_IN_GROUP_ACTION_REQUESTED";
export const SUBMIT_USOB_ADD_VEHICLES_IN_GROUP_ACTION_DISPATCHER =
  "SUBMIT_USOB_ADD_VEHICLES_IN_GROUP_ACTION_DISPATCHER";
export const SUBMIT_USOB_ADD_VEHICLES_IN_GROUP_ERROR_DISPATCHER =
  "SUBMIT_USOB_ADD_VEHICLES_IN_GROUP_ERROR_DISPATCHER";
export const SUBMIT_EMPTY_USOB_ADD_VEHICLES_IN_GROUP_STATE_ACTION =
  "SUBMIT_EMPTY_USOB_ADD_VEHICLES_IN_GROUP_STATE_ACTION";
export const USOB_ADD_VEHICLES_IN_GROUP_LOADING =
  "USOB_ADD_VEHICLES_IN_GROUP_LOADING";

//Action Creator
export const submitUSOBAddVehiclesInGroupAction = (data) => ({
  type: SUBMIT_USOB_ADD_VEHICLES_IN_GROUP_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitUSOBAddVehiclesInGroupActionDispatcher = (data) => ({
  type: SUBMIT_USOB_ADD_VEHICLES_IN_GROUP_ACTION_DISPATCHER,
  payload: data,
});

//Error Dispatcher
export const submitUSOBAddVehiclesInGroupErrorDispatcher = (data) => ({
  type: SUBMIT_USOB_ADD_VEHICLES_IN_GROUP_ERROR_DISPATCHER,
  payload: data,
});

//Loading Action
export const USOBAddVehiclesInGroupLoading = (loading) => ({
  type: USOB_ADD_VEHICLES_IN_GROUP_LOADING,
  payload: loading,
});

export const submitEmptyUSOBAddVehiclesInGroupStateAction = () => ({
  type: SUBMIT_EMPTY_USOB_ADD_VEHICLES_IN_GROUP_STATE_ACTION,
});
