import {
  REGIONS_LOADING,
  SUBMIT_REGIONS_ACTION_DISPATCHER,
  SUBMIT_REGIONS_ACTION_REQUESTED,
  SUBMIT_REGIONS_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { regionList: [], apiData: {} },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_REGIONS_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_REGIONS_ACTION_DISPATCHER:
      const regionList = []
      action.payload.data.forEach((e) => {
        regionList.push({ value: e.Region });
      });
      return {
        ...state,
        successData: {
          regionList: regionList.filter((v, i, a) => a.indexOf(v) === i),
          apiData: action.payload.data
        },
        loading: false,
      };
    case SUBMIT_REGIONS_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case REGIONS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
