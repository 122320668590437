export const SUBMIT_DEVICE_TRACKER_ACTION_REQUESTED =
  "SUBMIT_DEVICE_TRACKER_ACTION_REQUESTED";
export const SUBMIT_DEVICE_TRACKER_ACTION_DISPATCHER =
  "SUBMIT_DEVICE_TRACKER_ACTION_DISPATCHER";
export const SUBMIT_DEVICE_TRACKER_ERROR_DISPATCHER =
  "SUBMIT_DEVICE_TRACKER_ERROR_DISPATCHER";
export const DEVICE_TRACKER_LOADING = "DEVICE_TRACKER_LOADING";

//Action Creator
export const submitDeviceTrackerAction = (data) => ({
  type: SUBMIT_DEVICE_TRACKER_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitDeviceTrackerActionDispatcher = (data, center) => {
  // console.log("data, center: ", data, center);
  return {
    type: SUBMIT_DEVICE_TRACKER_ACTION_DISPATCHER,
    payload: data,
    latLng: center,
  };
};

export const submitDeviceTrackerErrorDispatcher = (data) => ({
  type: SUBMIT_DEVICE_TRACKER_ERROR_DISPATCHER,
  payload: data,
});

export const deviceTrackerLoading = (loading) => ({
  type: DEVICE_TRACKER_LOADING,
  payload: loading,
});
