import {
  CITIES_LOADING,
  SUBMIT_CITIES_ACTION_DISPATCHER,
  SUBMIT_CITIES_ACTION_REQUESTED,
  SUBMIT_CITIES_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { citiesList: [], apiData: {} },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_CITIES_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_CITIES_ACTION_DISPATCHER:
      const citiesList = []
      action.payload.data.forEach((e) => {
        citiesList.push({ value: e.CityName });
      });
      return {
        ...state,
        successData: {
          citiesList: citiesList.filter((v, i, a) => a.indexOf(v) === i),
          apiData: action.payload.data
        },
        loading: false,
      };
    case SUBMIT_CITIES_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case CITIES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
