export const SUBMIT_USOB_WORKSHOP_SERVICE_ACTION_REQUESTED =
  "SUBMIT_USOB_WORKSHOP_SERVICE_ACTION_REQUESTED";
export const SUBMIT_USOB_WORKSHOP_SERVICE_ACTION_DISPATCHER =
  "SUBMIT_USOB_WORKSHOP_SERVICE_ACTION_DISPATCHER";
export const SUBMIT_USOB_WORKSHOP_SERVICE_ERROR_DISPATCHER =
  "SUBMIT_USOB_WORKSHOP_SERVICE_ERROR_DISPATCHER";
export const USOB_WORKSHOP_SERVICE_LOADING = "USOB_WORKSHOP_SERVICE_LOADING";

//Action Creator
export const submitUSOBWorkshopServiceAction = (data, page) => ({
  type: SUBMIT_USOB_WORKSHOP_SERVICE_ACTION_REQUESTED,
  postData: data,
  page: page,
});

//Action Dispatcher
export const submitUSOBWorkshopServiceActionDispatcher = (data) => {
  let tempData = data?.success.data;
  // console.log("tempData: ", tempData);
  let tempRegNo = [];
  let tempCustomer = [];
  let tempModelNo = [];
  tempData.forEach((e) => {
    tempRegNo.push({
      value: e.registration_number,
      text: e.registration_number,
    });
    tempModelNo.push({ value: e.model_no, text: e.model_no });
    tempCustomer.push({ value: e.owner_name, text: e.owner_name });
  });
  return {
    type: SUBMIT_USOB_WORKSHOP_SERVICE_ACTION_DISPATCHER,
    payload: data,
    regNoFilter: [
      ...new Map(tempRegNo.map((item) => [item["value"], item])).values(),
    ],
    modelNoFilter: [
      ...new Map(tempModelNo.map((item) => [item["value"], item])).values(),
    ],
    customerFilter: [
      ...new Map(tempCustomer.map((item) => [item["value"], item])).values(),
    ],
  };
};

export const submitUSOBWorkshopServiceErrorDispatcher = (data) => ({
  type: SUBMIT_USOB_WORKSHOP_SERVICE_ERROR_DISPATCHER,
  payload: data,
});

export const USOBWorkshopServiceLoading = (loading) => ({
  type: USOB_WORKSHOP_SERVICE_LOADING,
  payload: loading,
});
