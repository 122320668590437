import {
  NAVIGATION_LOADING,
  SUBMIT_NAVIGATION_REQUESTED,
  SUBMIT_NAVIGATION_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { headerKey: "1" },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_NAVIGATION_REQUESTED:
      let lsKey = action.payload || localStorage.getItem("headerKey");
      // console.log("lsKey: ", lsKey);
      localStorage.setItem("headerKey", [lsKey]);
      return {
        ...state,
        successData: {
          headerKey: lsKey,
        },
        loading: false,
      };
    case SUBMIT_NAVIGATION_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case NAVIGATION_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
