import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBAddVehiclesInGroupLoading,
  submitUSOBAddVehiclesInGroupActionDispatcher,
  submitUSOBAddVehiclesInGroupErrorDispatcher,
  SUBMIT_USOB_ADD_VEHICLES_IN_GROUP_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBAddVehiclesInGroupAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post("/groups/map-vehicle", {
    ...postData,
  });
  return result;
};

function* submitUSOBAddVehiclesInGroupSaga({ postData }) {
  try {
    yield put(USOBAddVehiclesInGroupLoading(true));
    const data = yield call(postUSOBAddVehiclesInGroupAPI, postData);
    yield put(submitUSOBAddVehiclesInGroupActionDispatcher(data));
    yield put(submitUSOBAddVehiclesInGroupErrorDispatcher({}));
    yield put(USOBAddVehiclesInGroupLoading(false));
  } catch (e) {
    yield put(submitUSOBAddVehiclesInGroupActionDispatcher({}));
    yield put(submitUSOBAddVehiclesInGroupErrorDispatcher(e));
    yield put(USOBAddVehiclesInGroupLoading(false));
  }
}

function* submitUSOBAddVehiclesInGroupWatcher() {
  yield takeEvery(
    SUBMIT_USOB_ADD_VEHICLES_IN_GROUP_ACTION_REQUESTED,
    submitUSOBAddVehiclesInGroupSaga
  );
}
export default submitUSOBAddVehiclesInGroupWatcher;
