export const SUBMIT_USOB_WORKSHOP_SERVICES_ACTION_REQUESTED =
  "SUBMIT_USOB_WORKSHOP_SERVICES_ACTION_REQUESTED";
export const SUBMIT_USOB_WORKSHOP_SERVICES_ACTION_DISPATCHER =
  "SUBMIT_USOB_WORKSHOP_SERVICES_ACTION_DISPATCHER";
export const SUBMIT_USOB_WORKSHOP_SERVICES_ERROR_DISPATCHER =
  "SUBMIT_USOB_WORKSHOP_SERVICES_ERROR_DISPATCHER";
export const USOB_WORKSHOP_SERVICES_LOADING = "USOB_WORKSHOP_SERVICES_LOADING";

//Action Creator
export const submitUSOBWorkshopServicesAction = (data, query, page) => ({
  type: SUBMIT_USOB_WORKSHOP_SERVICES_ACTION_REQUESTED,
  filterQuery: query,
  postData: data,
  page: page,
});

//Action Dispatcher
export const submitUSOBWorkshopServicesActionDispatcher = (data, query) => {
  let filteredData = query
    ? data?.success?.data?.filter(
        (vehicle) =>
          (vehicle.chassis_id &&
            vehicle.chassis_id.toLowerCase().includes(query.toLowerCase())) ||
          (vehicle.workshop_name &&
            vehicle.workshop_name
              .toLowerCase()
              .includes(query.toLowerCase())) ||
          (vehicle.group_name &&
            vehicle.group_name.toLowerCase().includes(query.toLowerCase())) ||
          (vehicle.repair_category_name &&
            vehicle.repair_category_name
              .toLowerCase()
              .includes(query.toLowerCase())) ||
          (vehicle.registration_number &&
            vehicle.registration_number
              .toLowerCase()
              .includes(query.toLowerCase()))
      )
    : data?.success?.data;
  // console.log("filteredData: ", filteredData);
  let tempRegNo = [];
  let tempRepNo = [];
  let tempVehicleGrp = [];
  let tempWSName = [];
  let tempRepCat = [];
  let tempChassisNo = [];
  filteredData?.forEach((e) => {
    tempRegNo.push({
      value: e.registration_number,
      text: e.registration_number,
    });
    tempRepNo.push({
      value: e.repair_no,
      text: e.repair_no,
    });
    tempChassisNo.push({ value: e.chassis_id, text: e.chassis_id });
    tempVehicleGrp.push({ value: e.group_name, text: e.group_name });
    tempWSName.push({ value: e.workshop_name, text: e.workshop_name });
    tempRepCat.push({
      value: e.repair_category_name,
      text: e.repair_category_name,
    });
  });
  return {
    type: SUBMIT_USOB_WORKSHOP_SERVICES_ACTION_DISPATCHER,
    payload: data,
    filteredData: filteredData,
    regNoFilter: [
      ...new Map(tempRegNo.map((item) => [item["value"], item])).values(),
    ],
    repNoFilter: [
      ...new Map(tempRepNo.map((item) => [item["value"], item])).values(),
    ],
    chassisNoFilter: [
      ...new Map(tempChassisNo.map((item) => [item["value"], item])).values(),
    ],
    vehicleGrpFilter: [
      ...new Map(tempVehicleGrp.map((item) => [item["value"], item])).values(),
    ],
    wsNameFilter: [
      ...new Map(tempWSName.map((item) => [item["value"], item])).values(),
    ],
    repCatFilter: [
      ...new Map(tempRepCat.map((item) => [item["value"], item])).values(),
    ],
  };
};

export const submitUSOBWorkshopServicesErrorDispatcher = (data) => ({
  type: SUBMIT_USOB_WORKSHOP_SERVICES_ERROR_DISPATCHER,
  payload: data,
});

export const USOBWorkshopServicesLoading = (loading) => ({
  type: USOB_WORKSHOP_SERVICES_LOADING,
  payload: loading,
});
