import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  openJobsAwaitingByCustomerLoading,
  submitOpenJobsAwaitingByCustomerActionDispatcher,
  submitOpenJobsAwaitingByCustomerErrorDispatcher,
  SUBMIT_OPEN_JOBS_AWAITING_BY_CUSTOMER_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postOpenJobsAwaitingByCustomerAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/Post?customerdetail=57&Openjobs=hdj",
    {
      ...postData,
    }
  );
  return result;
};

function* submitOpenJobsAwaitingByCustomerSaga({ postData }) {
  try {
    yield put(openJobsAwaitingByCustomerLoading(true));
    const data = yield call(postOpenJobsAwaitingByCustomerAPI, postData);
    yield put(submitOpenJobsAwaitingByCustomerActionDispatcher(data));
    yield put(submitOpenJobsAwaitingByCustomerErrorDispatcher({}));
    yield put(openJobsAwaitingByCustomerLoading(false));
  } catch (e) {
    yield put(submitOpenJobsAwaitingByCustomerActionDispatcher({}));
    yield put(
      submitOpenJobsAwaitingByCustomerErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(openJobsAwaitingByCustomerLoading(false));
  }
}

function* submitOpenJobsAwaitingByCustomerWatcher() {
  yield takeEvery(
    SUBMIT_OPEN_JOBS_AWAITING_BY_CUSTOMER_ACTION_REQUESTED,
    submitOpenJobsAwaitingByCustomerSaga
  );
}
export default submitOpenJobsAwaitingByCustomerWatcher;
