import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";

import {
  hereSearchLoading,
  submitHereSearchActionDispatcher,
  submitHereSearchErrorDispatcher,
  SUBMIT_HERE_SEARCH_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBSegmentAPI = async (postData) => {
  const result = await axios.get(
    "https://geocode.search.hereapi.com/v1/geocode?q=" +
      postData +
      "&apiKey=" +
      process.env.REACT_APP_MAP_API_KEY
  );
  // console.log("result: ", result);
  return result;
};

function* submitUSOBSegmentSaga({ postData }) {
  try {
    yield put(hereSearchLoading(true));
    const data = yield call(postUSOBSegmentAPI, postData);
    yield put(submitHereSearchActionDispatcher(data));
    yield put(submitHereSearchErrorDispatcher({}));
    yield put(hereSearchLoading(false));
  } catch (e) {
    yield put(submitHereSearchActionDispatcher({}));
    yield put(
      submitHereSearchErrorDispatcher(e.response.data, console.log(e.response))
    );
    yield put(hereSearchLoading(false));
  }
}

function* submitUSOBSegmentWatcher() {
  yield takeEvery(SUBMIT_HERE_SEARCH_ACTION_REQUESTED, submitUSOBSegmentSaga);
}
export default submitUSOBSegmentWatcher;
