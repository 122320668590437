import { call, put, takeEvery } from "redux-saga/effects";
import { getAuthUserDetails } from "../../utils/Helpers";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBVehiclesLoading,
  submitUSOBVehiclesActionDispatcher,
  submitUSOBVehiclesErrorDispatcher,
  SUBMIT_USOB_VEHICLES_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBVehiclesAPI = async (postData) => {
  // console.log("postData: ", postData);
  // console.log("getAuthUserDetails().usob_cid: ", getAuthUserDetails().usob_cid);
  const result = await AXIOS_INSTANCE.get(
    "/vehicle" +
      (getAuthUserDetails().usob_cid === "0"
        ? "?"
        : "?customer_id=" + getAuthUserDetails().usob_cid) +
      (postData?.date_from
        ? postData?.date_to
          ? "&date_of_delivery_from=" +
            postData.date_from +
            "&repair_end_date_time_to=" +
            postData.date_to
          : "&date_of_delivery_from=" + postData.date_from
        : postData?.date_to
        ? "&repair_end_date_time_to=" + postData.date_to
        : postData?.vehicles
        ? "&vehicle_id=" + postData.vehicles
        : "")
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log("err: ", err);
    });
  return { data: result };
};

function* submitUSOBVehiclesSaga({ postData, filterQuery }) {
  try {
    yield put(USOBVehiclesLoading(true));
    const data = yield call(postUSOBVehiclesAPI, postData);
    yield put(submitUSOBVehiclesActionDispatcher(data, filterQuery));
    yield put(submitUSOBVehiclesErrorDispatcher({}));
    yield put(USOBVehiclesLoading(false));
  } catch (e) {
    yield put(submitUSOBVehiclesActionDispatcher({}));
    yield put(
      submitUSOBVehiclesErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(USOBVehiclesLoading(false));
  }
}

function* submitUSOBVehiclesWatcher() {
  yield takeEvery(
    SUBMIT_USOB_VEHICLES_ACTION_REQUESTED,
    submitUSOBVehiclesSaga
  );
}
export default submitUSOBVehiclesWatcher;
