import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBOpenJobsLoading,
  submitUSOBOpenJobsActionDispatcher,
  submitUSOBOpenJobsErrorDispatcher,
  SUBMIT_USOB_OPEN_JOBS_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBOpenJobsAPI = async (postData, filters) => {
  // console.log("filters: ", filters);
  // console.log("postData: ", postData);
  let api =
    "/dashboard/open-jobs?pagination=false" +
    (postData.repairCategoryID
      ? "&repair_category_id=" + postData.repairCategoryID
      : "") +
    (postData.reasonID ? "&reason_ids=" + postData.reasonID : "") +
    (filters?.segment ? "&segment=" + filters?.segment : "") +
    (filters?.vehicle_type ? "&vehicle_type=" + filters?.vehicle_type : "") +
    (filters?.customer_id ? "&customer_id=" + filters?.customer_id : "") +
    (filters?.vehicle_group ? "&vehicle_group=" + filters?.vehicle_group : "");
  const result = await AXIOS_INSTANCE.get(api);
  // console.log("result: ", result);
  return result;
};

function* submitUSOBOpenJobsSaga({ postData, page, filters }) {
  try {
    yield put(USOBOpenJobsLoading(true));
    const data = yield call(postUSOBOpenJobsAPI, postData, filters);
    yield put(submitUSOBOpenJobsActionDispatcher(data));
    yield put(submitUSOBOpenJobsErrorDispatcher({}));
    yield put(USOBOpenJobsLoading(false));
  } catch (e) {
    yield put(submitUSOBOpenJobsErrorDispatcher({}));
    yield put(submitUSOBOpenJobsErrorDispatcher(e));
    yield put(USOBOpenJobsLoading(false));
  }
}

function* submitUSOBOpenJobsWatcher() {
  yield takeEvery(
    SUBMIT_USOB_OPEN_JOBS_ACTION_REQUESTED,
    submitUSOBOpenJobsSaga
  );
}
export default submitUSOBOpenJobsWatcher;
