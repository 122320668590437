import {
  APPLICATIONS_LOADING,
  SUBMIT_APPLICATIONS_ACTION_DISPATCHER,
  SUBMIT_APPLICATIONS_ACTION_REQUESTED,
  SUBMIT_APPLICATIONS_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { applicationList: [], apiData: {} },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_APPLICATIONS_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_APPLICATIONS_ACTION_DISPATCHER:
      const applicationList = [];
      const allApplications = [];
      action.payload.data.forEach((e) => {
        allApplications.push(e.VehicleType);
        applicationList.push({ value: e.VehicleType });
      });
      var appListbyName = applicationList;
      appListbyName.sort(function (a, b) {
        var x = a.value.toLowerCase();
        var y = b.value.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      allApplications.sort();
      appListbyName.unshift({
        value: "All Applications",
        id: allApplications.toString(),
      });
      return {
        ...state,
        successData: {
          applicationList: applicationList.filter(
            (v, i, a) => a.indexOf(v) === i
          ),
          apiData: action.payload.data,
        },
        loading: false,
      };
    case SUBMIT_APPLICATIONS_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case APPLICATIONS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
