import _ from "lodash";
import moment from "moment";
import { ROUTERS } from "./Constants";

export const getAuthToken = () => {
  return localStorage.getItem("token");
};

export const getHeaderKey = () => {
  console.log(
    'localStorage.getItem("headerKey"): ',
    localStorage.getItem("headerKey")
  );
  return localStorage.getItem("headerKey");
};

export const getAuthUserDetails = () => {
  let userAuthDetails = {};
  let userAuthDetailsObj = localStorage.getItem("user_auth_details");
  if (!(typeof userAuthDetailsObj === "object")) {
    try {
      userAuthDetails = JSON.parse(userAuthDetailsObj);
    } catch (error) {
      console.log("getAuthUserDetails Error: ", error);
      userAuthDetailsObj = "error";
    }
  }
  return userAuthDetails;
};

export const getAge = (date) => {
  var diff = moment().diff(moment(date), "days");
  let month = (parseInt((diff % 365) / 30) / 12).toFixed(1);
  let age = parseInt(diff / 365) + (month % 1);
  return age;
};

export const getTimeTaken = (start_date_time, end_date_time) => {
  let days = moment(end_date_time).diff(start_date_time, "days");
  let hrs = moment(end_date_time).diff(start_date_time, "hours") - 24 * days;
  let mins =
    moment(end_date_time).diff(start_date_time, "minutes") -
    (60 * (24 * days) + 60 * hrs);
  let total = days > 0 ? days + "d " : "";
  total += hrs > 0 ? hrs + "h " : "";
  total += mins > 0 ? mins + "m" : "";
  return total || "-";
};

export const getFormattedDate = (date) => {
  return moment(date).format("DD-MMM-YYYY");
};

export const getYYYYMMDDDate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const getDDMMYYYYDate = (date) => {
  return moment(date).format("DD-MM-YYYY");
};

export const getFormattedTime = (time) => {
  return moment(time).format("hh:mm A");
};

export const getFormattedDateTime = (datetime) => {
  return moment(datetime).format("DD-MMM-YYYY hh:mm A");
};

export const getAdminAuthUserDetails = () => {
  let adminAuthDetails = {};
  let adminAuthDetailsObj = localStorage.getItem("admin_auth_details");
  if (!(typeof adminAuthDetailsObj === "object")) {
    try {
      adminAuthDetails = JSON.parse(adminAuthDetailsObj);
    } catch (error) {
      console.log("getAdminAuthUserDetails Error: ", error);
      adminAuthDetailsObj = "error";
    }
  }
  return adminAuthDetails;
};

export const getAuthUserPermissions = () => {
  let userAuthPermissions = {};
  let userAuthPermissionsObj = localStorage.getItem("user_permissions");
  if (!(typeof userAuthPermissionsObj === "object")) {
    try {
      userAuthPermissions =
        JSON.parse(userAuthPermissionsObj)[0] ||
        JSON.parse(userAuthPermissionsObj);
    } catch (error) {
      console.log("getAuthUserDetails Error: ", error);
      userAuthPermissionsObj = "error";
    }
  }
  return userAuthPermissions;
};

export const getAdminAuthUserPermissions = () => {
  let adminAuthPermissions = {};
  let adminAuthPermissionsObj = localStorage.getItem("admin_auth_permissions");
  if (!(typeof adminAuthPermissionsObj === "object")) {
    try {
      adminAuthPermissions = JSON.parse(adminAuthPermissionsObj);
    } catch (error) {
      console.log("getAdminAuthUserPermissions Error: ", error);
      adminAuthPermissionsObj = "error";
    }
  }
  return adminAuthPermissions;
};

export const setAuthUserDetails = (userDetails, userPermissions) => {
  localStorage.setItem("user_auth_details", JSON.stringify(userDetails));
  localStorage.setItem("user_permissions", JSON.stringify(userPermissions));
};

export const setAdminAuthUserDetails = (adminDetails, adminPermissions) => {
  localStorage.setItem("admin_auth_details", JSON.stringify(adminDetails));
  localStorage.setItem("admin_permissions", JSON.stringify(adminPermissions));
};

/**
 * @method inactivityTime
 * @description This function tracks active time of user and Logout is not for 20mins.
 * @return {*}
 */
export const inactivityTime = () => {
  var time;
  window.onload = resetTimer;
  // DOM Events
  document.onmousemove = resetTimer;
  document.onkeypress = resetTimer;
  function resetTimer() {
    localStorage.setItem("lastActivity", new Date().toLocaleString());
    clearTimeout(time);
    time = setTimeout(logout, 20 * 60 * 1000); //20mins
  }
  function logout() {
    localStorage.clear();
    alert(
      "Your session is timed out due to inactivity at " +
        new Date().toLocaleString() +
        ", Please Login Again!"
    );
    window.location.assign(ROUTERS.LOGIN);
  }
};

export const setFleetFilters = (siteIDs) => {
  siteIDs.sort();
  let vehType = [];
  getAuthUserDetails().lstApplicationtype.forEach((e) => {
    vehType.push(e.VehicleType);
  });
  vehType.sort();
  let modelType = [];
  getAuthUserDetails().lstModelType.forEach((e) => {
    modelType.push(e.Model);
  });
  modelType.sort();
  localStorage.setItem(
    "fleetFilters",
    JSON.stringify({
      UserId: getAuthUserDetails().UserId,
      FDate: moment().subtract(11, "months").format("YYYY-MM-DD"),
      TDate: moment().format("YYYY-MM-DD"),
      SiteId: siteIDs,
      VehicleModel: modelType,
      AgeBand: 0,
      City: getAuthUserDetails().lstSiteDetail[0]?.SiteCity,
      VehicleType: vehType,
    })
  );
  localStorage.setItem(
    "uptimeFilters",
    JSON.stringify({
      UserId: getAuthUserDetails().UserId,
      FromDate: moment().subtract(11, "months").format("MM-DD-YYYY"),
      ToDate: moment().format("MM-DD-YYYY"),
      SiteId: siteIDs,
      AgeBand: 0,
      // City: getAuthUserDetails().lstSiteDetail[0].SiteCity,
      VehicleType: vehType,
    })
  );
  localStorage.setItem(
    "tripFilters",
    JSON.stringify({
      MonthYear: moment().format("YYYY-MM-DD"),
      SiteID: siteIDs,
      AgeBand: 0,
      Model: modelType,
    })
  );
};
/**
 * @description this method return if the user is logged in or not
 * @param {*}
 * @return {boolean}
 */
export const isUserLoggedIn = () => {
  if (_.isEmpty(getAuthUserDetails()) || _.isEmpty(getAdminAuthUserDetails())) {
    return false;
  } else {
    return true;
  }
};
/**
 * @description this method return if the user is logged in or not
 * @param {*}
 * @return {boolean}
 */
export const vpUsers = () => {
  return [
    {
      value: "Antony  Verghese",
      id: 1539,
      data: {
        UserId: 1539,
        lstUserModel: [
          {
            UserId: 1539,
            RoleId: 0,
            CustomerId: 10546,
            usob_user_id: 0,
            usob_role_id: 0,
            usob_customer_id: 0,
            usob_id: null,
            usob_rid: null,
            usob_cid: null,
            AuthEngineId: null,
            UserName: "averghese@volvo.in",
            Designation: "Regional Service Manager",
            TimeZoneId: 0,
            Status: null,
            Message: null,
            Token: null,
            FirstName: "Antony ",
            LastName: "Verghese",
            RoleName: "User",
            IsDeleted: false,
            Password: null,
            UserMobile: "9073921165",
            UserArea: "Dhanbad",
            UserRegion: "East",
            CustomerMobile: null,
            CustomerName: "Volvo Trucks India",
            ContactPersonName: "Prathap",
            ContactPersonMobile: "9686794006",
            CustomerRegisterAddress: null,
            IsAdmin: false,
            lstUserModel: null,
            lstSiteDetail: null,
            lstCustomerDetail: null,
            lstCustomerPermission: null,
            lstApplicationtype: null,
            lstModelType: null,
          },
        ],
        lstSiteDetail: [
          {
            UserId: 0,
            SiteId: 451,
            SiteCode: null,
            SiteName: "BGR Mining & Infra Ltd, Manoharpur",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 400,
            SiteCode: null,
            SiteName: "Mados Infra Project Pvt. Ltd., Sonepur Bazari",
            SiteCity: "Kolkata",
            SiteAddress: "Kolkata West Bengal",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 420,
            SiteCode: null,
            SiteName: "HILLTOP HIRISE PVT LTD",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 515,
            SiteCode: null,
            SiteName: "KALINGA COMMERCIAL CORPORATION LIMITED, Jilling",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 453,
            SiteCode: null,
            SiteName: "Dilip Buildcon Ltd, Samaleswari",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 491,
            SiteCode: null,
            SiteName: "BLA PROJECTS PVT LTD, Bharatpur",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 561,
            SiteCode: null,
            SiteName: "Knowteq Equipcare LLP-Transdamodar",
            SiteCity: "Kolkata",
            SiteAddress: "Kolkata West Bengal",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 148,
            SiteCode: null,
            SiteName: "AT-Devprabha (JV),Lodhna",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 106,
            SiteCode: null,
            SiteName: "Coal Mines Associated Traders Pvt. Ltd., Sonepurbazari",
            SiteCity: "Kolkata",
            SiteAddress: "Kolkata West Bengal",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 109,
            SiteCode: null,
            SiteName: "Nilkanth, MIPL Sonepur",
            SiteCity: "Kolkata",
            SiteAddress: "Kolkata West Bengal",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 426,
            SiteCode: null,
            SiteName: "Sheranwali Infrastructure Private Limited",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 144,
            SiteCode: null,
            SiteName: "DEVPRABHA CONSTRUCTION PVT. LTD,Bhowrah",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 495,
            SiteCode: null,
            SiteName: "KNOWTEQ EQUIPCARE LLP, Benidih",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 110,
            SiteCode: null,
            SiteName: "KNOWTEQ EQUIPCARE LLP,ICML Asansol",
            SiteCity: "Kolkata",
            SiteAddress: "Kolkata West Bengal",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 452,
            SiteCode: null,
            SiteName: "KALINGA COMMERCIAL CORPORATION LIMITED, Kaliapani",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 526,
            SiteCode: null,
            SiteName: "Dhansar Engineering Co Pvt Ltd, Chetudih",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 480,
            SiteCode: null,
            SiteName: "KALINGA COMMERCIAL CORPORATION LIMITED, Sukinda",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 439,
            SiteCode: null,
            SiteName: "Bhubaneswari Coal Mining Ltd",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 532,
            SiteCode: null,
            SiteName: "KALINGA COMMERCIAL CORPORATION LIMITED, KUMURDA",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 142,
            SiteCode: null,
            SiteName: "KNOWTEQ EQUIPCARE LLP,Katapahari",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 519,
            SiteCode: null,
            SiteName: "R.K Transport and Constructions Limited, Talcher",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 493,
            SiteCode: null,
            SiteName: "Kejriwal Mining Private Limited, Khasmahal",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 327,
            SiteCode: null,
            SiteName: "PLR Projects Pvt. Ltd,Tapin",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 119,
            SiteCode: null,
            SiteName: "KALINGA COMMERCIAL CORPORATION LIMITED,Hill Top",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 379,
            SiteCode: null,
            SiteName: "NAARAAYANI SONS(P)LTD, Sukinda",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 120,
            SiteCode: null,
            SiteName: "KALINGA COMMERCIAL CORPORATION LIMITED,Daitari",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 472,
            SiteCode: null,
            SiteName: "Nilkanth Mining Pvt Ltd",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 538,
            SiteCode: null,
            SiteName: "Jalaram Transport (Non Gold), Talcher",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 528,
            SiteCode: null,
            SiteName: "Harsha Constructions Pvt. Ltd, Talcher",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 569,
            SiteCode: null,
            SiteName: "Nirupama Mining & Transporting Pvt. Ltd, , Badbil",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 428,
            SiteCode: null,
            SiteName: "ALOKE STEELS INDUSTRIES PRIVATE LIMITED (RPL)",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 417,
            SiteCode: null,
            SiteName: "A&A Logistic Services",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 146,
            SiteCode: null,
            SiteName: "AT-Devprabha (JV), Jeenagora",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 560,
            SiteCode: null,
            SiteName: "Bajwa Infrastructure Pvt. Ltd, Amlo Project",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 501,
            SiteCode: null,
            SiteName: "Sanjay Udyog Private Ltd., Basjora",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 429,
            SiteCode: null,
            SiteName: "Aarneel Technocrafts Pvt. Ltd (DBL)",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 122,
            SiteCode: null,
            SiteName: "AJANTHA EARTHMOVERS,Talcher",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 456,
            SiteCode: null,
            SiteName: "Sanjay Udyog Private Ltd.,Damoda",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 549,
            SiteCode: null,
            SiteName: "Hilltop Hirise Private Limited, Tetulmuri",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 131,
            SiteCode: null,
            SiteName: "MAHALAXMI INFRACONTRACT PVT. LTD.,Talcher",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 535,
            SiteCode: null,
            SiteName: "SAINIK MINING AND ALLIED SERVICES LTD, Dulunga",
            SiteCity: "Bhubaneswar",
            SiteAddress: "Bhubaneswar Odisha",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 151,
            SiteCode: null,
            SiteName: "KNOWTEQ EQUIPCARE LLP,Amrapali",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 150,
            SiteCode: null,
            SiteName: "THRIVENI SAINIK MINING PRIVATE LIMITED,Barkagaon",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 475,
            SiteCode: null,
            SiteName: "RKS Construction,Tapin North",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 167,
            SiteCode: null,
            SiteName: "MAHALAXMI INFRA CONTRACT PVT. LTD,Amrapali",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 545,
            SiteCode: null,
            SiteName: "Sanjay Udyog Private Ltd, Khasmahal",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 454,
            SiteCode: null,
            SiteName: "Dhansar Engineering Co P Ltd, Nichitpur",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 476,
            SiteCode: null,
            SiteName: "UCC-Infra (JV),Alkusha",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 136,
            SiteCode: null,
            SiteName: "KNOWTEQ EQUIPCARE LLP,Lalmatia",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 434,
            SiteCode: null,
            SiteName: "Hilltop Hirise Private Limited,Gonudih",
            SiteCity: "Dhanbad",
            SiteAddress: "Dhanbad Jharkhand",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
        ],
        lstCustomerPermission: [
          {
            Id: 70,
            FleetSummary: true,
            UptimeUtilization: true,
            TripSummary: true,
            SiteAdvisory: true,
            mining: true,
            bus: true,
            on_road: true,
          },
          {
            Id: 31,
            FleetSummary: true,
            UptimeUtilization: true,
            TripSummary: true,
            SiteAdvisory: true,
            mining: true,
            bus: true,
            on_road: true,
          },
        ],
        lstApplicationtype: [
          {
            UserId: 0,
            VehicleType: "Bus - Tourist",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - ROAD CONSTRUCTION",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - MINERAL MINING",
          },
          {
            UserId: 0,
            VehicleType: "TRUCK - UTILITY SERVICES",
          },
          {
            UserId: 0,
            VehicleType: "TRUCK - ON ROAD LH",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - COAL TRANSPORTATION",
          },
          {
            UserId: 0,
            VehicleType: "Truck - Special Application",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - QUARRY",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - OB MINING",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - IRRIGATION",
          },
        ],
        lstModelType: [
          {
            UserId: 0,
            Model: "B11R",
          },
          {
            UserId: 0,
            Model: "B8R",
          },
          {
            UserId: 0,
            Model: "B7R",
          },
          {
            UserId: 0,
            Model: "FM9",
          },
          {
            UserId: 0,
            Model: "FM7",
          },
          {
            UserId: 0,
            Model: "B7R MARK 2",
          },
          {
            UserId: 0,
            Model: "FM 420 8X4",
          },
          {
            UserId: 0,
            Model: "FM 480 10X4",
          },
          {
            UserId: 0,
            Model: "FMX 440 8X4",
          },
          {
            UserId: 0,
            Model: "FM 380 8X4",
          },
          {
            UserId: 0,
            Model: "FMX 460 8X4",
          },
          {
            UserId: 0,
            Model: "FMX 480 10X4",
          },
          {
            UserId: 0,
            Model: "FMX 520 10X4",
          },
          {
            UserId: 0,
            Model: "FM 420 6X4",
          },
          {
            UserId: 0,
            Model: "FM 460 6X4",
          },
          {
            UserId: 0,
            Model: "FM 380 4X2",
          },
          {
            UserId: 0,
            Model: "FM 400 8X4",
          },
          {
            UserId: 0,
            Model: "FMX 460 6X4",
          },
          {
            UserId: 0,
            Model: "FM 520 6X4",
          },
          {
            UserId: 0,
            Model: "FM 380 6X4",
          },
          {
            UserId: 0,
            Model: "FM 420 6X6",
          },
          {
            UserId: 0,
            Model: "FH 420 6X4",
          },
          {
            UserId: 0,
            Model: "FH 340 6X4",
          },
          {
            UserId: 0,
            Model: "FM 340 6X4",
          },
          {
            UserId: 0,
            Model: "FH 500 4X2",
          },
          {
            UserId: 0,
            Model: "FM 480 6X4",
          },
          {
            UserId: 0,
            Model: "FMX 480 8X4",
          },
          {
            UserId: 0,
            Model: "FM 480 6X6",
          },
          {
            UserId: 0,
            Model: "FM 440 6X4",
          },
          {
            UserId: 0,
            Model: "FM 300",
          },
          {
            UserId: 0,
            Model: "FH 12 6X4",
          },
          {
            UserId: 0,
            Model: "FM 290",
          },
          {
            UserId: 0,
            Model: "FM 420 4X2",
          },
          {
            UserId: 0,
            Model: "FMX 500 8X4",
          },
          {
            UserId: 0,
            Model: "FM 12 6X4",
          },
          {
            UserId: 0,
            Model: "FH 12 4X2",
          },
          {
            UserId: 0,
            Model: "FM 9 6X4",
          },
          {
            UserId: 0,
            Model: "FH 520 6X4",
          },
          {
            UserId: 0,
            Model: "FM 7 8X4",
          },
          {
            UserId: 0,
            Model: "FM 460 8X4",
          },
          {
            UserId: 0,
            Model: "FM 440 8X4",
          },
          {
            UserId: 0,
            Model: "B9R",
          },
          {
            UserId: 0,
            Model: "FM 400 6X4",
          },
        ],
      },
    },
    {
      value: "Shibu Ponnan",
      id: 1515,
      data: {
        UserId: 1515,
        lstUserModel: [
          {
            UserId: 1515,
            RoleId: 0,
            CustomerId: 10546,
            usob_user_id: 0,
            usob_role_id: 0,
            usob_customer_id: 0,
            usob_id: null,
            usob_rid: null,
            usob_cid: null,
            AuthEngineId: null,
            UserName: "sponnan@volvo.in",
            Designation: "RSM",
            TimeZoneId: 0,
            Status: null,
            Message: null,
            Token: null,
            FirstName: "Shibu",
            LastName: "Ponnan",
            RoleName: "User",
            IsDeleted: false,
            Password: null,
            UserMobile: "7338665800",
            UserArea: "Ramagundam",
            UserRegion: "South",
            CustomerMobile: null,
            CustomerName: "Volvo Trucks India",
            ContactPersonName: "Prathap",
            ContactPersonMobile: "9686794006",
            CustomerRegisterAddress: null,
            IsAdmin: false,
            lstUserModel: null,
            lstSiteDetail: null,
            lstCustomerDetail: null,
            lstCustomerPermission: null,
            lstApplicationtype: null,
            lstModelType: null,
          },
        ],
        lstSiteDetail: [
          {
            UserId: 0,
            SiteId: 566,
            SiteCode: null,
            SiteName: "KLP-Enterprise, Manuguru",
            SiteCity: "Kothagudem",
            SiteAddress: "Kothagudem Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 349,
            SiteCode: null,
            SiteName: "NCC LIMITED",
            SiteCity: "Ramagundam",
            SiteAddress: "Ramagundam Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 362,
            SiteCode: null,
            SiteName: "Avaya Construction Company",
            SiteCity: "Hyderabad",
            SiteAddress: "Hyderabad Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 200,
            SiteCode: null,
            SiteName: "Mahalaxmi Infracontract Limited,Neyveli",
            SiteCity: "Neyveli",
            SiteAddress: "Neyveli Tamil Nadu",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 314,
            SiteCode: null,
            SiteName: "SESA GOA LIMITED,Chitradurga",
            SiteCity: "Hospet",
            SiteAddress: "Hospet Karnataka",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 319,
            SiteCode: null,
            SiteName: "R.vidyasagar Rao,OCP-III",
            SiteCity: "Ramagundam",
            SiteAddress: "Ramagundam Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 481,
            SiteCode: null,
            SiteName: "KLP-Enterprises 2",
            SiteCity: "Kothagudem",
            SiteAddress: "Kothagudem Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 216,
            SiteCode: null,
            SiteName: "Gaurav Contracts Co.,Sriramapur",
            SiteCity: "Ramagundam",
            SiteAddress: "Ramagundam Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 227,
            SiteCode: null,
            SiteName: "Midwest Granite Pvt Ltd.,Kesamudram/Kodad",
            SiteCity: "Kothagudem",
            SiteAddress: "Kothagudem Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 482,
            SiteCode: null,
            SiteName: "S.V. Engineering Constructions",
            SiteCity: "Kothagudem",
            SiteAddress: "Kothagudem Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 215,
            SiteCode: null,
            SiteName: "AMR INDIA LIMITED,Thaticharla ",
            SiteCity: "Ramagundam",
            SiteAddress: "Ramagundam Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 558,
            SiteCode: null,
            SiteName: "Saakar Infra Nirman Pvt Ltd",
            SiteCity: "Ramagundam",
            SiteAddress: "Ramagundam Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 435,
            SiteCode: null,
            SiteName: "KNR Constructions Limited, Mallan Sagar",
            SiteCity: "Hyderabad",
            SiteAddress: "Hyderabad Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 457,
            SiteCode: null,
            SiteName: "Durga Construction Company, Indaram",
            SiteCity: "Ramagundam",
            SiteAddress: "Ramagundam Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 483,
            SiteCode: null,
            SiteName: "R Vidya Sagar Rao, Mandamarri",
            SiteCity: "Ramagundam",
            SiteAddress: "Ramagundam Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 523,
            SiteCode: null,
            SiteName: "Sushee Infra, OCP-III",
            SiteCity: "Ramagundam",
            SiteAddress: "Ramagundam Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 367,
            SiteCode: null,
            SiteName: "PLR PROJECTS ",
            SiteCity: "Hyderabad",
            SiteAddress: "Hyderabad Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 199,
            SiteCode: null,
            SiteName: "Mahalaxmi Infracontract Limited,Neyveli",
            SiteCity: "Neyveli",
            SiteAddress: "Neyveli Tamil Nadu",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 533,
            SiteCode: null,
            SiteName: "AMR India Ltd, Kurnool",
            SiteCity: "Nellore",
            SiteAddress: "Nellore Andra Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 207,
            SiteCode: null,
            SiteName: "Midwest Granite Pvt. Ltd.,Chimakurthy",
            SiteCity: "Nellore",
            SiteAddress: "Nellore Andra Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 466,
            SiteCode: null,
            SiteName: "S V Engineering Constructions, Koyagudem",
            SiteCity: "Kothagudem",
            SiteAddress: "Kothagudem Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 554,
            SiteCode: null,
            SiteName: "S.V. Engineering Constructions",
            SiteCity: "Kothagudem",
            SiteAddress: "Kothagudem Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 514,
            SiteCode: null,
            SiteName: "Pearl Mineral and Mines Pvt Ltd., Chimakurthy",
            SiteCity: "Nellore",
            SiteAddress: "Nellore Andra Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 562,
            SiteCode: null,
            SiteName: "AP Midwest Granite Pvt. Ltd, Chimakurthy",
            SiteCity: "Nellore",
            SiteAddress: "Nellore Andra Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 220,
            SiteCode: null,
            SiteName: "Sri Rajarajeshwari Constructions,Siddipet",
            SiteCity: "Hyderabad",
            SiteAddress: "Hyderabad Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 458,
            SiteCode: null,
            SiteName: "GRN Constructions, Khariguda",
            SiteCity: "Ramagundam",
            SiteAddress: "Ramagundam Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 553,
            SiteCode: null,
            SiteName: "GKR INFRACON (INDIA) PVT LTD",
            SiteCity: "Kothagudem",
            SiteAddress: "Kothagudem Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 204,
            SiteCode: null,
            SiteName: "THRIVENI EARTHMOVERS PVT.LTD",
            SiteCity: "Nellore",
            SiteAddress: "Nellore Andra Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 351,
            SiteCode: null,
            SiteName: "Durga Construction Co.",
            SiteCity: "Kothagudem",
            SiteAddress: "Kothagudem Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 441,
            SiteCode: null,
            SiteName: "KLP-Enterprises, Sathupalli",
            SiteCity: "Kothagudem",
            SiteAddress: "Kothagudem Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 524,
            SiteCode: null,
            SiteName: "Expressway Sevice Pvt Ltd, Mallan Sagar",
            SiteCity: "Hyderabad",
            SiteAddress: "Hyderabad Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 552,
            SiteCode: null,
            SiteName: "PC Patel, OCP-V",
            SiteCity: "Ramagundam",
            SiteAddress: "Ramagundam Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 437,
            SiteCode: null,
            SiteName: "HES Infra Pvt Ltd, Vattem",
            SiteCity: "Hyderabad",
            SiteAddress: "Hyderabad Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 520,
            SiteCode: null,
            SiteName: "Mahalaxmi Infra Mining LLP, RKOCP",
            SiteCity: "Ramagundam",
            SiteAddress: "Ramagundam Telengana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 202,
            SiteCode: null,
            SiteName: "Krishnapatnam  Port Company Ltd.,Nellore",
            SiteCity: "Nellore",
            SiteAddress: "Nellore Andra Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
        ],
        lstCustomerPermission: [
          {
            Id: 9,
            FleetSummary: true,
            UptimeUtilization: true,
            TripSummary: true,
            SiteAdvisory: true,
            mining: true,
            bus: true,
            on_road: true,
          },
        ],
        lstApplicationtype: [
          {
            UserId: 0,
            VehicleType: "Bus - Tourist",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - ROAD CONSTRUCTION",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - MINERAL MINING",
          },
          {
            UserId: 0,
            VehicleType: "TRUCK - UTILITY SERVICES",
          },
          {
            UserId: 0,
            VehicleType: "TRUCK - ON ROAD LH",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - COAL TRANSPORTATION",
          },
          {
            UserId: 0,
            VehicleType: "Truck - Special Application",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - QUARRY",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - OB MINING",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - IRRIGATION",
          },
        ],
        lstModelType: [
          {
            UserId: 0,
            Model: "B11R",
          },
          {
            UserId: 0,
            Model: "B8R",
          },
          {
            UserId: 0,
            Model: "B7R",
          },
          {
            UserId: 0,
            Model: "FM9",
          },
          {
            UserId: 0,
            Model: "FM7",
          },
          {
            UserId: 0,
            Model: "B7R MARK 2",
          },
          {
            UserId: 0,
            Model: "FM 420 8X4",
          },
          {
            UserId: 0,
            Model: "FM 480 10X4",
          },
          {
            UserId: 0,
            Model: "FMX 440 8X4",
          },
          {
            UserId: 0,
            Model: "FM 380 8X4",
          },
          {
            UserId: 0,
            Model: "FMX 460 8X4",
          },
          {
            UserId: 0,
            Model: "FMX 480 10X4",
          },
          {
            UserId: 0,
            Model: "FMX 520 10X4",
          },
          {
            UserId: 0,
            Model: "FM 420 6X4",
          },
          {
            UserId: 0,
            Model: "FM 460 6X4",
          },
          {
            UserId: 0,
            Model: "FM 380 4X2",
          },
          {
            UserId: 0,
            Model: "FM 400 8X4",
          },
          {
            UserId: 0,
            Model: "FMX 460 6X4",
          },
          {
            UserId: 0,
            Model: "FM 520 6X4",
          },
          {
            UserId: 0,
            Model: "FM 380 6X4",
          },
          {
            UserId: 0,
            Model: "FM 420 6X6",
          },
          {
            UserId: 0,
            Model: "FH 420 6X4",
          },
          {
            UserId: 0,
            Model: "FH 340 6X4",
          },
          {
            UserId: 0,
            Model: "FM 340 6X4",
          },
          {
            UserId: 0,
            Model: "FH 500 4X2",
          },
          {
            UserId: 0,
            Model: "FM 480 6X4",
          },
          {
            UserId: 0,
            Model: "FMX 480 8X4",
          },
          {
            UserId: 0,
            Model: "FM 480 6X6",
          },
          {
            UserId: 0,
            Model: "FM 440 6X4",
          },
          {
            UserId: 0,
            Model: "FM 300",
          },
          {
            UserId: 0,
            Model: "FH 12 6X4",
          },
          {
            UserId: 0,
            Model: "FM 290",
          },
          {
            UserId: 0,
            Model: "FM 420 4X2",
          },
          {
            UserId: 0,
            Model: "FMX 500 8X4",
          },
          {
            UserId: 0,
            Model: "FM 12 6X4",
          },
          {
            UserId: 0,
            Model: "FH 12 4X2",
          },
          {
            UserId: 0,
            Model: "FM 9 6X4",
          },
          {
            UserId: 0,
            Model: "FH 520 6X4",
          },
          {
            UserId: 0,
            Model: "FM 7 8X4",
          },
          {
            UserId: 0,
            Model: "FM 460 8X4",
          },
          {
            UserId: 0,
            Model: "FM 440 8X4",
          },
          {
            UserId: 0,
            Model: "B9R",
          },
          {
            UserId: 0,
            Model: "FM 400 6X4",
          },
        ],
      },
    },
    {
      value: "Arulmani Duraimoolam",
      id: 1520,
      data: {
        UserId: 1520,
        lstUserModel: [
          {
            UserId: 1520,
            RoleId: 0,
            CustomerId: 10546,
            usob_user_id: 0,
            usob_role_id: 0,
            usob_customer_id: 0,
            usob_id: null,
            usob_rid: null,
            usob_cid: null,
            AuthEngineId: null,
            UserName: "aduraimoolam@volvo.in",
            Designation: "RSM",
            TimeZoneId: 0,
            Status: null,
            Message: null,
            Token: null,
            FirstName: "Arulmani",
            LastName: "Duraimoolam",
            RoleName: "User",
            IsDeleted: false,
            Password: null,
            UserMobile: "9845635807",
            UserArea: "Vidabha",
            UserRegion: "Central",
            CustomerMobile: null,
            CustomerName: "Volvo Trucks India",
            ContactPersonName: "Prathap",
            ContactPersonMobile: "9686794006",
            CustomerRegisterAddress: null,
            IsAdmin: false,
            lstUserModel: null,
            lstSiteDetail: null,
            lstCustomerDetail: null,
            lstCustomerPermission: null,
            lstApplicationtype: null,
            lstModelType: null,
          },
        ],
        lstSiteDetail: [
          {
            UserId: 0,
            SiteId: 97,
            SiteCode: null,
            SiteName: "AW & Sons, Katni",
            SiteCity: "Nagpur-central",
            SiteAddress: "Nagpur-central Vidarbha-central",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 500,
            SiteCode: null,
            SiteName: "Hari Ram Godara, Kolgaon",
            SiteCity: "Chandrapur-central",
            SiteAddress: "Chandrapur-central Vidarbha-central",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 102,
            SiteCode: null,
            SiteName: " S.S. Chhatwal and Co. Pvt. Ltd., Gondegaon",
            SiteCity: "Nagpur-central",
            SiteAddress: "Nagpur-central Vidarbha-central",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 492,
            SiteCode: null,
            SiteName: "Karamjeet Singh & Company Limited, Majri",
            SiteCity: "Chandrapur-central",
            SiteAddress: "Chandrapur-central Vidarbha-central",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 432,
            SiteCode: null,
            SiteName: "Mann Engineering Company, Naygaon ",
            SiteCity: "Chandrapur-central",
            SiteAddress: "Chandrapur-central Vidarbha-central",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 497,
            SiteCode: null,
            SiteName: "Caliber Mercantile Private Limited, Pauni",
            SiteCity: "Chandrapur-central",
            SiteAddress: "Chandrapur-central Vidarbha-central",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 464,
            SiteCode: null,
            SiteName: "Narayani Sons Pvt Ltd, Kushmunda",
            SiteCity: "Korba",
            SiteAddress: "Korba Chhatisgarh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 430,
            SiteCode: null,
            SiteName: "Mahalaxmi Infracontract Pvt. Ltd, Mungoli",
            SiteCity: "Chandrapur-central",
            SiteAddress: "Chandrapur-central Vidarbha-central",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 415,
            SiteCode: null,
            SiteName: "AVTAR SINGH & COMPANY",
            SiteCity: "Korba",
            SiteAddress: "Korba Chhatisgarh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 308,
            SiteCode: null,
            SiteName: "Sainik Mining and Allied Services,Dipka",
            SiteCity: "Korba",
            SiteAddress: "Korba Chhatisgarh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 548,
            SiteCode: null,
            SiteName: "Karamjeet Singh & Co, Gevra",
            SiteCity: "Korba",
            SiteAddress: "Korba Chhatisgarh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 304,
            SiteCode: null,
            SiteName: "Karamjeet Singh & Co.,Parsa",
            SiteCity: "Korba",
            SiteAddress: "Korba Chhatisgarh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 490,
            SiteCode: null,
            SiteName: "Chennai Radha Engineering Works (P) Ltd",
            SiteCity: "Korba",
            SiteAddress: "Korba Chhatisgarh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 463,
            SiteCode: null,
            SiteName: "GRN Constructions Pvt Ltd, Bhatadi",
            SiteCity: "Chandrapur-central",
            SiteAddress: "Chandrapur-central Vidarbha-central",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 544,
            SiteCode: null,
            SiteName: "Mann Engineering Company, Dongamahua - Raigarh",
            SiteCity: "Korba",
            SiteAddress: "Korba Chhatisgarh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 431,
            SiteCode: null,
            SiteName: "GRN Constructions Pvt. Ltd, Pauni",
            SiteCity: "Chandrapur-central",
            SiteAddress: "Chandrapur-central Vidarbha-central",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 335,
            SiteCode: null,
            SiteName: "Sainik Mining & Allied Service,Bahanpart",
            SiteCity: "Korba",
            SiteAddress: "Korba Chhatisgarh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 546,
            SiteCode: null,
            SiteName: "Hilltop Hirise Private Limited, Kolarpimpri",
            SiteCity: "Chandrapur-central",
            SiteAddress: "Chandrapur-central Vidarbha-central",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 543,
            SiteCode: null,
            SiteName: "KLP Enterprises, Yekona",
            SiteCity: "Chandrapur-central",
            SiteAddress: "Chandrapur-central Vidarbha-central",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 522,
            SiteCode: null,
            SiteName: "NCC Ltd",
            SiteCity: "Nagpur-central",
            SiteAddress: "Nagpur-central Vidarbha-central",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 567,
            SiteCode: null,
            SiteName: "HARI RAM GODARA JV, RALIYA - GEVRA",
            SiteCity: "Korba",
            SiteAddress: "Korba Chhatisgarh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
        ],
        lstCustomerPermission: [
          {
            Id: 14,
            FleetSummary: true,
            UptimeUtilization: true,
            TripSummary: false,
            SiteAdvisory: true,
            mining: true,
            bus: true,
            on_road: true,
          },
        ],
        lstApplicationtype: [
          {
            UserId: 0,
            VehicleType: "Bus - Tourist",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - ROAD CONSTRUCTION",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - MINERAL MINING",
          },
          {
            UserId: 0,
            VehicleType: "TRUCK - UTILITY SERVICES",
          },
          {
            UserId: 0,
            VehicleType: "TRUCK - ON ROAD LH",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - COAL TRANSPORTATION",
          },
          {
            UserId: 0,
            VehicleType: "Truck - Special Application",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - QUARRY",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - OB MINING",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - IRRIGATION",
          },
        ],
        lstModelType: [
          {
            UserId: 0,
            Model: "B11R",
          },
          {
            UserId: 0,
            Model: "B8R",
          },
          {
            UserId: 0,
            Model: "B7R",
          },
          {
            UserId: 0,
            Model: "FM9",
          },
          {
            UserId: 0,
            Model: "FM7",
          },
          {
            UserId: 0,
            Model: "B7R MARK 2",
          },
          {
            UserId: 0,
            Model: "FM 420 8X4",
          },
          {
            UserId: 0,
            Model: "FM 480 10X4",
          },
          {
            UserId: 0,
            Model: "FMX 440 8X4",
          },
          {
            UserId: 0,
            Model: "FM 380 8X4",
          },
          {
            UserId: 0,
            Model: "FMX 460 8X4",
          },
          {
            UserId: 0,
            Model: "FMX 480 10X4",
          },
          {
            UserId: 0,
            Model: "FMX 520 10X4",
          },
          {
            UserId: 0,
            Model: "FM 420 6X4",
          },
          {
            UserId: 0,
            Model: "FM 460 6X4",
          },
          {
            UserId: 0,
            Model: "FM 380 4X2",
          },
          {
            UserId: 0,
            Model: "FM 400 8X4",
          },
          {
            UserId: 0,
            Model: "FMX 460 6X4",
          },
          {
            UserId: 0,
            Model: "FM 520 6X4",
          },
          {
            UserId: 0,
            Model: "FM 380 6X4",
          },
          {
            UserId: 0,
            Model: "FM 420 6X6",
          },
          {
            UserId: 0,
            Model: "FH 420 6X4",
          },
          {
            UserId: 0,
            Model: "FH 340 6X4",
          },
          {
            UserId: 0,
            Model: "FM 340 6X4",
          },
          {
            UserId: 0,
            Model: "FH 500 4X2",
          },
          {
            UserId: 0,
            Model: "FM 480 6X4",
          },
          {
            UserId: 0,
            Model: "FMX 480 8X4",
          },
          {
            UserId: 0,
            Model: "FM 480 6X6",
          },
          {
            UserId: 0,
            Model: "FM 440 6X4",
          },
          {
            UserId: 0,
            Model: "FM 300",
          },
          {
            UserId: 0,
            Model: "FH 12 6X4",
          },
          {
            UserId: 0,
            Model: "FM 290",
          },
          {
            UserId: 0,
            Model: "FM 420 4X2",
          },
          {
            UserId: 0,
            Model: "FMX 500 8X4",
          },
          {
            UserId: 0,
            Model: "FM 12 6X4",
          },
          {
            UserId: 0,
            Model: "FH 12 4X2",
          },
          {
            UserId: 0,
            Model: "FM 9 6X4",
          },
          {
            UserId: 0,
            Model: "FH 520 6X4",
          },
          {
            UserId: 0,
            Model: "FM 7 8X4",
          },
          {
            UserId: 0,
            Model: "FM 460 8X4",
          },
          {
            UserId: 0,
            Model: "FM 440 8X4",
          },
          {
            UserId: 0,
            Model: "B9R",
          },
          {
            UserId: 0,
            Model: "FM 400 6X4",
          },
        ],
      },
    },
    {
      value: "Suhas Sushant Das ",
      id: 1666,
      data: {
        UserId: 1666,
        lstUserModel: [
          {
            UserId: 1666,
            RoleId: 0,
            CustomerId: 10546,
            usob_user_id: 0,
            usob_role_id: 0,
            usob_customer_id: 0,
            usob_id: null,
            usob_rid: null,
            usob_cid: null,
            AuthEngineId: null,
            UserName: "ssdas@volvo.in",
            Designation: "Regional Service Manager",
            TimeZoneId: 0,
            Status: null,
            Message: null,
            Token: null,
            FirstName: "Suhas Sushant Das",
            LastName: null,
            RoleName: "User",
            IsDeleted: false,
            Password: null,
            UserMobile: "7869918244",
            UserArea: "Singrauli",
            UserRegion: "North",
            CustomerMobile: null,
            CustomerName: "Volvo Trucks India",
            ContactPersonName: "Prathap",
            ContactPersonMobile: "9686794006",
            CustomerRegisterAddress: null,
            IsAdmin: false,
            lstUserModel: null,
            lstSiteDetail: null,
            lstCustomerDetail: null,
            lstCustomerPermission: null,
            lstApplicationtype: null,
            lstModelType: null,
          },
        ],
        lstSiteDetail: [
          {
            UserId: 0,
            SiteId: 195,
            SiteCode: null,
            SiteName: "Avtar & Co., Barmer",
            SiteCity: "Jaipur",
            SiteAddress: "Jaipur Rajasthan",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 326,
            SiteCode: null,
            SiteName: "VPR Bina,Bina",
            SiteCity: "Singrauli",
            SiteAddress: "Singrauli Madhya Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 324,
            SiteCode: null,
            SiteName: "BIPL Amlohri",
            SiteCity: "Singrauli",
            SiteAddress: "Singrauli Madhya Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 470,
            SiteCode: null,
            SiteName: "Kandoi Transport Limited,Jayant",
            SiteCity: "Singrauli",
            SiteAddress: "Singrauli Madhya Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 478,
            SiteCode: null,
            SiteName: "Dhansar Engineering Pvt Ltd, BINA Project",
            SiteCity: "Singrauli",
            SiteAddress: "Singrauli Madhya Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 541,
            SiteCode: null,
            SiteName: "Delhivery Pvt Ltd",
            SiteCity: "Gurgaon",
            SiteAddress: "Gurgaon Haryana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 568,
            SiteCode: null,
            SiteName: "VPR Mining Jayant",
            SiteCity: "Singrauli",
            SiteAddress: "Singrauli Madhya Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 550,
            SiteCode: null,
            SiteName: "Nilkanth Mining, Sulliary",
            SiteCity: "Singrauli",
            SiteAddress: "Singrauli Madhya Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 190,
            SiteCode: null,
            SiteName: "KNIL,Dudhichua",
            SiteCity: "Singrauli",
            SiteAddress: "Singrauli Madhya Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 449,
            SiteCode: null,
            SiteName: "Dilip Buildcon Limited, Nighai OCP",
            SiteCity: "Singrauli",
            SiteAddress: "Singrauli Madhya Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 174,
            SiteCode: null,
            SiteName: "Kataria Carriers,Kanpur",
            SiteCity: "Gurgaon",
            SiteAddress: "Gurgaon Haryana",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 189,
            SiteCode: null,
            SiteName: "Avtar singh, Majhauli",
            SiteCity: "Singrauli",
            SiteAddress: "Singrauli Madhya Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 418,
            SiteCode: null,
            SiteName: "BLA & RA Mining Pvt Ltd",
            SiteCity: "Singrauli",
            SiteAddress: "Singrauli Madhya Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 469,
            SiteCode: null,
            SiteName: "Nilkanth Mining, Ghorbi",
            SiteCity: "Singrauli",
            SiteAddress: "Singrauli Madhya Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 542,
            SiteCode: null,
            SiteName: "Barbarik Project Limited, Amlohri",
            SiteCity: "Singrauli",
            SiteAddress: "Singrauli Madhya Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 401,
            SiteCode: null,
            SiteName: "M/S Kallal Logistics",
            SiteCity: "Jaipur",
            SiteAddress: "Jaipur Rajasthan",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 325,
            SiteCode: null,
            SiteName: "Shree Swaminarayan Infrastructure Pvt Ltd.,Amlohri",
            SiteCity: "Singrauli",
            SiteAddress: "Singrauli Madhya Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 386,
            SiteCode: null,
            SiteName: "Gajraj Amlohri",
            SiteCity: "Singrauli",
            SiteAddress: "Singrauli Madhya Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 547,
            SiteCode: null,
            SiteName: "Ramkripal Singh, Dudhuchuaa",
            SiteCity: "Singrauli",
            SiteAddress: "Singrauli Madhya Pradesh",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
        ],
        lstCustomerPermission: [
          {
            Id: 52,
            FleetSummary: true,
            UptimeUtilization: true,
            TripSummary: true,
            SiteAdvisory: true,
            mining: true,
            bus: true,
            on_road: true,
          },
        ],
        lstApplicationtype: [
          {
            UserId: 0,
            VehicleType: "Bus - Tourist",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - ROAD CONSTRUCTION",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - MINERAL MINING",
          },
          {
            UserId: 0,
            VehicleType: "TRUCK - UTILITY SERVICES",
          },
          {
            UserId: 0,
            VehicleType: "TRUCK - ON ROAD LH",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - COAL TRANSPORTATION",
          },
          {
            UserId: 0,
            VehicleType: "Truck - Special Application",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - QUARRY",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - OB MINING",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - IRRIGATION",
          },
        ],
        lstModelType: [
          {
            UserId: 0,
            Model: "B11R",
          },
          {
            UserId: 0,
            Model: "B8R",
          },
          {
            UserId: 0,
            Model: "B7R",
          },
          {
            UserId: 0,
            Model: "FM9",
          },
          {
            UserId: 0,
            Model: "FM7",
          },
          {
            UserId: 0,
            Model: "B7R MARK 2",
          },
          {
            UserId: 0,
            Model: "FM 420 8X4",
          },
          {
            UserId: 0,
            Model: "FM 480 10X4",
          },
          {
            UserId: 0,
            Model: "FMX 440 8X4",
          },
          {
            UserId: 0,
            Model: "FM 380 8X4",
          },
          {
            UserId: 0,
            Model: "FMX 460 8X4",
          },
          {
            UserId: 0,
            Model: "FMX 480 10X4",
          },
          {
            UserId: 0,
            Model: "FMX 520 10X4",
          },
          {
            UserId: 0,
            Model: "FM 420 6X4",
          },
          {
            UserId: 0,
            Model: "FM 460 6X4",
          },
          {
            UserId: 0,
            Model: "FM 380 4X2",
          },
          {
            UserId: 0,
            Model: "FM 400 8X4",
          },
          {
            UserId: 0,
            Model: "FMX 460 6X4",
          },
          {
            UserId: 0,
            Model: "FM 520 6X4",
          },
          {
            UserId: 0,
            Model: "FM 380 6X4",
          },
          {
            UserId: 0,
            Model: "FM 420 6X6",
          },
          {
            UserId: 0,
            Model: "FH 420 6X4",
          },
          {
            UserId: 0,
            Model: "FH 340 6X4",
          },
          {
            UserId: 0,
            Model: "FM 340 6X4",
          },
          {
            UserId: 0,
            Model: "FH 500 4X2",
          },
          {
            UserId: 0,
            Model: "FM 480 6X4",
          },
          {
            UserId: 0,
            Model: "FMX 480 8X4",
          },
          {
            UserId: 0,
            Model: "FM 480 6X6",
          },
          {
            UserId: 0,
            Model: "FM 440 6X4",
          },
          {
            UserId: 0,
            Model: "FM 300",
          },
          {
            UserId: 0,
            Model: "FH 12 6X4",
          },
          {
            UserId: 0,
            Model: "FM 290",
          },
          {
            UserId: 0,
            Model: "FM 420 4X2",
          },
          {
            UserId: 0,
            Model: "FMX 500 8X4",
          },
          {
            UserId: 0,
            Model: "FM 12 6X4",
          },
          {
            UserId: 0,
            Model: "FH 12 4X2",
          },
          {
            UserId: 0,
            Model: "FM 9 6X4",
          },
          {
            UserId: 0,
            Model: "FH 520 6X4",
          },
          {
            UserId: 0,
            Model: "FM 7 8X4",
          },
          {
            UserId: 0,
            Model: "FM 460 8X4",
          },
          {
            UserId: 0,
            Model: "FM 440 8X4",
          },
          {
            UserId: 0,
            Model: "B9R",
          },
          {
            UserId: 0,
            Model: "FM 400 6X4",
          },
        ],
      },
    },
    {
      value: "Vikash Anand ",
      id: 1700,
      data: {
        UserId: 1700,
        lstUserModel: [
          {
            UserId: 1700,
            RoleId: 0,
            CustomerId: 10546,
            usob_user_id: 0,
            usob_role_id: 0,
            usob_customer_id: 0,
            usob_id: null,
            usob_rid: null,
            usob_cid: null,
            AuthEngineId: null,
            UserName: "vanand1@volvo.in",
            Designation: "Regional Service Manager",
            TimeZoneId: 0,
            Status: null,
            Message: null,
            Token: null,
            FirstName: "Vikash Anand",
            LastName: null,
            RoleName: "User",
            IsDeleted: false,
            Password: null,
            UserMobile: "9830068777",
            UserArea: "West",
            UserRegion: "West",
            CustomerMobile: null,
            CustomerName: "Volvo Trucks India",
            ContactPersonName: "Prathap",
            ContactPersonMobile: "9686794006",
            CustomerRegisterAddress: null,
            IsAdmin: false,
            lstUserModel: null,
            lstSiteDetail: null,
            lstCustomerDetail: null,
            lstCustomerPermission: null,
            lstApplicationtype: null,
            lstModelType: null,
          },
        ],
        lstSiteDetail: [
          {
            UserId: 0,
            SiteId: 460,
            SiteCode: null,
            SiteName: "PC Patel Infra Pvt Ltd, Bhavnagar",
            SiteCity: "Ahmedabad",
            SiteAddress: "Ahmedabad Gujrat",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 465,
            SiteCode: null,
            SiteName: "Bhardwaj Infrastructure Pvt Ltd, Umarsar Kutch",
            SiteCity: "Ahmedabad",
            SiteAddress: "Ahmedabad Gujrat",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 113,
            SiteCode: null,
            SiteName: "DURGA CONSTRUCTIONS COMPANY,Rajpardi",
            SiteCity: "Surat",
            SiteAddress: "Surat Gujrat",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 413,
            SiteCode: null,
            SiteName: "KLP Enterprises,Mata Nu Math",
            SiteCity: "Ahmedabad",
            SiteAddress: "Ahmedabad Gujrat",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 461,
            SiteCode: null,
            SiteName: "HD Enterprise, Vastan",
            SiteCity: "Surat",
            SiteAddress: "Surat Gujrat",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 459,
            SiteCode: null,
            SiteName: "PC Patel Infra Pvt Ltd, Valia",
            SiteCity: "Surat",
            SiteAddress: "Surat Gujrat",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 563,
            SiteCode: null,
            SiteName: "GR Infraprojects Limited, Palghar, Mumbai",
            SiteCity: "Mumbai",
            SiteAddress: "Mumbai Maharastra",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
          {
            UserId: 0,
            SiteId: 564,
            SiteCode: null,
            SiteName: "GR Infraprojects Limited, Tadkeshwar,Surat",
            SiteCity: "Surat",
            SiteAddress: "Surat Gujrat",
            SiteContact: null,
            ActiveSite: false,
            TotalVehicle: 0,
            InActiveVehicle: 0,
            ActiveVehicle: 0,
          },
        ],
        lstCustomerPermission: [
          {
            Id: 94,
            FleetSummary: true,
            UptimeUtilization: true,
            TripSummary: true,
            SiteAdvisory: true,
            mining: true,
            bus: true,
            on_road: true,
          },
        ],
        lstApplicationtype: [
          {
            UserId: 0,
            VehicleType: "Bus - Tourist",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - ROAD CONSTRUCTION",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - MINERAL MINING",
          },
          {
            UserId: 0,
            VehicleType: "TRUCK - UTILITY SERVICES",
          },
          {
            UserId: 0,
            VehicleType: "TRUCK - ON ROAD LH",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - COAL TRANSPORTATION",
          },
          {
            UserId: 0,
            VehicleType: "Truck - Special Application",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - QUARRY",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - OB MINING",
          },
          {
            UserId: 0,
            VehicleType: "TIPPER - IRRIGATION",
          },
        ],
        lstModelType: [
          {
            UserId: 0,
            Model: "B11R",
          },
          {
            UserId: 0,
            Model: "B8R",
          },
          {
            UserId: 0,
            Model: "B7R",
          },
          {
            UserId: 0,
            Model: "FM9",
          },
          {
            UserId: 0,
            Model: "FM7",
          },
          {
            UserId: 0,
            Model: "B7R MARK 2",
          },
          {
            UserId: 0,
            Model: "FM 420 8X4",
          },
          {
            UserId: 0,
            Model: "FM 480 10X4",
          },
          {
            UserId: 0,
            Model: "FMX 440 8X4",
          },
          {
            UserId: 0,
            Model: "FM 380 8X4",
          },
          {
            UserId: 0,
            Model: "FMX 460 8X4",
          },
          {
            UserId: 0,
            Model: "FMX 480 10X4",
          },
          {
            UserId: 0,
            Model: "FMX 520 10X4",
          },
          {
            UserId: 0,
            Model: "FM 420 6X4",
          },
          {
            UserId: 0,
            Model: "FM 460 6X4",
          },
          {
            UserId: 0,
            Model: "FM 380 4X2",
          },
          {
            UserId: 0,
            Model: "FM 400 8X4",
          },
          {
            UserId: 0,
            Model: "FMX 460 6X4",
          },
          {
            UserId: 0,
            Model: "FM 520 6X4",
          },
          {
            UserId: 0,
            Model: "FM 380 6X4",
          },
          {
            UserId: 0,
            Model: "FM 420 6X6",
          },
          {
            UserId: 0,
            Model: "FH 420 6X4",
          },
          {
            UserId: 0,
            Model: "FH 340 6X4",
          },
          {
            UserId: 0,
            Model: "FM 340 6X4",
          },
          {
            UserId: 0,
            Model: "FH 500 4X2",
          },
          {
            UserId: 0,
            Model: "FM 480 6X4",
          },
          {
            UserId: 0,
            Model: "FMX 480 8X4",
          },
          {
            UserId: 0,
            Model: "FM 480 6X6",
          },
          {
            UserId: 0,
            Model: "FM 440 6X4",
          },
          {
            UserId: 0,
            Model: "FM 300",
          },
          {
            UserId: 0,
            Model: "FH 12 6X4",
          },
          {
            UserId: 0,
            Model: "FM 290",
          },
          {
            UserId: 0,
            Model: "FM 420 4X2",
          },
          {
            UserId: 0,
            Model: "FMX 500 8X4",
          },
          {
            UserId: 0,
            Model: "FM 12 6X4",
          },
          {
            UserId: 0,
            Model: "FH 12 4X2",
          },
          {
            UserId: 0,
            Model: "FM 9 6X4",
          },
          {
            UserId: 0,
            Model: "FH 520 6X4",
          },
          {
            UserId: 0,
            Model: "FM 7 8X4",
          },
          {
            UserId: 0,
            Model: "FM 460 8X4",
          },
          {
            UserId: 0,
            Model: "FM 440 8X4",
          },
          {
            UserId: 0,
            Model: "B9R",
          },
          {
            UserId: 0,
            Model: "FM 400 6X4",
          },
        ],
      },
    },
  ];
};
