export const SUBMIT_NEWREQUEST_ACTION_REQUESTED = "SUBMIT_NEWREQUEST_ACTION_REQUESTED";
export const SUBMIT_NEWREQUEST_ACTION_DISPATCHER =
  "SUBMIT_NEWREQUEST_ACTION_DISPATCHER";
export const SUBMIT_NEWREQUEST_ERROR_DISPATCHER = "SUBMIT_NEWREQUEST_ERROR_DISPATCHER";
export const NEWREQUEST_LOADING = "NEWREQUEST_LOADING";

//Action Creator
export const submitNewRequestAction = (data) => ({
  type: SUBMIT_NEWREQUEST_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitNewRequestActionDispatcher = (data) => ({
  type: SUBMIT_NEWREQUEST_ACTION_DISPATCHER,
  payload: data,
});

export const submitNewRequestErrorDispatcher = (data) => ({
  type: SUBMIT_NEWREQUEST_ERROR_DISPATCHER,
  payload: data,
});

export const newRequestLoading = (loading) => ({
  type: NEWREQUEST_LOADING,
  payload: loading,
});
