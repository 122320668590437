export const SUBMIT_UTILIZATION_BY_AGE_ACTION_REQUESTED = "SUBMIT_UTILIZATION_BY_AGE_ACTION_REQUESTED";
export const SUBMIT_UTILIZATION_BY_AGE_ACTION_DISPATCHER = "SUBMIT_UTILIZATION_BY_AGE_ACTION_DISPATCHER";
export const SUBMIT_UTILIZATION_BY_AGE_ERROR_DISPATCHER = "SUBMIT_UTILIZATION_BY_AGE_ERROR_DISPATCHER";
export const UTILIZATION_BY_AGE_LOADING = "UTILIZATION_BY_AGE_LOADING";

//Action Creator
export const submitUtilizationByAgeAction = (data) => ({
  type: SUBMIT_UTILIZATION_BY_AGE_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitUtilizationByAgeActionDispatcher = (data) => ({
  type: SUBMIT_UTILIZATION_BY_AGE_ACTION_DISPATCHER,
  payload: data,
});

export const submitUtilizationByAgeErrorDispatcher = (data) => ({
  type: SUBMIT_UTILIZATION_BY_AGE_ERROR_DISPATCHER,
  payload: data,
});

export const utilizationByAgeLoading = (loading) => ({
  type: UTILIZATION_BY_AGE_LOADING,
  payload: loading,
});

