import {
  OPEN_JOBS_BY_CUSTOMER_COUNTS_LOADING,
  SUBMIT_OPEN_JOBS_BY_CUSTOMER_COUNTS_ACTION_DISPATCHER,
  SUBMIT_OPEN_JOBS_BY_CUSTOMER_COUNTS_ACTION_REQUESTED,
  SUBMIT_OPEN_JOBS_BY_CUSTOMER_COUNTS_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { customerTotal: 0, apiData: {} },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_OPEN_JOBS_BY_CUSTOMER_COUNTS_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_OPEN_JOBS_BY_CUSTOMER_COUNTS_ACTION_DISPATCHER:
      let data = action.payload.data[0];
      let customerSum =
        parseInt(data.CustomerApproval) +
        parseInt(data.CustomerAR) +
        parseInt(data.CustomerManpower) +
        parseInt(data.CustomerToolsEquipments) +
        parseInt(data.CustomerParts) +
        parseInt(data.CustomerRepair) +
        parseInt(data.AccInsuranceSurvey) +
        parseInt(data.AccRepairEstimate);
      data.CustomerTotal = customerSum;
      return {
        ...state,
        successData: {
          apiData: data,
        },
        loading: false,
      };
    case SUBMIT_OPEN_JOBS_BY_CUSTOMER_COUNTS_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case OPEN_JOBS_BY_CUSTOMER_COUNTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
