import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  contactUsLoading,
  submitContactUsActionDispatcher,
  submitContactUsErrorDispatcher,
  SUBMIT_CONTACT_US_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postContactUsAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/ContactUsDetailsCustomerPortal?SiteIds=215&City=Ramagundam",
    {
      ...postData,
    }
  );
  return result;
};

function* submitContactUsSaga({ postData, filterQuery }) {
  try {
    yield put(contactUsLoading(true));
    const data = yield call(postContactUsAPI, postData);
    yield put(submitContactUsActionDispatcher(data.data, filterQuery));
    yield put(submitContactUsErrorDispatcher({}));
    yield put(contactUsLoading(false));
  } catch (e) {
    yield put(submitContactUsActionDispatcher({}));
    yield put(submitContactUsErrorDispatcher(e.response.data));
    yield put(contactUsLoading(false));
  }
}

function* submitContactUsWatcher() {
  yield takeEvery(SUBMIT_CONTACT_US_ACTION_REQUESTED, submitContactUsSaga);
}
export default submitContactUsWatcher;
