import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBWorkshopServicesLoading,
  submitUSOBWorkshopServicesActionDispatcher,
  submitUSOBWorkshopServicesErrorDispatcher,
  SUBMIT_USOB_WORKSHOP_SERVICES_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBWorkshopServicesAPI = async (postData, page) => {
  // console.log("postData: ", postData);
  let apiUrl = "/master-repair";
  if (postData) {
    apiUrl +=
      "?pagination=false" +
      (postData?.date_from
        ? "&repair_start_date_time=" + postData?.date_from
        : "") +
      (postData?.date_to ? "&repair_end_date_time=" + postData?.date_to : "") +
      (postData?.segment ? "&segment_id=" + postData?.segment : "") +
      (postData?.vehicle_type
        ? "&vehicle_type_id=" + postData?.vehicle_type
        : "") +
      (postData?.customer_id ? "&customer_id=" + postData?.customer_id : "");
    // + (page ? "page=" + page : "");
    // } else {
    //   apiUrl += page ? "page=" + page : "";
  }
  const result = await AXIOS_INSTANCE.get(apiUrl, {
    ...postData,
  });
  // console.log("result: ", result);
  return result;
};

function* submitUSOBWorkshopServicesSaga({ postData, filterQuery, page }) {
  try {
    yield put(USOBWorkshopServicesLoading(true));
    const data = yield call(postUSOBWorkshopServicesAPI, postData, page);
    yield put(
      submitUSOBWorkshopServicesActionDispatcher(data.data, filterQuery)
    );
    yield put(submitUSOBWorkshopServicesErrorDispatcher({}));
    yield put(USOBWorkshopServicesLoading(false));
  } catch (e) {
    yield put(submitUSOBWorkshopServicesActionDispatcher({}));
    yield put(
      submitUSOBWorkshopServicesErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(USOBWorkshopServicesLoading(false));
  }
}

function* submitUSOBWorkshopServicesWatcher() {
  yield takeEvery(
    SUBMIT_USOB_WORKSHOP_SERVICES_ACTION_REQUESTED,
    submitUSOBWorkshopServicesSaga
  );
}
export default submitUSOBWorkshopServicesWatcher;
