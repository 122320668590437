import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBWorkshopServiceLoading,
  submitUSOBWorkshopServiceActionDispatcher,
  submitUSOBWorkshopServiceErrorDispatcher,
  SUBMIT_USOB_WORKSHOP_SERVICE_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBWorkshopServiceAPI = async (postData, page) => {
  let apiUrl = "/workshop-service";
  if (postData) {
    apiUrl +=
      "?" +
      (postData.date_from ? "date_from=" + postData.date_from + "&" : "") +
      (postData.date_to ? "date_to=" + postData.date_to + "&" : "") +
      (page ? "page=" + page : "");
  }
  const result = await AXIOS_INSTANCE.get(apiUrl, {
    ...postData,
  })
    // console.log("result: ", result);
  return result ;
};

function* submitUSOBWorkshopServiceSaga({ postData, page }) {
  try {
    yield put(USOBWorkshopServiceLoading(true));
    const data = yield call(postUSOBWorkshopServiceAPI, postData, page);
    yield put(submitUSOBWorkshopServiceActionDispatcher(data.data));
    yield put(submitUSOBWorkshopServiceErrorDispatcher({}));
    yield put(USOBWorkshopServiceLoading(false));
  } catch (e) {
    yield put(submitUSOBWorkshopServiceActionDispatcher({}));
    yield put(
      submitUSOBWorkshopServiceErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(USOBWorkshopServiceLoading(false));
  }
}

function* submitUSOBWorkshopServiceWatcher() {
  yield takeEvery(
    SUBMIT_USOB_WORKSHOP_SERVICE_ACTION_REQUESTED,
    submitUSOBWorkshopServiceSaga
  );
}
export default submitUSOBWorkshopServiceWatcher;
