import {
  HERE_SEARCH_LOADING,
  SUBMIT_HERE_SEARCH_ACTION_DISPATCHER,
  SUBMIT_HERE_SEARCH_ACTION_REQUESTED,
  SUBMIT_HERE_SEARCH_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { searches: [], apiData: {} },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  const searchList = [];
  switch (action.type) {
    case SUBMIT_HERE_SEARCH_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_HERE_SEARCH_ACTION_DISPATCHER:
      let data = action.payload.data;
      // console.log("data: ", data);
      data?.items?.forEach((e) => {
        searchList.push({
          value: e.title,
          id: e.id,
          position: e.position,
        });
      });
      // searchList.sort(function (a, b) {
      //   var x = a.value.toLowerCase();
      //   var y = b.value.toLowerCase();
      //   return x < y ? -1 : x > y ? 1 : 0;
      // });
      return {
        ...state,
        successData: {
          searches: searchList,
          apiData: data.success,
        },
        loading: false,
      };
    case SUBMIT_HERE_SEARCH_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case HERE_SEARCH_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
