import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  customerListLoading,
  submitCustomerListActionDispatcher,
  submitCustomerListErrorDispatcher,
  SUBMIT_CUSTOMER_LIST_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postCustomerListAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post("/CustomerPortal/customerList?SiteId=21", {
    ...postData,
  });
  return result;
};

function* submitCustomerListSaga({ postData }) {
  try {
    yield put(customerListLoading(true));
    const data = yield call(postCustomerListAPI, postData);
    yield put(submitCustomerListActionDispatcher(data));
    yield put(submitCustomerListErrorDispatcher({}));
    yield put(customerListLoading(false));
  } catch (e) {
    yield put(submitCustomerListActionDispatcher({}));
    yield put(
      submitCustomerListErrorDispatcher(e.response.data)
    );
    yield put(customerListLoading(false));
  }
}

function* submitCustomerListWatcher() {
  yield takeEvery(SUBMIT_CUSTOMER_LIST_ACTION_REQUESTED, submitCustomerListSaga);
}
export default submitCustomerListWatcher;
