import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  deviceTrackerLoading,
  submitDeviceTrackerActionDispatcher,
  submitDeviceTrackerErrorDispatcher,
  SUBMIT_DEVICE_TRACKER_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postDeviceTrackerAPI = async (postData) => {
  // console.log('postData: ', postData);
  let apiUrl = "/CustomerPortal/post?Tracking=tt&deviceType=5";
  const result = await AXIOS_INSTANCE.post(apiUrl, {
    Token: "teramatrix",
    UtcMinute: 330,
    UserId: 825,
    DealerNameParam: "All",
    VanCityParam: "All",
    VanStateParam: "All",
    deviceAlias: [],
  });
  // console.log("result: ", result);
  return result;
};

function* submitDeviceTrackerSaga({ postData }) {
  try {
    yield put(deviceTrackerLoading(true));
    const data = yield call(postDeviceTrackerAPI, postData);
    yield put(submitDeviceTrackerActionDispatcher(data.data));
    yield put(submitDeviceTrackerErrorDispatcher({}));
    yield put(deviceTrackerLoading(false));
  } catch (e) {
    yield put(submitDeviceTrackerActionDispatcher({}));
    yield put(submitDeviceTrackerErrorDispatcher(e.response));
    yield put(deviceTrackerLoading(false));
  }
}

function* submitDeviceTrackerWatcher() {
  yield takeEvery(
    SUBMIT_DEVICE_TRACKER_ACTION_REQUESTED,
    submitDeviceTrackerSaga
  );
}
export default submitDeviceTrackerWatcher;
