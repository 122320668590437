import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  openJobsByCustomerCountsLoading,
  submitOpenJobsByCustomerCountsActionDispatcher,
  submitOpenJobsByCustomerCountsErrorDispatcher,
  SUBMIT_OPEN_JOBS_BY_CUSTOMER_COUNTS_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postOpenJobsByCustomerCountsAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/OpenJobsbyCustomerCounts?Value=54&openjobscount=hrd&customercount=uss",
    {
      ...postData,
    }
  );
  return result;
};

function* submitOpenJobsByCustomerCountsSaga({ postData }) {
  try {
    yield put(openJobsByCustomerCountsLoading(true));
    const data = yield call(postOpenJobsByCustomerCountsAPI, postData);
    yield put(submitOpenJobsByCustomerCountsActionDispatcher(data));
    yield put(submitOpenJobsByCustomerCountsErrorDispatcher({}));
    yield put(openJobsByCustomerCountsLoading(false));
  } catch (e) {
    yield put(submitOpenJobsByCustomerCountsActionDispatcher({}));
    yield put(
      submitOpenJobsByCustomerCountsErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(openJobsByCustomerCountsLoading(false));
  }
}

function* submitOpenJobsByCustomerCountsWatcher() {
  yield takeEvery(
    SUBMIT_OPEN_JOBS_BY_CUSTOMER_COUNTS_ACTION_REQUESTED,
    submitOpenJobsByCustomerCountsSaga
  );
}
export default submitOpenJobsByCustomerCountsWatcher;
