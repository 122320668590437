import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  tripCountLoading,
  submitTripCountActionDispatcher,
  submitTripCountErrorDispatcher,
  SUBMIT_TRIP_COUNT_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postTripCountAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post("/CustomerPortal/TripCountDetail?tripcount=12", {
    ...postData,
  });
  return result;
};

function* submitTripCountSaga({ postData }) {
  try {
    yield put(tripCountLoading(true));
    const data = yield call(postTripCountAPI, postData);
    yield put(submitTripCountActionDispatcher(data));
    yield put(submitTripCountErrorDispatcher({}));
    yield put(tripCountLoading(false));
  } catch (e) {
    yield put(submitTripCountActionDispatcher({}));
    yield put(
      submitTripCountErrorDispatcher(e)
    );
    yield put(tripCountLoading(false));
  }
}

function* submitTripCountWatcher() {
  yield takeEvery(SUBMIT_TRIP_COUNT_ACTION_REQUESTED, submitTripCountSaga);
}
export default submitTripCountWatcher;
