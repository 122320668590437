import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  utilizationByModelLoading,
  submitUtilizationByModelActionDispatcher,
  submitUtilizationByModelErrorDispatcher,
  SUBMIT_UTILIZATION_BY_MODEL_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUtilizationByModelAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post("/CustomerPortal/Post?Uptime=avf&p1=xyz&UtilizationModel=hgf&Graph=5", {
    ...postData,
  });
  return result;
};

function* submitUtilizationByModelSaga({ postData }) {
  try {
    yield put(utilizationByModelLoading(true));
    const data = yield call(postUtilizationByModelAPI, postData);
    yield put(submitUtilizationByModelActionDispatcher(data));
    yield put(submitUtilizationByModelErrorDispatcher({}));
    yield put(utilizationByModelLoading(false));
  } catch (e) {
    yield put(submitUtilizationByModelActionDispatcher({}));
    yield put(
      submitUtilizationByModelErrorDispatcher(e.response.data)
    );
    yield put(utilizationByModelLoading(false));
  }
}

function* submitUtilizationByModelWatcher() {
  yield takeEvery(SUBMIT_UTILIZATION_BY_MODEL_ACTION_REQUESTED, submitUtilizationByModelSaga);
}
export default submitUtilizationByModelWatcher;
