import moment from "moment";
import {
  UTILIZATION_BY_MONTH_LOADING,
  SUBMIT_UTILIZATION_BY_MONTH_ACTION_DISPATCHER,
  SUBMIT_UTILIZATION_BY_MONTH_ACTION_REQUESTED,
  SUBMIT_UTILIZATION_BY_MONTH_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { chartData: [], excelData: [], apiData: {} },
  failureData: {},
  loading: false,
  isLogout: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_UTILIZATION_BY_MONTH_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_UTILIZATION_BY_MONTH_ACTION_DISPATCHER:
      const tempArr = [["Month/Year", "Percentage", { role: "annotation" }]];
      const tempExcelArr = [["Month/Year", "Percentage"]];
      action.payload.data.forEach((e) => {
        tempArr.push([
          moment(e.MonthYear).format("MMM-YY"),
          parseFloat(e.Percentage.toFixed(1)),
          `${e.Percentage.toFixed()}`,
        ]);
        tempExcelArr.push([
          moment(e.MonthYear).format("MMM-YY"),
          parseFloat(e.Percentage.toFixed(1)),
        ]);
      });
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const sorter = (a, b) => {
        a = a[0].toString().split("/");
        b = b[0].toString().split("/");
        if (a[1] !== b[1]) {
          return a[1] - b[1];
        } else {
          return months.indexOf(a[0]) - months.indexOf(b[0]);
        }
      };
      tempArr.sort(sorter);
      tempExcelArr.sort(sorter);
      // tempArr.sort(function (a, b) {
      //   return a[1] - b[1] || a[0] - b[0];
      // });
      return {
        ...state,
        successData: { chartData: tempArr, excelData: tempExcelArr, apiData: action.payload.data },
        loading: false,
      };
    case SUBMIT_UTILIZATION_BY_MONTH_ERROR_DISPATCHER:
      return {
        ...state,
        formError: action.payload,
        loading: false,
      };
    case UTILIZATION_BY_MONTH_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
