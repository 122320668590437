export const SUBMIT_NEWS_ACTION_REQUESTED = "SUBMIT_NEWS_ACTION_REQUESTED";
export const SUBMIT_NEWS_ACTION_DISPATCHER = "SUBMIT_NEWS_ACTION_DISPATCHER";
export const SUBMIT_NEWS_ERROR_DISPATCHER = "SUBMIT_NEWS_ERROR_DISPATCHER";
export const NEWS_LOADING = "NEWS_LOADING";

//Action Creator
export const submitNewsAction = (data, filters) => ({
  type: SUBMIT_NEWS_ACTION_REQUESTED,
  postData: data,
  filterQuery: filters,
});

//Action Dispatcher
export const submitNewsActionDispatcher = (data, filters) => {
  // console.log("data: ", data);
  // console.log("actionfilters: ", filters);
  let filteredData = filters?.search
    ? data?.data?.success?.news?.filter((item) =>
        item.news_title.toLowerCase().includes(filters?.search?.toLowerCase())
      )
    : data?.data?.success?.news;
  filteredData?.sort((a, b) => {
    return (
      new Date(b.modified_time).getTime() - new Date(a.modified_time).getTime()
    );
  });
  return {
    type: SUBMIT_NEWS_ACTION_DISPATCHER,
    payload: data,
    filteredData: filteredData,
  };
};

export const submitNewsErrorDispatcher = (data) => ({
  type: SUBMIT_NEWS_ERROR_DISPATCHER,
  payload: data,
});

export const newsLoading = (loading) => ({
  type: NEWS_LOADING,
  payload: loading,
});
