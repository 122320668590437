import {
  USOB_VEHICLE_GROUPS_LOADING,
  SUBMIT_USOB_VEHICLE_GROUPS_ACTION_DISPATCHER,
  SUBMIT_USOB_VEHICLE_GROUPS_ACTION_REQUESTED,
  SUBMIT_USOB_VEHICLE_GROUPS_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    vehicleGroups: [],
    vehicleGrpFilter: [],
    perPage: 5,
    currPage: 1,
    totalRows: 1,
    apiData: {},
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  const vehicleGroupList = [];
  switch (action.type) {
    case SUBMIT_USOB_VEHICLE_GROUPS_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_USOB_VEHICLE_GROUPS_ACTION_DISPATCHER:
      let data = action.payload;
      let meta = action.payload.data.success?.meta;
      let tempVehicleGrp = [];

      data?.data?.success?.data.forEach((e) => {
        tempVehicleGrp.push({
          value: e.name,
          text: e.name,
        });
        vehicleGroupList.push({
          value: e.name,
          id: e.id,
          total_vehicles: e.total_vehicles,
        });
      });
      // console.log("vehicleGroupList: ", vehicleGroupList);
      return {
        ...state,
        successData: {
          apiData: data?.data?.success,
          vehicleGroups: vehicleGroupList,
          vehicleGrpFilter: [
            ...new Map(
              tempVehicleGrp.map((item) => [item["value"], item])
            ).values(),
          ],
          total: parseInt(meta?.pagination.total) || 1,
          currPage: parseInt(meta?.pagination.current_page) || 1,
          pageSize: parseInt(meta?.pagination.per_page) || 10,
        },
        loading: false,
      };
    case SUBMIT_USOB_VEHICLE_GROUPS_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case USOB_VEHICLE_GROUPS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
