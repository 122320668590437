export const SUBMIT_TRIP_BY_YEAR_ACTION_REQUESTED =
  "SUBMIT_TRIP_BY_YEAR_ACTION_REQUESTED";
export const SUBMIT_TRIP_BY_YEAR_ACTION_DISPATCHER =
  "SUBMIT_TRIP_BY_YEAR_ACTION_DISPATCHER";
export const SUBMIT_TRIP_BY_YEAR_ERROR_DISPATCHER =
  "SUBMIT_TRIP_BY_YEAR_ERROR_DISPATCHER";
export const TRIP_BY_YEAR_LOADING = "TRIP_BY_YEAR_LOADING";

//Action Creator
export const submitTripByYearAction = (data) => ({
  type: SUBMIT_TRIP_BY_YEAR_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitTripByYearActionDispatcher = (data) => ({
  type: SUBMIT_TRIP_BY_YEAR_ACTION_DISPATCHER,
  payload: data,
});

export const submitTripByYearErrorDispatcher = (data) => ({
  type: SUBMIT_TRIP_BY_YEAR_ERROR_DISPATCHER,
  payload: data,
});

export const tripByYearLoading = (loading) => ({
  type: TRIP_BY_YEAR_LOADING,
  payload: loading,
});
