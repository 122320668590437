export const SUBMIT_MODELS_ACTION_REQUESTED = "SUBMIT_MODELS_ACTION_REQUESTED";
export const SUBMIT_MODELS_ACTION_DISPATCHER =
  "SUBMIT_MODELS_ACTION_DISPATCHER";
export const SUBMIT_MODELS_ERROR_DISPATCHER = "SUBMIT_MODELS_ERROR_DISPATCHER";
export const MODELS_LOADING = "MODELS_LOADING";

//Action Creator
export const submitModelsAction = (data) => ({
  type: SUBMIT_MODELS_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitModelsActionDispatcher = (data) => ({
  type: SUBMIT_MODELS_ACTION_DISPATCHER,
  payload: data,
});

export const submitModelsErrorDispatcher = (data) => ({
  type: SUBMIT_MODELS_ERROR_DISPATCHER,
  payload: data,
});

export const modelsLoading = (loading) => ({
  type: MODELS_LOADING,
  payload: loading,
});
