import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  customerListUSOBLoading,
  submitCustomerListUSOBActionDispatcher,
  submitCustomerListUSOBErrorDispatcher,
  SUBMIT_CUSTOMER_LIST_USOB_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postCustomerListUSOBAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post("/CustomerPortal/USOBcustomerList?NotAdmin=true", {
    ...postData,
  });
  return result;
};

function* submitCustomerListUSOBSaga({ postData }) {
  try {
    yield put(customerListUSOBLoading(true));
    const data = yield call(postCustomerListUSOBAPI, postData);
    yield put(submitCustomerListUSOBActionDispatcher(data));
    yield put(submitCustomerListUSOBErrorDispatcher({}));
    yield put(customerListUSOBLoading(false));
  } catch (e) {
    yield put(submitCustomerListUSOBActionDispatcher({}));
    yield put(
      submitCustomerListUSOBErrorDispatcher(e.response.data)
    );
    yield put(customerListUSOBLoading(false));
  }
}

function* submitCustomerListUSOBWatcher() {
  yield takeEvery(SUBMIT_CUSTOMER_LIST_USOB_ACTION_REQUESTED, submitCustomerListUSOBSaga);
}
export default submitCustomerListUSOBWatcher;
