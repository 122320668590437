//Action Types
export const SUBMIT_USOB_REGISTER_ACTION_SERVICE_ACTION_REQUESTED =
  "SUBMIT_USOB_REGISTER_ACTION_SERVICE_ACTION_REQUESTED";
export const SUBMIT_USOB_REGISTER_ACTION_SERVICE_ACTION_DISPATCHER =
  "SUBMIT_USOB_REGISTER_ACTION_SERVICE_ACTION_DISPATCHER";
export const SUBMIT_USOB_REGISTER_ACTION_SERVICE_ERROR_DISPATCHER =
  "SUBMIT_USOB_REGISTER_ACTION_SERVICE_ERROR_DISPATCHER";
export const SUBMIT_EMPTY_USOB_REGISTER_ACTION_SERVICE_STATE_ACTION =
  "SUBMIT_EMPTY_USOB_REGISTER_ACTION_SERVICE_STATE_ACTION";
export const USOB_REGISTER_ACTION_SERVICE_LOADING =
  "USOB_REGISTER_ACTION_SERVICE_LOADING";

//Action Creator
export const submitUSOBRegisterActionServiceAction = (data) => ({
  type: SUBMIT_USOB_REGISTER_ACTION_SERVICE_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitUSOBRegisterActionServiceActionDispatcher = (data) => ({
  type: SUBMIT_USOB_REGISTER_ACTION_SERVICE_ACTION_DISPATCHER,
  payload: data,
});

//Error Dispatcher
export const submitUSOBRegisterActionServiceErrorDispatcher = (data) => ({
  type: SUBMIT_USOB_REGISTER_ACTION_SERVICE_ERROR_DISPATCHER,
  payload: data,
});

//Loading Action
export const USOBRegisterActionServiceLoading = (loading) => ({
  type: USOB_REGISTER_ACTION_SERVICE_LOADING,
  payload: loading,
});

export const submitEmptyUSOBRegisterActionServiceStateAction = () => ({
  type: SUBMIT_EMPTY_USOB_REGISTER_ACTION_SERVICE_STATE_ACTION,
});
