import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBServiceRequestsLoading,
  submitUSOBServiceRequestsActionDispatcher,
  submitUSOBServiceRequestsErrorDispatcher,
  SUBMIT_USOB_SERVICE_REQUESTS_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBServiceRequestsAPI = async (postData, page) => {
  // console.log('postData: ', postData);
  let apiUrl = "/action-service?pagination=false";
  if (postData) {
    apiUrl +=
      (postData.date_from ? "&date_from=" + postData.date_from : "") +
      (postData.date_to ? "&date_to=" + postData.date_to : "");
    // + (page ? "page=" + page : "");
  }
  const result = await AXIOS_INSTANCE.get(apiUrl, {
    ...postData,
  });
  // console.log("result: ", result);
  return result;
};

function* submitUSOBServiceRequestsSaga({ postData, page }) {
  try {
    yield put(USOBServiceRequestsLoading(true));
    const data = yield call(postUSOBServiceRequestsAPI, postData, page);
    yield put(submitUSOBServiceRequestsActionDispatcher(data.data));
    yield put(submitUSOBServiceRequestsErrorDispatcher({}));
    yield put(USOBServiceRequestsLoading(false));
  } catch (e) {
    yield put(submitUSOBServiceRequestsActionDispatcher({}));
    yield put(
      submitUSOBServiceRequestsErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(USOBServiceRequestsLoading(false));
  }
}

function* submitUSOBServiceRequestsWatcher() {
  yield takeEvery(
    SUBMIT_USOB_SERVICE_REQUESTS_ACTION_REQUESTED,
    submitUSOBServiceRequestsSaga
  );
}
export default submitUSOBServiceRequestsWatcher;
