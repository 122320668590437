import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  prodBrochureLoading,
  submitProdBrochureActionDispatcher,
  submitProdBrochureErrorDispatcher,
  SUBMIT_PROD_BROCHURE_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBSegmentAPI = async (postData) => {
  // console.log("postData: ", postData);
  let apiUrl = "/product-brochure";
  if (postData) {
    apiUrl +=
      "?" +
      (postData.status ? "status=" + postData.status + "&" : "") +
      (postData.category
        ? "category=" + postData.category + "&"
        : "");
  }

  const result = await AXIOS_INSTANCE.get(apiUrl.slice(0, -1));
  // console.log("result: ", result);
  return result;
};

function* submitUSOBSegmentSaga({ postData }) {
  try {
    yield put(prodBrochureLoading(true));
    const data = yield call(postUSOBSegmentAPI, postData);
    yield put(submitProdBrochureActionDispatcher(data));
    yield put(submitProdBrochureErrorDispatcher({}));
    yield put(prodBrochureLoading(false));
  } catch (e) {
    yield put(submitProdBrochureActionDispatcher({}));
    yield put(
      submitProdBrochureErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(prodBrochureLoading(false));
  }
}

function* submitUSOBSegmentWatcher() {
  yield takeEvery(SUBMIT_PROD_BROCHURE_ACTION_REQUESTED, submitUSOBSegmentSaga);
}
export default submitUSOBSegmentWatcher;
