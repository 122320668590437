//Action Types
export const SUBMIT_REGISTER_COMPLAINT_ACTION_REQUESTED =
  "SUBMIT_REGISTER_COMPLAINT_ACTION_REQUESTED";
export const SUBMIT_REGISTER_COMPLAINT_ACTION_DISPATCHER =
  "SUBMIT_REGISTER_COMPLAINT_ACTION_DISPATCHER";
export const SUBMIT_REGISTER_COMPLAINT_ERROR_DISPATCHER =
  "SUBMIT_REGISTER_COMPLAINT_ERROR_DISPATCHER";
export const SUBMIT_EMPTY_REGISTER_COMPLAINT_STATE_ACTION =
  "SUBMIT_EMPTY_REGISTER_COMPLAINT_STATE_ACTION";
export const REGISTER_COMPLAINT_LOADING = "REGISTER_COMPLAINT_LOADING";

//Action Creator
export const submitRegisterComplaintAction = (data) => ({
  type: SUBMIT_REGISTER_COMPLAINT_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitRegisterComplaintActionDispatcher = (data) => ({
  type: SUBMIT_REGISTER_COMPLAINT_ACTION_DISPATCHER,
  payload: data,
});

//Error Dispatcher
export const submitRegisterComplaintErrorDispatcher = (data) => ({
  type: SUBMIT_REGISTER_COMPLAINT_ERROR_DISPATCHER,
  payload: data,
});

//Loading Action
export const registerComplaintLoading = (loading) => ({
  type: REGISTER_COMPLAINT_LOADING,
  payload: loading,
});

export const submitEmptyRegisterComplaintStateAction = () => ({
  type: SUBMIT_EMPTY_REGISTER_COMPLAINT_STATE_ACTION,
});
