import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  createVehicleGroupLoading,
  submitCreateVehicleGroupActionDispatcher,
  submitCreateVehicleGroupErrorDispatcher,
  SUBMIT_CREATE_VEHICLE_GROUP_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postCreateVehicleGroupAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/Insertgroupmaster?groupname=" +
      postData?.name +
      "&userid=" +
      postData?.user_id
  );
  return result;
};

function* submitCreateVehicleGroupSaga({ postData }) {
  try {
    yield put(createVehicleGroupLoading(true));
    const data = yield call(postCreateVehicleGroupAPI, postData);
    yield put(submitCreateVehicleGroupActionDispatcher(data));
    yield put(submitCreateVehicleGroupErrorDispatcher({}));
    yield put(createVehicleGroupLoading(false));
  } catch (e) {
    yield put(submitCreateVehicleGroupActionDispatcher({}));
    yield put(submitCreateVehicleGroupErrorDispatcher(e));
    yield put(createVehicleGroupLoading(false));
  }
}

function* submitCreateVehicleGroupWatcher() {
  yield takeEvery(
    SUBMIT_CREATE_VEHICLE_GROUP_ACTION_REQUESTED,
    submitCreateVehicleGroupSaga
  );
}
export default submitCreateVehicleGroupWatcher;
