import moment from "moment";
import { getFormattedDate } from "../../utils/Helpers";
import {
  OPEN_JOBS_BY_CUSTOMER_AND_DATE_LOADING,
  SUBMIT_OPEN_JOBS_BY_CUSTOMER_AND_DATE_ACTION_DISPATCHER,
  SUBMIT_OPEN_JOBS_BY_CUSTOMER_AND_DATE_ACTION_REQUESTED,
  SUBMIT_OPEN_JOBS_BY_CUSTOMER_AND_DATE_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    openJobsDetailsList: [],
    openJobsDetailsMobList: [[]],
    excelData: [],
    siteFilters: [],
    areaFilters: [],
    chassisFilters: [],
    jobCategoryFilters: [],
    apiData: {},
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  let tempExcelArr = [
    [
      "Chassis Number",
      "Door Number",
      "Site Name",
      "Area",
      "Job Category",
      "Concern Description",
      "Pending Scince Date/Days",
    ],
  ];
  switch (action.type) {
    case SUBMIT_OPEN_JOBS_BY_CUSTOMER_AND_DATE_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_OPEN_JOBS_BY_CUSTOMER_AND_DATE_ACTION_DISPATCHER:
      let mobList = [];
      for (let i = 0; i < action.payload.length; i += 5) {
        mobList.push(action.payload.slice(i, i + 5));
      }
      action.payload.forEach((e) => {
        tempExcelArr.push([
          e.ChassisNumber,
          e.DoorNumber,
          e.SiteName,
          e.area,
          e.StatusName,
          e.Message,
          `${getFormattedDate(e.StartDate)}  ${moment().diff(
            e.StartDate,
            "days"
          )} Days `,
        ]);
      });
      return {
        ...state,
        successData: {
          openJobsDetailsList: action.payload,
          openJobsDetailsMobList: mobList,
          excelData: tempExcelArr,
          siteFilters: action.siteFilter,
          areaFilters: action.areaFilter,
          chassisFilters: action.chassisFilter,
          jobCategoryFilters: action.jobCategoryFilter,
          apiData: action.payload,
        },
        loading: false,
      };
    case SUBMIT_OPEN_JOBS_BY_CUSTOMER_AND_DATE_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case OPEN_JOBS_BY_CUSTOMER_AND_DATE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
