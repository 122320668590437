import {
  UTILIZATION_BY_AGE_LOADING,
  SUBMIT_UTILIZATION_BY_AGE_ACTION_DISPATCHER,
  SUBMIT_UTILIZATION_BY_AGE_ACTION_REQUESTED,
  SUBMIT_UTILIZATION_BY_AGE_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { chartData: [], excelData: [], chartTicks: [], apiData: {} },
  failureData: {},
  loading: false,
  isLogout: false,
};

const reducer = (state = initialState, action) => {
  let maxAge = 0;
  let minAge = 25;
  switch (action.type) {
    case SUBMIT_UTILIZATION_BY_AGE_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_UTILIZATION_BY_AGE_ACTION_DISPATCHER:
      const tempArr = [
        [
          "Age Band",
          "Percentage",
          { role: "annotation" },
          { role: "tooltip", type: "string", p: { html: true } },
        ],
      ];
      const tempExcelArr = [
        [
          "From Date",
          JSON.parse(localStorage.getItem("fleetFilters")).FDate,
          "",
        ],
        [
          "To Date",
          JSON.parse(localStorage.getItem("fleetFilters")).TDate,
          "",
        ],
        ["", "", ""],
        ["Age Band", "Percentage", "Vehicle Count"]];
      action.payload.data.forEach((e) => {
        maxAge = maxAge < e.AgeBand ? e.AgeBand : maxAge;
        minAge = minAge > e.AgeBand ? e.AgeBand : minAge;
      });
      let avgOfAbove8 = 0;
      let vehCountOfAbove8 = 0;
      let numOfRec = 0;
      let ticksArr = [];
      if (maxAge > 8 && minAge < 5) {
        //All Agebands
        action.payload.data.forEach((e) => {
          if (e.AgeBand <= 8) {
            tempArr.push([
              `${e.AgeBand}`,
              parseFloat(e.Percentage.toFixed(1)),
              parseFloat(e.Percentage.toFixed(1)),
              `<center style="padding:5px; width:100px;">Age Band : <b style="font-size:15px;">${`${e.AgeBand}`}</b><br /> %age : <b style="font-size:15px;">${parseFloat(
                e.Percentage.toFixed(1)
              )}</b>%<br /> Veh.# : <b style="font-size:15px;">${parseFloat(
                e.VehicleCount.toFixed(1)
              )}</b></center>`,
            ]);
            tempExcelArr.push([
              `${e.AgeBand}`,
              parseFloat(e.Percentage.toFixed(1)),
              parseFloat(e.VehicleCount.toFixed(1)),
            ]);
            ticksArr.push({ v: e.AgeBand, f: `${e.AgeBand}` });
          } else {
            avgOfAbove8 += e.Percentage;
            vehCountOfAbove8 += e.VehicleCount;
            numOfRec += 1;
          }
        });
        avgOfAbove8 = avgOfAbove8 / numOfRec; //Avg calculation
        tempArr.push([
          //pushing avg
          "Above 8",
          parseFloat(avgOfAbove8.toFixed(1)),
          parseFloat(avgOfAbove8.toFixed(1)),
          `<center style="padding:5px; width:100px;">Age Band : <b style="font-size:15px;">Above 8
    </b><br /> %age : <b style="font-size:15px;">${parseFloat(
      avgOfAbove8.toFixed(1)
    )}</b>%<br /> Veh.# : <b style="font-size:15px;">${parseFloat(
            vehCountOfAbove8.toFixed(1)
          )}</b></center>`,
        ]);
        tempExcelArr.push([
          "Above 8",
          parseFloat(avgOfAbove8.toFixed(1)),
          parseFloat(vehCountOfAbove8.toFixed(1)),
        ]);
        ticksArr.push({ v: 9, f: `9=<` });
        maxAge = 9;
      } else {
        action.payload.data.forEach((e) => {
          tempArr.push([
            e.AgeBand,
            parseFloat(e.Percentage.toFixed(1)),
            parseFloat(e.Percentage.toFixed(1)),
            `<center style="padding:5px; width:100px;">Age Band : <b style="font-size:15px;">${
              e.AgeBand
            }</b><br /> %age : <b style="font-size:15px;">${parseFloat(
              e.Percentage.toFixed(1)
            )}</b>%<br /> Veh.# : <b style="font-size:15px;">${parseFloat(
              e.VehicleCount.toFixed(1)
            )}</b></center>`,
          ]);
          tempExcelArr.push([
            `${e.AgeBand}`,
            parseFloat(e.Percentage.toFixed(1)),
            parseFloat(e.VehicleCount.toFixed(1)),
          ]);
        });
        for (let i = minAge - 1; i <= maxAge + 1; i++) {
          ticksArr.push(i);
        }
      }
      return {
        ...state,
        successData: {
          chartData: tempArr,
          excelData: tempExcelArr,
          chartTicks: ticksArr,
          apiData: action.payload.data,
        },
        loading: false,
      };
    case SUBMIT_UTILIZATION_BY_AGE_ERROR_DISPATCHER:
      return {
        ...state,
        formError: action.payload,
        loading: false,
      };
    case UTILIZATION_BY_AGE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
