export const SUBMIT_CITIES_ACTION_REQUESTED = "SUBMIT_CITIES_ACTION_REQUESTED";
export const SUBMIT_CITIES_ACTION_DISPATCHER =
  "SUBMIT_CITIES_ACTION_DISPATCHER";
export const SUBMIT_CITIES_ERROR_DISPATCHER = "SUBMIT_CITIES_ERROR_DISPATCHER";
export const CITIES_LOADING = "CITIES_LOADING";

//Action Creator
export const submitCitiesAction = (data) => ({
  type: SUBMIT_CITIES_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitCitiesActionDispatcher = (data) => ({
  type: SUBMIT_CITIES_ACTION_DISPATCHER,
  payload: data,
});

export const submitCitiesErrorDispatcher = (data) => ({
  type: SUBMIT_CITIES_ERROR_DISPATCHER,
  payload: data,
});

export const citiesLoading = (loading) => ({
  type: CITIES_LOADING,
  payload: loading,
});
