import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  insertUpdateShiftLoading,
  submitInsertUpdateShiftActionDispatcher,
  submitInsertUpdateShiftErrorDispatcher,
  SUBMIT_INSERT_UPDATE_SHIFT_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postInsertUpdateShiftAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/ShiftTimeInsert?createdetail=abc",
    { ...postData }
  );
  return result;
};

function* submitInsertUpdateShiftSaga({ postData }) {
  try {
    yield put(insertUpdateShiftLoading(true));
    const data = yield call(postInsertUpdateShiftAPI, postData);
    yield put(submitInsertUpdateShiftActionDispatcher(data));
    yield put(submitInsertUpdateShiftErrorDispatcher({}));
    yield put(insertUpdateShiftLoading(false));
  } catch (e) {
    yield put(submitInsertUpdateShiftActionDispatcher({}));
    yield put(submitInsertUpdateShiftErrorDispatcher(e));
    yield put(insertUpdateShiftLoading(false));
  }
}

function* submitInsertUpdateShiftWatcher() {
  yield takeEvery(
    SUBMIT_INSERT_UPDATE_SHIFT_ACTION_REQUESTED,
    submitInsertUpdateShiftSaga
  );
}
export default submitInsertUpdateShiftWatcher;
