import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  tripByMonthLoading,
  submitTripByMonthActionDispatcher,
  submitTripByMonthErrorDispatcher,
  SUBMIT_TRIP_BY_MONTH_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postTripByMonthAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/TripCountDayWiseDetail?tripday=25",
    {
      ...postData,
    }
  );
  return result;
};

function* submitTripByMonthSaga({ postData }) {
  try {
    yield put(tripByMonthLoading(true));
    const data = yield call(postTripByMonthAPI, postData);
    yield put(submitTripByMonthActionDispatcher(data));
    yield put(submitTripByMonthErrorDispatcher({}));
    yield put(tripByMonthLoading(false));
  } catch (e) {
    yield put(submitTripByMonthActionDispatcher({}));
    yield put(submitTripByMonthErrorDispatcher(e));
    yield put(tripByMonthLoading(false));
  }
}

function* submitTripByMonthWatcher() {
  yield takeEvery(SUBMIT_TRIP_BY_MONTH_ACTION_REQUESTED, submitTripByMonthSaga);
}
export default submitTripByMonthWatcher;
