//Action Types
export const SUBMIT_CHANGEPASSWORD_ACTION_REQUESTED =
  "SUBMIT_CHANGEPASSWORD_ACTION_REQUESTED";
export const SUBMIT_CHANGEPASSWORD_ACTION_DISPATCHER =
  "SUBMIT_CHANGEPASSWORD_ACTION_DISPATCHER";
export const SUBMIT_CHANGEPASSWORD_ERROR_DISPATCHER =
  "SUBMIT_CHANGEPASSWORD_ERROR_DISPATCHER";
export const SUBMIT_EMPTYCHANGEPASSWORDSTATE_ERROR_DISPATCHER =
  "SUBMIT_EMPTYCHANGEPASSWORDSTATE_ERROR_DISPATCHER";
export const CHANGEPASSWORD_LOADING = "CHANGEPASSWORD_LOADING";

//Action Creator
export const submitChangePasswordAction = (data) => ({
  type: SUBMIT_CHANGEPASSWORD_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitChangePasswordActionDispatcher = (data) => ({
  type: SUBMIT_CHANGEPASSWORD_ACTION_DISPATCHER,
  payload: data,
});

//Error Dispatcher
export const submitChangePasswordErrorDispatcher = (data) => ({
  type: SUBMIT_CHANGEPASSWORD_ERROR_DISPATCHER,
  payload: data,
});

//Loading Action
export const changePasswordLoading = (loading) => ({
  type: CHANGEPASSWORD_LOADING,
  payload: loading,
});

export const submitEmptyChangePasswordStateAction = () => ({
  type: SUBMIT_EMPTYCHANGEPASSWORDSTATE_ERROR_DISPATCHER,
});
