export const SUBMIT_SITES_ACTION_REQUESTED = "SUBMIT_SITES_ACTION_REQUESTED";
export const SUBMIT_SITES_ACTION_DISPATCHER = "SUBMIT_SITES_ACTION_DISPATCHER";
export const SUBMIT_SITES_ERROR_DISPATCHER = "SUBMIT_SITES_ERROR_DISPATCHER";
export const SITES_SEARCH = "SITES_SEARCH";
export const SITES_LOADING = "SITES_LOADING";
export const CITY_BASED_SITES = "CITY_BASED_SITES";

//Action Creator
export const submitSitesAction = (data, query) => ({
  type: SUBMIT_SITES_ACTION_REQUESTED,
  postData: data,
  filterQuery: query,
});

//Action Dispatcher
export const submitSitesActionDispatcher = (data, query) => ({
  type: SUBMIT_SITES_ACTION_DISPATCHER,
  payload: data,
  filteredData: query
    ? data.filter((site) =>
        site.SiteName.toLowerCase().includes(query.toLowerCase())
      )
    : data,
});

export const submitSitesErrorDispatcher = (data) => ({
  type: SUBMIT_SITES_ERROR_DISPATCHER,
  payload: data,
});

export const sitesLoading = (loading) => ({
  type: SITES_LOADING,
  payload: loading,
});

export const sitesSearch = (data, query) => ({
  type: SITES_SEARCH,
  payload: data,
  filteredData: query
    ? data.filter((site) =>
        site.SiteName.toLowerCase().includes(query.toLowerCase())
      )
    : data,
});

export const cityBasedSites = (data, query) => ({
  type: CITY_BASED_SITES,
  payload: data,
  filteredData: query
    ? data.filter((site) =>
        site.SiteCity.toLowerCase().includes(query.toLowerCase())
      )
    : data,
});
