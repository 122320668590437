import {
  getAdminAuthUserDetails,
  getFormattedDate,
  getFormattedTime,
} from "../../utils/Helpers";
import {
  USOB_SERVICE_REQUESTS_LOADING,
  SUBMIT_USOB_SERVICE_REQUESTS_ACTION_DISPATCHER,
  SUBMIT_USOB_SERVICE_REQUESTS_ACTION_REQUESTED,
  SUBMIT_USOB_SERVICE_REQUESTS_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    data: [],
    total: 1,
    currPage: 1,
    pageSize: 10,
    excelData: [],
    apiData: {},
    regNoFilter: [],
    modelNoFilter: [],
    customerFilter: [],
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  // let mobList = [];
  switch (action.type) {
    case SUBMIT_USOB_SERVICE_REQUESTS_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_USOB_SERVICE_REQUESTS_ACTION_DISPATCHER:
      let data = action.payload;
      let exl = [
        [
          getAdminAuthUserDetails().usob_customer_id === 0 ? "Customer" : "",
          "Date",
          "Time",
          "Registration Number",
          "Model Number",
          "Breakdown Location",
          "Vehicle Group",
        ],
      ];
      data.success?.data.forEach((e) => {
        exl.push([
          getAdminAuthUserDetails().usob_customer_id === 0 ? e.owner_name : "",
          getFormattedDate(e.created_at),
          getFormattedTime(e.created_at),
          `${e.registration_number_name}`,
          `${e.model_name}`,
          e.breakdown_location,
          e.vehicle_group || "",
        ]);
      });
      // console.log("data.success.data: ", data.success.data);
      return {
        ...state,
        successData: {
          // total: parseInt(data.success.meta.pagination.total),
          // currPage: parseInt(data.success.meta.pagination.current_page),
          // pageSize: parseInt(data.success.meta.pagination.per_page) || 10,
          data: data.success.data,
          excelData: exl,
          apiData: data.success,
          regNoFilter: action.regNoFilter,
          modelNoFilter: action.modelNoFilter,
          customerFilter: action.customerFilter,
        },
        loading: false,
      };
    case SUBMIT_USOB_SERVICE_REQUESTS_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case USOB_SERVICE_REQUESTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
