import {
  MODELS_LOADING,
  SUBMIT_MODELS_ACTION_DISPATCHER,
  SUBMIT_MODELS_ACTION_REQUESTED,
  SUBMIT_MODELS_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { modelList: [], apiData: {} },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_MODELS_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_MODELS_ACTION_DISPATCHER:
      const modelList = [];
      const allModels = [];
      action.payload.data.forEach((e) => {
        allModels.push(e.Model);
        modelList.push({ value: e.Model });
      });
      var modelListbyName = modelList;
      modelListbyName.sort(function (a, b) {
        var x = a.value.toLowerCase();
        var y = b.value.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      allModels.sort();
      modelListbyName.unshift({
        value: "All Models",
        id: allModels.toString(),
      });
      action.payload.data.forEach((e) => {});
      return {
        ...state,
        successData: {
          modelList: modelList.filter((v, i, a) => a.indexOf(v) === i),
          apiData: action.payload.data,
        },
        loading: false,
      };
    case SUBMIT_MODELS_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case MODELS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
