export const SUBMIT_USOB_OPEN_JOBS_BY_TYPE_ACTION_REQUESTED =
  "SUBMIT_USOB_OPEN_JOBS_BY_TYPE_ACTION_REQUESTED";
export const SUBMIT_USOB_OPEN_JOBS_BY_TYPE_ACTION_DISPATCHER =
  "SUBMIT_USOB_OPEN_JOBS_BY_TYPE_ACTION_DISPATCHER";
export const SUBMIT_PREVENTIVE_MAINTAINANCE_ACTION_DISPATCHER =
  "SUBMIT_PREVENTIVE_MAINTAINANCE_ACTION_DISPATCHER";
export const SUBMIT_AGGREGATE_REPAIR_ACTION_DISPATCHER =
  "SUBMIT_AGGREGATE_REPAIR_ACTION_DISPATCHER";
export const SUBMIT_RUNNING_REPAIR_ACTION_DISPATCHER =
  "SUBMIT_RUNNING_REPAIR_ACTION_DISPATCHER";
export const SUBMIT_ACCIDENTAL_REPAIR_ACTION_DISPATCHER =
  "SUBMIT_ACCIDENTAL_REPAIR_ACTION_DISPATCHER";
export const SUBMIT_PREDICTIVE_MAINTAINANCE_ACTION_DISPATCHER =
  "SUBMIT_PREDICTIVE_MAINTAINANCE_ACTION_DISPATCHER";
export const SUBMIT_SUPER_STRUCTURE_ACTION_DISPATCHER =
  "SUBMIT_SUPER_STRUCTURE_ACTION_DISPATCHER";
export const SUBMIT_CUSTOMER_AR_ACTION_DISPATCHER =
  "SUBMIT_CUSTOMER_AR_ACTION_DISPATCHER";
export const SUBMIT_CUSTOMER_PARTS_ACTION_DISPATCHER =
  "SUBMIT_CUSTOMER_PARTS_ACTION_DISPATCHER";
export const SUBMIT_CUSTOMER_TOOLS_ACTION_DISPATCHER =
  "SUBMIT_CUSTOMER_TOOLS_ACTION_DISPATCHER";
export const SUBMIT_CUSTOMER_APPROVAL_ACTION_DISPATCHER =
  "SUBMIT_CUSTOMER_APPROVAL_ACTION_DISPATCHER";
export const SUBMIT_CUSTOMER_REPAIR_ACTION_DISPATCHER =
  "SUBMIT_CUSTOMER_REPAIR_ACTION_DISPATCHER";
export const SUBMIT_CUSTOMER_MANPOWER_ACTION_DISPATCHER =
  "SUBMIT_CUSTOMER_MANPOWER_ACTION_DISPATCHER";
export const SUBMIT_VOLVO_APPROVAL_ACTION_DISPATCHER =
  "SUBMIT_VOLVO_APPROVAL_ACTION_DISPATCHER";
export const SUBMIT_VOLVO_FAULT_DIGANOSIS_ACTION_DISPATCHER =
  "SUBMIT_VOLVO_FAULT_DIGANOSIS_ACTION_DISPATCHER";
export const SUBMIT_VOLVO_MANPOWER_ACTION_DISPATCHER =
  "SUBMIT_VOLVO_MANPOWER_ACTION_DISPATCHER";
export const SUBMIT_VOLVO_PARTS_NA_ACTION_DISPATCHER =
  "SUBMIT_VOLVO_PARTS_NA_ACTION_DISPATCHER";
export const SUBMIT_VOLVO_TOOLS_EQUIP_ACTION_DISPATCHER =
  "SUBMIT_VOLVO_TOOLS_EQUIP_ACTION_DISPATCHER";
export const SUBMIT_VENDOR_PARTS_ACTION_DISPATCHER =
  "SUBMIT_VENDOR_PARTS_ACTION_DISPATCHER";
export const SUBMIT_VENDOR_MANPOWER_ACTION_DISPATCHER =
  "SUBMIT_VENDOR_MANPOWER_ACTION_DISPATCHER";
export const SUBMIT_VENDOR_APPROVAL_ACTION_DISPATCHER =
  "SUBMIT_VENDOR_APPROVAL_ACTION_DISPATCHER";
export const SUBMIT_USOB_OPEN_JOBS_BY_TYPE_ERROR_DISPATCHER =
  "SUBMIT_USOB_OPEN_JOBS_BY_TYPE_ERROR_DISPATCHER";
export const USOB_OPEN_JOBS_BY_TYPE_LOADING = "USOB_OPEN_JOBS_BY_TYPE_LOADING";

//Action Creator
export const submitUSOBOpenJobsByTypeAction = (data, filter) => ({
  type: SUBMIT_USOB_OPEN_JOBS_BY_TYPE_ACTION_REQUESTED,
  postData: data,
  filters: filter
});

//Action Dispatcher
export const submitUSOBOpenJobsByTypeActionDispatcher = (data) => {
  // console.log("data: ", data);
  if (data.category === "Preventive Maintenance") {
    return {
      type: SUBMIT_PREVENTIVE_MAINTAINANCE_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "Aggregate Repair") {
    return {
      type: SUBMIT_AGGREGATE_REPAIR_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "Running Repair") {
    return {
      type: SUBMIT_RUNNING_REPAIR_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "Accident Repair") {
    return {
      type: SUBMIT_ACCIDENTAL_REPAIR_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "Predictive Maintenance") {
    return {
      type: SUBMIT_PREDICTIVE_MAINTAINANCE_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "Super Structure") {
    return {
      type: SUBMIT_SUPER_STRUCTURE_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "CustomerAR") {
    return {
      type: SUBMIT_CUSTOMER_AR_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "CustomerParts") {
    return {
      type: SUBMIT_CUSTOMER_PARTS_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "CustomerToolsEquipments") {
    return {
      type: SUBMIT_CUSTOMER_TOOLS_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "CustomerApproval") {
    return {
      type: SUBMIT_CUSTOMER_APPROVAL_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "CustomerRepair") {
    return {
      type: SUBMIT_CUSTOMER_REPAIR_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "CustomerManpower") {
    return {
      type: SUBMIT_CUSTOMER_MANPOWER_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "Volvo Approval") {
    return {
      type: SUBMIT_VOLVO_APPROVAL_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "Volvo - Fault diagnosis") {
    return {
      type: SUBMIT_VOLVO_FAULT_DIGANOSIS_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "Volvo Manpower (Hrs)") {
    return {
      type: SUBMIT_VOLVO_MANPOWER_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "Volvo - Parts not available") {
    return {
      type: SUBMIT_VOLVO_PARTS_NA_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "Volvo - Tools / Equipments") {
    return {
      type: SUBMIT_VOLVO_TOOLS_EQUIP_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "Vendor Parts (Hrs)") {
    return {
      type: SUBMIT_VENDOR_PARTS_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "Vendor Manpower (Hrs)") {
    return {
      type: SUBMIT_VENDOR_MANPOWER_ACTION_DISPATCHER,
      payload: data,
    };
  } else if (data.category === "Vendor Approval (Hrs)") {
    return {
      type: SUBMIT_VENDOR_APPROVAL_ACTION_DISPATCHER,
      payload: data,
    };
  } else {
    return {
      type: SUBMIT_USOB_OPEN_JOBS_BY_TYPE_ACTION_DISPATCHER,
      payload: data,
    };
  }
};

export const submitUSOBOpenJobsByTypeErrorDispatcher = (data) => ({
  type: SUBMIT_USOB_OPEN_JOBS_BY_TYPE_ERROR_DISPATCHER,
  payload: data,
});

export const USOBOpenJobsByTypeLoading = (loading) => ({
  type: USOB_OPEN_JOBS_BY_TYPE_LOADING,
  payload: loading,
});
