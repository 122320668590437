export const SUBMIT_OPEN_JOBS_BY_CUSTOMER_AND_DATE_ACTION_REQUESTED =
  "SUBMIT_OPEN_JOBS_BY_CUSTOMER_AND_DATE_ACTION_REQUESTED";
export const SUBMIT_OPEN_JOBS_BY_CUSTOMER_AND_DATE_ACTION_DISPATCHER =
  "SUBMIT_OPEN_JOBS_BY_CUSTOMER_AND_DATE_ACTION_DISPATCHER";
export const SUBMIT_OPEN_JOBS_BY_CUSTOMER_AND_DATE_ERROR_DISPATCHER =
  "SUBMIT_OPEN_JOBS_BY_CUSTOMER_AND_DATE_ERROR_DISPATCHER";
export const OPEN_JOBS_BY_CUSTOMER_AND_DATE_LOADING =
  "OPEN_JOBS_BY_CUSTOMER_AND_DATE_LOADING";

//Action Creator
export const submitOpenJobsByCustomerAndDateAction = (data) => ({
  type: SUBMIT_OPEN_JOBS_BY_CUSTOMER_AND_DATE_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitOpenJobsByCustomerAndDateActionDispatcher = (data) => {
  let tempChassis = [];
  let tempSite = [];
  let tempArea = [];
  let tempJobCategory = [];
  data?.data?.OpenJobsDetailsList.forEach((e) => {
    tempSite.push({ value: e.SiteName, text: e.SiteName });
    tempArea.push({ value: e.area, text: e.area });
    tempChassis.push({ value: e.ChassisNumber, text: e.ChassisNumber });
    tempJobCategory.push({ value: e.StatusName, text: e.StatusName });
  });
  return {
    type: SUBMIT_OPEN_JOBS_BY_CUSTOMER_AND_DATE_ACTION_DISPATCHER,
    payload: data?.data?.OpenJobsDetailsList,
    siteFilter: [...new Map(tempSite.map((item) => [item["value"], item])).values()],
    areaFilter: [...new Map(tempArea.map((item) => [item["value"], item])).values()],
    chassisFilter: [...new Map(tempChassis.map((item) => [item["value"], item])).values()],
    jobCategoryFilter: [...new Map(tempJobCategory.map((item) => [item["value"], item])).values()],
  };
};

export const submitOpenJobsByCustomerAndDateErrorDispatcher = (data) => ({
  type: SUBMIT_OPEN_JOBS_BY_CUSTOMER_AND_DATE_ERROR_DISPATCHER,
  payload: data,
});

export const openJobsByCustomerAndDateLoading = (loading) => ({
  type: OPEN_JOBS_BY_CUSTOMER_AND_DATE_LOADING,
  payload: loading,
});
