import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  newRequestLoading,
  submitNewRequestActionDispatcher,
  submitNewRequestErrorDispatcher,
  SUBMIT_NEWREQUEST_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postNewRequestAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post("/global?state=ttpl", {
    ...postData,
  });
  return result;
};

function* submitNewRequestSaga({ postData }) {
  try {
    yield put(newRequestLoading(true));
    const data = yield call(postNewRequestAPI, postData);
    yield put(submitNewRequestActionDispatcher(data));
    yield put(submitNewRequestErrorDispatcher({}));
    yield put(newRequestLoading(false));
  } catch (e) {
    yield put(submitNewRequestActionDispatcher({}));
    yield put(submitNewRequestErrorDispatcher(e.response.data));
    yield put(newRequestLoading(false));
  }
}

function* submitNewRequestWatcher() {
  yield takeEvery(SUBMIT_NEWREQUEST_ACTION_REQUESTED, submitNewRequestSaga);
}
export default submitNewRequestWatcher;
