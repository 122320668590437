export const SUBMIT_UTILIZATION_BY_MODEL_ACTION_REQUESTED = "SUBMIT_UTILIZATION_BY_MODEL_ACTION_REQUESTED";
export const SUBMIT_UTILIZATION_BY_MODEL_ACTION_DISPATCHER = "SUBMIT_UTILIZATION_BY_MODEL_ACTION_DISPATCHER";
export const SUBMIT_UTILIZATION_BY_MODEL_ERROR_DISPATCHER = "SUBMIT_UTILIZATION_BY_MODEL_ERROR_DISPATCHER";
export const UTILIZATION_BY_MODEL_LOADING = "UTILIZATION_BY_MODEL_LOADING";

//Action Creator
export const submitUtilizationByModelAction = (data) => ({
  type: SUBMIT_UTILIZATION_BY_MODEL_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitUtilizationByModelActionDispatcher = (data) => ({
  type: SUBMIT_UTILIZATION_BY_MODEL_ACTION_DISPATCHER,
  payload: data,
});

export const submitUtilizationByModelErrorDispatcher = (data) => ({
  type: SUBMIT_UTILIZATION_BY_MODEL_ERROR_DISPATCHER,
  payload: data,
});

export const utilizationByModelLoading = (loading) => ({
  type: UTILIZATION_BY_MODEL_LOADING,
  payload: loading,
});

