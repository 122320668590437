import {
  VEHICLE_GROUPS_LOADING,
  SUBMIT_VEHICLE_GROUPS_ACTION_DISPATCHER,
  SUBMIT_VEHICLE_GROUPS_ACTION_REQUESTED,
  SUBMIT_VEHICLE_GROUPS_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    data: [],
    vehicleGroups: [],
    vehicleGrpFilter: [],
    apiData: {},
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  const vehicleGroupList = [];
  switch (action.type) {
    case SUBMIT_VEHICLE_GROUPS_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_VEHICLE_GROUPS_ACTION_DISPATCHER:
      let data = action.payload?.data;
      // console.log("data: ", data);
      // let meta = action.payload.data.success?.meta;
      let tempVehicleGrp = [];

      data?.forEach((e) => {
        tempVehicleGrp.push({
          value: e.name,
          text: e.name,
        });
        vehicleGroupList.push({
          value: e.name,
          id: e.id,
          // total_vehicles: e.total_vehicles,
        });
      });
      // console.log("vehicleGroupList: ", vehicleGroupList);
      return {
        ...state,
        successData: {
          data: data,
          apiData: action.payload,
          vehicleGroups: vehicleGroupList,
          vehicleGrpFilter: [
            ...new Map(
              tempVehicleGrp.map((item) => [item["value"], item])
            ).values(),
          ],
        },
        loading: false,
      };
    case SUBMIT_VEHICLE_GROUPS_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case VEHICLE_GROUPS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
