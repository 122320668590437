export const SUBMIT_CHECKLIST_ACTION_REQUESTED =
  "SUBMIT_CHECKLIST_ACTION_REQUESTED";
export const SUBMIT_CHECKLIST_ACTION_DISPATCHER =
  "SUBMIT_CHECKLIST_ACTION_DISPATCHER";
export const SUBMIT_CHECKLIST_ERROR_DISPATCHER =
  "SUBMIT_CHECKLIST_ERROR_DISPATCHER";
export const CHECKLIST_LOADING = "CHECKLIST_LOADING";

//Action Creator
export const submitChecklistAction = (data) => ({
  type: SUBMIT_CHECKLIST_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitChecklistActionDispatcher = (data) => ({
  type: SUBMIT_CHECKLIST_ACTION_DISPATCHER,
  payload: data,
});

export const submitChecklistErrorDispatcher = (data) => ({
  type: SUBMIT_CHECKLIST_ERROR_DISPATCHER,
  payload: data,
});

export const ChecklistLoading = (loading) => ({
  type: CHECKLIST_LOADING,
  payload: loading,
});
