export const SUBMIT_USOB_SEGMENTS_ACTION_REQUESTED =
  "SUBMIT_USOB_SEGMENTS_ACTION_REQUESTED";
export const SUBMIT_USOB_SEGMENTS_ACTION_DISPATCHER =
  "SUBMIT_USOB_SEGMENTS_ACTION_DISPATCHER";
export const SUBMIT_USOB_SEGMENTS_ERROR_DISPATCHER =
  "SUBMIT_USOB_SEGMENTS_ERROR_DISPATCHER";
export const USOB_SEGMENTS_LOADING = "USOB_SEGMENTS_LOADING";

//Action Creator
export const submitUSOBSegmentsAction = (data) => ({
  type: SUBMIT_USOB_SEGMENTS_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitUSOBSegmentsActionDispatcher = (data) => ({
  type: SUBMIT_USOB_SEGMENTS_ACTION_DISPATCHER,
  payload: data,
});

export const submitUSOBSegmentsErrorDispatcher = (data) => ({
  type: SUBMIT_USOB_SEGMENTS_ERROR_DISPATCHER,
  payload: data,
});

export const USOBSegmentsLoading = (loading) => ({
  type: USOB_SEGMENTS_LOADING,
  payload: loading,
});
