import { getFormattedDate } from "../../utils/Helpers";

export const SUBMIT_USOB_VEHICLES_ACTION_REQUESTED =
  "SUBMIT_USOB_VEHICLES_ACTION_REQUESTED";
export const SUBMIT_USOB_VEHICLES_ACTION_DISPATCHER =
  "SUBMIT_USOB_VEHICLES_ACTION_DISPATCHER";
export const SUBMIT_USOB_VEHICLES_ERROR_DISPATCHER =
  "SUBMIT_USOB_VEHICLES_ERROR_DISPATCHER";
export const USOB_VEHICLES_LOADING = "USOB_VEHICLES_LOADING";
// export const USOB_VEHICLES_SEARCH = "USOB_VEHICLES_SEARCH";

//Action Creator
export const submitUSOBVehiclesAction = (data, query) => ({
  type: SUBMIT_USOB_VEHICLES_ACTION_REQUESTED,
  postData: data,
  filterQuery: query,
});

//Action Dispatcher
export const submitUSOBVehiclesActionDispatcher = (data, query) => {
  // console.log("query: ", query);
  // let tempData = data.data?.data?.success?.vehicles;
  // console.log("tempData: ", tempData);
  let filteredData = query
    ? data.data?.data?.success?.vehicles.filter(
        (vehicle) =>
          (vehicle.chassis_id &&
            vehicle.chassis_id.toLowerCase().includes(query.toLowerCase())) ||
          (vehicle.model_name &&
            vehicle.model_name.toLowerCase().includes(query.toLowerCase())) ||
          (vehicle.registration_number &&
            vehicle.registration_number
              .toLowerCase()
              .includes(query.toLowerCase()))
      )
    : data.data?.data?.success?.vehicles;
  let tempRegNo = [];
  let tempChassis = [];
  let tempVehicleGrp = [];
  let tempModelNo = [];
  let tempAge = [];
  let tempDOD = [];
  // console.log("filteredData: ", filteredData);
  filteredData?.forEach((e) => {
    tempRegNo.push({
      value: e.registration_number,
      text: e.registration_number,
    });
    tempChassis.push({ value: e.chassis_id, text: e.chassis_id });
    tempVehicleGrp.push({ value: e.vehicle_group, text: e.vehicle_group });
    tempModelNo.push({
      value: e.model_name,
      text: e.model_name,
    });
    tempAge.push({
      value: e.age_band,
      text: e.age_band,
    });
    tempAge.sort();
    tempDOD.push({
      value: e.date_of_delivery,
      text: getFormattedDate(e.date_of_delivery),
    });
  });
  return {
    type: SUBMIT_USOB_VEHICLES_ACTION_DISPATCHER,
    payload: data.data,
    filteredData: filteredData,
    regNoFilter: [
      ...new Map(tempRegNo.map((item) => [item["value"], item])).values(),
    ],
    chassisFilter: [
      ...new Map(tempChassis.map((item) => [item["value"], item])).values(),
    ],
    vehicleGrpFilter: [
      ...new Map(tempVehicleGrp.map((item) => [item["value"], item])).values(),
    ],
    modelNoFilter: [
      ...new Map(tempModelNo.map((item) => [item["value"], item])).values(),
    ],
    ageFilter: [
      ...new Map(tempAge.map((item) => [item["value"], item])).values(),
    ],
    dodFilter: [
      ...new Map(tempDOD.map((item) => [item["value"], item])).values(),
    ],
  };
};

export const submitUSOBVehiclesErrorDispatcher = (data) => ({
  type: SUBMIT_USOB_VEHICLES_ERROR_DISPATCHER,
  payload: data,
});

export const USOBVehiclesLoading = (loading) => ({
  type: USOB_VEHICLES_LOADING,
  payload: loading,
});

// export const USOBVehiclesSearch = (data, query) => {
//   console.log("data: ", data);
//   let filteredData = query
//     ? data.filter(
//         (vehicle) =>
//           vehicle.chassis_id.toLowerCase().includes(query.toLowerCase()) ||
//           vehicle.model_name.toLowerCase().includes(query.toLowerCase()) ||
//           vehicle.registration_number
//             .toLowerCase()
//             .includes(query.toLowerCase())
//       )
//     : data;
//   let tempRegNo = [];
//   let tempChassis = [];
//   let tempVehicleGrp = [];
//   let tempModelNo = [];
//   let tempAge = [];
//   let tempDOD = [];
//   console.log("filteredData: ", filteredData);
//   filteredData.forEach((e) => {
//     let tempDOD = [];
//     tempRegNo.push({
//       value: e.registration_number,
//       text: e.registration_number,
//     });
//     tempChassis.push({ value: e.chassis_id, text: e.chassis_id });
//     tempVehicleGrp.push({ value: e.vehicle_group, text: e.vehicle_group });
//     tempModelNo.push({
//       value: e.model_name,
//       text: e.model_name,
//     });
//     tempAge.push({
//       value: e.age_band,
//       text: e.age_band,
//     });
//     tempDOD.push({ value: e.date_of_delivery, text: e.date_of_delivery });
//   });
//   return {
//     type: USOB_VEHICLES_SEARCH,
//     payload: filteredData,
//     regNoFilter: [
//       ...new Map(tempRegNo.map((item) => [item["value"], item])).values(),
//     ],
//     chassisFilter: [
//       ...new Map(tempChassis.map((item) => [item["value"], item])).values(),
//     ],
//     vehicleGrpFilter: [
//       ...new Map(tempVehicleGrp.map((item) => [item["value"], item])).values(),
//     ],
//     modelNoFilter: [
//       ...new Map(tempModelNo.map((item) => [item["value"], item])).values(),
//     ],
//     ageFilter: [
//       ...new Map(tempAge.map((item) => [item["value"], item])).values(),
//     ],
//     dodFilter: [
//       ...new Map(tempDOD.map((item) => [item["value"], item])).values(),
//     ],
//   };
// };
