import moment from "moment";
import {
  EVENTS_LOADING,
  SUBMIT_EVENTS_ACTION_DISPATCHER,
  SUBMIT_EVENTS_ACTION_REQUESTED,
  SUBMIT_EVENTS_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { events: [], eventGrps: [], excelData: [], apiData: {} },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  const eventList = [];
  const eventGrpList = [];
  let tempExcelArr = [];
  switch (action.type) {
    case SUBMIT_EVENTS_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_EVENTS_ACTION_DISPATCHER:
      let data = action.payload.data?.success;
      data?.eventData?.forEach((e) => {
        let eve = {
          title: e.title,
          start: e.start,
          end: e.end,
          textColor: "#000000",
          extendedProps: {
            id: e.id,
            checkList: false,
            textColor: "#000000",
            border: "3px solid",
            borderColor: e.color,
            bgColor: "#FFFFFF",
            startTime: moment(e.start).format("hh:mm a"),
            endTime: moment(e.end).format("hh:mm a"),
            start: moment(e.start).format("DD MMM YY hh:mm a"),
            end: moment(e.end).format("DD MMM YY hh:mm a"),
            chassisID: e.chassis_id,
            type: e.checkup_type,
            custName: e.customer_name,
            area: e.area,
            region: e.region_name,
            status: e.status,
          },
          description: e.description,
        };
        eventList.push(eve);
        let grpListCount = eventGrpList.length;
        let eventGrp = eventGrpList.find(
          (obj) =>
            obj.extendedProps.type === e.checkup_type &&
            obj.start === e.start &&
            obj.end === e.end
        );
        if (eventGrp) {
          eventGrp.extendedProps.events.push(eve);
          eventGrp.extendedProps.eventsCount += 1;
        } else {
          eventGrpList.push({
            title: e.checkup_type,
            start: e.start,
            end: e.end,
            textColor: "#000000",
            extendedProps: {
              id: grpListCount,
              checkList: false,
              start: moment(e.start).format("MMMM D, YYYY"),
              textColor: "#000000",
              border: "3px solid",
              borderColor: e.color,
              bgColor: "#FFFFFF",
              type: e.checkup_type,
              status: e.status,
              events: [eve],
              eventsCount: 1,
            },
          });
        }
        tempExcelArr.push([
          e.checkup_type,
          e.customer_name,
          e.title,
          e.start,
          e.end,
          e.status,
          e.description,
          e.model_name,
          e.chassis_id,
          e.workshop_name,
          e.area,
          e.region_name,
        ]);
      });

      return {
        ...state,
        successData: {
          events: eventList,
          eventGrps: eventGrpList,
          excelData: tempExcelArr,
          apiData: data,
        },
        loading: false,
      };
    case SUBMIT_EVENTS_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case EVENTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
