export const SUBMIT_USOB_VEHICLE_TYPES_ACTION_REQUESTED =
  "SUBMIT_USOB_VEHICLE_TYPES_ACTION_REQUESTED";
export const SUBMIT_USOB_VEHICLE_TYPES_ACTION_DISPATCHER =
  "SUBMIT_USOB_VEHICLE_TYPES_ACTION_DISPATCHER";
export const SUBMIT_USOB_VEHICLE_TYPES_ERROR_DISPATCHER =
  "SUBMIT_USOB_VEHICLE_TYPES_ERROR_DISPATCHER";
export const USOB_VEHICLE_TYPES_LOADING = "USOB_VEHICLE_TYPES_LOADING";

//Action Creator
export const submitUSOBVehicleTypesAction = (data) => ({
  type: SUBMIT_USOB_VEHICLE_TYPES_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitUSOBVehicleTypesActionDispatcher = (data) => ({
  type: SUBMIT_USOB_VEHICLE_TYPES_ACTION_DISPATCHER,
  payload: data,
});

export const submitUSOBVehicleTypesErrorDispatcher = (data) => ({
  type: SUBMIT_USOB_VEHICLE_TYPES_ERROR_DISPATCHER,
  payload: data,
});

export const USOBVehicleTypesLoading = (loading) => ({
  type: USOB_VEHICLE_TYPES_LOADING,
  payload: loading,
});
