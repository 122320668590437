export const SUBMIT_VEHICLE_GROUP_DETAILS_ACTION_REQUESTED =
  "SUBMIT_VEHICLE_GROUP_DETAILS_ACTION_REQUESTED";
export const SUBMIT_VEHICLE_GROUP_DETAILS_ACTION_DISPATCHER =
  "SUBMIT_VEHICLE_GROUP_DETAILS_ACTION_DISPATCHER";
export const SUBMIT_VEHICLE_GROUP_DETAILS_ERROR_DISPATCHER =
  "SUBMIT_VEHICLE_GROUP_DETAILS_ERROR_DISPATCHER";
export const VEHICLE_GROUP_DETAILS_LOADING =
  "VEHICLE_GROUP_DETAILS_LOADING";

//Action Creator
export const submitVehicleGroupDetailsAction = (data) => ({
  type: SUBMIT_VEHICLE_GROUP_DETAILS_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitVehicleGroupDetailsActionDispatcher = (data) => ({
  type: SUBMIT_VEHICLE_GROUP_DETAILS_ACTION_DISPATCHER,
  payload: data,
});

export const submitVehicleGroupDetailsErrorDispatcher = (data) => ({
  type: SUBMIT_VEHICLE_GROUP_DETAILS_ERROR_DISPATCHER,
  payload: data,
});

export const vehicleGroupDetailsLoading = (loading) => ({
  type: VEHICLE_GROUP_DETAILS_LOADING,
  payload: loading,
});
