export const SUBMIT_EVENTS_ACTION_REQUESTED =
  "SUBMIT_EVENTS_ACTION_REQUESTED";
export const SUBMIT_EVENTS_ACTION_DISPATCHER =
  "SUBMIT_EVENTS_ACTION_DISPATCHER";
export const SUBMIT_EVENTS_ERROR_DISPATCHER =
  "SUBMIT_EVENTS_ERROR_DISPATCHER";
export const EVENTS_LOADING = "EVENTS_LOADING";

//Action Creator
export const submitEventsAction = (data) => ({
  type: SUBMIT_EVENTS_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitEventsActionDispatcher = (data) => ({
  type: SUBMIT_EVENTS_ACTION_DISPATCHER,
  payload: data,
});

export const submitEventsErrorDispatcher = (data) => ({
  type: SUBMIT_EVENTS_ERROR_DISPATCHER,
  payload: data,
});

export const EventsLoading = (loading) => ({
  type: EVENTS_LOADING,
  payload: loading,
});
