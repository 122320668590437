import {
  USOB_VEHICLE_DATA_LOADING,
  SUBMIT_USOB_VEHICLE_DATA_OPERATION_ACTION_DISPATCHER,
  SUBMIT_USOB_VEHICLE_DATA_WORKSHOP_ACTION_DISPATCHER,
  SUBMIT_USOB_VEHICLE_DATA_HUB_ACTION_DISPATCHER,
  SUBMIT_USOB_VEHICLE_DATA_ACCIDENTAL_ACTION_DISPATCHER,
  SUBMIT_USOB_VEHICLE_DATA_ACTION_REQUESTED,
  SUBMIT_USOB_VEHICLE_DATA_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    operationData: { data: [], perPage: 10, currPage: 1, totalRows: 1 },
    workshopData: { data: [], perPage: 10, currPage: 1, totalRows: 1 },
    hubData: { data: [], perPage: 10, currPage: 1, totalRows: 1 },
    accidentalData: { data: [], perPage: 10, currPage: 1, totalRows: 1 },
    apiData: {},
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  let operationData = { data: [], perPage: 10, currPage: 1, totalRows: 1 };
  let workshopData = { data: [], perPage: 10, currPage: 1, totalRows: 1 };
  let hubData = { data: [], perPage: 10, currPage: 1, totalRows: 1 };
  let accidentalData = { data: [], perPage: 10, currPage: 1, totalRows: 1 };
  switch (action.type) {
    case SUBMIT_USOB_VEHICLE_DATA_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_USOB_VEHICLE_DATA_OPERATION_ACTION_DISPATCHER:
      let opData = action.payload.data;
      operationData.data = opData.success.data;
      operationData.perPage = parseInt(opData.success.meta.pagination.per_page);
      operationData.currPage = parseInt(
        opData.success.meta.pagination.current_page
      );
      operationData.totalRows = parseInt(opData.success.meta.pagination.total);
      return {
        ...state,
        successData: {
          ...state.successData,
          operationData: operationData,
        },
        loading: false,
      };

    case SUBMIT_USOB_VEHICLE_DATA_WORKSHOP_ACTION_DISPATCHER:
      let wsData = action.payload.data;
      workshopData.data = wsData.success.data;
      workshopData.perPage = parseInt(wsData.success.meta.pagination.per_page);
      workshopData.currPage = parseInt(
        wsData.success.meta.pagination.current_page
      );
      workshopData.totalRows = parseInt(wsData.success.meta.pagination.total);
      return {
        ...state,
        successData: {
          ...state.successData,
          workshopData: workshopData,
        },
        loading: false,
      };
    case SUBMIT_USOB_VEHICLE_DATA_HUB_ACTION_DISPATCHER:
      let hsData = action.payload.data;
      hubData.data = hsData.success.data;
      hubData.perPage = parseInt(hsData.success.meta.pagination.per_page);
      hubData.currPage = parseInt(hsData.success.meta.pagination.current_page);
      hubData.totalRows = parseInt(hsData.success.meta.pagination.total);
      return {
        ...state,
        successData: {
          ...state.successData,
          hubData: hubData,
        },
        loading: false,
      };
    case SUBMIT_USOB_VEHICLE_DATA_ACCIDENTAL_ACTION_DISPATCHER:
      let asData = action.payload.data;
      accidentalData.data = asData.success.data;
      accidentalData.perPage = parseInt(
        asData.success.meta.pagination.per_page
      );
      accidentalData.currPage = parseInt(
        asData.success.meta.pagination.current_page
      );
      accidentalData.totalRows = parseInt(asData.success.meta.pagination.total);
      return {
        ...state,
        successData: {
          ...state.successData,
          accidentalData: accidentalData,
        },
        loading: false,
      };
    case SUBMIT_USOB_VEHICLE_DATA_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case USOB_VEHICLE_DATA_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
