// import moment from "moment";
import moment from "moment";
import {
  TRIP_DATA_LOADING,
  SUBMIT_TRIP_DATA_ACTION_DISPATCHER,
  SUBMIT_TRIP_DATA_ACTION_REQUESTED,
  SUBMIT_TRIP_DATA_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { apiData: {}, excelData: [], chassies: [], siteName: "" },
  failureData: {},
  loading: false,
  isLogout: false,
};
const reducer = (state = initialState, action) => {
  let tempExcelArr = [];
  // let chassies = [];
  switch (action.type) {
    case SUBMIT_TRIP_DATA_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_TRIP_DATA_ACTION_DISPATCHER:
      const data = action.payload.data[0];
      tempExcelArr.push(
        ["Trip Count Details - Total Vehicle", "", "", "", "", "", "", ""],
        [
          "Date:- " +
            moment(data.FromDate).format("DD-MM-YYYY") +
            " to " +
            moment(data.ToDate).format("DD-MM-YYYY"),
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
        ["", "", "", "", "", "", "", ""],
        [
          "Site Name",
          "Chassis Number",
          "Model Name",
          "Shift Date",
          "Shift 1 (" +
            moment(data.Shift1ST, ["HH:mm"]).format("hh:mm a") +
            "-" +
            moment(data.Shift1ET, ["HH:mm"]).format("hh:mm a") +
            ")",
          "Shift 2 (" +
            moment(data.Shift2ST, ["HH:mm"]).format("hh:mm a") +
            "-" +
            moment(data.Shift2ET, ["HH:mm"]).format("hh:mm a") +
            ")",
          "Shift 3 (" +
            moment(data.Shift3ST, ["HH:mm"]).format("hh:mm a") +
            "-" +
            moment(data.Shift3ET, ["HH:mm"]).format("hh:mm a") +
            ")",
          "Total Trips",
        ]
      );
      // console.log("action.payload.data[0]: ", action.payload.data[0]);
      data.Sitelist.forEach((e) => {
        let exist = false;
        tempExcelArr.forEach((i) => {
          if (i[1] === e.ChassisNumber && i[3] === e.ShiftDate) {
            exist = true;
          }
        });
        if (!exist) {
          let shift1 = 0;
          let shift2 = 0;
          let shift3 = 0;
          for (let i = 0; i < e.tripcountlist.length; i++) {
            if (e.tripcountlist[i].ShiftName === "Shift 1") {
              shift1 = e.tripcountlist[i].TripCount;
            } else if (e.tripcountlist[i].ShiftName === "Shift 2") {
              shift2 = e.tripcountlist[i].TripCount;
            } else if (e.tripcountlist[i].ShiftName === "Shift 3") {
              shift3 = e.tripcountlist[i].TripCount;
            }
          }
          tempExcelArr.push([
            e.SiteName,
            e.ChassisNumber,
            e.ModelName,
            e.ShiftDate,
            shift1,
            shift2,
            shift3,
            shift1 + shift2 + shift3,
          ]);
          // exist = false;
          // chassies.forEach((arr) => {
          //   if (arr[0] === e.ChassisNumber) {
          //     exist = true;
          //   }
          // });
          // if (!exist) {
          //   chassies.push([e.ChassisNumber, e.ModelName]);
          // }
        }
      });
      // console.log("chassies: ", chassies);
      // console.log("tempExcelArr: ", tempExcelArr);
      return {
        ...state,
        successData: {
          apiData: action.payload.data,
          excelData: tempExcelArr,
          // chassies: Array.from(new Set(chassies)),
          siteName: data.Sitelist[0].SiteName,
        },
        loading: false,
      };
    case SUBMIT_TRIP_DATA_ERROR_DISPATCHER:
      return {
        ...state,
        formError: action.payload,
        loading: false,
      };
    case TRIP_DATA_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
