import moment from "moment";
import {
  UPTIME_BY_MONTH_LOADING,
  SUBMIT_UPTIME_BY_MONTH_ACTION_DISPATCHER,
  SUBMIT_UPTIME_BY_MONTH_ACTION_REQUESTED,
  SUBMIT_UPTIME_BY_MONTH_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { chartData: [], excelData: [], apiData: {} },
  failureData: {},
  loading: false,
  isLogout: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_UPTIME_BY_MONTH_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_UPTIME_BY_MONTH_ACTION_DISPATCHER:
      const tempArr = [["Month", "Percentage", { role: "annotation" }]];
      const tempExcelArr = [["Month", "Percentage"]];
      action.payload.data.forEach((e) => {
        tempArr.push([
          moment(e.MonthYear).format("MMM-YY"),
          parseFloat(e.Percentage.toFixed(1)),
          `${e.Percentage.toFixed()}`,
        ]);
        tempExcelArr.push([
          moment(e.MonthYear).format("MMM-YY"),
          parseFloat(e.Percentage.toFixed(1)),
        ]);
      });
      return {
        ...state,
        successData: {
          chartData: tempArr,
          excelData: tempExcelArr,
          apiData: action.payload.data,
        },
        loading: false,
      };
    case SUBMIT_UPTIME_BY_MONTH_ERROR_DISPATCHER:
      return {
        ...state,
        formError: action.payload,
        loading: false,
      };
    case UPTIME_BY_MONTH_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
