//Action Types
export const SUBMIT_FORGOTPASSWORD_ACTION_REQUESTED =
  "SUBMIT_FORGOTPASSWORD_ACTION_REQUESTED";
export const SUBMIT_FORGOTPASSWORD_ACTION_DISPATCHER =
  "SUBMIT_FORGOTPASSWORD_ACTION_DISPATCHER";
export const SUBMIT_FORGOTPASSWORD_ERROR_DISPATCHER =
  "SUBMIT_FORGOTPASSWORD_ERROR_DISPATCHER";
export const SUBMIT_EMPTY_FORGOTPASSWORD_STATE_ACTION =
  "SUBMIT_EMPTY_FORGOTPASSWORD_STATE_ACTION";
export const FORGOTPASSWORD_LOADING = "FORGOTPASSWORD_LOADING";

//Action Creator
export const submitForgotPasswordAction = (data) => ({
  type: SUBMIT_FORGOTPASSWORD_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitForgotPasswordActionDispatcher = (data) => ({
  type: SUBMIT_FORGOTPASSWORD_ACTION_DISPATCHER,
  payload: data,
});

//Error Dispatcher
export const submitForgotPasswordErrorDispatcher = (data) => ({
  type: SUBMIT_FORGOTPASSWORD_ERROR_DISPATCHER,
  payload: data,
});

//Loading Action
export const forgotPasswordLoading = (loading) => ({
  type: FORGOTPASSWORD_LOADING,
  payload: loading,
});

export const submitEmptyForgotPasswordStateAction = () => ({
  type: SUBMIT_EMPTY_FORGOTPASSWORD_STATE_ACTION,
});
