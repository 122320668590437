import moment from "moment";
import {
  VEHICLES_LOADING,
  SUBMIT_VEHICLES_ACTION_DISPATCHER,
  SUBMIT_VEHICLES_ACTION_REQUESTED,
  SUBMIT_VEHICLES_ERROR_DISPATCHER,
  SEARCH_VEHICLE,
} from "./Action";

const initialState = {
  successData: {
    vehiclesList: [],
    myVehicles: [],
    excelData: [],
    siteFilters: [],
    chassisFilters: [],
    doorFilters: [],
    modelFilters: [],
    statusFilters: [],
    vehicleAgeFilters: [],
    ageFilters: [],
    filteredList: [],
    filteredMobList: [],
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  const vehicleList = [];
  let mobList = [];
  let tempExcelArr = [
    [
      "Site Code",
      "Site Name",
      "Area",
      "Chassis Number",
      "Door Number",
      "Application Type",
      "DoD (Date of Delivery)",
      "Model Number",
      "Vehicle Age",
      "Age Band",
      "Vehicle Status",
      "Current Reading (Engine Hours)",
    ],
  ];
  const ageCal = (date) => {
    var diff = moment().diff(moment(date), "days");
    let month = (parseInt((diff % 365) / 30) / 12).toFixed(1);
    let age = parseInt(diff / 365) + (month % 1);
    return age;
  };
  switch (action.type) {
    case SUBMIT_VEHICLES_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_VEHICLES_ACTION_DISPATCHER:
      for (let i = 0; i < action.filteredData.length; i += 5) {
        mobList.push(action.filteredData.slice(i, i + 5));
      }
      action.payload.forEach((e) => {
        vehicleList.push({ value: e.VehicleId + " " + e.VehicleType });
        tempExcelArr.push([
          e.SiteCode,
          e.SiteName,
          e.SiteArea,
          e.ChassisNumber,
          e.DoorNumber,
          e.VehicleType,
          e.InstallationDate,
          e.ModelNumber,
          ageCal(e.InstallationDate),
          Math.ceil(ageCal(e.InstallationDate)),
          e.ActiveStatus ? "Active" : "In-Active",
          e.CurrentReading,
        ]);
      });
      return {
        ...state,
        successData: {
          myVehicles: vehicleList,
          excelData: tempExcelArr,
          vehiclesList: action.payload,
          siteFilters: action.siteFilter,
          chassisFilters: action.chassisFilter,
          doorFilters: action.doorFilter,
          modelFilters: action.modelFilter,
          statusFilters: action.statusFilter,
          vehicleAgeFilters: action.vehicleAgeFilter,
          ageFilters: action.ageFilter,
          filteredList: action.filteredData,
          filteredMobList: mobList,
        },
        loading: false,
      };
    case SUBMIT_VEHICLES_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case VEHICLES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case SEARCH_VEHICLE:
      for (let i = 0; i < action.filteredData.length; i += 5) {
        mobList.push(action.filteredData.slice(i, i + 5));
      }
      action.payload.forEach((e) => {
        vehicleList.push({ value: e.VehicleId + " " + e.VehicleType });
        tempExcelArr.push([
          e.SiteCode,
          e.SiteName,
          e.SiteArea,
          e.ChassisNumber,
          e.DoorNumber,
          e.InstallationDate,
          e.ModelNumber,
          ageCal(e.InstallationDate),
          Math.ceil(ageCal(e.InstallationDate)),
          e.ActiveStatus ? "Active" : "In-Active",
          e.CurrentReading,
        ]);
      });
      return {
        ...state,
        successData: {
          myVehicles: vehicleList,
          excelData: tempExcelArr,
          vehiclesList: action.payload,
          siteFilters: action.siteFilter,
          chassisFilters: action.chassisFilter,
          doorFilters: action.doorFilter,
          modelFilters: action.modelFilter,
          statusFilters: action.statusFilter,
          vehicleAgeFilters: action.vehicleAgeFilter,
          ageFilters: action.ageFilter,
          filteredList: action.filteredData,
          filteredMobList: mobList,
        },
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
