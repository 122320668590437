import {
  USOB_DASHBOARD_SUMMARY_LOADING,
  SUBMIT_USOB_DASHBOARD_SUMMARY_ACTION_DISPATCHER,
  SUBMIT_USOB_DASHBOARD_SUMMARY_ACTION_REQUESTED,
  SUBMIT_USOB_DASHBOARD_SUMMARY_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    vehiclesData: {
      total_vehicles: 0,
      in_operation: 0,
      in_workshop_service: 0,
      in_hub_service: 0,
      accidental_service: 0,
    },
    openJobs: {
      total_jobs: 0,
      AccidentRepair: {
        repair_category_id: 0,
        count: 0,
      },
      PreventiveMaintenance: {
        repair_category_id: 0,
        count: 0,
      },
      PredictiveMaintenance: {
        repair_category_id: 0,
        count: 0,
      },
      SuperStructure: {
        repair_category_id: 0,
        count: 0,
      },
      AggregateRepair: {
        repair_category_id: 0,
        count: 0,
      },
      RunningRepair: {
        repair_category_id: 0,
        count: 0,
      },
    },
    jobsByDelayReason: {
      total_customer_reasons: 0,
      total_volvo_reasons: 0,
      volvo_delay_reason: {
        VolvoApproval: {
          count: 0,
          reason_id: [],
        },
        VolvoFaultdiagnosis: {
          count: 0,
          reason_id: [],
        },
        VolvoManpower: {
          count: 0,
          reason_id: [],
        },
        VolvoPartsNotAvailable: {
          count: 0,
          reason_id: [],
        },
        VolvoToolsEquipments: {
          count: 0,
          reason_id: [],
        },
        VendorApproval: {
          count: 0,
          reason_id: [],
        },
        VendorApprovalHrs: {
          count: 0,
          reason_id: [],
        },
        VendorParts: {
          count: 0,
          reason_id: [],
        },
        VendorPartsHrs: {
          count: 0,
          reason_id: [],
        },
        VendorManpower: {
          count: 0,
          reason_id: [],
        },
        VendorManpowerHrs: {
          count: 0,
          reason_id: [],
        },
        AccRepairEstimate: {
          count: 0,
          reason_id: [],
        },
        AccInsuranceSurvey: {
          count: 0,
          reason_id: [],
        },
        unassigned: {
          count: 0,
          repair_id: [],
        },
      },
      customer_delay_reason: {
        CustomerRepair: {
          count: 0,
          reason_id: [],
        },
        CustomerParts: {
          count: 0,
          reason_id: [],
        },
        CustomerToolsEquipments: {
          count: 0,
          reason_id: [],
        },
        CustomerApprovalPO: {
          count: 0,
          reason_id: [],
        },
        CustomerAR: {
          count: 0,
          reason_id: [],
        },
        CustomerManPower: {
          count: 0,
          reason_id: [],
        },
      },
    },
    apiData: {},
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_USOB_DASHBOARD_SUMMARY_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_USOB_DASHBOARD_SUMMARY_ACTION_DISPATCHER:
      let data = action.payload?.data?.success;
      // console.log("action.payload.data: ", action.payload.data);
      return {
        ...state,
        successData: {
          vehiclesData: {
            total_vehicles: data?.vehiclesData?.total_vehicles,
            in_operation: data?.vehiclesData?.in_operation,
            in_workshop_service: data?.vehiclesData?.in_workshop_service,
            in_hub_service: data?.vehiclesData?.in_hub_service,
            accidental_service: data?.vehiclesData?.accidental_service,
          },
          openJobs: {
            total_jobs: data?.openJobs?.total_jobs,
            AccidentRepair: {
              repair_category_id:
                data?.openJobs?.categories["Accident Repair"]
                  ?.repair_category_id,
              count: data?.openJobs?.categories["Accident Repair"]?.count,
            },
            PreventiveMaintenance: {
              repair_category_id:
                data?.openJobs?.categories["Preventive Maintenance"]
                  ?.repair_category_id,
              count:
                data?.openJobs?.categories["Preventive Maintenance"]?.count,
            },
            PredictiveMaintenance: {
              repair_category_id:
                data?.openJobs?.categories["Predictive Maintenance"]
                  ?.repair_category_id,
              count:
                data?.openJobs?.categories["Predictive Maintenance"]?.count,
            },
            SuperStructure: {
              repair_category_id:
                data?.openJobs?.categories["Super Structure"]
                  ?.repair_category_id,
              count: data?.openJobs?.categories["Super Structure"]?.count,
            },
            AggregateRepair: {
              repair_category_id:
                data?.openJobs?.categories["Aggregate Repair"]
                  ?.repair_category_id,
              count: data?.openJobs?.categories["Aggregate Repair"]?.count,
            },
            RunningRepair: {
              repair_category_id:
                data?.openJobs?.categories["Running Repair"]
                  ?.repair_category_id,
              count: data?.openJobs?.categories["Running Repair"]?.count,
            },
          },
          jobsByDelayReason: {
            total_customer_reasons:
              data?.jobsByDelayReason?.total_customer_reasons,
            total_volvo_reasons: data?.jobsByDelayReason?.total_volvo_reasons,
            volvo_delay_reason: {
              VolvoApproval: {
                count:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Approval"
                  ]?.count,
                reason_id:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Approval"
                  ]?.reason_id,
              },
              VolvoFaultdiagnosis: {
                count:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Fault diagnosis"
                  ]?.count,
                reason_id:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Approval"
                  ]?.reason_id,
              },
              VolvoManpower: {
                count:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Man power"
                  ]?.count,
                reason_id:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Approval"
                  ]?.reason_id,
              },
              VolvoPartsNotAvailable: {
                count:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Parts not available"
                  ]?.count,
                reason_id:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Approval"
                  ]?.reason_id,
              },
              VolvoToolsEquipments: {
                count:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Tools / Equipments"
                  ]?.count,
                reason_id:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Approval"
                  ]?.reason_id,
              },
              VendorApproval: {
                count:
                  data?.jobsByDelayReason?.volvo_delay_reason["Vendor Approval"]
                    ?.count,
                reason_id:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Approval"
                  ]?.reason_id,
              },
              VendorApprovalHrs: {
                count:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Vendor Approval (Hrs)"
                  ]?.count,
                reason_id:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Approval"
                  ]?.reason_id,
              },
              VendorParts: {
                count:
                  data?.jobsByDelayReason?.volvo_delay_reason["Vendor Parts"]
                    ?.count,
                reason_id:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Approval"
                  ]?.reason_id,
              },
              VendorPartsHrs: {
                count:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Vendor Parts (Hrs)"
                  ]?.count,
                reason_id:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Approval"
                  ]?.reason_id,
              },
              VendorManpower: {
                count:
                  data?.jobsByDelayReason?.volvo_delay_reason["Vendor Manpower"]
                    ?.count,
                reason_id:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Approval"
                  ]?.reason_id,
              },
              VendorManpowerHrs: {
                count:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Vendor Manpower (Hrs)"
                  ]?.count,
                reason_id:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Approval"
                  ]?.reason_id,
              },
              AccRepairEstimate: {
                count:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Acc - Repair Estimate"
                  ]?.count,
                reason_id:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Approval"
                  ]?.reason_id,
              },
              AccInsuranceSurvey: {
                count:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Acc - Insurance Survey"
                  ]?.count,
                reason_id:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Approval"
                  ]?.reason_id,
              },
              unassigned: {
                count:
                  data?.jobsByDelayReason?.volvo_delay_reason["unassigned"]
                    ?.count,
                repair_id:
                  data?.jobsByDelayReason?.volvo_delay_reason[
                    "Volvo - Approval"
                  ]?.reason_id,
              },
            },
            customer_delay_reason: {
              CustomerRepair: {
                count: 0,
                reason_id: [],
              },
              CustomerParts: {
                count: 0,
                reason_id: [],
              },
              CustomerToolsEquipments: {
                count: 0,
                reason_id: [],
              },
              CustomerApprovalPO: {
                count: 0,
                reason_id: [],
              },
              CustomerAR: {
                count: 0,
                reason_id: [],
              },
              CustomerManPower: {
                count: 0,
                reason_id: [],
              },
            },
          },
          apiData: data,
        },
        loading: false,
      };
    case SUBMIT_USOB_DASHBOARD_SUMMARY_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case USOB_DASHBOARD_SUMMARY_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
