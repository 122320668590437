export const SUBMIT_USOB_DEALER_LOCATOR_ACTION_REQUESTED =
  "SUBMIT_USOB_DEALER_LOCATOR_ACTION_REQUESTED";
export const SUBMIT_USOB_DEALER_LOCATOR_ACTION_DISPATCHER =
  "SUBMIT_USOB_DEALER_LOCATOR_ACTION_DISPATCHER";
export const SUBMIT_USOB_DEALER_LOCATOR_ERROR_DISPATCHER =
  "SUBMIT_USOB_DEALER_LOCATOR_ERROR_DISPATCHER";
export const USOB_DEALER_LOCATOR_LOADING = "USOB_DEALER_LOCATOR_LOADING";

//Action Creator
export const submitUSOBDealerLocatorAction = (center) => ({
  type: SUBMIT_USOB_DEALER_LOCATOR_ACTION_REQUESTED,
  latLng: center,
});

//Action Dispatcher
export const submitUSOBDealerLocatorActionDispatcher = (data, center) => {
  // console.log("data, center: ", data, center);
  return {
    type: SUBMIT_USOB_DEALER_LOCATOR_ACTION_DISPATCHER,
    payload: data,
    latLng: center,
  };
};

export const submitUSOBDealerLocatorErrorDispatcher = (data) => ({
  type: SUBMIT_USOB_DEALER_LOCATOR_ERROR_DISPATCHER,
  payload: data,
});

export const USOBDealerLocatorLoading = (loading) => ({
  type: USOB_DEALER_LOCATOR_LOADING,
  payload: loading,
});
