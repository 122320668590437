export const SUBMIT_TRIP_BY_MODEL_ACTION_REQUESTED =
  "SUBMIT_TRIP_BY_MODEL_ACTION_REQUESTED";
export const SUBMIT_TRIP_BY_MODEL_ACTION_DISPATCHER =
  "SUBMIT_TRIP_BY_MODEL_ACTION_DISPATCHER";
export const SUBMIT_TRIP_BY_MODEL_ERROR_DISPATCHER =
  "SUBMIT_TRIP_BY_MODEL_ERROR_DISPATCHER";
export const TRIP_BY_MODEL_LOADING = "TRIP_BY_MODEL_LOADING";

//Action Creator
export const submitTripByModelAction = (data) => ({
  type: SUBMIT_TRIP_BY_MODEL_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitTripByModelActionDispatcher = (data) => ({
  type: SUBMIT_TRIP_BY_MODEL_ACTION_DISPATCHER,
  payload: data,
});

export const submitTripByModelErrorDispatcher = (data) => ({
  type: SUBMIT_TRIP_BY_MODEL_ERROR_DISPATCHER,
  payload: data,
});

export const tripByModelLoading = (loading) => ({
  type: TRIP_BY_MODEL_LOADING,
  payload: loading,
});
