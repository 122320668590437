export const SUBMIT_CUSTOMER_LIST_ACTION_REQUESTED = "SUBMIT_CUSTOMER_LIST_ACTION_REQUESTED";
export const SUBMIT_CUSTOMER_LIST_ACTION_DISPATCHER = "SUBMIT_CUSTOMER_LIST_ACTION_DISPATCHER";
export const SUBMIT_CUSTOMER_LIST_ERROR_DISPATCHER = "SUBMIT_CUSTOMER_LIST_ERROR_DISPATCHER";
export const CUSTOMER_LIST_LOADING = "CUSTOMER_LIST_LOADING";

//Action Creator
export const submitCustomerListAction = (data) => ({
  type: SUBMIT_CUSTOMER_LIST_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitCustomerListActionDispatcher = (data) => ({
  type: SUBMIT_CUSTOMER_LIST_ACTION_DISPATCHER,
  payload: data,
});

export const submitCustomerListErrorDispatcher = (data) => ({
  type: SUBMIT_CUSTOMER_LIST_ERROR_DISPATCHER,
  payload: data,
});

export const customerListLoading = (loading) => ({
  type: CUSTOMER_LIST_LOADING,
  payload: loading,
});