import { getAdminAuthUserDetails } from "../../utils/Helpers";
import {
  BREAKDOWN_LOADING,
  SUBMIT_BREAKDOWN_ACTION_DISPATCHER,
  SUBMIT_BREAKDOWN_ACTION_REQUESTED,
  SUBMIT_BREAKDOWN_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    breakdowns: 0,
    deviceList: [],
    breakdownList: [],
    regNoFilter: [],
    chassisFilter: [],
    breakLocFilter: [],
    vehicleGrpFilter: [],
    currStatFilter: [],
    excelData: [],
    // openSinceFilter: [],
    apiData: {},
  },
  failureData: {},
  loading: false,
  isLogout: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_BREAKDOWN_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_BREAKDOWN_ACTION_DISPATCHER:
      let exlData = [
        [
          getAdminAuthUserDetails().usob_customer_id === 0
            ? "Customer"
            : "Vehicle Group",
          "Registration Number",
          "Chassis Number",
          "Breakdown Location",
          "Current Status",
          "Assigned To",
          "Van Registration Number",
          "Dealer Name",
          "Created At",
        ],
      ];
      let devices = [];
      action.payload?.data?.forEach((e) => {
        devices.push(e.device_alias);
        exlData.push([
          getAdminAuthUserDetails().usob_customer_id === 0
            ? e.customer_name
            : e.vehicle_group,
          e.brackdown_vehicle_registration_number,
          e.chassis_no,
          e.breakdown_location,
          e.alias,
          e.assigned_to,
          e.assigned_vehicle_registration_No,
          e.vehicle_alias,
          e.creation_time,
        ]);
      });
      // console.log("action.payload.data: ", action.payload.data);
      localStorage.setItem("devices", JSON.stringify(devices));
      return {
        ...state,
        successData: {
          breakdowns: action.payload.data.length,
          breakdownList: action.payload.data,
          deviceList: devices,
          regNoFilter: action.regNoFilter,
          chassisFilter: action.chassisFilter,
          breakLocFilter: action.breakLocFilter,
          vehicleGrpFilter: action.vehicleGrpFilter,
          currStatFilter: action.currStatFilter,
          // openSinceFilter: action.openSinceFilter,
          excelData: exlData,
          apiData: action.payload,
        },
        loading: false,
      };
    case SUBMIT_BREAKDOWN_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case BREAKDOWN_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
