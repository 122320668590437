import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBRenameVehicleGroupLoading,
  submitUSOBRenameVehicleGroupActionDispatcher,
  submitUSOBRenameVehicleGroupErrorDispatcher,
  SUBMIT_USOB_RENAME_VEHICLE_GROUP_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBRenameVehicleGroupAPI = async (postData, grpID) => {
  const result = await AXIOS_INSTANCE.put("/groups/" + grpID, { ...postData });
  return result;
};

function* submitUSOBRenameVehicleGroupSaga({ postData, grpID }) {
  try {
    yield put(USOBRenameVehicleGroupLoading(true));
    const data = yield call(postUSOBRenameVehicleGroupAPI, postData, grpID);
    yield put(submitUSOBRenameVehicleGroupActionDispatcher(data));
    yield put(submitUSOBRenameVehicleGroupErrorDispatcher({}));
    yield put(USOBRenameVehicleGroupLoading(false));
  } catch (e) {
    yield put(submitUSOBRenameVehicleGroupActionDispatcher({}));
    yield put(submitUSOBRenameVehicleGroupErrorDispatcher(e));
    yield put(USOBRenameVehicleGroupLoading(false));
  }
}

function* submitUSOBRenameVehicleGroupWatcher() {
  yield takeEvery(
    SUBMIT_USOB_RENAME_VEHICLE_GROUP_ACTION_REQUESTED,
    submitUSOBRenameVehicleGroupSaga
  );
}
export default submitUSOBRenameVehicleGroupWatcher;
