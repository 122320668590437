import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  siteAdvisoryLoading,
  submitSiteAdvisoryActionDispatcher,
  submitSiteAdvisoryErrorDispatcher,
  SUBMIT_SITE_ADVISORY_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postSiteAdvisoryAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/Post?sites=abf&Parameter=xyz",
    {
      ...postData,
    }
  );
  return result;
};

function* submitSiteAdvisorySaga({ postData, filterQuery }) {
  try {
    yield put(siteAdvisoryLoading(true));
    const data = yield call(postSiteAdvisoryAPI, postData);
    yield put(submitSiteAdvisoryActionDispatcher(data.data, filterQuery));
    yield put(submitSiteAdvisoryErrorDispatcher({}));
    yield put(siteAdvisoryLoading(false));
  } catch (e) {
    yield put(submitSiteAdvisoryActionDispatcher({}));
    yield put(submitSiteAdvisoryErrorDispatcher(e.response.data));
    yield put(siteAdvisoryLoading(false));
  }
}

function* submitSiteAdvisoryWatcher() {
  yield takeEvery(
    SUBMIT_SITE_ADVISORY_ACTION_REQUESTED,
    submitSiteAdvisorySaga
  );
}
export default submitSiteAdvisoryWatcher;
