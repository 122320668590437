import moment from "moment";

export const SUBMIT_VEHICLES_ACTION_REQUESTED =
  "SUBMIT_VEHICLES_ACTION_REQUESTED";
export const SUBMIT_VEHICLES_ACTION_DISPATCHER =
  "SUBMIT_VEHICLES_ACTION_DISPATCHER";
export const SUBMIT_VEHICLES_ERROR_DISPATCHER =
  "SUBMIT_VEHICLES_ERROR_DISPATCHER";
export const VEHICLES_LOADING = "VEHICLES_LOADING";
export const SEARCH_VEHICLE = "SEARCH_VEHICLE";

//Action Creator
export const submitVehiclesAction = (data, query) => ({
  type: SUBMIT_VEHICLES_ACTION_REQUESTED,
  postData: data,
  filterQuery: query,
});

//Action Dispatcher
export const submitVehiclesActionDispatcher = (data, query) => {
  // console.log('data: ', data);
  let tempChassis = [];
  let tempSite = [];
  let tempDoor = [];
  let tempModel = [];
  let tempStatus = [];
  let tempVehicleAge = [];
  let tempAge = [];
  let filteredData = query
    ? data.filter(
        (vehicle) =>
          vehicle.ChassisNumber.toLowerCase().includes(query.toLowerCase()) ||
          vehicle.DoorNumber.toLowerCase().includes(query.toLowerCase())
      )
    : data;
  filteredData.forEach((e) => {
    tempSite.push({ value: e.SiteName, text: e.SiteName });
    tempChassis.push({ value: e.ChassisNumber, text: e.ChassisNumber });
    tempDoor.push({ value: e.DoorNumber, text: e.DoorNumber });
    tempModel.push({ value: e.ModelNumber, text: e.ModelNumber });
    e.ActiveStatus
      ? tempStatus.push({ value: e.ActiveStatus, text: "ACTIVE" })
      : tempStatus.push({ value: e.ActiveStatus, text: "IN-ACTIVE" });
    var diff = moment().diff(moment(e.InstallationDate), "days");
    let month = (parseInt((diff % 365) / 30) / 12).toFixed(1);
    let age = parseInt(diff / 365) + (month % 1);
    tempVehicleAge.push({ value: age, text: age });
    tempAge.push({ value: Math.ceil(age), text: Math.ceil(age) });
    tempAge.sort();
  });
  return {
    type: SUBMIT_VEHICLES_ACTION_DISPATCHER,
    payload: data,
    filteredData: filteredData,
    siteFilter: [
      ...new Map(tempSite.map((item) => [item["value"], item])).values(),
    ],
    chassisFilter: [
      ...new Map(tempChassis.map((item) => [item["value"], item])).values(),
    ],
    doorFilter: [
      ...new Map(tempDoor.map((item) => [item["value"], item])).values(),
    ],
    modelFilter: [
      ...new Map(tempModel.map((item) => [item["value"], item])).values(),
    ],
    statusFilter: [
      ...new Map(tempStatus.map((item) => [item["value"], item])).values(),
    ],
    vehicleAgeFilter: [
      ...new Map(tempVehicleAge.map((item) => [item["value"], item])).values(),
    ],
    ageFilter: [
      ...new Map(tempAge.map((item) => [item["value"], item])).values(),
    ],
  };
};

export const submitVehiclesErrorDispatcher = (data) => ({
  type: SUBMIT_VEHICLES_ERROR_DISPATCHER,
  payload: data,
});

export const vehiclesLoading = (loading) => ({
  type: VEHICLES_LOADING,
  payload: loading,
});

export const searchVehicle = (data, query) => {
  // console.log('data: ', data);
  let tempChassis = [];
  let tempSite = [];
  let tempDoor = [];
  let tempModel = [];
  let tempStatus = [];
  let tempVehicleAge = [];
  let tempAge = [];
  let filteredData = query
    ? data.filter(
        (vehicle) =>
          vehicle.ChassisNumber.toLowerCase().includes(query.toLowerCase()) ||
          vehicle.DoorNumber.toLowerCase().includes(query.toLowerCase())
      )
    : data;
  filteredData.forEach((e) => {
    tempSite.push({ value: e.SiteName, text: e.SiteName });
    tempChassis.push({ value: e.ChassisNumber, text: e.ChassisNumber });
    tempDoor.push({ value: e.DoorNumber, text: e.DoorNumber });
    tempModel.push({ value: e.ModelNumber, text: e.ModelNumber });
    e.ActiveStatus
      ? tempStatus.push({ value: e.ActiveStatus, text: "ACTIVE" })
      : tempStatus.push({ value: e.ActiveStatus, text: "IN-ACTIVE" });
    var diff = moment().diff(moment(e.InstallationDate), "days");
    let month = (parseInt((diff % 365) / 30) / 12).toFixed(1);
    let age = parseInt(diff / 365) + (month % 1);
    tempVehicleAge.push({ value: age, text: age });
    tempAge.push({ value: Math.ceil(age), text: Math.ceil(age) });
  });
  return {
    type: SUBMIT_VEHICLES_ACTION_DISPATCHER,
    payload: data,
    filteredData: filteredData,
    siteFilter: [
      ...new Map(tempSite.map((item) => [item["value"], item])).values(),
    ],
    chassisFilter: [
      ...new Map(tempChassis.map((item) => [item["value"], item])).values(),
    ],
    doorFilter: [
      ...new Map(tempDoor.map((item) => [item["value"], item])).values(),
    ],
    modelFilter: [
      ...new Map(tempModel.map((item) => [item["value"], item])).values(),
    ],
    statusFilter: [
      ...new Map(tempStatus.map((item) => [item["value"], item])).values(),
    ],
    vehicleAgeFilter: [
      ...new Map(tempVehicleAge.map((item) => [item["value"], item])).values(),
    ],
    ageFilter: [
      ...new Map(tempAge.map((item) => [item["value"], item])).values(),
    ],
  };
};
