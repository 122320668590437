import {
  NEWREQUEST_LOADING,
  SUBMIT_NEWREQUEST_ACTION_DISPATCHER,
  SUBMIT_NEWREQUEST_ACTION_REQUESTED,
  SUBMIT_NEWREQUEST_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { apiData: {} },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_NEWREQUEST_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_NEWREQUEST_ACTION_DISPATCHER:
      // const regionList = []
      // action.payload.data.forEach((e) => {
      //   regionList.push({ value: e.Region });
      // });
      // console.log('regionList: ', regionList);
      return {
        ...state,
        successData: {
          // regionList: regionList.filter((v, i, a) => a.indexOf(v) === i),
          apiData: action.payload.data
        },
        loading: false,
      };
    case SUBMIT_NEWREQUEST_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case NEWREQUEST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
