export const SUBMIT_CUSTOMER_LIST_USOB_ACTION_REQUESTED = "SUBMIT_CUSTOMER_LIST_USOB_ACTION_REQUESTED";
export const SUBMIT_CUSTOMER_LIST_USOB_ACTION_DISPATCHER = "SUBMIT_CUSTOMER_LIST_USOB_ACTION_DISPATCHER";
export const SUBMIT_CUSTOMER_LIST_USOB_ERROR_DISPATCHER = "SUBMIT_CUSTOMER_LIST_USOB_ERROR_DISPATCHER";
export const CUSTOMER_LIST_USOB_LOADING = "CUSTOMER_LIST_USOB_LOADING";

//Action Creator
export const submitCustomerListUSOBAction = (data) => ({
  type: SUBMIT_CUSTOMER_LIST_USOB_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitCustomerListUSOBActionDispatcher = (data) => ({
  type: SUBMIT_CUSTOMER_LIST_USOB_ACTION_DISPATCHER,
  payload: data,
});

export const submitCustomerListUSOBErrorDispatcher = (data) => ({
  type: SUBMIT_CUSTOMER_LIST_USOB_ERROR_DISPATCHER,
  payload: data,
});

export const customerListUSOBLoading = (loading) => ({
  type: CUSTOMER_LIST_USOB_LOADING,
  payload: loading,
});