import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  vehicleGroupDetailsLoading,
  submitVehicleGroupDetailsActionDispatcher,
  submitVehicleGroupDetailsErrorDispatcher,
  SUBMIT_VEHICLE_GROUP_DETAILS_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postVehicleGroupDetailsAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.get(
    "/CustomerPortal/Getvehiclemasterwithgroup?groupid=" + postData
  );
  // console.log("result: ", result);
  return result;
};

function* submitVehicleGroupDetailsSaga({ postData, page }) {
  try {
    yield put(vehicleGroupDetailsLoading(true));
    const data = yield call(postVehicleGroupDetailsAPI, postData, page);
    yield put(submitVehicleGroupDetailsActionDispatcher(data));
    yield put(submitVehicleGroupDetailsErrorDispatcher({}));
    yield put(vehicleGroupDetailsLoading(false));
  } catch (e) {
    yield put(submitVehicleGroupDetailsActionDispatcher({}));
    yield put(
      submitVehicleGroupDetailsErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(vehicleGroupDetailsLoading(false));
  }
}

function* submitVehicleGroupDetailsWatcher() {
  yield takeEvery(
    SUBMIT_VEHICLE_GROUP_DETAILS_ACTION_REQUESTED,
    submitVehicleGroupDetailsSaga
  );
}
export default submitVehicleGroupDetailsWatcher;
