import {
  OPEN_JOBS_AWAITING_BY_CUSTOMER_LOADING,
  SUBMIT_OPEN_JOBS_AWAITING_BY_CUSTOMER_ACTION_DISPATCHER,
  SUBMIT_OPEN_JOBS_AWAITING_BY_CUSTOMER_ACTION_REQUESTED,
  SUBMIT_OPEN_JOBS_AWAITING_BY_CUSTOMER_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    openJobsAwaitingByCustomerList: [],
    openJobsAwaitingByCustomerMobList: [[]],
    siteFilters: [],
    chassisFilters: [],
    jobCategoryFilters: [],
    apiData: {},
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_OPEN_JOBS_AWAITING_BY_CUSTOMER_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_OPEN_JOBS_AWAITING_BY_CUSTOMER_ACTION_DISPATCHER:
      let mobList = [];
      for (let i = 0; i < action.payload.length; i += 5) {
        mobList.push(action.payload.slice(i, i + 5));
      }
      return {
        ...state,
        successData: {
          openJobsAwaitingByCustomerList: action.payload,
          openJobsAwaitingByCustomerMobList: mobList,
          siteFilters: action.siteFilter,
          chassisFilters: action.chassisFilter,
          jobCategoryFilters: action.jobCategoryFilter,
          apiData: action.payload,
        },
        loading: false,
      };
    case SUBMIT_OPEN_JOBS_AWAITING_BY_CUSTOMER_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case OPEN_JOBS_AWAITING_BY_CUSTOMER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
