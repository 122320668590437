import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  openJobsByCustomerAndDateLoading,
  submitOpenJobsByCustomerAndDateActionDispatcher,
  submitOpenJobsByCustomerAndDateErrorDispatcher,
  SUBMIT_OPEN_JOBS_BY_CUSTOMER_AND_DATE_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postOpenJobsByCustomerAndDateAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/Post?Value=5&ExtraParameter=wer&ExtraPara2=xyz",
    {
      ...postData,
    }
  );
  return result;
};

function* submitOpenJobsByCustomerAndDateSaga({ postData }) {
  try {
    yield put(openJobsByCustomerAndDateLoading(true));
    const data = yield call(postOpenJobsByCustomerAndDateAPI, postData);
    yield put(submitOpenJobsByCustomerAndDateActionDispatcher(data));
    yield put(submitOpenJobsByCustomerAndDateErrorDispatcher({}));
    yield put(openJobsByCustomerAndDateLoading(false));
  } catch (e) {
    yield put(submitOpenJobsByCustomerAndDateActionDispatcher({}));
    yield put(
      submitOpenJobsByCustomerAndDateErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(openJobsByCustomerAndDateLoading(false));
  }
}

function* submitOpenJobsByCustomerAndDateWatcher() {
  yield takeEvery(
    SUBMIT_OPEN_JOBS_BY_CUSTOMER_AND_DATE_ACTION_REQUESTED,
    submitOpenJobsByCustomerAndDateSaga
  );
}
export default submitOpenJobsByCustomerAndDateWatcher;
