import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  checkupTypesLoading,
  submitCheckupTypesActionDispatcher,
  submitCheckupTypesErrorDispatcher,
  SUBMIT_CHECKUP_TYPES_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postCheckupTypesAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.get(
    "/checklist-type",
    {
      ...postData,
    }
  );
  return result;
};

function* submitCheckupTypesSaga({ postData }) {
  try {
    yield put(checkupTypesLoading(true));
    const data = yield call(postCheckupTypesAPI, postData);
    yield put(submitCheckupTypesActionDispatcher(data));
    yield put(submitCheckupTypesErrorDispatcher({}));
    yield put(checkupTypesLoading(false));
  } catch (e) {
    yield put(submitCheckupTypesActionDispatcher({}));
    yield put(
      submitCheckupTypesErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(checkupTypesLoading(false));
  }
}

function* submitCheckupTypesWatcher() {
  yield takeEvery(SUBMIT_CHECKUP_TYPES_ACTION_REQUESTED, submitCheckupTypesSaga);
}
export default submitCheckupTypesWatcher;
