import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  tripDataLoading,
  submitTripDataActionDispatcher,
  submitTripDataErrorDispatcher,
  SUBMIT_TRIP_DATA_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postTripDataAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/TripCountChassisandDateWishReport?count=true",
    {
      ...postData,
    }
  );
  return result;
};

function* submitTripDataSaga({ postData }) {
  try {
    yield put(tripDataLoading(true));
    const data = yield call(postTripDataAPI, postData);
    yield put(submitTripDataActionDispatcher(data));
    yield put(submitTripDataErrorDispatcher({}));
    yield put(tripDataLoading(false));
  } catch (e) {
    yield put(submitTripDataActionDispatcher({}));
    yield put(submitTripDataErrorDispatcher(e));
    yield put(tripDataLoading(false));
  }
}

function* submitTripDataWatcher() {
  yield takeEvery(SUBMIT_TRIP_DATA_ACTION_REQUESTED, submitTripDataSaga);
}
export default submitTripDataWatcher;
