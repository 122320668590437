import moment from "moment";
import {
  USOB_DEALER_LOCATOR_LOADING,
  SUBMIT_USOB_DEALER_LOCATOR_ACTION_DISPATCHER,
  SUBMIT_USOB_DEALER_LOCATOR_ACTION_REQUESTED,
  SUBMIT_USOB_DEALER_LOCATOR_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    data: [],
    cities: [],
    states: [],
    apiData: {},
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  // let mobList = [];
  switch (action.type) {
    case SUBMIT_USOB_DEALER_LOCATOR_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_USOB_DEALER_LOCATOR_ACTION_DISPATCHER:
      let data = action.payload?.success;
      let dealersData = [];
      let cities = [];
      let states = [];
      var rad = function (x) {
        return (x * Math.PI) / 180;
      };
      var getDistance = function (p1, p2) {
        var R = 6378137; // Earth’s mean radius in meter
        var dLat = rad(p2.lat - p1.lat);
        var dLong = rad(p2.lng - p1.lng);
        var a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(rad(p1.lat)) *
            Math.cos(rad(p2.lat)) *
            Math.sin(dLong / 2) *
            Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return parseFloat(d / 1000).toFixed(2); // returns the distance in kilometer
      };
      data?.dealerLocators.forEach((e) => {
        cities.push({
          value: e.city,
        });
        states.push({
          value: e.state_name,
        });
        dealersData.push({
          ...e,
          distanceToDealer: getDistance(action.latLng, {
            lat: e.latitude,
            lng: e.longitude,
          }),
          openStatus: moment().isBetween(
            moment(e.lunch_time_from, "HH:mm:ss"),
            moment(e.lunch_time_to, "HH:mm:ss")
          )
            ? "Lunch"
            : moment().isBetween(
                moment(e.operating_start_time, "HH:mm:ss"),
                moment(e.operating_end_time, "HH:mm:ss")
              )
            ? "Open"
            : "Closed",
        });
      });
      dealersData.sort((a, b) => a.distanceToDealer - b.distanceToDealer);
      cities.sort(function (a, b) {
        var x = a.value.toLowerCase();
        var y = b.value.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      states.sort(function (a, b) {
        var x = a.value.toLowerCase();
        var y = b.value.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      // console.log("dealersData: ", dealersData);
      // console.log('data: ', data);
      return {
        ...state,
        successData: {
          data: dealersData,
          cities: [
            ...new Map(cities.map((item) => [item["value"], item])).values(),
          ],
          states: [
            ...new Map(states.map((item) => [item["value"], item])).values(),
          ],
          apiData: data,
        },
        loading: false,
      };
    case SUBMIT_USOB_DEALER_LOCATOR_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case USOB_DEALER_LOCATOR_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
