import {
  USOB_OPEN_JOBS_LOADING,
  SUBMIT_USOB_OPEN_JOBS_ACTION_DISPATCHER,
  SUBMIT_USOB_OPEN_JOBS_ACTION_REQUESTED,
  SUBMIT_USOB_OPEN_JOBS_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    total: 1,
    currPage: 1,
    pageSize: 10,
    excelData: [],
    regNoFilter: [],
    chassisFilter: [],
    servLocFilter: [],
    conDescFilter: [],
    currStatFilter: [],
    openSinceFilter: [],
    apiData: {},
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_USOB_OPEN_JOBS_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_USOB_OPEN_JOBS_ACTION_DISPATCHER:
      let data = action.payload.data;
      let exl = [
        [
          "Customer",
          "Registration Number",
          "Chassis Number",
          "Vehicle Group",
          "Service Location",
          "Concern Description",
          "Current Status",
          "Open Since",
        ],
      ];
      data.success?.data.forEach((e) => {
        exl.push([
          e.customer_name,
          e.registration_number,
          e.chassis_id,
          e.vehicle_group,
          e.workshop_name,
          e.concern_description,
          e.delay_reason_name,
          e.open_since + " days",
        ]);
      });
      // console.log("action.payload.data: ", action.payload.data);
      return {
        ...state,
        successData: {
          // total: parseInt(data.success.meta.pagination.total),
          // currPage: parseInt(data.success.meta.pagination.current_page),
          // pageSize: parseInt(data.success.meta.pagination.per_page) || 10,
          excelData: exl,
          regNoFilter: action.regNoFilter,
          chassisFilter: action.chassisFilter,
          servLocFilter: action.servLocFilter,
          conDescFilter: action.conDescFilter,
          currStatFilter: action.currStatFilter,
          openSinceFilter: action.openSinceFilter,
          apiData: data.success,
        },
        loading: false,
      };
    case SUBMIT_USOB_OPEN_JOBS_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case USOB_OPEN_JOBS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
