export const SUBMIT_FUEL_EFFICIENCY_SITES_ACTION_REQUESTED =
  "SUBMIT_FUEL_EFFICIENCY_SITES_ACTION_REQUESTED";
export const SUBMIT_FUEL_EFFICIENCY_SITES_ACTION_DISPATCHER =
  "SUBMIT_FUEL_EFFICIENCY_SITES_ACTION_DISPATCHER";
export const SUBMIT_FUEL_EFFICIENCY_SITES_ERROR_DISPATCHER =
  "SUBMIT_FUEL_EFFICIENCY_SITES_ERROR_DISPATCHER";
export const FUEL_EFFICIENCY_SITES_LOADING = "FUEL_EFFICIENCY_SITES_LOADING";

//Action Creator
export const submitFuelEfficiencySitesAction = (data) => ({
  type: SUBMIT_FUEL_EFFICIENCY_SITES_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitFuelEfficiencySitesActionDispatcher = (data) => ({
  type: SUBMIT_FUEL_EFFICIENCY_SITES_ACTION_DISPATCHER,
  payload: data,
});

export const submitFuelEfficiencySitesErrorDispatcher = (data) => ({
  type: SUBMIT_FUEL_EFFICIENCY_SITES_ERROR_DISPATCHER,
  payload: data,
});

export const FuelEfficiencySitesLoading = (loading) => ({
  type: FUEL_EFFICIENCY_SITES_LOADING,
  payload: loading,
});
