import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBDashboardSummaryLoading,
  submitUSOBDashboardSummaryActionDispatcher,
  submitUSOBDashboardSummaryErrorDispatcher,
  SUBMIT_USOB_DASHBOARD_SUMMARY_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBDashboardSummaryAPI = async (postData) => {
  // console.log("postData: ", postData);
  let apiUrl = "/dashboard/summary?";
  if (postData) {
    apiUrl +=
      (postData.segment ? "segment=" + postData.segment + "&" : "") +
      (postData.vehicle_type
        ? "vehicle_type=" + postData.vehicle_type + "&"
        : "") +
      (postData.customer_id
        ? "customer_id=" + postData.customer_id + "&"
        : "") +
      (postData.vehicle_group
        ? "vehicle_group=" + postData.vehicle_group + "&"
        : "") +
      (postData.date_from ? "date_from=" + postData.date_from + "&" : "") +
      (postData.date_to ? "date_to=" + postData.date_to + "&" : "");
  }
  const result = await AXIOS_INSTANCE.get(apiUrl.slice(0, -1))
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log("err: ", err);
    });
  // console.log("result: ", result);
  return result;
};

function* submitUSOBDashboardSummarySaga({ postData }) {
  try {
    yield put(USOBDashboardSummaryLoading(true));
    const data = yield call(postUSOBDashboardSummaryAPI, postData);
    yield put(submitUSOBDashboardSummaryActionDispatcher(data));
    yield put(submitUSOBDashboardSummaryErrorDispatcher({}));
    yield put(USOBDashboardSummaryLoading(false));
  } catch (e) {
    yield put(submitUSOBDashboardSummaryActionDispatcher({}));
    yield put(
      submitUSOBDashboardSummaryErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(USOBDashboardSummaryLoading(false));
  }
}

function* submitUSOBDashboardSummaryWatcher() {
  yield takeEvery(
    SUBMIT_USOB_DASHBOARD_SUMMARY_ACTION_REQUESTED,
    submitUSOBDashboardSummarySaga
  );
}
export default submitUSOBDashboardSummaryWatcher;
