//Action Types
export const SUBMIT_ADD_VEHICLES_IN_GROUP_ACTION_REQUESTED =
  "SUBMIT_ADD_VEHICLES_IN_GROUP_ACTION_REQUESTED";
export const SUBMIT_ADD_VEHICLES_IN_GROUP_ACTION_DISPATCHER =
  "SUBMIT_ADD_VEHICLES_IN_GROUP_ACTION_DISPATCHER";
export const SUBMIT_ADD_VEHICLES_IN_GROUP_ERROR_DISPATCHER =
  "SUBMIT_ADD_VEHICLES_IN_GROUP_ERROR_DISPATCHER";
export const SUBMIT_EMPTY_ADD_VEHICLES_IN_GROUP_STATE_ACTION =
  "SUBMIT_EMPTY_ADD_VEHICLES_IN_GROUP_STATE_ACTION";
export const ADD_VEHICLES_IN_GROUP_LOADING =
  "ADD_VEHICLES_IN_GROUP_LOADING";

//Action Creator
export const submitAddVehiclesInGroupAction = (data) => ({
  type: SUBMIT_ADD_VEHICLES_IN_GROUP_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitAddVehiclesInGroupActionDispatcher = (data) => ({
  type: SUBMIT_ADD_VEHICLES_IN_GROUP_ACTION_DISPATCHER,
  payload: data,
});

//Error Dispatcher
export const submitAddVehiclesInGroupErrorDispatcher = (data) => ({
  type: SUBMIT_ADD_VEHICLES_IN_GROUP_ERROR_DISPATCHER,
  payload: data,
});

//Loading Action
export const addVehiclesInGroupLoading = (loading) => ({
  type: ADD_VEHICLES_IN_GROUP_LOADING,
  payload: loading,
});

export const submitEmptyAddVehiclesInGroupStateAction = () => ({
  type: SUBMIT_EMPTY_ADD_VEHICLES_IN_GROUP_STATE_ACTION,
});
