import {
  CONTACT_US_LOADING,
  SEARCH_CONTACT,
  SUBMIT_CONTACT_US_ACTION_DISPATCHER,
  SUBMIT_CONTACT_US_ACTION_REQUESTED,
  SUBMIT_CONTACT_US_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    contactUsList: [],
    filteredContacts: [],
    filteredMobContacts: [],
  },
  failureData: {},
  loading: false,
  isLogout: false,
};

const reducer = (state = initialState, action) => {
  let mobList = [];
  switch (action.type) {
    case SUBMIT_CONTACT_US_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_CONTACT_US_ACTION_DISPATCHER:
      for (let i = 0; i < action.filteredData.length; i += 5) {
        mobList.push(action.filteredData.slice(i, i + 5));
      }
      return {
        ...state,
        successData: {
          contactUsList: action.payload,
          filteredContacts: action.filteredData,
          filteredMobContacts: mobList,
        },
        loading: false,
      };
    case SUBMIT_CONTACT_US_ERROR_DISPATCHER:
      return {
        ...state,
        formError: action.payload,
        loading: false,
      };
    case CONTACT_US_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SEARCH_CONTACT:
      for (let i = 0; i < action.filteredData.length; i += 5) {
        mobList.push(action.filteredData.slice(i, i + 5));
      }
      return {
        ...state,
        successData: {
          contactUsList: action.payload,
          filteredContacts: action.filteredData,
          filteredMobContacts: mobList,
        },
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
