//Action Types
export const SUBMIT_USOB_CREATE_VEHICLE_GROUP_ACTION_REQUESTED =
  "SUBMIT_USOB_CREATE_VEHICLE_GROUP_ACTION_REQUESTED";
export const SUBMIT_USOB_CREATE_VEHICLE_GROUP_ACTION_DISPATCHER =
  "SUBMIT_USOB_CREATE_VEHICLE_GROUP_ACTION_DISPATCHER";
export const SUBMIT_USOB_CREATE_VEHICLE_GROUP_ERROR_DISPATCHER =
  "SUBMIT_USOB_CREATE_VEHICLE_GROUP_ERROR_DISPATCHER";
export const SUBMIT_EMPTY_USOB_CREATE_VEHICLE_GROUP_STATE_ACTION =
  "SUBMIT_EMPTY_USOB_CREATE_VEHICLE_GROUP_STATE_ACTION";
export const USOB_CREATE_VEHICLE_GROUP_LOADING =
  "USOB_CREATE_VEHICLE_GROUP_LOADING";

//Action Creator
export const submitUSOBCreateVehicleGroupAction = (data) => ({
  type: SUBMIT_USOB_CREATE_VEHICLE_GROUP_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitUSOBCreateVehicleGroupActionDispatcher = (data) => ({
  type: SUBMIT_USOB_CREATE_VEHICLE_GROUP_ACTION_DISPATCHER,
  payload: data,
});

//Error Dispatcher
export const submitUSOBCreateVehicleGroupErrorDispatcher = (data) => ({
  type: SUBMIT_USOB_CREATE_VEHICLE_GROUP_ERROR_DISPATCHER,
  payload: data,
});

//Loading Action
export const USOBCreateVehicleGroupLoading = (loading) => ({
  type: USOB_CREATE_VEHICLE_GROUP_LOADING,
  payload: loading,
});

export const submitEmptyUSOBCreateVehicleGroupStateAction = () => ({
  type: SUBMIT_EMPTY_USOB_CREATE_VEHICLE_GROUP_STATE_ACTION,
});
