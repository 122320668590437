import {
  DEVICE_TRACKER_LOADING,
  SUBMIT_DEVICE_TRACKER_ACTION_DISPATCHER,
  SUBMIT_DEVICE_TRACKER_ACTION_REQUESTED,
  SUBMIT_DEVICE_TRACKER_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    data: [],
    apiData: {},
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  // let mobList = [];
  switch (action.type) {
    case SUBMIT_DEVICE_TRACKER_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_DEVICE_TRACKER_ACTION_DISPATCHER:
      let data = action?.payload;
      // console.log("data: ", data);
      // let devicesData = [];
      // var rad = function (x) {
      //   return (x * Math.PI) / 180;
      // };
      // var getDistance = function (p1, p2) {
      //   var R = 6378137; // Earth’s mean radius in meter
      //   var dLat = rad(p2.lat - p1.lat);
      //   var dLong = rad(p2.lng - p1.lng);
      //   var a =
      //     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      //     Math.cos(rad(p1.lat)) *
      //       Math.cos(rad(p2.lat)) *
      //       Math.sin(dLong / 2) *
      //       Math.sin(dLong / 2);
      //   var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      //   var d = R * c;
      //   return parseFloat(d / 1000).toFixed(2); // returns the distance in kilometer
      // };
      // devicesData.sort((a, b) => a.distanceToDealer - b.distanceToDealer);
      // console.log("devicesData: ", devicesData);
      // console.log('data: ', data);
      return {
        ...state,
        successData: {
          data: data,
          apiData: data,
        },
        loading: false,
      };
    case SUBMIT_DEVICE_TRACKER_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case DEVICE_TRACKER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
