import moment from "moment";
import {
  SERVICE_HISTORY_LOADING,
  SUBMIT_SERVICE_HISTORY_ACTION_DISPATCHER,
  SUBMIT_SERVICE_HISTORY_ACTION_REQUESTED,
  SUBMIT_SERVICE_HISTORY_ERROR_DISPATCHER,
} from "./Action";
import { getTimeTaken } from "../../utils/Helpers";

const initialState = {
  successData: {
    serviceHistoryList: [],
    myServiceHistory: [],
    excelData: [],
    siteFilters: [],
    chassisFilters: [],
    doorFilters: [],
    repairCategoryFilters: [],
    rankFilters: [],
    repairEndDateFilters: [],
    filteredList: [],
    filteredMobList: [],
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  const vehicleList = [];
  let tempExcelArr = [
    [
      "Repair ID",
      "Door Number",
      "Chassis Number",
      "Site Name",
      "Repair Start Date",
      "Repair End Date",
      "Total Time",
      "Repair Category",
      "Job Card Description",
    ]
  ];
  let mobList = [];
  switch (action.type) {
    case SUBMIT_SERVICE_HISTORY_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_SERVICE_HISTORY_ACTION_DISPATCHER:
      for (let i = 0; i < action.filteredData.length; i += 5) {
        mobList.push(action.filteredData.slice(i, i + 5));
      }
      action.payload.forEach((e) => {
        vehicleList.push({ value: e.VehicleId + " " + e.VehicleType });
        tempExcelArr.push([
          e.RepairId,
          e.DoorNumber,
          e.ChassisNumber,
          e.SiteName,
          moment(e.RepairStartDate).format("DD-MM-YYYY"),
          moment(e.RepairEndDate).format("DD-MM-YYYY"),
          getTimeTaken(e.RepairStartDate, e.RepairEndDate) || " - ",
          e.RepairCategory,
          e.Description,
        ]);
      });
      return {
        ...state,
        successData: {
          myServiceHistory: vehicleList,
          excelData: tempExcelArr,
          serviceHistoryList: action.payload,
          siteFilters: action.siteFilter,
          chassisFilters: action.chassisFilter,
          doorFilters: action.doorFilter,
          repairCategoryFilters: action.repairCategoryFilter,
          rankFilters: action.rankFilter,
          repairEndDateFilters: action.repairEndDateFilter,
          filteredList: action.filteredData,
          filteredMobList: mobList,
        },
        loading: false,
      };
    case SUBMIT_SERVICE_HISTORY_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case SERVICE_HISTORY_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
