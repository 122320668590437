import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  uptimeByMonthLoading,
  submitUptimeByMonthActionDispatcher,
  submitUptimeByMonthErrorDispatcher,
  SUBMIT_UPTIME_BY_MONTH_ACTION_REQUESTED,
} from "./Action"; // Path should come here
import moment from "moment";

const postUptimeByMonthAPI = async (postData) => {
  // console.log('postData: ', postData);
  postData.FromDate = moment(postData.FromDate).format("YYYY-MM-DD") + " 12:00:00";
  postData.ToDate = moment(postData.ToDate).format("YYYY-MM-DD") + " 23:59:59";
  // console.log('postData: ', postData);
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/UptimeMonthGraph?Graph=45",
    {
      ...postData,
    }
  );
  return result;
};

function* submitUptimeByMonthSaga({ postData }) {
  try {
    yield put(uptimeByMonthLoading(true));
    const data = yield call(postUptimeByMonthAPI, postData);
    yield put(submitUptimeByMonthActionDispatcher(data));
    yield put(submitUptimeByMonthErrorDispatcher({}));
    yield put(uptimeByMonthLoading(false));
  } catch (e) {
    yield put(submitUptimeByMonthActionDispatcher({}));
    yield put(submitUptimeByMonthErrorDispatcher(e.response.data));
    yield put(uptimeByMonthLoading(false));
  }
}

function* submitUptimeByMonthWatcher() {
  yield takeEvery(
    SUBMIT_UPTIME_BY_MONTH_ACTION_REQUESTED,
    submitUptimeByMonthSaga
  );
}
export default submitUptimeByMonthWatcher;
