import {
  VEHICLE_GROUP_DETAILS_LOADING,
  SUBMIT_VEHICLE_GROUP_DETAILS_ACTION_DISPATCHER,
  SUBMIT_VEHICLE_GROUP_DETAILS_ACTION_REQUESTED,
  SUBMIT_VEHICLE_GROUP_DETAILS_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    vehicleGroupDet: {},
    apiData: {},
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_VEHICLE_GROUP_DETAILS_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_VEHICLE_GROUP_DETAILS_ACTION_DISPATCHER:
      let data = action.payload.data;
      // console.log("data: ", data);
      return {
        ...state,
        successData: {
          apiData: data,
          vehicleGroupDet: data,
        },
        loading: false,
      };
    case SUBMIT_VEHICLE_GROUP_DETAILS_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case VEHICLE_GROUP_DETAILS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
