import { call, put, takeEvery } from "redux-saga/effects";

import {
  cmsRegisterComplaintLoading,
  submitCMSRegisterComplaintActionDispatcher,
  submitCMSRegisterComplaintErrorDispatcher,
  SUBMIT_CMS_REGISTER_COMPLAINT_ACTION_REQUESTED,
} from "./Action"; // Path should come here
import axios from "axios";

const postCMSRegisterComplaintAPI = async (postData) => {
  console.log("postData: ", postData);
  const result = await axios.post(
    "http://10.10.11.57/volvo_css_cms/public/api/v1/complaint/create-complaint",
    {
      ...postData,
    }
  );
  console.log(result);
  return result;
};

function* submitCMSRegisterComplaintSaga({ postData }) {
  try {
    yield put(cmsRegisterComplaintLoading(true));
    const data = yield call(postCMSRegisterComplaintAPI, postData);
    yield put(submitCMSRegisterComplaintActionDispatcher(data));
    yield put(submitCMSRegisterComplaintErrorDispatcher({}));
    yield put(cmsRegisterComplaintLoading(false));
  } catch (e) {
    yield put(submitCMSRegisterComplaintActionDispatcher({}));
    yield put(submitCMSRegisterComplaintErrorDispatcher(e));
    yield put(cmsRegisterComplaintLoading(false));
  }
}

function* submitCMSRegisterComplaintWatcher() {
  yield takeEvery(
    SUBMIT_CMS_REGISTER_COMPLAINT_ACTION_REQUESTED,
    submitCMSRegisterComplaintSaga
  );
}
export default submitCMSRegisterComplaintWatcher;
