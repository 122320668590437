import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  uptimeByAgeLoading,
  submitUptimeByAgeActionDispatcher,
  submitUptimeByAgeErrorDispatcher,
  SUBMIT_UPTIME_BY_AGE_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUptimeByAgeAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post("/CustomerPortal/Post?Uptime=EngineHourDetails&p1=cvy&Graph=2", {
    ...postData,
  });
  return result;
};

function* submitUptimeByAgeSaga({ postData }) {
  try {
    yield put(uptimeByAgeLoading(true));
    const data = yield call(postUptimeByAgeAPI, postData);
    yield put(submitUptimeByAgeActionDispatcher(data));
    yield put(submitUptimeByAgeErrorDispatcher({}));
    yield put(uptimeByAgeLoading(false));
  } catch (e) {
    yield put(submitUptimeByAgeActionDispatcher({}));
    yield put(
      submitUptimeByAgeErrorDispatcher(e.response.data)
    );
    yield put(uptimeByAgeLoading(false));
  }
}

function* submitUptimeByAgeWatcher() {
  yield takeEvery(SUBMIT_UPTIME_BY_AGE_ACTION_REQUESTED, submitUptimeByAgeSaga);
}
export default submitUptimeByAgeWatcher;
