import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBVehicleGroupsLoading,
  submitUSOBVehicleGroupsActionDispatcher,
  submitUSOBVehicleGroupsErrorDispatcher,
  SUBMIT_USOB_VEHICLE_GROUPS_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBVehicleGroupsAPI = async (postData, page) => {
  const result = await AXIOS_INSTANCE.get("/groups?page=" + page)
    // console.log("result: ", result);
  return result ;
};

function* submitUSOBVehicleGroupsSaga({ postData, page }) {
  try {
    yield put(USOBVehicleGroupsLoading(true));
    const data = yield call(postUSOBVehicleGroupsAPI, postData, page);
    yield put(submitUSOBVehicleGroupsActionDispatcher(data));
    yield put(submitUSOBVehicleGroupsErrorDispatcher({}));
    yield put(USOBVehicleGroupsLoading(false));
  } catch (e) {
    yield put(submitUSOBVehicleGroupsActionDispatcher({}));
    yield put(
      submitUSOBVehicleGroupsErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(USOBVehicleGroupsLoading(false));
  }
}

function* submitUSOBVehicleGroupsWatcher() {
  yield takeEvery(
    SUBMIT_USOB_VEHICLE_GROUPS_ACTION_REQUESTED,
    submitUSOBVehicleGroupsSaga
  );
}
export default submitUSOBVehicleGroupsWatcher;
