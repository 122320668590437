export const SUBMIT_REGIONS_ACTION_REQUESTED = "SUBMIT_REGIONS_ACTION_REQUESTED";
export const SUBMIT_REGIONS_ACTION_DISPATCHER =
  "SUBMIT_REGIONS_ACTION_DISPATCHER";
export const SUBMIT_REGIONS_ERROR_DISPATCHER = "SUBMIT_REGIONS_ERROR_DISPATCHER";
export const REGIONS_LOADING = "REGIONS_LOADING";

//Action Creator
export const submitRegionsAction = (data) => ({
  type: SUBMIT_REGIONS_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitRegionsActionDispatcher = (data) => ({
  type: SUBMIT_REGIONS_ACTION_DISPATCHER,
  payload: data,
});

export const submitRegionsErrorDispatcher = (data) => ({
  type: SUBMIT_REGIONS_ERROR_DISPATCHER,
  payload: data,
});

export const regionsLoading = (loading) => ({
  type: REGIONS_LOADING,
  payload: loading,
});
