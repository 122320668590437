import { getFormattedDateTime, getTimeTaken } from "../../utils/Helpers";
import {
  USOB_WORKSHOP_SERVICES_LOADING,
  SUBMIT_USOB_WORKSHOP_SERVICES_ACTION_DISPATCHER,
  SUBMIT_USOB_WORKSHOP_SERVICES_ACTION_REQUESTED,
  SUBMIT_USOB_WORKSHOP_SERVICES_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    data: [],
    total: 1,
    currPage: 1,
    pageSize: 10,
    apiData: {},
    excelData: [],
    regNoFilter: [],
    repNoFilter: [],
    chassisFilter: [],
    vehicleGrpFilter: [],
    wsNameFilter: [],
    repairCatFilter: [],
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  // let mobList = [];
  switch (action.type) {
    case SUBMIT_USOB_WORKSHOP_SERVICES_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_USOB_WORKSHOP_SERVICES_ACTION_DISPATCHER:
      let data = action.payload;
      // console.log("data.success.data: ", data.success.data);
      let excelData = [
        [
          "Repair ID",
          "Registration Number",
          "Chassis Number",
          "Customer Name",
          "Repair Start Date",
          "Repair End Date",
          "Total Time",
          "Workshop Name",
          "Area",
          "Region",
          "Repair Category",
          "Job Card Description",
        ],
      ];
      action.filteredData.forEach((e) => {
        excelData.push([
          e.repair_no,
          e.registration_number,
          e.chassis_id,
          e.customer_name,
          getFormattedDateTime(e.repair_start_date_time),
          getFormattedDateTime(e.repair_end_date_time),
          getTimeTaken(e.repair_start_date_time, e.repair_end_date_time) ||
            " - ",
          e.workshop_name,
          e.area_name,
          e.region_name,
          e.repair_category_name,
          e.concern_description,
        ]);
      });
      return {
        ...state,
        successData: {
          // total: parseInt(data.success.meta.pagination.total),
          // currPage: parseInt(data.success.meta.pagination.current_page),
          // pageSize: parseInt(data.success.meta.pagination.per_page) || 10,
          data: action.filteredData,
          apiData: data.success,
          excelData: excelData,
          regNoFilter: action.regNoFilter,
          repNoFilter: action.repNoFilter,
          chassisFilter: action.chassisNoFilter,
          vehicleGrpFilter: action.vehicleGrpFilter,
          wsNameFilter: action.wsNameFilter,
          repairCatFilter: action.repCatFilter,
        },
        loading: false,
      };
    case SUBMIT_USOB_WORKSHOP_SERVICES_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case USOB_WORKSHOP_SERVICES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
