import {
  CUSTOMER_LIST_USOB_LOADING,
  SUBMIT_CUSTOMER_LIST_USOB_ACTION_DISPATCHER,
  SUBMIT_CUSTOMER_LIST_USOB_ACTION_REQUESTED,
  SUBMIT_CUSTOMER_LIST_USOB_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { customerCredentials: [] },
  failureData: {},
  loading: false,
  isLogout: false,
};

const reducer = (state = initialState, action) => {
  const customerCredList = [];
  switch (action.type) {
    case SUBMIT_CUSTOMER_LIST_USOB_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_CUSTOMER_LIST_USOB_ACTION_DISPATCHER:
      action.payload.data.forEach((e) => {
        customerCredList.push({
          value:
            e.lstUserModel[0].FirstName +
            " " +
            (e.lstUserModel[0].LastName || ""),
          id: e.lstUserModel[0].UserId,
          data: e,
        });
      });
      var customerCredListbyName = customerCredList.slice(0);
      customerCredListbyName.sort(function (a, b) {
        var x = a.value.toLowerCase();
        var y = b.value.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      return {
        ...state,
        successData: {
          customerCredentials: customerCredListbyName,
        },
        loading: false,
      };
    case SUBMIT_CUSTOMER_LIST_USOB_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case CUSTOMER_LIST_USOB_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
