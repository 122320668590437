import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  newsLoading,
  submitNewsActionDispatcher,
  submitNewsErrorDispatcher,
  SUBMIT_NEWS_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postNewsAPI = async (postData, filterQuery) => {
  // console.log("sagafilterQuery: ", filterQuery);
  // console.log("postData: ", postData);
  let apiUrl = "/news";
  if (postData) {
    apiUrl +=
      "?" +
      (postData.display_ticker
        ? "display_ticker=" + postData.display_ticker + "&"
        : "") +
      (postData.display_news
        ? "display_news=" + postData.display_news + "&"
        : "") +
      (postData.vehicle_category
        ? "vehicle_category=" + postData.vehicle_category + "&"
        : "") +
      (filterQuery?.news_start_date_time
        ? "news_start_date_time=" + filterQuery?.news_start_date_time + "&"
        : "") +
      (filterQuery?.news_end_date_time
        ? "news_end_date_time=" + filterQuery?.news_end_date_time + "&"
        : "");
  }

  const result = await AXIOS_INSTANCE.get(apiUrl.slice(0, -1));
  // console.log("result: ", result);
  return result;
};

function* submitNewsSaga({ postData, filterQuery }) {
  try {
    yield put(newsLoading(true));
    const data = yield call(postNewsAPI, postData, filterQuery);
    // console.log('filterQuery: ', filterQuery);
    yield put(submitNewsActionDispatcher(data, filterQuery));
    yield put(submitNewsErrorDispatcher({}));
    yield put(newsLoading(false));
  } catch (e) {
    yield put(submitNewsActionDispatcher({}));
    yield put(submitNewsErrorDispatcher(e));
    yield put(newsLoading(false));
  }
}

function* submitNewsWatcher() {
  yield takeEvery(SUBMIT_NEWS_ACTION_REQUESTED, submitNewsSaga);
}
export default submitNewsWatcher;
