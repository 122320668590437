import {
  USOB_OPEN_JOBS_BY_TYPE_LOADING,
  SUBMIT_PREVENTIVE_MAINTAINANCE_ACTION_DISPATCHER,
  SUBMIT_AGGREGATE_REPAIR_ACTION_DISPATCHER,
  SUBMIT_RUNNING_REPAIR_ACTION_DISPATCHER,
  SUBMIT_ACCIDENTAL_REPAIR_ACTION_DISPATCHER,
  SUBMIT_PREDICTIVE_MAINTAINANCE_ACTION_DISPATCHER,
  SUBMIT_SUPER_STRUCTURE_ACTION_DISPATCHER,
  SUBMIT_CUSTOMER_AR_ACTION_DISPATCHER,
  SUBMIT_CUSTOMER_PARTS_ACTION_DISPATCHER,
  SUBMIT_CUSTOMER_TOOLS_ACTION_DISPATCHER,
  SUBMIT_CUSTOMER_APPROVAL_ACTION_DISPATCHER,
  SUBMIT_CUSTOMER_REPAIR_ACTION_DISPATCHER,
  SUBMIT_CUSTOMER_MANPOWER_ACTION_DISPATCHER,
  SUBMIT_VOLVO_APPROVAL_ACTION_DISPATCHER,
  SUBMIT_VOLVO_FAULT_DIGANOSIS_ACTION_DISPATCHER,
  SUBMIT_VOLVO_MANPOWER_ACTION_DISPATCHER,
  SUBMIT_VOLVO_PARTS_NA_ACTION_DISPATCHER,
  SUBMIT_VOLVO_TOOLS_EQUIP_ACTION_DISPATCHER,
  SUBMIT_VENDOR_PARTS_ACTION_DISPATCHER,
  SUBMIT_VENDOR_MANPOWER_ACTION_DISPATCHER,
  SUBMIT_VENDOR_APPROVAL_ACTION_DISPATCHER,
  SUBMIT_USOB_OPEN_JOBS_BY_TYPE_ACTION_REQUESTED,
  SUBMIT_USOB_OPEN_JOBS_BY_TYPE_ERROR_DISPATCHER,
  SUBMIT_USOB_OPEN_JOBS_BY_TYPE_ACTION_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    // openJobsData: {
    preventiveMaintainanceData: {
      data: [],
      perPage: 10,
      currPage: 1,
      totalRows: 1,
    },
    aggregateRepairData: { data: [], perPage: 10, currPage: 1, totalRows: 1 },
    runningRepairData: { data: [], perPage: 10, currPage: 1, totalRows: 1 },
    accidentalRepairData: {
      data: [],
      perPage: 10,
      currPage: 1,
      totalRows: 1,
    },
    predictiveMaintainanceData: {
      data: [],
      perPage: 10,
      currPage: 1,
      totalRows: 1,
    },
    superStructureData: { data: [], perPage: 10, currPage: 1, totalRows: 1 },
    // },
    // customerJobsData: {
    customerARData: { data: [], perPage: 10, currPage: 1, totalRows: 1 },
    customerPartsData: { data: [], perPage: 10, currPage: 1, totalRows: 1 },
    customerToolsEquipData: {
      data: [],
      perPage: 10,
      currPage: 1,
      totalRows: 1,
    },
    customerApprovalData: {
      data: [],
      perPage: 10,
      currPage: 1,
      totalRows: 1,
    },
    customerRepairData: { data: [], perPage: 10, currPage: 1, totalRows: 1 },
    customerManpowerData: {
      data: [],
      perPage: 10,
      currPage: 1,
      totalRows: 1,
    },
    // },
    // volvoData: {
    volvoApprovalData: {
      data: [],
      perPage: 10,
      currPage: 1,
      totalRows: 1,
    },
    volvoFaultDiganosisData: {
      data: [],
      perPage: 10,
      currPage: 1,
      totalRows: 1,
    },
    volvoManPowerData: {
      data: [],
      perPage: 10,
      currPage: 1,
      totalRows: 1,
    },
    volvoToolsEquipData: {
      data: [],
      perPage: 10,
      currPage: 1,
      totalRows: 1,
    },
    volvoPartsData: { data: [], perPage: 10, currPage: 1, totalRows: 1 },
    // },
    // vendorData: {
    vendorPartsData: { data: [], perPage: 10, currPage: 1, totalRows: 1 },
    vendorManpowerData: {
      data: [],
      perPage: 10,
      currPage: 1,
      totalRows: 1,
    },
    vendorApprovalData: {
      data: [],
      perPage: 10,
      currPage: 1,
      totalRows: 1,
    },
    // },
    apiData: {
      data: [],
      perPage: 10,
      currPage: 1,
      totalRows: 1,
    },
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  // openJobsData: {
  let preventiveMaintainanceData = {
    data: [],
    perPage: 10,
    currPage: 1,
    totalRows: 1,
  };
  let aggregateRepairData = {
    data: [],
    perPage: 10,
    currPage: 1,
    totalRows: 1,
  };
  let runningRepairData = { data: [], perPage: 10, currPage: 1, totalRows: 1 };
  let accidentalRepairData = {
    data: [],
    perPage: 10,
    currPage: 1,
    totalRows: 1,
  };
  let predictiveMaintainanceData = {
    data: [],
    perPage: 10,
    currPage: 1,
    totalRows: 1,
  };
  let superStructureData = { data: [], perPage: 10, currPage: 1, totalRows: 1 };
  // },
  // customerJobsData: {
  let customerARData = { data: [], perPage: 10, currPage: 1, totalRows: 1 };
  let customerPartsData = { data: [], perPage: 10, currPage: 1, totalRows: 1 };
  let customerToolsEquipData = {
    data: [],
    perPage: 10,
    currPage: 1,
    totalRows: 1,
  };
  let customerApprovalData = {
    data: [],
    perPage: 10,
    currPage: 1,
    totalRows: 1,
  };
  let customerRepairData = { data: [], perPage: 10, currPage: 1, totalRows: 1 };
  let customerManpowerData = {
    data: [],
    perPage: 10,
    currPage: 1,
    totalRows: 1,
  };
  // },
  // volvoData: {
  let volvoApprovalData = {
    data: [],
    perPage: 10,
    currPage: 1,
    totalRows: 1,
  };
  let volvoFaultDiganosisData = {
    data: [],
    perPage: 10,
    currPage: 1,
    totalRows: 1,
  };
  let volvoManPowerData = {
    data: [],
    perPage: 10,
    currPage: 1,
    totalRows: 1,
  };
  let volvoToolsEquipData = {
    data: [],
    perPage: 10,
    currPage: 1,
    totalRows: 1,
  };
  let volvoPartsData = { data: [], perPage: 10, currPage: 1, totalRows: 1 };
  // },
  // vendorData: {
  let vendorPartsData = {
    data: [],
    perPage: 10,
    currPage: 1,
    totalRows: 1,
  };
  let vendorManpowerData = {
    data: [],
    perPage: 10,
    currPage: 1,
    totalRows: 1,
  };
  let vendorApprovalData = {
    data: [],
    perPage: 10,
    currPage: 1,
    totalRows: 1,
  };
  let apiData = {
    data: [],
    perPage: 10,
    currPage: 1,
    totalRows: 1,
  };
  // },
  switch (action.type) {
    case SUBMIT_USOB_OPEN_JOBS_BY_TYPE_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_USOB_OPEN_JOBS_BY_TYPE_ACTION_DISPATCHER:
      let aData = action.payload.data?.success;
      apiData.data = aData?.data;
      apiData.perPage = parseInt(aData?.meta?.pagination?.per_page);
      apiData.currPage = parseInt(aData?.meta?.pagination?.current_page);
      apiData.totalRows = parseInt(aData?.meta?.pagination?.total);
      return {
        ...state,
        successData: {
          ...state.successData,
          apiData: apiData,
        },
        loading: false,
      };
    case SUBMIT_PREVENTIVE_MAINTAINANCE_ACTION_DISPATCHER:
      let pmData = action.payload.data?.success;
      preventiveMaintainanceData.data = pmData?.data;
      preventiveMaintainanceData.perPage = parseInt(
        pmData?.meta?.pagination?.per_page
      );
      preventiveMaintainanceData.currPage = parseInt(
        pmData?.meta?.pagination?.current_page
      );
      preventiveMaintainanceData.totalRows = parseInt(
        pmData?.meta?.pagination?.total
      );
      return {
        ...state,
        successData: {
          ...state.successData,
          preventiveMaintainanceData: preventiveMaintainanceData,
        },
        loading: false,
      };
    case SUBMIT_AGGREGATE_REPAIR_ACTION_DISPATCHER:
      let arData = action.payload.data?.success;
      aggregateRepairData.data = arData?.data;
      aggregateRepairData.perPage = parseInt(
        arData?.meta?.pagination?.per_page
      );
      aggregateRepairData.currPage = parseInt(
        arData?.meta?.pagination?.current_page
      );
      aggregateRepairData.totalRows = parseInt(arData?.meta?.pagination?.total);
      return {
        ...state,
        successData: {
          ...state.successData,
          aggregateRepairData: aggregateRepairData,
        },
        loading: false,
      };
    case SUBMIT_RUNNING_REPAIR_ACTION_DISPATCHER:
      let rrData = action.payload.data?.success;
      runningRepairData.data = rrData?.data;
      runningRepairData.perPage = parseInt(rrData?.meta?.pagination?.per_page);
      runningRepairData.currPage = parseInt(
        rrData?.meta?.pagination?.current_page
      );
      runningRepairData.totalRows = parseInt(rrData?.meta?.pagination?.total);
      return {
        ...state,
        successData: {
          ...state.successData,
          runningRepairData: runningRepairData,
        },
        loading: false,
      };
    case SUBMIT_ACCIDENTAL_REPAIR_ACTION_DISPATCHER:
      let acrData = action.payload.data?.success;
      accidentalRepairData.data = acrData?.data;
      accidentalRepairData.perPage = parseInt(
        acrData?.meta?.pagination?.per_page
      );
      accidentalRepairData.currPage = parseInt(
        acrData?.meta?.pagination?.current_page
      );
      accidentalRepairData.totalRows = parseInt(
        acrData?.meta?.pagination?.total
      );
      return {
        ...state,
        successData: {
          ...state.successData,
          accidentalRepairData: accidentalRepairData,
        },
        loading: false,
      };
    case SUBMIT_PREDICTIVE_MAINTAINANCE_ACTION_DISPATCHER:
      let prData = action.payload.data?.success;
      predictiveMaintainanceData.data = prData?.data;
      predictiveMaintainanceData.perPage = parseInt(
        prData?.meta?.pagination?.per_page
      );
      predictiveMaintainanceData.currPage = parseInt(
        prData?.meta?.pagination?.current_page
      );
      predictiveMaintainanceData.totalRows = parseInt(
        prData?.meta?.pagination?.total
      );
      return {
        ...state,
        successData: {
          ...state.successData,
          predictiveMaintainanceData: predictiveMaintainanceData,
        },
        loading: false,
      };
    case SUBMIT_SUPER_STRUCTURE_ACTION_DISPATCHER:
      let ssData = action.payload.data?.success;
      superStructureData.data = ssData?.data;
      superStructureData.perPage = parseInt(ssData?.meta?.pagination?.per_page);
      superStructureData.currPage = parseInt(
        ssData?.meta?.pagination?.current_page
      );
      superStructureData.totalRows = parseInt(ssData?.meta?.pagination?.total);
      return {
        ...state,
        successData: {
          ...state.successData,
          superStructureData: superStructureData,
        },
        loading: false,
      };
    case SUBMIT_CUSTOMER_AR_ACTION_DISPATCHER:
      let carData = action.payload.data?.success;
      customerARData.data = carData?.data;
      customerARData.perPage = parseInt(carData?.meta?.pagination?.per_page);
      customerARData.currPage = parseInt(
        carData?.meta?.pagination?.current_page
      );
      customerARData.totalRows = parseInt(carData?.meta?.pagination?.total);
      return {
        ...state,
        successData: {
          ...state.successData,
          customerARData: customerARData,
        },
        loading: false,
      };
    case SUBMIT_CUSTOMER_PARTS_ACTION_DISPATCHER:
      let cpData = action.payload.data?.success;
      customerPartsData.data = cpData?.data;
      customerPartsData.perPage = parseInt(cpData?.meta?.pagination?.per_page);
      customerPartsData.currPage = parseInt(
        cpData?.meta?.pagination?.current_page
      );
      customerPartsData.totalRows = parseInt(cpData?.meta?.pagination?.total);
      return {
        ...state,
        successData: {
          ...state.successData,
          customerPartsData: customerPartsData,
        },
        loading: false,
      };
    case SUBMIT_CUSTOMER_TOOLS_ACTION_DISPATCHER:
      let ctData = action.payload.data?.success;
      customerToolsEquipData.data = ctData?.data;
      customerToolsEquipData.perPage = parseInt(
        ctData?.meta?.pagination?.per_page
      );
      customerToolsEquipData.currPage = parseInt(
        ctData?.meta?.pagination?.current_page
      );
      customerToolsEquipData.totalRows = parseInt(
        ctData?.meta?.pagination?.total
      );
      return {
        ...state,
        successData: {
          ...state.successData,
          customerToolsEquipData: customerToolsEquipData,
        },
        loading: false,
      };
    case SUBMIT_CUSTOMER_APPROVAL_ACTION_DISPATCHER:
      let caData = action.payload.data?.success;
      customerApprovalData.data = caData?.data;
      customerApprovalData.perPage = parseInt(
        caData?.meta?.pagination?.per_page
      );
      customerApprovalData.currPage = parseInt(
        caData?.meta?.pagination?.current_page
      );
      customerApprovalData.totalRows = parseInt(
        caData?.meta?.pagination?.total
      );
      return {
        ...state,
        successData: {
          ...state.successData,
          customerApprovalData: customerApprovalData,
        },
        loading: false,
      };
    case SUBMIT_CUSTOMER_REPAIR_ACTION_DISPATCHER:
      let crData = action.payload.data?.success;
      customerRepairData.data = crData?.data;
      customerRepairData.perPage = parseInt(crData?.meta?.pagination?.per_page);
      customerRepairData.currPage = parseInt(
        crData?.meta?.pagination?.current_page
      );
      customerRepairData.totalRows = parseInt(crData?.meta?.pagination?.total);
      return {
        ...state,
        successData: {
          ...state.successData,
          customerRepairData: customerRepairData,
        },
        loading: false,
      };
    case SUBMIT_CUSTOMER_MANPOWER_ACTION_DISPATCHER:
      let cmData = action.payload.data?.success;
      customerManpowerData.data = cmData?.data;
      customerManpowerData.perPage = parseInt(
        cmData?.meta?.pagination?.per_page
      );
      customerManpowerData.currPage = parseInt(
        cmData?.meta?.pagination?.current_page
      );
      customerManpowerData.totalRows = parseInt(
        cmData?.meta?.pagination?.total
      );
      return {
        ...state,
        successData: {
          ...state.successData,
          customerManpowerData: customerManpowerData,
        },
        loading: false,
      };
    case SUBMIT_VOLVO_APPROVAL_ACTION_DISPATCHER:
      let vaData = action.payload.data?.success;
      volvoApprovalData.data = vaData?.data;
      volvoApprovalData.perPage = parseInt(vaData?.meta?.pagination?.per_page);
      volvoApprovalData.currPage = parseInt(
        vaData?.meta?.pagination?.current_page
      );
      volvoApprovalData.totalRows = parseInt(vaData?.meta?.pagination?.total);
      return {
        ...state,
        successData: {
          ...state.successData,
          volvoApprovalData: volvoApprovalData,
        },
        loading: false,
      };
    case SUBMIT_VOLVO_FAULT_DIGANOSIS_ACTION_DISPATCHER:
      let vfdData = action.payload.data?.success;
      volvoFaultDiganosisData.data = vfdData?.data;
      volvoFaultDiganosisData.perPage = parseInt(
        vfdData?.meta?.pagination?.per_page
      );
      volvoFaultDiganosisData.currPage = parseInt(
        vfdData?.meta?.pagination?.current_page
      );
      volvoFaultDiganosisData.totalRows = parseInt(
        vfdData?.meta?.pagination?.total
      );
      return {
        ...state,
        successData: {
          ...state.successData,
          volvoFaultDiganosisData: volvoFaultDiganosisData,
        },
        loading: false,
      };
    case SUBMIT_VOLVO_MANPOWER_ACTION_DISPATCHER:
      let vmData = action.payload.data?.success;
      volvoManPowerData.data = vmData?.data;
      volvoManPowerData.perPage = parseInt(vmData?.meta?.pagination?.per_page);
      volvoManPowerData.currPage = parseInt(
        vmData?.meta?.pagination?.current_page
      );
      volvoManPowerData.totalRows = parseInt(vmData?.meta?.pagination?.total);
      return {
        ...state,
        successData: {
          ...state.successData,
          volvoManPowerData: volvoManPowerData,
        },
        loading: false,
      };
    case SUBMIT_VOLVO_PARTS_NA_ACTION_DISPATCHER:
      let vpData = action.payload.data?.success;
      volvoPartsData.data = vpData?.data;
      volvoPartsData.perPage = parseInt(vpData?.meta?.pagination?.per_page);
      volvoPartsData.currPage = parseInt(
        vpData?.meta?.pagination?.current_page
      );
      volvoPartsData.totalRows = parseInt(vpData?.meta?.pagination?.total);
      return {
        ...state,
        successData: {
          ...state.successData,
          volvoPartsData: volvoPartsData,
        },
        loading: false,
      };
    case SUBMIT_VOLVO_TOOLS_EQUIP_ACTION_DISPATCHER:
      let vtData = action.payload.data?.success;
      volvoToolsEquipData.data = vtData?.data;
      volvoToolsEquipData.perPage = parseInt(
        vtData?.meta?.pagination?.per_page
      );
      volvoToolsEquipData.currPage = parseInt(
        vtData?.meta?.pagination?.current_page
      );
      volvoToolsEquipData.totalRows = parseInt(vtData?.meta?.pagination?.total);
      return {
        ...state,
        successData: {
          ...state.successData,
          volvoToolsEquipData: volvoToolsEquipData,
        },
        loading: false,
      };
    case SUBMIT_VENDOR_PARTS_ACTION_DISPATCHER:
      let vepData = action.payload.data?.success;
      vendorPartsData.data = vepData?.data;
      vendorPartsData.perPage = parseInt(vepData?.meta?.pagination?.per_page);
      vendorPartsData.currPage = parseInt(
        vepData?.meta?.pagination?.current_page
      );
      vendorPartsData.totalRows = parseInt(vepData?.meta?.pagination?.total);
      return {
        ...state,
        successData: {
          ...state.successData,
          vendorPartsData: vendorPartsData,
        },
        loading: false,
      };
    case SUBMIT_VENDOR_MANPOWER_ACTION_DISPATCHER:
      let vemData = action.payload.data?.success;
      vendorManpowerData.data = vemData?.data;
      vendorManpowerData.perPage = parseInt(
        vemData?.meta?.pagination?.per_page
      );
      vendorManpowerData.currPage = parseInt(
        vemData?.meta?.pagination?.current_page
      );
      vendorManpowerData.totalRows = parseInt(vemData?.meta?.pagination?.total);
      return {
        ...state,
        successData: {
          ...state.successData,
          vendorManpowerData: vendorManpowerData,
        },
        loading: false,
      };
    case SUBMIT_VENDOR_APPROVAL_ACTION_DISPATCHER:
      let veaData = action.payload.data?.success;
      vendorApprovalData.data = veaData?.data;
      vendorApprovalData.perPage = parseInt(
        veaData?.meta?.pagination?.per_page
      );
      vendorApprovalData.currPage = parseInt(
        veaData?.meta?.pagination?.current_page
      );
      vendorApprovalData.totalRows = parseInt(veaData?.meta?.pagination?.total);
      return {
        ...state,
        successData: {
          ...state.successData,
          vendorApprovalData: vendorApprovalData,
        },
        loading: false,
      };
    case SUBMIT_USOB_OPEN_JOBS_BY_TYPE_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case USOB_OPEN_JOBS_BY_TYPE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
