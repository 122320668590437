import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  addVehiclesInGroupLoading,
  submitAddVehiclesInGroupActionDispatcher,
  submitAddVehiclesInGroupErrorDispatcher,
  SUBMIT_ADD_VEHICLES_IN_GROUP_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postAddVehiclesInGroupAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/Insertgroupvehiclemaster?groupid=" +
      postData?.group_id +
      "&vehicleid=" +
      postData.vehicles
  );
  return result;
};

function* submitAddVehiclesInGroupSaga({ postData }) {
  try {
    yield put(addVehiclesInGroupLoading(true));
    const data = yield call(postAddVehiclesInGroupAPI, postData);
    yield put(submitAddVehiclesInGroupActionDispatcher(data));
    yield put(submitAddVehiclesInGroupErrorDispatcher({}));
    yield put(addVehiclesInGroupLoading(false));
  } catch (e) {
    yield put(submitAddVehiclesInGroupActionDispatcher({}));
    yield put(submitAddVehiclesInGroupErrorDispatcher(e));
    yield put(addVehiclesInGroupLoading(false));
  }
}

function* submitAddVehiclesInGroupWatcher() {
  yield takeEvery(
    SUBMIT_ADD_VEHICLES_IN_GROUP_ACTION_REQUESTED,
    submitAddVehiclesInGroupSaga
  );
}
export default submitAddVehiclesInGroupWatcher;
