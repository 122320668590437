export const SUBMIT_CAROUSEL_IMGS_ACTION_REQUESTED =
  "SUBMIT_CAROUSEL_IMGS_ACTION_REQUESTED";
export const SUBMIT_CAROUSEL_IMGS_ACTION_DISPATCHER =
  "SUBMIT_CAROUSEL_IMGS_ACTION_DISPATCHER";
export const SUBMIT_CAROUSEL_IMGS_ERROR_DISPATCHER =
  "SUBMIT_CAROUSEL_IMGS_ERROR_DISPATCHER";
export const CAROUSEL_IMGS_LOADING = "CAROUSEL_IMGS_LOADING";

//Action Creator
export const submitCarouselImgsAction = (data) => ({
  type: SUBMIT_CAROUSEL_IMGS_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitCarouselImgsActionDispatcher = (data) => ({
  type: SUBMIT_CAROUSEL_IMGS_ACTION_DISPATCHER,
  payload: data,
});

export const submitCarouselImgsErrorDispatcher = (data) => ({
  type: SUBMIT_CAROUSEL_IMGS_ERROR_DISPATCHER,
  payload: data,
});

export const carouselImgsLoading = (loading) => ({
  type: CAROUSEL_IMGS_LOADING,
  payload: loading,
});
