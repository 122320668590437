import {
  VAS_LOADING,
  SUBMIT_VAS_ACTION_DISPATCHER,
  SUBMIT_VAS_ACTION_REQUESTED,
  SUBMIT_VAS_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    vasList: [],
    regNoFilter: [],
    chassisFilter: [],
    custFilter: [],
    breakLocFilter: [],
    vehicleGrpFilter: [],
    assignToFilter: [],
    excelData: [],
    apiData: {},
  },
  failureData: {},
  loading: false,
  isLogout: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_VAS_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_VAS_ACTION_DISPATCHER:
      // console.log("action.payload.data: ", action.payload);
      let excelData = [
        [
          "Ticket ID",
          "Registration Number",
          "Chassis Number",
          "Breakdown Location",
          "Concern Description",
          "Assigned To",
          "Ticket Opened At",
          "Ticket Closed At",
          "Total VAS Time",
          "Customer Delay",
        ],
      ];
      let dataWithID = [];
      action.filteredData.forEach((e, key) => {
        dataWithID.push({ id: key, ...e });
        excelData.push([
          e.ticket_id,
          e.vehicle_registration_number,
          e.chassis_number,
          e.breakdown_location,
          e.description,
          e.assign_to,
          e.ticket_open_time,
          e.ticket_closure_time,
          e.vas_time,
          e.customer_delay_time,
        ]);
      });
      // console.log("dataWithID: ", dataWithID);
      return {
        ...state,
        successData: {
          vasList: dataWithID, //action.filteredData,
          tktIDFilter: action.tktIDFilter,
          regNoFilter: action.regNoFilter,
          chassisFilter: action.chassisFilter,
          custFilter: action.custFilter,
          breakLocFilter: action.breakLocFilter,
          vehicleGrpFilter: action.vehicleGrpFilter,
          assignToFilter: action.assignToFilter,
          excelData: excelData,
          apiData: action.payload,
        },
        loading: false,
      };
    case SUBMIT_VAS_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case VAS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
