export const SUBMIT_USOB_VEHICLE_GROUPS_ACTION_REQUESTED =
  "SUBMIT_USOB_VEHICLE_GROUPS_ACTION_REQUESTED";
export const SUBMIT_USOB_VEHICLE_GROUPS_ACTION_DISPATCHER =
  "SUBMIT_USOB_VEHICLE_GROUPS_ACTION_DISPATCHER";
export const SUBMIT_USOB_VEHICLE_GROUPS_ERROR_DISPATCHER =
  "SUBMIT_USOB_VEHICLE_GROUPS_ERROR_DISPATCHER";
export const USOB_VEHICLE_GROUPS_LOADING = "USOB_VEHICLE_GROUPS_LOADING";

//Action Creator
export const submitUSOBVehicleGroupsAction = (data, page) => ({
  type: SUBMIT_USOB_VEHICLE_GROUPS_ACTION_REQUESTED,
  postData: data,
  page: page
});

//Action Dispatcher
export const submitUSOBVehicleGroupsActionDispatcher = (data) => ({
  type: SUBMIT_USOB_VEHICLE_GROUPS_ACTION_DISPATCHER,
  payload: data,
});

export const submitUSOBVehicleGroupsErrorDispatcher = (data) => ({
  type: SUBMIT_USOB_VEHICLE_GROUPS_ERROR_DISPATCHER,
  payload: data,
});

export const USOBVehicleGroupsLoading = (loading) => ({
  type: USOB_VEHICLE_GROUPS_LOADING,
  payload: loading,
});
