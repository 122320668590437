import moment from "moment";
import {
  SEARCH_SITE_ADVISORY,
  SITE_ADVISORY_LOADING,
  SUBMIT_SITE_ADVISORY_ACTION_DISPATCHER,
  SUBMIT_SITE_ADVISORY_ACTION_REQUESTED,
  SUBMIT_SITE_ADVISORY_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    siteAdvisoryList: [],
    excelData: [],
    filteredList: [],
    filteredMobList: [],
    siteFilters: [],
  },
  failureData: {},
  loading: false,
  isLogout: false,
};

const reducer = (state = initialState, action) => {
  let mobList = [];
  let tempExcelArr = [["Site Code", "Site Name", "Time Period", "Uploaded On"]];
  switch (action.type) {
    case SUBMIT_SITE_ADVISORY_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_SITE_ADVISORY_ACTION_DISPATCHER:
      for (let i = 0; i < action.filteredData.length; i += 5) {
        mobList.push(action.filteredData.slice(i, i + 5));
      }
      action.filteredData.forEach((e) => {
        tempExcelArr.push([
          `${e.SiteCode}`,
          `${e.SiteName}`,
          `${moment(e.Time_Period).format("MMMM-YYYY")}`,
          `${moment(e.Upload_date).format("DD-MM-YYYY")}`,
        ]);
      });
      return {
        ...state,
        successData: {
          siteAdvisoryList: action.payload,
          excelData: tempExcelArr,
          filteredList: action.filteredData,
          filteredMobList: mobList,
          siteFilters: action.siteFilter,
        },
        loading: false,
      };
    case SUBMIT_SITE_ADVISORY_ERROR_DISPATCHER:
      return {
        ...state,
        formError: action.payload,
        loading: false,
      };
    case SITE_ADVISORY_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SEARCH_SITE_ADVISORY:
      for (let i = 0; i < action.filteredData.length; i += 5) {
        mobList.push(action.filteredData.slice(i, i + 5));
      }
      action.filteredData.forEach((e) => {
        tempExcelArr.push([
          `${e.SiteCode}`,
          `${e.SiteName}`,
          `${moment(e.Time_Period).format("MMMM-YYYY")}`,
          `${moment(e.Upload_date).format("DD-MM-YYYY")}`,
        ]);
      });
      return {
        ...state,
        successData: {
          siteAdvisoryList: action.payload,
          excelData: tempExcelArr,
          filteredList: action.filteredData,
          filteredMobList: mobList,
          siteFilters: action.siteFilter,
        },
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
