import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  myShiftsLoading,
  submitMyShiftsActionDispatcher,
  submitMyShiftsErrorDispatcher,
  SUBMIT_MY_SHIFTS_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postMyShiftsAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/ShiftDetails?shiftdetail=25",
    {
      ...postData,
    }
  );
  return result;
};

function* submitMyShiftsSaga({ postData }) {
  try {
    yield put(myShiftsLoading(true));
    const data = yield call(postMyShiftsAPI, postData);
    yield put(submitMyShiftsActionDispatcher(data.data));
    yield put(submitMyShiftsErrorDispatcher({}));
    yield put(myShiftsLoading(false));
  } catch (e) {
    yield put(submitMyShiftsActionDispatcher({}));
    yield put(
      submitMyShiftsErrorDispatcher(e.response.data, console.log(e.response))
    );
    yield put(myShiftsLoading(false));
  }
}

function* submitMyShiftsWatcher() {
  yield takeEvery(SUBMIT_MY_SHIFTS_ACTION_REQUESTED, submitMyShiftsSaga);
}
export default submitMyShiftsWatcher;
