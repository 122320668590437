export const SUBMIT_USOB_WORKSHOPS_ACTION_REQUESTED =
  "SUBMIT_USOB_WORKSHOPS_ACTION_REQUESTED";
export const SUBMIT_USOB_WORKSHOPS_ACTION_DISPATCHER =
  "SUBMIT_USOB_WORKSHOPS_ACTION_DISPATCHER";
export const SUBMIT_USOB_WORKSHOPS_ERROR_DISPATCHER =
  "SUBMIT_USOB_WORKSHOPS_ERROR_DISPATCHER";
export const USOB_WORKSHOPS_LOADING = "USOB_WORKSHOPS_LOADING";

//Action Creator
export const submitUSOBWorkshopsAction = (data) => ({
  type: SUBMIT_USOB_WORKSHOPS_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitUSOBWorkshopsActionDispatcher = (data) => ({
  type: SUBMIT_USOB_WORKSHOPS_ACTION_DISPATCHER,
  payload: data.data,
});

export const submitUSOBWorkshopsErrorDispatcher = (data) => ({
  type: SUBMIT_USOB_WORKSHOPS_ERROR_DISPATCHER,
  payload: data,
});

export const USOBWorkshopsLoading = (loading) => ({
  type: USOB_WORKSHOPS_LOADING,
  payload: loading,
});
