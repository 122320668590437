import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  uptimeByModelLoading,
  submitUptimeByModelActionDispatcher,
  submitUptimeByModelErrorDispatcher,
  SUBMIT_UPTIME_BY_MODEL_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUptimeByModelAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/Post?Uptime=abc&p1=xyz&UptimeModel=Extrapara&Graph=5&uptimeGraph=8",
    {
      ...postData,
    }
  );
  return result;
};

function* submitUptimeByModelSaga({ postData }) {
  try {
    yield put(uptimeByModelLoading(true));
    const data = yield call(postUptimeByModelAPI, postData);
    yield put(submitUptimeByModelActionDispatcher(data));
    yield put(submitUptimeByModelErrorDispatcher({}));
    yield put(uptimeByModelLoading(false));
  } catch (e) {
    yield put(submitUptimeByModelActionDispatcher({}));
    yield put(submitUptimeByModelErrorDispatcher(e.response.data));
    yield put(uptimeByModelLoading(false));
  }
}

function* submitUptimeByModelWatcher() {
  yield takeEvery(
    SUBMIT_UPTIME_BY_MODEL_ACTION_REQUESTED,
    submitUptimeByModelSaga
  );
}
export default submitUptimeByModelWatcher;
