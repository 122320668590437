import {
  FUEL_EFFICIENCY_SITES_LOADING,
  SUBMIT_FUEL_EFFICIENCY_SITES_ACTION_DISPATCHER,
  SUBMIT_FUEL_EFFICIENCY_SITES_ACTION_REQUESTED,
  SUBMIT_FUEL_EFFICIENCY_SITES_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    mySites: [],
    apiData: {},
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  var siteListbyName = [];
  switch (action.type) {
    case SUBMIT_FUEL_EFFICIENCY_SITES_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_FUEL_EFFICIENCY_SITES_ACTION_DISPATCHER:
      let siteList = [];
      let siteIDs = [];
      let data = action.payload.data?.success;
      // console.log("data: ", data);
      data?.forEach((e) => {
        siteIDs.push(e.site_id);
        siteList.push({
          value: e.site_code + " - " + e.site,
          name: e.site,
          id: e.site_id,
        });
      });
      siteIDs.sort();
      siteList.sort(function (a, b) {
        var x = a.name.toLowerCase();
        var y = b.name.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      siteListbyName = siteList;
      siteListbyName.unshift({
        value: "All Sites",
        id: siteIDs.toString(),
      });
      return {
        ...state,
        successData: {
          mySites: siteListbyName,
          siteIDs: siteIDs,
          apiData: data,
        },
        loading: false,
      };
    case SUBMIT_FUEL_EFFICIENCY_SITES_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case FUEL_EFFICIENCY_SITES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
