import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  vasLoading,
  submitVASActionDispatcher,
  submitVASErrorDispatcher,
  SUBMIT_VAS_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postVASAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal?getvasactionservie=true",
    { ...postData }
  );
  return result;
};

function* submitVASSaga({ postData, filterQuery }) {
  try {
    yield put(vasLoading(true));
    const data = yield call(postVASAPI, postData);
    yield put(submitVASActionDispatcher(data, filterQuery));
    yield put(submitVASErrorDispatcher({}));
    yield put(vasLoading(false));
  } catch (e) {
    yield put(submitVASActionDispatcher({}));
    yield put(submitVASErrorDispatcher(e.response.data));
    yield put(vasLoading(false));
  }
}

function* submitVASWatcher() {
  yield takeEvery(SUBMIT_VAS_ACTION_REQUESTED, submitVASSaga);
}
export default submitVASWatcher;
