import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  vehiclesLoading,
  submitVehiclesActionDispatcher,
  submitVehiclesErrorDispatcher,
  SUBMIT_VEHICLES_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postVehiclesAPI = async (postData) => {
  // console.log("postData: ", postData);
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/Post?Vehiclelist=abc&ListNo=5",
    {
      ...postData,
    }
  );
  return result;
};

function* submitVehiclesSaga({ postData, filterQuery }) {
  try {
    yield put(vehiclesLoading(true));
    const data = yield call(postVehiclesAPI, postData);
    yield put(submitVehiclesActionDispatcher(data?.data, filterQuery));
    yield put(submitVehiclesErrorDispatcher({}));
    yield put(vehiclesLoading(false));
  } catch (e) {
    yield put(submitVehiclesActionDispatcher({}));
    yield put(
      submitVehiclesErrorDispatcher(e?.response?.data, console.log(e.response))
    );
    yield put(vehiclesLoading(false));
  }
}

function* submitVehiclesWatcher() {
  yield takeEvery(SUBMIT_VEHICLES_ACTION_REQUESTED, submitVehiclesSaga);
}
export default submitVehiclesWatcher;
