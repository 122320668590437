export const SUBMIT_VAS_ACTION_REQUESTED = "SUBMIT_VAS_ACTION_REQUESTED";
export const SUBMIT_VAS_ACTION_DISPATCHER = "SUBMIT_VAS_ACTION_DISPATCHER";
export const SUBMIT_VAS_ERROR_DISPATCHER = "SUBMIT_VAS_ERROR_DISPATCHER";
export const VAS_LOADING = "VAS_LOADING";

//Action Creator
export const submitVASAction = (data, query) => ({
  type: SUBMIT_VAS_ACTION_REQUESTED,
  postData: data,
  filterQuery: query,
});

//Action Dispatcher
export const submitVASActionDispatcher = (data, query) => {
  // console.log('data: ', data.data);
  let filteredData = query
    ? data?.data.filter(
        (vehicle) =>
          (vehicle.chassis_number &&
            vehicle.chassis_number
              .toLowerCase()
              .includes(query.toLowerCase())) ||
          (vehicle.breakdown_location &&
            vehicle.breakdown_location
              .toLowerCase()
              .includes(query.toLowerCase())) ||
          (vehicle.vehicle_registration_number &&
            vehicle.vehicle_registration_number
              .toLowerCase()
              .includes(query.toLowerCase()))
      )
    : data?.data;
  // console.log("filteredData: ", filteredData);
  let tempRegNo = [];
  let tempTktID = [];
  let tempChassis = [];
  let tempCust = [];
  let tempVehicleGrp = [];
  let tempBreakLoc = [];
  let tempCurrStat = [];

  filteredData?.forEach((e) => {
    tempTktID.push({
      value: e.ticket_id,
      text: e.ticket_id,
    });
    tempRegNo.push({
      value: e.vehicle_registration_number,
      text: e.vehicle_registration_number,
    });
    tempChassis.push({ value: e.chassis_number, text: e.chassis_number });
    tempCust.push({ value: e.customer_name, text: e.customer_name });
    tempBreakLoc.push({
      value: e.breakdown_location,
      text: e.breakdown_location,
    });
    tempVehicleGrp.push({
      value: e.vehicle_group ? e.vehicle_group : "-",
      text: e.vehicle_group ? e.vehicle_group : "-",
    });
    tempCurrStat.push({
      value: e.assign_to,
      text: e.assign_to,
    });
  });
  return {
    type: SUBMIT_VAS_ACTION_DISPATCHER,
    payload: data,
    filteredData: filteredData,
    tktIDFilter: [
      ...new Map(tempTktID.map((item) => [item["value"], item])).values(),
    ],
    regNoFilter: [
      ...new Map(tempRegNo.map((item) => [item["value"], item])).values(),
    ],
    chassisFilter: [
      ...new Map(tempChassis.map((item) => [item["value"], item])).values(),
    ],
    custFilter: [
      ...new Map(tempCust.map((item) => [item["value"], item])).values(),
    ],
    breakLocFilter: [
      ...new Map(tempBreakLoc.map((item) => [item["value"], item])).values(),
    ],
    vehicleGrpFilter: [
      ...new Map(tempVehicleGrp.map((item) => [item["value"], item])).values(),
    ],
    assignToFilter: [
      ...new Map(tempCurrStat.map((item) => [item["value"], item])).values(),
    ],
  };
};

export const submitVASErrorDispatcher = (data) => ({
  type: SUBMIT_VAS_ERROR_DISPATCHER,
  payload: data,
});

export const vasLoading = (loading) => ({
  type: VAS_LOADING,
  payload: loading,
});
