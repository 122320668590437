import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBDeleteVehicleGroupLoading,
  submitUSOBDeleteVehicleGroupActionDispatcher,
  submitUSOBDeleteVehicleGroupErrorDispatcher,
  SUBMIT_USOB_DELETE_VEHICLE_GROUP_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBDeleteVehicleGroupAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.delete(
    "/groups/" + postData?.group_id
  );
  return result;
};

function* submitUSOBDeleteVehicleGroupSaga({ postData }) {
  try {
    yield put(USOBDeleteVehicleGroupLoading(true));
    const data = yield call(postUSOBDeleteVehicleGroupAPI, postData);
    yield put(submitUSOBDeleteVehicleGroupActionDispatcher(data));
    yield put(submitUSOBDeleteVehicleGroupErrorDispatcher({}));
    yield put(USOBDeleteVehicleGroupLoading(false));
  } catch (e) {
    yield put(submitUSOBDeleteVehicleGroupActionDispatcher({}));
    yield put(submitUSOBDeleteVehicleGroupErrorDispatcher(e));
    yield put(USOBDeleteVehicleGroupLoading(false));
  }
}

function* submitUSOBDeleteVehicleGroupWatcher() {
  yield takeEvery(
    SUBMIT_USOB_DELETE_VEHICLE_GROUP_ACTION_REQUESTED,
    submitUSOBDeleteVehicleGroupSaga
  );
}
export default submitUSOBDeleteVehicleGroupWatcher;
