import moment from "moment";
import {
  TRIP_COUNT_LOADING,
  SUBMIT_TRIP_COUNT_ACTION_DISPATCHER,
  SUBMIT_TRIP_COUNT_ACTION_REQUESTED,
  SUBMIT_TRIP_COUNT_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { chartData: [], apiData: {} },
  failureData: {},
  loading: false,
  isLogout: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_TRIP_COUNT_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_TRIP_COUNT_ACTION_DISPATCHER:
      const tempArr = [];
      if (action.payload.data) {
        const tempHeadArr = [
          action.payload.data[0].SiteName,
          "Total Trip Count",
        ];
        action.payload.data[0].tripcountgraphlistByChassis.forEach((e) => {
          tempHeadArr.push(moment(e.CreatedDate).format("DD-MMM-YYYY hh:mm A"));
        });
        tempArr.push(tempHeadArr);
        // tempArr.push({ role: "annotation" });
        action.payload.data.forEach((e) => {
          const tempCountArr = [e.ChassisId];
          let totalTripCount = 0;
          for (let i = 0; i < tempHeadArr.length - 1; i++) {
            if (e.tripcountgraphlistByChassis[i]) {
              tempCountArr.push(e.tripcountgraphlistByChassis[i].TripCount);
              totalTripCount += e.tripcountgraphlistByChassis[i].TripCount;
            }
          }
          tempCountArr.splice(1, 0, totalTripCount);
          tempArr.push(tempCountArr);
        });
      }
      // console.log("tempArr: ", tempArr);
      return {
        ...state,
        successData: { chartData: tempArr, apiData: action.payload.data },
        loading: false,
      };
    case SUBMIT_TRIP_COUNT_ERROR_DISPATCHER:
      return {
        ...state,
        formError: action.payload,
        loading: false,
      };
    case TRIP_COUNT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
