import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  tripByYearLoading,
  submitTripByYearActionDispatcher,
  submitTripByYearErrorDispatcher,
  SUBMIT_TRIP_BY_YEAR_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postTripByYearAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/TripCountMonthWiseDetail?tripmonth=21",
    {
      ...postData,
    }
  );
  return result;
};

function* submitTripByYearSaga({ postData }) {
  try {
    yield put(tripByYearLoading(true));
    const data = yield call(postTripByYearAPI, postData);
    yield put(submitTripByYearActionDispatcher(data));
    yield put(submitTripByYearErrorDispatcher({}));
    yield put(tripByYearLoading(false));
  } catch (e) {
    yield put(submitTripByYearActionDispatcher({}));
    yield put(submitTripByYearErrorDispatcher(e));
    yield put(tripByYearLoading(false));
  }
}

function* submitTripByYearWatcher() {
  yield takeEvery(SUBMIT_TRIP_BY_YEAR_ACTION_REQUESTED, submitTripByYearSaga);
}
export default submitTripByYearWatcher;
