import moment from "moment";
import {
  TRIP_BY_YEAR_LOADING,
  SUBMIT_TRIP_BY_YEAR_ACTION_DISPATCHER,
  SUBMIT_TRIP_BY_YEAR_ACTION_REQUESTED,
  SUBMIT_TRIP_BY_YEAR_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { chartData: [], apiData: {} },
  failureData: {},
  loading: false,
  isLogout: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_TRIP_BY_YEAR_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_TRIP_BY_YEAR_ACTION_DISPATCHER:
      const tempArr = [];
      if (action.payload.data) {
        const tempHeadArr = [
          "Months",
          "Total Trip Count",
          "Shift 1",
          "Shift 2",
          "Shift 3",
        ];
        // action.payload.data[0].tripcountlist.forEach((e) => {
        //   tempHeadArr.push(e.ShiftName);
        // });
        tempArr.push(tempHeadArr);
        // tempArr.push({ role: "annotation" });
        action.payload.data.forEach((e) => {
          const tempCountArr = [
            moment(e.ShiftTime,"MM-YYYY").format("MMMM-YYYY"),
            0,
            0,
            0,
          ];
          let totalTripCount = 0;
          for (let i = 0; i < e.tripcountlist.length; i++) {
            if (e.tripcountlist[i].ShiftName === "Shift 1") {
              tempCountArr.splice(1, 1, e.tripcountlist[i].TripCount);
              totalTripCount += e.tripcountlist[i].TripCount;
            } else if (e.tripcountlist[i].ShiftName === "Shift 2") {
              tempCountArr.splice(2, 1, e.tripcountlist[i].TripCount);
              totalTripCount += e.tripcountlist[i].TripCount;
            } else if (e.tripcountlist[i].ShiftName === "Shift 3") {
              tempCountArr.splice(3, 1, e.tripcountlist[i].TripCount);
              totalTripCount += e.tripcountlist[i].TripCount;
            }
          }
          tempCountArr.splice(1, 0, totalTripCount);
          tempArr.push(tempCountArr);
        });
      }
      return {
        ...state,
        successData: { chartData: tempArr, apiData: action.payload.data },
        loading: false,
      };
    case SUBMIT_TRIP_BY_YEAR_ERROR_DISPATCHER:
      return {
        ...state,
        formError: action.payload,
        loading: false,
      };
    case TRIP_BY_YEAR_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
