import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  sitesLoading,
  submitSitesActionDispatcher,
  submitSitesErrorDispatcher,
  SUBMIT_SITES_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postSitesAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/Post?sitelist=ccv&sitenumber=5",
    {
      ...postData,
    }
  );
  return result;
};

function* submitSitesSaga({ postData, filterQuery }) {
  try {
    yield put(sitesLoading(true));
    const data = yield call(postSitesAPI, postData);
    yield put(submitSitesActionDispatcher(data.data, filterQuery));
    yield put(submitSitesErrorDispatcher({}));
    yield put(sitesLoading(false));
  } catch (e) {
    yield put(submitSitesActionDispatcher({}));
    yield put(
      submitSitesErrorDispatcher(e.response.data, console.log(e.response))
    );
    yield put(sitesLoading(false));
  }
}

function* submitSitesWatcher() {
  yield takeEvery(SUBMIT_SITES_ACTION_REQUESTED, submitSitesSaga);
}
export default submitSitesWatcher;
