import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBRegisterWorkshopServiceLoading,
  submitUSOBRegisterWorkshopServiceActionDispatcher,
  submitUSOBRegisterWorkshopServiceErrorDispatcher,
  SUBMIT_USOB_REGISTER_WORKSHOP_SERVICE_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBRegisterWorkshopServiceAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post("/workshop-service/book", {
    ...postData,
  });
  return result;
};

function* submitUSOBRegisterWorkshopServiceSaga({ postData }) {
  try {
    yield put(USOBRegisterWorkshopServiceLoading(true));
    const data = yield call(postUSOBRegisterWorkshopServiceAPI, postData);
    yield put(submitUSOBRegisterWorkshopServiceActionDispatcher(data));
    yield put(submitUSOBRegisterWorkshopServiceErrorDispatcher({}));
    yield put(USOBRegisterWorkshopServiceLoading(false));
  } catch (e) {
    yield put(submitUSOBRegisterWorkshopServiceActionDispatcher({}));
    yield put(submitUSOBRegisterWorkshopServiceErrorDispatcher(e));
    yield put(USOBRegisterWorkshopServiceLoading(false));
  }
}

function* submitUSOBRegisterWorkshopServiceWatcher() {
  yield takeEvery(
    SUBMIT_USOB_REGISTER_WORKSHOP_SERVICE_ACTION_REQUESTED,
    submitUSOBRegisterWorkshopServiceSaga
  );
}
export default submitUSOBRegisterWorkshopServiceWatcher;
