export const SUBMIT_TRIP_SITES_ACTION_REQUESTED = "SUBMIT_TRIP_SITES_ACTION_REQUESTED";
export const SUBMIT_TRIP_SITES_ACTION_DISPATCHER = "SUBMIT_TRIP_SITES_ACTION_DISPATCHER";
export const SUBMIT_TRIP_SITES_ERROR_DISPATCHER = "SUBMIT_TRIP_SITES_ERROR_DISPATCHER";
export const TRIP_SITES_LOADING = "TRIP_SITES_LOADING";

//Action Creator
export const submitTripSitesAction = (data, query) => ({
  type: SUBMIT_TRIP_SITES_ACTION_REQUESTED,
  postData: data,
  filterQuery: query,
});

//Action Dispatcher
export const submitTripSitesActionDispatcher = (data, query) => ({
  type: SUBMIT_TRIP_SITES_ACTION_DISPATCHER,
  payload: data,
  filteredData: query
    ? data.filter((site) =>
        site.SiteName.toLowerCase().includes(query.toLowerCase())
      )
    : data,
});

export const submitTripSitesErrorDispatcher = (data) => ({
  type: SUBMIT_TRIP_SITES_ERROR_DISPATCHER,
  payload: data,
});

export const tripSitesLoading = (loading) => ({
  type: TRIP_SITES_LOADING,
  payload: loading,
});