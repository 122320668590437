export const SUBMIT_PROD_BROCHURE_ACTION_REQUESTED =
  "SUBMIT_PROD_BROCHURE_ACTION_REQUESTED";
export const SUBMIT_PROD_BROCHURE_ACTION_DISPATCHER =
  "SUBMIT_PROD_BROCHURE_ACTION_DISPATCHER";
export const SUBMIT_PROD_BROCHURE_ERROR_DISPATCHER =
  "SUBMIT_PROD_BROCHURE_ERROR_DISPATCHER";
export const PROD_BROCHURE_LOADING = "PROD_BROCHURE_LOADING";

//Action Creator
export const submitProdBrochureAction = (data) => ({
  type: SUBMIT_PROD_BROCHURE_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitProdBrochureActionDispatcher = (data) => ({
  type: SUBMIT_PROD_BROCHURE_ACTION_DISPATCHER,
  payload: data,
});

export const submitProdBrochureErrorDispatcher = (data) => ({
  type: SUBMIT_PROD_BROCHURE_ERROR_DISPATCHER,
  payload: data,
});

export const prodBrochureLoading = (loading) => ({
  type: PROD_BROCHURE_LOADING,
  payload: loading,
});
