//Action Types
export const SUBMIT_INSERT_UPDATE_SHIFT_ACTION_REQUESTED =
  "SUBMIT_INSERT_UPDATE_SHIFT_ACTION_REQUESTED";
export const SUBMIT_INSERT_UPDATE_SHIFT_ACTION_DISPATCHER =
  "SUBMIT_INSERT_UPDATE_SHIFT_ACTION_DISPATCHER";
export const SUBMIT_INSERT_UPDATE_SHIFT_ERROR_DISPATCHER =
  "SUBMIT_INSERT_UPDATE_SHIFT_ERROR_DISPATCHER";
export const SUBMIT_EMPTY_INSERT_UPDATE_SHIFT_STATE_ACTION =
  "SUBMIT_EMPTY_INSERT_UPDATE_SHIFT_STATE_ACTION";
export const INSERT_UPDATE_SHIFT_LOADING = "INSERT_UPDATE_SHIFT_LOADING";

//Action Creator
export const submitInsertUpdateShiftAction = (data) => ({
  type: SUBMIT_INSERT_UPDATE_SHIFT_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitInsertUpdateShiftActionDispatcher = (data) => ({
  type: SUBMIT_INSERT_UPDATE_SHIFT_ACTION_DISPATCHER,
  payload: data,
});

//Error Dispatcher
export const submitInsertUpdateShiftErrorDispatcher = (data) => ({
  type: SUBMIT_INSERT_UPDATE_SHIFT_ERROR_DISPATCHER,
  payload: data,
});

//Loading Action
export const insertUpdateShiftLoading = (loading) => ({
  type: INSERT_UPDATE_SHIFT_LOADING,
  payload: loading,
});

export const submitEmptyInsertUpdateShiftStateAction = () => ({
  type: SUBMIT_EMPTY_INSERT_UPDATE_SHIFT_STATE_ACTION,
});
