import {
  USOB_VEHICLE_TYPES_LOADING,
  SUBMIT_USOB_VEHICLE_TYPES_ACTION_DISPATCHER,
  SUBMIT_USOB_VEHICLE_TYPES_ACTION_REQUESTED,
  SUBMIT_USOB_VEHICLE_TYPES_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { vehicleTypes: [], apiData: {} },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  const vehicleTypeList = [];
  switch (action.type) {
    case SUBMIT_USOB_VEHICLE_TYPES_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_USOB_VEHICLE_TYPES_ACTION_DISPATCHER:
      let data = action.payload.data;
      // console.log("data: ", data);
      data.success.vehicleTypes.forEach((e) => {
        vehicleTypeList.push({
          value: e.type,
          id: e.vehicle_type_id,
          vehicle_type_id: e.id,
        });
      });
      vehicleTypeList.sort(function (a, b) {
        var x = a.value.toLowerCase();
        var y = b.value.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      return {
        ...state,
        successData: {
          vehicleTypes: vehicleTypeList,
          apiData: data.success,
        },
        loading: false,
      };
    case SUBMIT_USOB_VEHICLE_TYPES_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case USOB_VEHICLE_TYPES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
