import {
  MY_SHIFTS_LOADING,
  SUBMIT_MY_SHIFTS_ACTION_DISPATCHER,
  SUBMIT_MY_SHIFTS_ACTION_REQUESTED,
  SUBMIT_MY_SHIFTS_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    myShifts: [],
    mobMyShifts: [],
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  let mobList = [];
  switch (action.type) {
    case SUBMIT_MY_SHIFTS_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_MY_SHIFTS_ACTION_DISPATCHER:
      for (let i = 0; i < action.payload.length; i += 5) {
        mobList.push(action.payload.slice(i, i + 5));
      }
      return {
        ...state,
        successData: {
          myShifts: action.payload,
          mobMyShifts: mobList,
        },
        loading: false,
      };
    case SUBMIT_MY_SHIFTS_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case MY_SHIFTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
