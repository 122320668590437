export const SUBMIT_UPTIME_BY_MODEL_ACTION_REQUESTED =
  "SUBMIT_UPTIME_BY_MODEL_ACTION_REQUESTED";
export const SUBMIT_UPTIME_BY_MODEL_ACTION_DISPATCHER =
  "SUBMIT_UPTIME_BY_MODEL_ACTION_DISPATCHER";
export const SUBMIT_UPTIME_BY_MODEL_ERROR_DISPATCHER =
  "SUBMIT_UPTIME_BY_MODEL_ERROR_DISPATCHER";
export const UPTIME_BY_MODEL_LOADING = "UPTIME_BY_MODEL_LOADING";

//Action Creator
export const submitUptimeByModelAction = (data) => ({
  type: SUBMIT_UPTIME_BY_MODEL_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitUptimeByModelActionDispatcher = (data) => ({
  type: SUBMIT_UPTIME_BY_MODEL_ACTION_DISPATCHER,
  payload: data,
});

export const submitUptimeByModelErrorDispatcher = (data) => ({
  type: SUBMIT_UPTIME_BY_MODEL_ERROR_DISPATCHER,
  payload: data,
});

export const uptimeByModelLoading = (loading) => ({
  type: UPTIME_BY_MODEL_LOADING,
  payload: loading,
});
