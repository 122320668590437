import { call, put, takeEvery } from "redux-saga/effects";
import { getAuthUserDetails } from "../../utils/Helpers";
import {
  FuelEfficiencyLoading,
  submitFuelEfficiencyActionDispatcher,
  submitFuelEfficiencyErrorDispatcher,
  SUBMIT_FUEL_EFFICIENCY_ACTION_REQUESTED,
} from "./Action"; // Path should come here
import axios from "axios";

const postFuelEfficiencyAPI = async (postData) => {
  let cid =
    localStorage.getItem("defPortal") === "VAS"
      ? getAuthUserDetails().CustomerId
      : getAuthUserDetails().usob_cid;
  // console.log("postData: ", postData);
  const result = await axios.get(
    process.env.REACT_APP_NEV_TYPE === "production" ?
      "https://volvodriverx.in/api/v1/fuel-efficiency/" + cid + postData :
      "https://volvodriverx.siplsolutions.com/api/v1/fuel-efficiency/" + cid + postData
    //   ? postData.dates
    //   : "" //
  );
  //
  return result;
};

function* submitFuelEfficiencySaga({ postData }) {
  try {
    yield put(FuelEfficiencyLoading(true));
    const data = yield call(postFuelEfficiencyAPI, postData);
    yield put(submitFuelEfficiencyActionDispatcher(data));
    yield put(submitFuelEfficiencyErrorDispatcher({}));
    yield put(FuelEfficiencyLoading(false));
  } catch (e) {
    yield put(submitFuelEfficiencyActionDispatcher({}));
    yield put(submitFuelEfficiencyErrorDispatcher(e.response?.data));
    yield put(FuelEfficiencyLoading(false));
  }
}

function* submitFuelEfficiencyWatcher() {
  yield takeEvery(
    SUBMIT_FUEL_EFFICIENCY_ACTION_REQUESTED,
    submitFuelEfficiencySaga
  );
}
export default submitFuelEfficiencyWatcher;
