import { call, put, takeEvery } from "redux-saga/effects";
import { getAuthUserDetails } from "../../utils/Helpers";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  EventsLoading,
  submitEventsActionDispatcher,
  submitEventsErrorDispatcher,
  SUBMIT_EVENTS_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postEventAPI = async () => {
  let cid = localStorage.getItem("defPortal") === "USOB" ? getAuthUserDetails()?.usob_cid  : getAuthUserDetails().CustomerId;
  let source = localStorage.getItem("defPortal") === "USOB" ? "USOB" : "Uptime";
  const result = await AXIOS_INSTANCE.get(
    getAuthUserDetails()?.IsAdmin ? "/event?source=" + source : "/event?source=" + source + "&customer_id=" + cid
  );
  // console.log("result: ", result);
  return result;
};

function* submitEventSaga({ postData }) {
  try {
    yield put(EventsLoading(true));
    const data = yield call(postEventAPI, postData);
    yield put(submitEventsActionDispatcher(data));
    yield put(submitEventsErrorDispatcher({}));
    yield put(EventsLoading(false));
  } catch (e) {
    yield put(submitEventsActionDispatcher({}));
    yield put(
      submitEventsErrorDispatcher(e.response.data, console.log(e.response))
    );
    yield put(EventsLoading(false));
  }
}

function* submitEventWatcher() {
  yield takeEvery(SUBMIT_EVENTS_ACTION_REQUESTED, submitEventSaga);
}
export default submitEventWatcher;
