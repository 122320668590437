import {
  UPTIME_BY_MODEL_LOADING,
  SUBMIT_UPTIME_BY_MODEL_ACTION_DISPATCHER,
  SUBMIT_UPTIME_BY_MODEL_ACTION_REQUESTED,
  SUBMIT_UPTIME_BY_MODEL_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { chartData: [], excelData: [], apiData: {} },
  failureData: {},
  loading: false,
  isLogout: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_UPTIME_BY_MODEL_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_UPTIME_BY_MODEL_ACTION_DISPATCHER:
      const tempArr = [["ModelType", "Percentage", { role: "annotation" }]];
      const tempExcelArr = [
        ["From Date", JSON.parse(localStorage.getItem("fleetFilters")).FDate],
        ["To Date", JSON.parse(localStorage.getItem("fleetFilters")).TDate],
        ["", ""],
        ["Model Type", "Percentage"],
      ];
      action.payload.data.forEach((e) => {
        tempArr.push([
          e.ModelType,
          parseFloat(e.Percentage.toFixed(1)),
          `${parseFloat(e.Percentage.toFixed(1))}`,
        ]);
        tempExcelArr.push([e.ModelType, parseFloat(e.Percentage.toFixed(1))]);
      });
      return {
        ...state,
        successData: {
          chartData: tempArr,
          excelData: tempExcelArr,
          apiData: action.payload.data,
        },
        loading: false,
      };
    case SUBMIT_UPTIME_BY_MODEL_ERROR_DISPATCHER:
      return {
        ...state,
        formError: action.payload,
        loading: false,
      };
    case UPTIME_BY_MODEL_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
