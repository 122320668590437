export const SUBMIT_UPTIME_BY_MONTH_ACTION_REQUESTED =
  "SUBMIT_UPTIME_BY_MONTH_ACTION_REQUESTED";
export const SUBMIT_UPTIME_BY_MONTH_ACTION_DISPATCHER =
  "SUBMIT_UPTIME_BY_MONTH_ACTION_DISPATCHER";
export const SUBMIT_UPTIME_BY_MONTH_ERROR_DISPATCHER =
  "SUBMIT_UPTIME_BY_MONTH_ERROR_DISPATCHER";
export const UPTIME_BY_MONTH_LOADING = "UPTIME_BY_MONTH_LOADING";

//Action Creator
export const submitUptimeByMonthAction = (data) => ({
  type: SUBMIT_UPTIME_BY_MONTH_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitUptimeByMonthActionDispatcher = (data) => ({
  type: SUBMIT_UPTIME_BY_MONTH_ACTION_DISPATCHER,
  payload: data,
});

export const submitUptimeByMonthErrorDispatcher = (data) => ({
  type: SUBMIT_UPTIME_BY_MONTH_ERROR_DISPATCHER,
  payload: data,
});

export const uptimeByMonthLoading = (loading) => ({
  type: UPTIME_BY_MONTH_LOADING,
  payload: loading,
});
