export const SUBMIT_TRIP_DATA_ACTION_REQUESTED =
  "SUBMIT_TRIP_DATA_ACTION_REQUESTED";
export const SUBMIT_TRIP_DATA_ACTION_DISPATCHER =
  "SUBMIT_TRIP_DATA_ACTION_DISPATCHER";
export const SUBMIT_TRIP_DATA_ERROR_DISPATCHER =
  "SUBMIT_TRIP_DATA_ERROR_DISPATCHER";
export const TRIP_DATA_LOADING = "TRIP_DATA_LOADING";

//Action Creator
export const submitTripDataAction = (data) => ({
  type: SUBMIT_TRIP_DATA_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitTripDataActionDispatcher = (data) => ({
  type: SUBMIT_TRIP_DATA_ACTION_DISPATCHER,
  payload: data,
});

export const submitTripDataErrorDispatcher = (data) => ({
  type: SUBMIT_TRIP_DATA_ERROR_DISPATCHER,
  payload: data,
});

export const tripDataLoading = (loading) => ({
  type: TRIP_DATA_LOADING,
  payload: loading,
});
