class ApiRequest {
  constructor() {
    this.VASApiBaseURL =
      process.env.REACT_APP_NEV_TYPE === "production"
        ? process.env.REACT_APP_VAS_PROD_URL
        : process.env.REACT_APP_VAS_DEV_URL;
    this.USOBApiBaseURL =
      process.env.REACT_APP_NEV_TYPE === "production"
        ? process.env.REACT_APP_USOB_PROD_URL
        : process.env.REACT_APP_USOB_DEV_URL;
  }
}

export default new ApiRequest();
