import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  forgotPasswordLoading,
  submitForgotPasswordActionDispatcher,
  submitForgotPasswordErrorDispatcher,
  SUBMIT_FORGOTPASSWORD_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postForgotPasswordAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/ForgotPassword?ForgotPass=abc&AnyNumber=5",
    { ...postData }
  );
  return result;
};

function* submitForgotPasswordSaga({ postData }) {
  try {
    yield put(forgotPasswordLoading(true));
    const data = yield call(postForgotPasswordAPI, postData);
    yield put(submitForgotPasswordActionDispatcher(data));
    yield put(submitForgotPasswordErrorDispatcher({}));
    yield put(forgotPasswordLoading(false));
  } catch (e) {
    yield put(submitForgotPasswordActionDispatcher({}));
    yield put(submitForgotPasswordErrorDispatcher(e.response.data));
    yield put(forgotPasswordLoading(false));
  }
}

function* submitForgotPasswordWatcher() {
  yield takeEvery(
    SUBMIT_FORGOTPASSWORD_ACTION_REQUESTED,
    submitForgotPasswordSaga
  );
}
export default submitForgotPasswordWatcher;
