//Action Types
export const SUBMIT_CREATE_VEHICLE_GROUP_ACTION_REQUESTED =
  "SUBMIT_CREATE_VEHICLE_GROUP_ACTION_REQUESTED";
export const SUBMIT_CREATE_VEHICLE_GROUP_ACTION_DISPATCHER =
  "SUBMIT_CREATE_VEHICLE_GROUP_ACTION_DISPATCHER";
export const SUBMIT_CREATE_VEHICLE_GROUP_ERROR_DISPATCHER =
  "SUBMIT_CREATE_VEHICLE_GROUP_ERROR_DISPATCHER";
export const SUBMIT_EMPTY_CREATE_VEHICLE_GROUP_STATE_ACTION =
  "SUBMIT_EMPTY_CREATE_VEHICLE_GROUP_STATE_ACTION";
export const CREATE_VEHICLE_GROUP_LOADING = "CREATE_VEHICLE_GROUP_LOADING";

//Action Creator
export const submitCreateVehicleGroupAction = (data) => ({
  type: SUBMIT_CREATE_VEHICLE_GROUP_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitCreateVehicleGroupActionDispatcher = (data) => ({
  type: SUBMIT_CREATE_VEHICLE_GROUP_ACTION_DISPATCHER,
  payload: data,
});

//Error Dispatcher
export const submitCreateVehicleGroupErrorDispatcher = (data) => ({
  type: SUBMIT_CREATE_VEHICLE_GROUP_ERROR_DISPATCHER,
  payload: data,
});

//Loading Action
export const createVehicleGroupLoading = (loading) => ({
  type: CREATE_VEHICLE_GROUP_LOADING,
  payload: loading,
});

export const submitEmptyCreateVehicleGroupStateAction = () => ({
  type: SUBMIT_EMPTY_CREATE_VEHICLE_GROUP_STATE_ACTION,
});
