//Action Types
export const SUBMIT_RENAME_VEHICLE_GROUP_ACTION_REQUESTED =
  "SUBMIT_RENAME_VEHICLE_GROUP_ACTION_REQUESTED";
export const SUBMIT_RENAME_VEHICLE_GROUP_ACTION_DISPATCHER =
  "SUBMIT_RENAME_VEHICLE_GROUP_ACTION_DISPATCHER";
export const SUBMIT_RENAME_VEHICLE_GROUP_ERROR_DISPATCHER =
  "SUBMIT_RENAME_VEHICLE_GROUP_ERROR_DISPATCHER";
export const SUBMIT_EMPTY_RENAME_VEHICLE_GROUP_STATE_ACTION =
  "SUBMIT_EMPTY_RENAME_VEHICLE_GROUP_STATE_ACTION";
export const RENAME_VEHICLE_GROUP_LOADING = "RENAME_VEHICLE_GROUP_LOADING";

//Action Creator
export const submitRenameVehicleGroupAction = (data) => ({
  type: SUBMIT_RENAME_VEHICLE_GROUP_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitRenameVehicleGroupActionDispatcher = (data) => ({
  type: SUBMIT_RENAME_VEHICLE_GROUP_ACTION_DISPATCHER,
  payload: data,
});

//Error Dispatcher
export const submitRenameVehicleGroupErrorDispatcher = (data) => ({
  type: SUBMIT_RENAME_VEHICLE_GROUP_ERROR_DISPATCHER,
  payload: data,
});

//Loading Action
export const renameVehicleGroupLoading = (loading) => ({
  type: RENAME_VEHICLE_GROUP_LOADING,
  payload: loading,
});

export const submitEmptyRenameVehicleGroupStateAction = () => ({
  type: SUBMIT_EMPTY_RENAME_VEHICLE_GROUP_STATE_ACTION,
});
