import { SEARCH_SERVICE_HISTORY } from "../service-history/Action";

export const SUBMIT_SITE_ADVISORY_ACTION_REQUESTED =
  "SUBMIT_SITE_ADVISORY_ACTION_REQUESTED";
export const SUBMIT_SITE_ADVISORY_ACTION_DISPATCHER =
  "SUBMIT_SITE_ADVISORY_ACTION_DISPATCHER";
export const SUBMIT_SITE_ADVISORY_ERROR_DISPATCHER =
  "SUBMIT_SITE_ADVISORY_ERROR_DISPATCHER";
export const SITE_ADVISORY_LOADING = "SITE_ADVISORY_LOADING";
export const SEARCH_SITE_ADVISORY = "SEARCH_SITE_ADVISORY";

//Action Creator
export const submitSiteAdvisoryAction = (data, query) => ({
  type: SUBMIT_SITE_ADVISORY_ACTION_REQUESTED,
  postData: data,
  filterQuery: query,
});

//Action Dispatcher
export const submitSiteAdvisoryActionDispatcher = (data, query) => {
  let tempSite = [];
  let filterData = query
    ? data.filter((advisory) =>
        advisory.file_name.toLowerCase().includes(query.toLowerCase())
      )
    : data;
  filterData.forEach((e) => {
    tempSite.push({ text: e.SiteName, value: e.SiteName });
  });
  return {
    type: SUBMIT_SITE_ADVISORY_ACTION_DISPATCHER,
    payload: data,
    filteredData: filterData,
    siteFilter: [
      ...new Map(tempSite.map((item) => [item["value"], item])).values(),
    ],
  };
};

export const submitSiteAdvisoryErrorDispatcher = (data) => ({
  type: SUBMIT_SITE_ADVISORY_ERROR_DISPATCHER,
  payload: data,
});

export const searchSiteAdvisory = (data, query) => {
  let tempSite = [];
  let filterData = query
    ? data.filter((advisory) =>
        advisory.file_name.toLowerCase().includes(query.toLowerCase())
      )
    : data;
  filterData.forEach((e) => {
    tempSite.push({ text: e.SiteName, value: e.SiteName });
  });
  return {
    type: SEARCH_SERVICE_HISTORY,
    payload: data,
    filteredData: filterData,
    siteFilter: [
      ...new Map(tempSite.map((item) => [item["value"], item])).values(),
    ],
  };
};

export const siteAdvisoryLoading = (loading) => ({
  type: SITE_ADVISORY_LOADING,
  payload: loading,
});
