export const SUBMIT_NAVIGATION_REQUESTED = "SUBMIT_NAVIGATION_REQUESTED";
export const SUBMIT_NAVIGATION_DISPATCHER = "SUBMIT_NAVIGATION_DISPATCHER";
export const SUBMIT_NAVIGATION_ERROR_DISPATCHER =
  "SUBMIT_NAVIGATION_ERROR_DISPATCHER";
export const NAVIGATION_LOADING = "NAVIGATION_LOADING";

//Action Creator
export const submitNavigationAction = (data) => ({
  type: SUBMIT_NAVIGATION_REQUESTED,
  payload: data
});

// //Action Dispatcher
// export const submitNavigationActionDispatcher = () => ({
//   type: SUBMIT_NAVIGATION_DISPATCHER,
// });

export const submitNavigationErrorDispatcher = () => ({
  type: SUBMIT_NAVIGATION_ERROR_DISPATCHER,
});

export const applicationsLoading = (loading) => ({
  type: NAVIGATION_LOADING,
  payload: loading,
});
