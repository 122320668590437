import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  tripSitesLoading,
  submitTripSitesActionDispatcher,
  submitTripSitesErrorDispatcher,
  SUBMIT_TRIP_SITES_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postTripSitesAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/TripCountSitelistDetail?loginUserid=1",
    {
      ...postData,
    }
  );
  return result;
};

function* submitTripSitesSaga({ postData, filterQuery }) {
  try {
    yield put(tripSitesLoading(true));
    const data = yield call(postTripSitesAPI, postData);
    yield put(submitTripSitesActionDispatcher(data.data, filterQuery));
    yield put(submitTripSitesErrorDispatcher({}));
    yield put(tripSitesLoading(false));
  } catch (e) {
    yield put(submitTripSitesActionDispatcher({}));
    yield put(
      submitTripSitesErrorDispatcher(e.response.data, console.log(e.response))
    );
    yield put(tripSitesLoading(false));
  }
}

function* submitTripSitesWatcher() {
  yield takeEvery(SUBMIT_TRIP_SITES_ACTION_REQUESTED, submitTripSitesSaga);
}
export default submitTripSitesWatcher;
