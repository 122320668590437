import {
  USOB_SEGMENTS_LOADING,
  SUBMIT_USOB_SEGMENTS_ACTION_DISPATCHER,
  SUBMIT_USOB_SEGMENTS_ACTION_REQUESTED,
  SUBMIT_USOB_SEGMENTS_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { segments: [], apiData: {} },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  const segmentList = [];
  switch (action.type) {
    case SUBMIT_USOB_SEGMENTS_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_USOB_SEGMENTS_ACTION_DISPATCHER:
      let data = action.payload.data;
      data?.success.segments.forEach((e) => {
        segmentList.push({
          value: e.segment,
          id: e.segment_id,
          segment_id: e.id,
        });
      });
      segmentList.sort(function (a, b) {
        var x = a.value.toLowerCase();
        var y = b.value.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      return {
        ...state,
        successData: {
          segments: segmentList,
          apiData: data.success,
        },
        loading: false,
      };
    case SUBMIT_USOB_SEGMENTS_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case USOB_SEGMENTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
