import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBCustomersLoading,
  submitUSOBCustomersActionDispatcher,
  submitUSOBCustomersErrorDispatcher,
  SUBMIT_USOB_CUSTOMERS_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBSegmentAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.get("/customer")
    // console.log("result: ", result);
  return result ;
};

function* submitUSOBSegmentSaga({ postData }) {
  try {
    yield put(USOBCustomersLoading(true));
    const data = yield call(postUSOBSegmentAPI, postData);
    yield put(submitUSOBCustomersActionDispatcher(data));
    yield put(submitUSOBCustomersErrorDispatcher({}));
    yield put(USOBCustomersLoading(false));
  } catch (e) {
    yield put(submitUSOBCustomersActionDispatcher({}));
    yield put(
      submitUSOBCustomersErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(USOBCustomersLoading(false));
  }
}

function* submitUSOBSegmentWatcher() {
  yield takeEvery(
    SUBMIT_USOB_CUSTOMERS_ACTION_REQUESTED,
    submitUSOBSegmentSaga
  );
}
export default submitUSOBSegmentWatcher;
