import "core-js/features/global-this";
import "globalthis/auto";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
// import { register } from "./serviceWorker";
import ReduxToastr from "react-redux-toastr";
import configureStore from "./store/Configure";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import App from "./App";

import "./styles/fonts.less";
import "./styles/index.less";
import "antd/dist/antd.less";
import ErrorBoundary from "./ErrorBoundary";

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        <ReduxToastr
          timeOut={5000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          getState={(state) => state.toastr} // This is the default
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
if (process.env.NEV_TYPE !== "development") {
  // register();
}
