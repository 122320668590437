//Action Types
export const SUBMIT_EDIT_PROFILE_ACTION_REQUESTED =
  "SUBMIT_EDIT_PROFILE_ACTION_REQUESTED";
export const SUBMIT_EDIT_PROFILE_ACTION_DISPATCHER =
  "SUBMIT_EDIT_PROFILE_ACTION_DISPATCHER";
export const SUBMIT_EDIT_PROFILE_ERROR_DISPATCHER =
  "SUBMIT_EDIT_PROFILE_ERROR_DISPATCHER";
export const SUBMIT_EMPTY_EDIT_PROFILE_STATE_ACTION =
  "SUBMIT_EMPTY_EDIT_PROFILE_STATE_ACTION";
export const EDIT_PROFILE_LOADING = "EDIT_PROFILE_LOADING";

//Action Creator
export const submitEditProfileAction = (data) => ({
  type: SUBMIT_EDIT_PROFILE_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitEditProfileActionDispatcher = (data) => ({
  type: SUBMIT_EDIT_PROFILE_ACTION_DISPATCHER,
  payload: data,
});

//Error Dispatcher
export const submitEditProfileErrorDispatcher = (data) => ({
  type: SUBMIT_EDIT_PROFILE_ERROR_DISPATCHER,
  payload: data,
});

//Loading Action
export const editProfileLoading = (loading) => ({
  type: EDIT_PROFILE_LOADING,
  payload: loading,
});

export const submitEmptyEditProfileStateAction = () => ({
  type: SUBMIT_EMPTY_EDIT_PROFILE_STATE_ACTION,
});
