export const SUBMIT_CONTACT_US_ACTION_REQUESTED =
  "SUBMIT_CONTACT_US_ACTION_REQUESTED";
export const SUBMIT_CONTACT_US_ACTION_DISPATCHER =
  "SUBMIT_CONTACT_US_ACTION_DISPATCHER";
export const SUBMIT_CONTACT_US_ERROR_DISPATCHER =
  "SUBMIT_CONTACT_US_ERROR_DISPATCHER";
export const CONTACT_US_LOADING = "CONTACT_US_LOADING";
export const SEARCH_CONTACT = "SEARCH_CONTACT";

//Action Creator
export const submitContactUsAction = (data, query) => ({
  type: SUBMIT_CONTACT_US_ACTION_REQUESTED,
  postData: data,
  filterQuery: query,
});

//Action Dispatcher
export const submitContactUsActionDispatcher = (data, query) => ({
  type: SUBMIT_CONTACT_US_ACTION_DISPATCHER,
  payload: data,
  filteredData: query
  ? data.filter((contact) => contact.Name.toLowerCase().includes(query.toLowerCase()))
  : data,
});

export const submitContactUsErrorDispatcher = (data) => ({
  type: SUBMIT_CONTACT_US_ERROR_DISPATCHER,
  payload: data,
});

export const contactUsLoading = (loading) => ({
  type: CONTACT_US_LOADING,
  payload: loading,
});

export const searchContact = (data, query) => ({
  type: SEARCH_CONTACT,
  payload: data,
  filteredData: query
    ? data.filter((contact) => contact.Name.toLowerCase().includes(query.toLowerCase()))
    : data,
});
