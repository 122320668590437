export const SUBMIT_HERE_SEARCH_ACTION_REQUESTED =
  "SUBMIT_HERE_SEARCH_ACTION_REQUESTED";
export const SUBMIT_HERE_SEARCH_ACTION_DISPATCHER =
  "SUBMIT_HERE_SEARCH_ACTION_DISPATCHER";
export const SUBMIT_HERE_SEARCH_ERROR_DISPATCHER =
  "SUBMIT_HERE_SEARCH_ERROR_DISPATCHER";
export const HERE_SEARCH_LOADING = "HERE_SEARCH_LOADING";

//Action Creator
export const submitHereSearchAction = (data) => ({
  type: SUBMIT_HERE_SEARCH_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitHereSearchActionDispatcher = (data) => ({
  type: SUBMIT_HERE_SEARCH_ACTION_DISPATCHER,
  payload: data,
});

export const submitHereSearchErrorDispatcher = (data) => ({
  type: SUBMIT_HERE_SEARCH_ERROR_DISPATCHER,
  payload: data,
});

export const hereSearchLoading = (loading) => ({
  type: HERE_SEARCH_LOADING,
  payload: loading,
});
