import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  breakdownLoading,
  submitBreakdownActionDispatcher,
  submitBreakdownErrorDispatcher,
  SUBMIT_BREAKDOWN_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postBreakdownAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.get(
    "/CustomerPortal?customer_id=" +
      (postData?.customer_id || postData?.customer_id === 0
        ? postData?.customer_id
        : "") +
      "&city=" +
      (postData?.city ? postData?.city : "") +
      "&state=" +
      (postData?.state ? postData?.state : "") +
      "&startdate=" +
      (postData?.startdate ? postData?.startdate : "") +
      "&enddate=" +
      (postData?.enddate ? postData?.enddate : "") +
      "&segment=" +
      (postData?.segment ? postData?.segment : "") +
      "&vehicletype=" +
      (postData?.vehicletype ? postData?.vehicletype : "") +
      "&vehiclegroup=" +
      (postData?.vehiclegroup ? postData?.vehiclegroup : "")+
      "&userregion=" +
      (postData?.region ? postData?.region : "")
  );
  return result;
};

function* submitBreakdownSaga({ postData }) {
  try {
    yield put(breakdownLoading(true));
    const data = yield call(postBreakdownAPI, postData);
    yield put(submitBreakdownActionDispatcher(data));
    yield put(submitBreakdownErrorDispatcher({}));
    yield put(breakdownLoading(false));
  } catch (e) {
    yield put(submitBreakdownActionDispatcher({}));
    yield put(submitBreakdownErrorDispatcher(e.response.data));
    yield put(breakdownLoading(false));
  }
}

function* submitBreakdownWatcher() {
  yield takeEvery(SUBMIT_BREAKDOWN_ACTION_REQUESTED, submitBreakdownSaga);
}
export default submitBreakdownWatcher;
