export const SUBMIT_CHECKUP_TYPES_ACTION_REQUESTED = "SUBMIT_CHECKUP_TYPES_ACTION_REQUESTED";
export const SUBMIT_CHECKUP_TYPES_ACTION_DISPATCHER =
  "SUBMIT_CHECKUP_TYPES_ACTION_DISPATCHER";
export const SUBMIT_CHECKUP_TYPES_ERROR_DISPATCHER = "SUBMIT_CHECKUP_TYPES_ERROR_DISPATCHER";
export const CHECKUP_TYPES_LOADING = "CHECKUP_TYPES_LOADING";

//Action Creator
export const submitCheckupTypesAction = (data) => ({
  type: SUBMIT_CHECKUP_TYPES_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitCheckupTypesActionDispatcher = (data) => ({
  type: SUBMIT_CHECKUP_TYPES_ACTION_DISPATCHER,
  payload: data,
});

export const submitCheckupTypesErrorDispatcher = (data) => ({
  type: SUBMIT_CHECKUP_TYPES_ERROR_DISPATCHER,
  payload: data,
});

export const checkupTypesLoading = (loading) => ({
  type: CHECKUP_TYPES_LOADING,
  payload: loading,
});
