export const SUBMIT_SSO_LOGIN_ACTION_REQUESTED =
  "SUBMIT_SSO_LOGIN_ACTION_REQUESTED";
export const SUBMIT_SSO_LOGIN_ACTION_DISPATCHER =
  "SUBMIT_SSO_LOGIN_ACTION_DISPATCHER";
export const SUBMIT_SSO_LOGIN_ERROR_DISPATCHER =
  "SUBMIT_SSO_LOGIN_ERROR_DISPATCHER";
export const SSO_LOGIN_LOADING = "SSO_LOGIN_LOADING";
export const USER_LOGOUT = "USER_LOGOUT";

//Action Creator
export const submitSSOLoginAction = (data) => ({
  type: SUBMIT_SSO_LOGIN_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitSSOLoginActionDispatcher = (data) => ({
  type: SUBMIT_SSO_LOGIN_ACTION_DISPATCHER,
  payload: data,
});

export const submitSSOLoginErrorDispatcher = (data) => ({
  type: SUBMIT_SSO_LOGIN_ERROR_DISPATCHER,
  payload: data,
});

export const ssoLoginLoading = (loading) => ({
  type: SSO_LOGIN_LOADING,
  payload: loading,
});

export const logout = () => ({
  type: USER_LOGOUT,
});
