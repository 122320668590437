export const SUBMIT_FUEL_EFFICIENCY_ACTION_REQUESTED =
  "SUBMIT_FUEL_EFFICIENCY_ACTION_REQUESTED";
export const SUBMIT_FUEL_EFFICIENCY_ACTION_DISPATCHER =
  "SUBMIT_FUEL_EFFICIENCY_ACTION_DISPATCHER";
export const SUBMIT_FUEL_EFFICIENCY_ERROR_DISPATCHER =
  "SUBMIT_FUEL_EFFICIENCY_ERROR_DISPATCHER";
export const FUEL_EFFICIENCY_LOADING = "FUEL_EFFICIENCY_LOADING";

//Action Creator
export const submitFuelEfficiencyAction = (data) => ({
  type: SUBMIT_FUEL_EFFICIENCY_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitFuelEfficiencyActionDispatcher = (data) => ({
  type: SUBMIT_FUEL_EFFICIENCY_ACTION_DISPATCHER,
  payload: data,
});

export const submitFuelEfficiencyErrorDispatcher = (data) => ({
  type: SUBMIT_FUEL_EFFICIENCY_ERROR_DISPATCHER,
  payload: data,
});

export const FuelEfficiencyLoading = (loading) => ({
  type: FUEL_EFFICIENCY_LOADING,
  payload: loading,
});
