import {
  TRIP_SITES_LOADING,
  SUBMIT_TRIP_SITES_ACTION_DISPATCHER,
  SUBMIT_TRIP_SITES_ACTION_REQUESTED,
  SUBMIT_TRIP_SITES_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    mySites: [],
    siteList: [],
    siteIDs: [],
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  const siteList = [];
  const sitesList = [];
  const siteIDs = [];
  switch (action.type) {
    case SUBMIT_TRIP_SITES_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_TRIP_SITES_ACTION_DISPATCHER:
      var siteListbyName = [];
      action.payload.forEach((e) => {
        siteIDs.push(e.SiteId);
        siteList.push({
          value: e.site_code + " - " + e.site_name,
          id: e.SiteId,
        });
        sitesList.push({
          value: e.site_code + " - " + e.site_name,
          id: e.SiteId,
        });
      });
      siteIDs.sort();
      siteListbyName = siteList;
      siteListbyName.unshift({
        value: "All Sites",
        id: siteIDs.toString(),
        city: 0,
      });
      return {
        ...state,
        successData: {
          mySites: siteListbyName,
          siteList: sitesList,
          siteIDs: siteIDs,
        },
        loading: false,
      };
    case SUBMIT_TRIP_SITES_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case TRIP_SITES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
