import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  editProfileLoading,
  submitEditProfileActionDispatcher,
  submitEditProfileErrorDispatcher,
  SUBMIT_EDIT_PROFILE_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postEditProfileAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/UpdateUserVCP?UpdateUser",
    { ...postData }
  );
  return result;
};

function* submitEditProfileSaga({ postData }) {
  try {
    yield put(editProfileLoading(true));
    const data = yield call(postEditProfileAPI, postData);
    yield put(submitEditProfileActionDispatcher(data));
    yield put(submitEditProfileErrorDispatcher({}));
    yield put(editProfileLoading(false));
  } catch (e) {
    yield put(submitEditProfileActionDispatcher({}));
    yield put(submitEditProfileErrorDispatcher(e));
    yield put(editProfileLoading(false));
  }
}

function* submitEditProfileWatcher() {
  yield takeEvery(
    SUBMIT_EDIT_PROFILE_ACTION_REQUESTED,
    submitEditProfileSaga
  );
}
export default submitEditProfileWatcher;
