import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  registerComplaintLoading,
  submitRegisterComplaintActionDispatcher,
  submitRegisterComplaintErrorDispatcher,
  SUBMIT_REGISTER_COMPLAINT_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postRegisterComplaintAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post("/complaints/register", {
    ...postData,
  });
  return result;
};

function* submitRegisterComplaintSaga({ postData }) {
  try {
    yield put(registerComplaintLoading(true));
    const data = yield call(postRegisterComplaintAPI, postData);
    yield put(submitRegisterComplaintActionDispatcher(data));
    yield put(submitRegisterComplaintErrorDispatcher({}));
    yield put(registerComplaintLoading(false));
  } catch (e) {
    yield put(submitRegisterComplaintActionDispatcher({}));
    yield put(submitRegisterComplaintErrorDispatcher(e));
    yield put(registerComplaintLoading(false));
  }
}

function* submitRegisterComplaintWatcher() {
  yield takeEvery(
    SUBMIT_REGISTER_COMPLAINT_ACTION_REQUESTED,
    submitRegisterComplaintSaga
  );
}
export default submitRegisterComplaintWatcher;
