export const SUBMIT_VEHICLE_GROUPS_ACTION_REQUESTED =
  "SUBMIT_VEHICLE_GROUPS_ACTION_REQUESTED";
export const SUBMIT_VEHICLE_GROUPS_ACTION_DISPATCHER =
  "SUBMIT_VEHICLE_GROUPS_ACTION_DISPATCHER";
export const SUBMIT_VEHICLE_GROUPS_ERROR_DISPATCHER =
  "SUBMIT_VEHICLE_GROUPS_ERROR_DISPATCHER";
export const VEHICLE_GROUPS_LOADING = "VEHICLE_GROUPS_LOADING";

//Action Creator
export const submitVehicleGroupsAction = () => ({
  type: SUBMIT_VEHICLE_GROUPS_ACTION_REQUESTED,
});

//Action Dispatcher
export const submitVehicleGroupsActionDispatcher = (data) => ({
  type: SUBMIT_VEHICLE_GROUPS_ACTION_DISPATCHER,
  payload: data,
});

export const submitVehicleGroupsErrorDispatcher = (data) => ({
  type: SUBMIT_VEHICLE_GROUPS_ERROR_DISPATCHER,
  payload: data,
});

export const vehicleGroupsLoading = (loading) => ({
  type: VEHICLE_GROUPS_LOADING,
  payload: loading,
});
