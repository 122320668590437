import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  utilizationByAgeLoading,
  submitUtilizationByAgeActionDispatcher,
  submitUtilizationByAgeErrorDispatcher,
  SUBMIT_UTILIZATION_BY_AGE_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUtilizationByAgeAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post("/CustomerPortal/Post?Type=EngineHourDetails&p1=xyz", {
    ...postData,
  });
  return result;
};

function* submitUtilizationByAgeSaga({ postData }) {
  try {
    yield put(utilizationByAgeLoading(true));
    const data = yield call(postUtilizationByAgeAPI, postData);
    yield put(submitUtilizationByAgeActionDispatcher(data));
    yield put(submitUtilizationByAgeErrorDispatcher({}));
    yield put(utilizationByAgeLoading(false));
  } catch (e) {
    console.log('Error in UtilizationByAge: ',e)
    yield put(submitUtilizationByAgeActionDispatcher({}));
    yield put(
      submitUtilizationByAgeErrorDispatcher(e.response.data)
    );
    yield put(utilizationByAgeLoading(false));
  }
}

function* submitUtilizationByAgeWatcher() {
  yield takeEvery(SUBMIT_UTILIZATION_BY_AGE_ACTION_REQUESTED, submitUtilizationByAgeSaga);
}
export default submitUtilizationByAgeWatcher;
