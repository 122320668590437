import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBVehicleDataLoading,
  submitUSOBVehicleDataActionDispatcher,
  submitUSOBVehicleDataErrorDispatcher,
  SUBMIT_USOB_VEHICLE_DATA_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const getUSOBVehicleDataAPI = async (header, page) => {
  let filterValues = header.filters;
  let filters = "";
  if (filterValues) {
    if (filterValues.customer_id) {
      filters += "customer_id=" + filterValues.customer_id + "&";
    }
    if (filterValues.segment) {
      filters += "segment=" + filterValues.segment + "&";
    }
    if (filterValues.vehicle_group) {
      filters += "vehicle_group=" + filterValues.vehicle_group + "&";
    }
    if (filterValues.vehicle_type) {
      filters += "vehicle_type=" + filterValues.vehicle_type + "&";
    }
    if (filterValues.date_from) {
      filters += "date_from=" + filterValues.date_from + "&";
    }
    if (filterValues.date_to) {
      filters += "date_to=" + filterValues.date_to + "&";
    }
  }
  const result = await AXIOS_INSTANCE.get(
    "/dashboard/vehicle-data/" + header.type + "?" + filters + "page=" + page,
    header
  );
  // console.log("result: ", result);
  return result;
};

function* submitUSOBVehicleDataSaga({ header, page }) {
  try {
    yield put(USOBVehicleDataLoading(true));
    const data = yield call(getUSOBVehicleDataAPI, header, page);
    yield put(submitUSOBVehicleDataActionDispatcher(data));
    yield put(submitUSOBVehicleDataErrorDispatcher({}));
    yield put(USOBVehicleDataLoading(false));
  } catch (e) {
    yield put(submitUSOBVehicleDataActionDispatcher({}));
    yield put(
      submitUSOBVehicleDataErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(USOBVehicleDataLoading(false));
  }
}

function* submitUSOBVehicleDataWatcher() {
  yield takeEvery(
    SUBMIT_USOB_VEHICLE_DATA_ACTION_REQUESTED,
    submitUSOBVehicleDataSaga
  );
}
export default submitUSOBVehicleDataWatcher;
