import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  regionsLoading,
  submitRegionsActionDispatcher,
  submitRegionsErrorDispatcher,
  SUBMIT_REGIONS_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postRegionsAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post("/global", {
    ...postData,
  });
  return result;
};

function* submitRegionsSaga({ postData }) {
  try {
    yield put(regionsLoading(true));
    const data = yield call(postRegionsAPI, postData);
    yield put(submitRegionsActionDispatcher(data));
    yield put(submitRegionsErrorDispatcher({}));
    yield put(regionsLoading(false));
  } catch (e) {
    yield put(submitRegionsActionDispatcher({}));
    yield put(submitRegionsErrorDispatcher(e.response.data));
    yield put(regionsLoading(false));
  }
}

function* submitRegionsWatcher() {
  yield takeEvery(SUBMIT_REGIONS_ACTION_REQUESTED, submitRegionsSaga);
}
export default submitRegionsWatcher;
