import {
  CAROUSEL_IMGS_LOADING,
  SUBMIT_CAROUSEL_IMGS_ACTION_DISPATCHER,
  SUBMIT_CAROUSEL_IMGS_ACTION_REQUESTED,
  SUBMIT_CAROUSEL_IMGS_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { carouselImgs: [], apiData: {} },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_CAROUSEL_IMGS_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_CAROUSEL_IMGS_ACTION_DISPATCHER:
      let data = action.payload.data;
      // console.log("data: ", data);
      // data.success.forEach((e) => {
      //   carouselImgs.push({
      //     value: e.customer_name,
      //     id: e.customer_id,
      //   });
      // });
      return {
        ...state,
        successData: {
          carouselImgs: data.success?.carouselImages,
          apiData: data.success,
        },
        loading: false,
      };
    case SUBMIT_CAROUSEL_IMGS_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case CAROUSEL_IMGS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
