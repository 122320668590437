//Action Types
export const SUBMIT_USOB_RENAME_VEHICLE_GROUP_ACTION_REQUESTED =
  "SUBMIT_USOB_RENAME_VEHICLE_GROUP_ACTION_REQUESTED";
export const SUBMIT_USOB_RENAME_VEHICLE_GROUP_ACTION_DISPATCHER =
  "SUBMIT_USOB_RENAME_VEHICLE_GROUP_ACTION_DISPATCHER";
export const SUBMIT_USOB_RENAME_VEHICLE_GROUP_ERROR_DISPATCHER =
  "SUBMIT_USOB_RENAME_VEHICLE_GROUP_ERROR_DISPATCHER";
export const SUBMIT_EMPTY_USOB_RENAME_VEHICLE_GROUP_STATE_ACTION =
  "SUBMIT_EMPTY_USOB_RENAME_VEHICLE_GROUP_STATE_ACTION";
export const USOB_RENAME_VEHICLE_GROUP_LOADING =
  "USOB_RENAME_VEHICLE_GROUP_LOADING";

//Action Creator
export const submitUSOBRenameVehicleGroupAction = (data, id) => ({
  type: SUBMIT_USOB_RENAME_VEHICLE_GROUP_ACTION_REQUESTED,
  postData: data,
  grpID : id
});

//Action Dispatcher
export const submitUSOBRenameVehicleGroupActionDispatcher = (data) => ({
  type: SUBMIT_USOB_RENAME_VEHICLE_GROUP_ACTION_DISPATCHER,
  payload: data,
});

//Error Dispatcher
export const submitUSOBRenameVehicleGroupErrorDispatcher = (data) => ({
  type: SUBMIT_USOB_RENAME_VEHICLE_GROUP_ERROR_DISPATCHER,
  payload: data,
});

//Loading Action
export const USOBRenameVehicleGroupLoading = (loading) => ({
  type: USOB_RENAME_VEHICLE_GROUP_LOADING,
  payload: loading,
});

export const submitEmptyUSOBRenameVehicleGroupStateAction = () => ({
  type: SUBMIT_EMPTY_USOB_RENAME_VEHICLE_GROUP_STATE_ACTION,
});
