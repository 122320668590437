import {
  NEWS_LOADING,
  SUBMIT_NEWS_ACTION_DISPATCHER,
  SUBMIT_NEWS_ACTION_REQUESTED,
  SUBMIT_NEWS_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { news: [], ticker: [], apiData: {} },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  let tickerStr = [];
  // let anchStart = "<a href='";
  // let anchMid =
  //   "' target='_blank' style='color: white; text-decoration: none;'>";
  // let anchEnd = "</a>";

  let newsArr = [];
  switch (action.type) {
    case SUBMIT_NEWS_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_NEWS_ACTION_DISPATCHER:
      let data = action.payload.data;
      // console.log("data: ", data);
      action.filteredData?.forEach((e, key) => {
        tickerStr.push({
          srno: key + 1,
          news: e.news_title,
          link: e.news_link,
        });
        // anchStart + e.news_link + anchMid + "  • " + e.news_title + anchEnd;
        newsArr.push({ srno: key + 1, ...e });
      });
      newsArr.sort((a, b) => a.srno - b.srno);
      return {
        ...state,
        successData: {
          news: newsArr,
          ticker: tickerStr,
          apiData: data?.success,
        },
        loading: false,
      };
    case SUBMIT_NEWS_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case NEWS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
