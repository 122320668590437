import {
  USOB_WORKSHOP_SERVICE_LOADING,
  SUBMIT_USOB_WORKSHOP_SERVICE_ACTION_DISPATCHER,
  SUBMIT_USOB_WORKSHOP_SERVICE_ACTION_REQUESTED,
  SUBMIT_USOB_WORKSHOP_SERVICE_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    data: [],
    total: 1,
    currPage: 1,
    pageSize: 10,
    apiData: {},
    // regNoFilter: [],
    // modelNoFilter: [],
    // customerFilter: [],
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  // let mobList = [];
  switch (action.type) {
    case SUBMIT_USOB_WORKSHOP_SERVICE_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_USOB_WORKSHOP_SERVICE_ACTION_DISPATCHER:
      let data = action.payload;
      // console.log("data.success.data: ", data.success.data);
      return {
        ...state,
        successData: {
          total: parseInt(data.success.meta.pagination.total),
          currPage: parseInt(data.success.meta.pagination.current_page),
          pageSize: parseInt(data.success.meta.pagination.per_page) || 10,
          data: data.success.data,
          apiData: data.success,
          // regNoFilter: action.regNoFilter,
          // modelNoFilter: action.modelNoFilter,
          // customerFilter: action.customerFilter,
        },
        loading: false,
      };
    case SUBMIT_USOB_WORKSHOP_SERVICE_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case USOB_WORKSHOP_SERVICE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
