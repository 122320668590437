export const SUBMIT_TRIP_BY_MONTH_ACTION_REQUESTED =
  "SUBMIT_TRIP_BY_MONTH_ACTION_REQUESTED";
export const SUBMIT_TRIP_BY_MONTH_ACTION_DISPATCHER =
  "SUBMIT_TRIP_BY_MONTH_ACTION_DISPATCHER";
export const SUBMIT_TRIP_BY_MONTH_ERROR_DISPATCHER =
  "SUBMIT_TRIP_BY_MONTH_ERROR_DISPATCHER";
export const TRIP_BY_MONTH_LOADING = "TRIP_BY_MONTH_LOADING";

//Action Creator
export const submitTripByMonthAction = (data) => ({
  type: SUBMIT_TRIP_BY_MONTH_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitTripByMonthActionDispatcher = (data) => ({
  type: SUBMIT_TRIP_BY_MONTH_ACTION_DISPATCHER,
  payload: data,
});

export const submitTripByMonthErrorDispatcher = (data) => ({
  type: SUBMIT_TRIP_BY_MONTH_ERROR_DISPATCHER,
  payload: data,
});

export const tripByMonthLoading = (loading) => ({
  type: TRIP_BY_MONTH_LOADING,
  payload: loading,
});
