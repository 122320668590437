import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  deleteVehicleGroupLoading,
  submitDeleteVehicleGroupActionDispatcher,
  submitDeleteVehicleGroupErrorDispatcher,
  SUBMIT_DELETE_VEHICLE_GROUP_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postDeleteVehicleGroupAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/updategroupmaster?Action=delete",
    { ...postData }
  );
  return result;
};

function* submitDeleteVehicleGroupSaga({ postData }) {
  try {
    yield put(deleteVehicleGroupLoading(true));
    const data = yield call(postDeleteVehicleGroupAPI, postData);
    yield put(submitDeleteVehicleGroupActionDispatcher(data));
    yield put(submitDeleteVehicleGroupErrorDispatcher({}));
    yield put(deleteVehicleGroupLoading(false));
  } catch (e) {
    yield put(submitDeleteVehicleGroupActionDispatcher({}));
    yield put(submitDeleteVehicleGroupErrorDispatcher(e));
    yield put(deleteVehicleGroupLoading(false));
  }
}

function* submitDeleteVehicleGroupWatcher() {
  yield takeEvery(
    SUBMIT_DELETE_VEHICLE_GROUP_ACTION_REQUESTED,
    submitDeleteVehicleGroupSaga
  );
}
export default submitDeleteVehicleGroupWatcher;
