export const SUBMIT_TRIP_COUNT_ACTION_REQUESTED = "SUBMIT_TRIP_COUNT_ACTION_REQUESTED";
export const SUBMIT_TRIP_COUNT_ACTION_DISPATCHER = "SUBMIT_TRIP_COUNT_ACTION_DISPATCHER";
export const SUBMIT_TRIP_COUNT_ERROR_DISPATCHER = "SUBMIT_TRIP_COUNT_ERROR_DISPATCHER";
export const TRIP_COUNT_LOADING = "TRIP_COUNT_LOADING";

//Action Creator
export const submitTripCountAction = (data) => ({
  type: SUBMIT_TRIP_COUNT_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitTripCountActionDispatcher = (data) => ({
  type: SUBMIT_TRIP_COUNT_ACTION_DISPATCHER,
  payload: data,
});

export const submitTripCountErrorDispatcher = (data) => ({
  type: SUBMIT_TRIP_COUNT_ERROR_DISPATCHER,
  payload: data,
});

export const tripCountLoading = (loading) => ({
  type: TRIP_COUNT_LOADING,
  payload: loading,
});

