export const SUBMIT_BREAKDOWN_ACTION_REQUESTED =
  "SUBMIT_BREAKDOWN_ACTION_REQUESTED";
export const SUBMIT_BREAKDOWN_ACTION_DISPATCHER =
  "SUBMIT_BREAKDOWN_ACTION_DISPATCHER";
export const SUBMIT_BREAKDOWN_ERROR_DISPATCHER =
  "SUBMIT_BREAKDOWN_ERROR_DISPATCHER";
export const BREAKDOWN_LOADING = "BREAKDOWN_LOADING";

//Action Creator
export const submitBreakdownAction = (data) => ({
  type: SUBMIT_BREAKDOWN_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitBreakdownActionDispatcher = (data) => {
  let tempData = data?.data;
  // console.log("tempData: ", tempData);
  let tempRegNo = [];
  let tempChassis = [];
  let tempBreakLoc = [];
  let tempVehicleGrp = [];
  let tempCurrStat = [];
  // let tempOpenSince = [];
  tempData.forEach((e) => {
    tempRegNo.push({
      value: e.brackdown_vehicle_registration_number,
      text: e.brackdown_vehicle_registration_number,
    });
    tempChassis.push({ value: e.chassis_no, text: e.chassis_no });
    tempBreakLoc.push({
      value: e.breakdown_location,
      text: e.breakdown_location,
    });
    tempVehicleGrp.push({
      value: e.vehicle_group,
      text: e.vehicle_group,
    });
    tempCurrStat.push({
      value: e.alias,
      text: e.alias,
    });
    // tempOpenSince.push({ value: e.open_since, text: e.open_since + " Days" });
  });
  return {
    type: SUBMIT_BREAKDOWN_ACTION_DISPATCHER,
    payload: data,
    regNoFilter: [
      ...new Map(tempRegNo.map((item) => [item["value"], item])).values(),
    ],
    chassisFilter: [
      ...new Map(tempChassis.map((item) => [item["value"], item])).values(),
    ],
    breakLocFilter: [
      ...new Map(tempBreakLoc.map((item) => [item["value"], item])).values(),
    ],
    vehicleGrpFilter: [
      ...new Map(tempVehicleGrp.map((item) => [item["value"], item])).values(),
    ],
    currStatFilter: [
      ...new Map(tempCurrStat.map((item) => [item["value"], item])).values(),
    ],
    // openSinceFilter: [
    //   ...new Map(tempOpenSince.map((item) => [item["value"], item])).values(),
    // ],
  };
};

export const submitBreakdownErrorDispatcher = (data) => ({
  type: SUBMIT_BREAKDOWN_ERROR_DISPATCHER,
  payload: data,
});

export const breakdownLoading = (loading) => ({
  type: BREAKDOWN_LOADING,
  payload: loading,
});
