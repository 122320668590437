import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBWorkshopsLoading,
  submitUSOBWorkshopsActionDispatcher,
  submitUSOBWorkshopsErrorDispatcher,
  SUBMIT_USOB_WORKSHOPS_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBSegmentAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.get("/workshop")
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log("err: ", err);
    });
  return { data: result };
};

function* submitUSOBSegmentSaga({ postData }) {
  try {
    yield put(USOBWorkshopsLoading(true));
    const data = yield call(postUSOBSegmentAPI, postData);
    yield put(submitUSOBWorkshopsActionDispatcher(data));
    yield put(submitUSOBWorkshopsErrorDispatcher({}));
    yield put(USOBWorkshopsLoading(false));
  } catch (e) {
    yield put(submitUSOBWorkshopsActionDispatcher({}));
    yield put(
      submitUSOBWorkshopsErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(USOBWorkshopsLoading(false));
  }
}

function* submitUSOBSegmentWatcher() {
  yield takeEvery(SUBMIT_USOB_WORKSHOPS_ACTION_REQUESTED, submitUSOBSegmentSaga);
}
export default submitUSOBSegmentWatcher;
