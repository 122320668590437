import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  changePasswordLoading,
  submitChangePasswordActionDispatcher,
  submitChangePasswordErrorDispatcher,
  SUBMIT_CHANGEPASSWORD_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postChangePasswordAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/PasswordChange?ExtraPara=abc",
    { ...postData }
  );
  return result;
};

function* submitChangePasswordSaga({ postData }) {
  try {
    yield put(changePasswordLoading(true));
    const data = yield call(postChangePasswordAPI, postData);
    yield put(submitChangePasswordActionDispatcher(data));
    yield put(submitChangePasswordErrorDispatcher({}));
    yield put(changePasswordLoading(false));
  } catch (e) {
    yield put(submitChangePasswordActionDispatcher({}));
    yield put(submitChangePasswordErrorDispatcher(e.response.data));
    yield put(changePasswordLoading(false));
  }
}

function* submitChangePasswordWatcher() {
  yield takeEvery(
    SUBMIT_CHANGEPASSWORD_ACTION_REQUESTED,
    submitChangePasswordSaga
  );
}
export default submitChangePasswordWatcher;
