export const SUBMIT_USOB_VEHICLE_DATA_ACTION_REQUESTED =
  "SUBMIT_USOB_VEHICLE_DATA_ACTION_REQUESTED";
export const SUBMIT_USOB_VEHICLE_DATA_OPERATION_ACTION_DISPATCHER =
  "SUBMIT_USOB_VEHICLE_DATA_OPERATION_ACTION_DISPATCHER";
export const SUBMIT_USOB_VEHICLE_DATA_WORKSHOP_ACTION_DISPATCHER =
  "SUBMIT_USOB_VEHICLE_DATA_WORKSHOP_ACTION_DISPATCHER";
export const SUBMIT_USOB_VEHICLE_DATA_HUB_ACTION_DISPATCHER =
  "SUBMIT_USOB_VEHICLE_DATA_HUB_ACTION_DISPATCHER";
export const SUBMIT_USOB_VEHICLE_DATA_ACCIDENTAL_ACTION_DISPATCHER =
  "SUBMIT_USOB_VEHICLE_DATA_ACCIDENTAL_ACTION_DISPATCHER";
export const SUBMIT_USOB_VEHICLE_DATA_ERROR_DISPATCHER =
  "SUBMIT_USOB_VEHICLE_DATA_ERROR_DISPATCHER";
export const USOB_VEHICLE_DATA_LOADING = "USOB_VEHICLE_DATA_LOADING";

//Action Creator
export const submitUSOBVehicleDataAction = (data, page) => ({
  type: SUBMIT_USOB_VEHICLE_DATA_ACTION_REQUESTED,
  header: data,
  page: page,
});

//Action Dispatcher
export const submitUSOBVehicleDataActionDispatcher = (data, page) => {
  // console.log('data: ', data);
  if (data.data.success.type === "in_operation") {
    return {
      type: SUBMIT_USOB_VEHICLE_DATA_OPERATION_ACTION_DISPATCHER,
      payload: data,
      page: page,
    };
  } else if (data.data.success.type === "in_workshop_service") {
    return {
      type: SUBMIT_USOB_VEHICLE_DATA_WORKSHOP_ACTION_DISPATCHER,
      payload: data,
      page: page,
    };
  } else if (data.data.success.type === "in_hub_service") {
    return {
      type: SUBMIT_USOB_VEHICLE_DATA_HUB_ACTION_DISPATCHER,
      payload: data,
      page: page,
    };
  } else if (data.data.success.type === "accidental_service") {
    return {
      type: SUBMIT_USOB_VEHICLE_DATA_ACCIDENTAL_ACTION_DISPATCHER,
      payload: data,
      page: page,
    };
  }
};

export const submitUSOBVehicleDataErrorDispatcher = (data, page) => ({
  type: SUBMIT_USOB_VEHICLE_DATA_ERROR_DISPATCHER,
  payload: data,
  page: page,
});

export const USOBVehicleDataLoading = (loading) => ({
  type: USOB_VEHICLE_DATA_LOADING,
  payload: loading,
});
