export const SUBMIT_USOB_VEHICLE_GROUP_DETAILS_ACTION_REQUESTED =
  "SUBMIT_USOB_VEHICLE_GROUP_DETAILS_ACTION_REQUESTED";
export const SUBMIT_USOB_VEHICLE_GROUP_DETAILS_ACTION_DISPATCHER =
  "SUBMIT_USOB_VEHICLE_GROUP_DETAILS_ACTION_DISPATCHER";
export const SUBMIT_USOB_VEHICLE_GROUP_DETAILS_ERROR_DISPATCHER =
  "SUBMIT_USOB_VEHICLE_GROUP_DETAILS_ERROR_DISPATCHER";
export const USOB_VEHICLE_GROUP_DETAILS_LOADING =
  "USOB_VEHICLE_GROUP_DETAILS_LOADING";

//Action Creator
export const submitUSOBVehicleGroupDetailsAction = (data) => ({
  type: SUBMIT_USOB_VEHICLE_GROUP_DETAILS_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitUSOBVehicleGroupDetailsActionDispatcher = (data) => ({
  type: SUBMIT_USOB_VEHICLE_GROUP_DETAILS_ACTION_DISPATCHER,
  payload: data,
});

export const submitUSOBVehicleGroupDetailsErrorDispatcher = (data) => ({
  type: SUBMIT_USOB_VEHICLE_GROUP_DETAILS_ERROR_DISPATCHER,
  payload: data,
});

export const USOBVehicleGroupDetailsLoading = (loading) => ({
  type: USOB_VEHICLE_GROUP_DETAILS_LOADING,
  payload: loading,
});
