import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  loginLoading,
  submitLoginActionDispatcher,
  submitLoginErrorDispatcher,
  SUBMIT_LOGIN_ACTION_REQUESTED,
} from "./Action"; // Path should come here
import axios from "axios";
import { toastr } from "react-redux-toastr";

const postLoginAPI = async (postData) => {
  if (postData.SSO) {
    try {
      const ssoResult = await axios.post(
        "https://volvosso.siplsolutions.com/verify-sso-token",
        {
          ...postData.ssoData,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );
      if (ssoResult.data.message) {
        const result = await AXIOS_INSTANCE.post("/CustomerPortal/LogOn", {
          Token: "teramatrix",
          ...postData,
        });
        return result;
      } else {
        toastr.error(
          "Unauthorized Access! Please login with correct credentials."
        );
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized specifically
        toastr.error(
          "Unauthorized Access! Please login with correct credentials."
        );
      } else {
        toastr.error("An error occurred. Please try again later.");
      }
    }
    // const ssoResult = await axios.post(
    //   "https://volvosso.siplsolutions.com/verify-sso-token",
    //   // "http://10.10.11.73/volvo-sso/public/verify-sso-token",
    //   // "http://10.10.11.57/volvo-sso/public/verify-sso-token",
    //   {
    //     ...postData.ssoData,
    //   },
    //   {
    //     headers: {
    //       'Access-Control-Allow-Origin': '*', // Change to specific origin if possible
    //       'Content-Type': 'application/json', // Adjust content type if needed
    //       // Add any other headers as required
    //     },
    //   }
    // );
    // if (ssoResult.data.message) {
    //   const result = await AXIOS_INSTANCE.post("/CustomerPortal/LogOn", {
    //     Token: "teramatrix",
    //     ...postData,
    //   });
    //   return result;
    // }else{
    //   toastr.error("Un-Aurthorised Access! Please Login with correct credentials.");
    // }
  } else {
    const result = await AXIOS_INSTANCE.post("/CustomerPortal/LogOn", {
      Token: "teramatrix",
      ...postData,
    });
    return result;
  }
};

function* submitLoginSaga({ postData }) {
  try {
    yield put(loginLoading(true));
    const data = yield call(postLoginAPI, postData);
    yield put(submitLoginActionDispatcher(data));
    yield put(submitLoginErrorDispatcher({}));
    yield put(loginLoading(false));
  } catch (e) {
    yield put(submitLoginActionDispatcher({}));
    yield put(submitLoginErrorDispatcher(e.response.data));
    yield put(loginLoading(false));
  }
}

function* submitLoginWatcher() {
  yield takeEvery(SUBMIT_LOGIN_ACTION_REQUESTED, submitLoginSaga);
}
export default submitLoginWatcher;
