import {
  SUBMIT_USOB_CREATE_VEHICLE_GROUP_ACTION_REQUESTED,
  SUBMIT_USOB_CREATE_VEHICLE_GROUP_ACTION_DISPATCHER,
  SUBMIT_USOB_CREATE_VEHICLE_GROUP_ERROR_DISPATCHER,
  SUBMIT_EMPTY_USOB_CREATE_VEHICLE_GROUP_STATE_ACTION,
  USOB_CREATE_VEHICLE_GROUP_LOADING,
} from "./Action";

const initialState = {
  successData: { message: "", apiData: {} },
  failureData: { message: "", apiData: {} },
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_USOB_CREATE_VEHICLE_GROUP_ACTION_REQUESTED:
      return { ...state, loading: true };
    case SUBMIT_USOB_CREATE_VEHICLE_GROUP_ACTION_DISPATCHER:
      // console.log('action.payload.data: ', action.payload.data);
      if (action.payload.data) {
        return {
          ...state,
          successData: {
            message: action.payload.data.success? "success" : "",
            apiData: action.payload.data,
          },
          loading: false,
        };
      } else {
        return { ...state, loading: false };
      }
    case SUBMIT_USOB_CREATE_VEHICLE_GROUP_ERROR_DISPATCHER:
      if (action.payload.data) {
        return {
          ...state,
          successData: {
            message: action.payload.data.Message,
            apiData: action.payload.data,
          },
          loading: false,
        };
      } else {
        return { ...state, loading: false };
      }
    case USOB_CREATE_VEHICLE_GROUP_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SUBMIT_EMPTY_USOB_CREATE_VEHICLE_GROUP_STATE_ACTION:
      return {
        ...state,
        successData: {
          message: "",
          apiData: {}
        },
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
