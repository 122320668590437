import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  vehicleGroupsLoading,
  submitVehicleGroupsActionDispatcher,
  submitVehicleGroupsErrorDispatcher,
  SUBMIT_VEHICLE_GROUPS_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postVehicleGroupsAPI = async () => {
  const result = await AXIOS_INSTANCE.get("/CustomerPortal/Getgroupmaster");
  // console.log("result: ", result);
  return result;
};

function* submitVehicleGroupsSaga() {
  try {
    yield put(vehicleGroupsLoading(true));
    const data = yield call(postVehicleGroupsAPI);
    yield put(submitVehicleGroupsActionDispatcher(data));
    yield put(submitVehicleGroupsErrorDispatcher({}));
    yield put(vehicleGroupsLoading(false));
  } catch (e) {
    yield put(submitVehicleGroupsActionDispatcher({}));
    yield put(
      submitVehicleGroupsErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(vehicleGroupsLoading(false));
  }
}

function* submitVehicleGroupsWatcher() {
  yield takeEvery(
    SUBMIT_VEHICLE_GROUPS_ACTION_REQUESTED,
    submitVehicleGroupsSaga
  );
}
export default submitVehicleGroupsWatcher;
