import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  fleetSummaryAndRepairReportCountsLoading,
  submitFleetSummaryAndRepairReportCountsActionDispatcher,
  submitFleetSummaryAndRepairReportCountsErrorDispatcher,
  SUBMIT_FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postFleetSummaryAndRepairReportCountsAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/Post?UptimeDashboard=Uptime",
    {
      ...postData,
    }
  );
  return result;
};

function* submitFleetSummaryAndRepairReportCountsSaga({ postData }) {
  try {
    yield put(fleetSummaryAndRepairReportCountsLoading(true));
    const data = yield call(postFleetSummaryAndRepairReportCountsAPI, postData);
    yield put(submitFleetSummaryAndRepairReportCountsActionDispatcher(data));
    yield put(submitFleetSummaryAndRepairReportCountsErrorDispatcher({}));
    yield put(fleetSummaryAndRepairReportCountsLoading(false));
  } catch (e) {
    yield put(submitFleetSummaryAndRepairReportCountsActionDispatcher({}));
    yield put(
      submitFleetSummaryAndRepairReportCountsErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(fleetSummaryAndRepairReportCountsLoading(false));
  }
}

function* submitFleetSummaryAndRepairReportCountsWatcher() {
  yield takeEvery(
    SUBMIT_FLEET_SUMMARY_AND_REPAIR_REPORT_COUNTS_ACTION_REQUESTED,
    submitFleetSummaryAndRepairReportCountsSaga
  );
}
export default submitFleetSummaryAndRepairReportCountsWatcher;
