import moment from "moment";

export const SUBMIT_SERVICE_HISTORY_ACTION_REQUESTED =
  "SUBMIT_SERVICE_HISTORY_ACTION_REQUESTED";
export const SUBMIT_SERVICE_HISTORY_ACTION_DISPATCHER =
  "SUBMIT_SERVICE_HISTORY_ACTION_DISPATCHER";
export const SUBMIT_SERVICE_HISTORY_ERROR_DISPATCHER =
  "SUBMIT_SERVICE_HISTORY_ERROR_DISPATCHER";
export const SERVICE_HISTORY_LOADING = "SERVICE_HISTORY_LOADING";
export const SEARCH_SERVICE_HISTORY = "SEARCH_SERVICE_HISTORY";

//Action Creator
export const submitServiceHistoryAction = (data, query) => ({
  type: SUBMIT_SERVICE_HISTORY_ACTION_REQUESTED,
  postData: data,
  filterQuery: query,
});

//Action Dispatcher
export const submitServiceHistoryActionDispatcher = (data, query) => {
  let tempChassis = [];
  let tempSite = [];
  let tempDoor = [];
  let tempRepairCategory = [];
  let tempRank = [];
  let tempRepairEndDate = [];
  let filteredData = query
    ? data.filter(
        (vehicle) =>
          vehicle.ChassisNumber.includes(query) ||
          vehicle.DoorNumber.includes(query)
      )
    : data;
  filteredData.forEach((e) => {
    tempSite.push({ text: e.SiteName, value: e.SiteName });
    tempChassis.push({ text: e.ChassisNumber, value: e.ChassisNumber });
    tempDoor.push({ text: e.DoorNumber, value: e.DoorNumber });
    tempRepairCategory.push({
      text: e.RepairCategory,
      value: e.RepairCategory,
    });
    tempRank.push({ text: e.Rank, value: e.Rank });
    var date = moment(e.RepairEndDate).format("DD-MMM-YYYY hh:mm A");
    tempRepairEndDate.push({ text: e.RepairEndDate, value: date });
  });
  return {
    type: SUBMIT_SERVICE_HISTORY_ACTION_DISPATCHER,
    payload: data,
    filteredData: filteredData,
    siteFilter: [
      ...new Map(tempSite.map((item) => [item["value"], item])).values(),
    ],
    chassisFilter: [
      ...new Map(tempChassis.map((item) => [item["value"], item])).values(),
    ],
    doorFilter: [
      ...new Map(tempDoor.map((item) => [item["value"], item])).values(),
    ],
    repairCategoryFilter: [
      ...new Map(
        tempRepairCategory.map((item) => [item["value"], item])
      ).values(),
    ],
    rankFilter: [
      ...new Map(tempRank.map((item) => [item["value"], item])).values(),
    ],
    repairEndDateFilter: [
      ...new Map(
        tempRepairEndDate.map((item) => [item["value"], item])
      ).values(),
    ],
  };
};

export const submitServiceHistoryErrorDispatcher = (data) => ({
  type: SUBMIT_SERVICE_HISTORY_ERROR_DISPATCHER,
  payload: data,
});

export const serviceHistoryLoading = (loading) => ({
  type: SERVICE_HISTORY_LOADING,
  payload: loading,
});

export const searchServiceHistory = (data, query) => {
  console.log('data: ', data);
  let tempChassis = [];
  let tempSite = [];
  let tempDoor = [];
  let tempRepairCategory = [];
  let tempRank = [];
  let tempRepairEndDate = [];
  let filteredData = query
    ? data.filter(
        (vehicle) =>
          vehicle.ChassisNumber.includes(query.ChassisNumber) ||
          vehicle.DoorNumber.includes(query.DoorNumber)
      )
    : data;
  data.forEach((e) => {
    tempSite.push({ text: e.SiteName, value: e.SiteName });
    tempChassis.push({ text: e.ChassisNumber, value: e.ChassisNumber });
    tempDoor.push({ text: e.DoorNumber, value: e.DoorNumber });
    tempRepairCategory.push({
      text: e.RepairCategory,
      value: e.RepairCategory,
    });
    tempRank.push({ text: e.Rank, value: e.Rank });
    var date = moment(e.RepairEndDate).format("DD-MMM-YYYY hh:mm A");
    tempRepairEndDate.push({ text: e.RepairEndDate, value: date });
  });
  return {
    type: SUBMIT_SERVICE_HISTORY_ACTION_DISPATCHER,
    payload: data,
    filteredData: filteredData,
    siteFilter: [
      ...new Map(tempSite.map((item) => [item["value"], item])).values(),
    ],
    chassisFilter: [
      ...new Map(tempChassis.map((item) => [item["value"], item])).values(),
    ],
    doorFilter: [
      ...new Map(tempDoor.map((item) => [item["value"], item])).values(),
    ],
    repairCategoryFilter: [
      ...new Map(
        tempRepairCategory.map((item) => [item["value"], item])
      ).values(),
    ],
    rankFilter: [
      ...new Map(tempRank.map((item) => [item["value"], item])).values(),
    ],
    repairEndDateFilter: [
      ...new Map(
        tempRepairEndDate.map((item) => [item["value"], item])
      ).values(),
    ],
  };
};
