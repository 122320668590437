import {
  PROD_BROCHURE_LOADING,
  SUBMIT_PROD_BROCHURE_ACTION_DISPATCHER,
  SUBMIT_PROD_BROCHURE_ACTION_REQUESTED,
  SUBMIT_PROD_BROCHURE_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: { brochures: [], apiData: {} },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_PROD_BROCHURE_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_PROD_BROCHURE_ACTION_DISPATCHER:
      let data = action.payload.data;
      // console.log("data: ", data);
      // data.success.forEach((e) => {
      //   brochures.push({
      //     value: e.customer_name,
      //     id: e.customer_id,
      //   });
      // });
      return {
        ...state,
        successData: {
          brochures: data.success?.productBrochures,
          apiData: data.success,
        },
        loading: false,
      };
    case SUBMIT_PROD_BROCHURE_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case PROD_BROCHURE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
