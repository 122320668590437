import { getFormattedDate } from "../../utils/Helpers";
import {
  USOB_VEHICLES_LOADING,
  SUBMIT_USOB_VEHICLES_ACTION_DISPATCHER,
  SUBMIT_USOB_VEHICLES_ACTION_REQUESTED,
  SUBMIT_USOB_VEHICLES_ERROR_DISPATCHER,
  // USOB_VEHICLES_SEARCH,
} from "./Action";

const initialState = {
  successData: {
    vehicles: [],
    filteredData: [],
    apiData: {},
    excelData: [],
    regNoFilter: [],
    chassisFilter: [],
    vehicleGrpFilter: [],
    modelNoFilter: [],
    ageFilter: [],
    dodFilter: [],
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  const vehicleList = [];
  switch (action.type) {
    case SUBMIT_USOB_VEHICLES_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_USOB_VEHICLES_ACTION_DISPATCHER:
      let data = action.payload;
      let exl = [
        [
          "Registration Number",
          "Chassis Number",
          "Vehicle Group",
          "Model Number",
          "Vehicle Age",
          "DOD (Date of Delivery)",
        ],
      ];
      // console.log("action.filteredData: ", action.filteredData);
      action.filteredData?.forEach((e) => {
        exl.push([
          e.registration_number,
          e.chassis_id,
          e.vehicle_group || "-",
          e.model_name,
          e.age_band,
          getFormattedDate(e.date_of_delivery),
        ]);
        vehicleList.push({
          value: e.registration_number,
          id: e.vehicle_id,
          key: e.id,
          registration_number: e.vehicle_id,
          model_name: e.model_name,
          master_model_no: e.master_model_no,
          customer_name: e.customer_name,
          customer_contact_number: e.customer_contact_number,
          // vehicle_type_id: e.id,
          // vehicle_id: ,
          // customer_id: e.customer_id,
          // chassis_id: e.chassis_id,
          // segment: e.segment,
          // vehicle_type: e.vehicle_type,
          // door_number: e.door_number,
          // emission_level: e.emission_level,
          // date_of_delivery: e.date_of_delivery,
          // state: e.state,
          // region: e.region,
          // city: e.city,
          // age_band: e.age_band,
        });
      });
      return {
        ...state,
        successData: {
          vehicles: [
            ...new Map(
              vehicleList.map((item) => [item["value"], item])
            ).values(),
          ],
          excelData: exl,
          regNoFilter: action.regNoFilter,
          chassisFilter: action.chassisFilter,
          vehicleGrpFilter: action.vehicleGrpFilter,
          modelNoFilter: action.modelNoFilter,
          ageFilter: action.ageFilter,
          dodFilter: action.dodFilter,
          filteredData: action.filteredData,
          apiData: data?.success,
        },
        loading: false,
      };
    case SUBMIT_USOB_VEHICLES_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case USOB_VEHICLES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    // case USOB_VEHICLES_SEARCH:
    //   let filtereddata = action.payload;
    //   let excel = [
    //     [
    //       "Registration Number",
    //       "Chassis Number",
    //       "Vehicle Group",
    //       "Model Number",
    //       "Vehicle Age",
    //       "DOD (Date of Delivery)",
    //     ],
    //   ];
    //   filtereddata.forEach((e) => {
    //     excel.push([
    //       e.registration_number,
    //       e.chassis_id,
    //       e.vehicle_group || "-",
    //       e.model_name,
    //       e.age_band,
    //       e.date_of_delivery,
    //     ]);
    //     vehicleList.push({
    //       value: e.registration_number,
    //       id: e.vehicle_id,
    //       key: e.id,
    //       registration_number: e.vehicle_id,
    //       model_name: e.model_name,
    //       master_model_no: e.master_model_no,
    //       customer_name: e.customer_name,
    //       customer_contact_number: e.customer_contact_number,
    //       // vehicle_type_id: e.id,
    //       // vehicle_id: ,
    //       // customer_id: e.customer_id,
    //       // chassis_id: e.chassis_id,
    //       // segment: e.segment,
    //       // vehicle_type: e.vehicle_type,
    //       // door_number: e.door_number,
    //       // emission_level: e.emission_level,
    //       // date_of_delivery: e.date_of_delivery,
    //       // state: e.state,
    //       // region: e.region,
    //       // city: e.city,
    //       // age_band: e.age_band,
    //     });
    //   });
    //   return {
    //     ...state,
    //     successData: {
    //       vehicles: vehicleList,
    //       excelData: excel,
    //       regNoFilter: action.regNoFilter,
    //       chassisFilter: action.chassisFilter,
    //       vehicleGrpFilter: action.vehicleGrpFilter,
    //       modelNoFilter: action.modelNoFilter,
    //       ageFilter: action.ageFilter,
    //       dodFilter: action.dodFilter,
    //       apiData: { vehicles: filtereddata },
    //     },
    //     loading: false,
    //   };
    default:
      return state;
  }
};

export default reducer;
