export const SUBMIT_UTILIZATION_BY_MONTH_ACTION_REQUESTED = "SUBMIT_UTILIZATION_BY_MONTH_ACTION_REQUESTED";
export const SUBMIT_UTILIZATION_BY_MONTH_ACTION_DISPATCHER = "SUBMIT_UTILIZATION_BY_MONTH_ACTION_DISPATCHER";
export const SUBMIT_UTILIZATION_BY_MONTH_ERROR_DISPATCHER = "SUBMIT_UTILIZATION_BY_MONTH_ERROR_DISPATCHER";
export const UTILIZATION_BY_MONTH_LOADING = "UTILIZATION_BY_MONTH_LOADING";

//Action Creator
export const submitUtilizationByMonthAction = (data) => ({
  type: SUBMIT_UTILIZATION_BY_MONTH_ACTION_REQUESTED,
  postData: data,
});

//Action Dispatcher
export const submitUtilizationByMonthActionDispatcher = (data) => ({
  type: SUBMIT_UTILIZATION_BY_MONTH_ACTION_DISPATCHER,
  payload: data,
});

export const submitUtilizationByMonthErrorDispatcher = (data) => ({
  type: SUBMIT_UTILIZATION_BY_MONTH_ERROR_DISPATCHER,
  payload: data,
});

export const utilizationByMonthLoading = (loading) => ({
  type: UTILIZATION_BY_MONTH_LOADING,
  payload: loading,
});

