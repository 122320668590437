import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  tripByModelLoading,
  submitTripByModelActionDispatcher,
  submitTripByModelErrorDispatcher,
  SUBMIT_TRIP_BY_MODEL_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postTripByModelAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post(
    "/CustomerPortal/TripCountModelTypeDetail?modeltype=15",
    {
      ...postData,
    }
  );
  return result;
};

function* submitTripByModelSaga({ postData }) {
  try {
    yield put(tripByModelLoading(true));
    const data = yield call(postTripByModelAPI, postData);
    yield put(submitTripByModelActionDispatcher(data));
    yield put(submitTripByModelErrorDispatcher({}));
    yield put(tripByModelLoading(false));
  } catch (e) {
    yield put(submitTripByModelActionDispatcher({}));
    yield put(submitTripByModelErrorDispatcher(e));
    yield put(tripByModelLoading(false));
  }
}

function* submitTripByModelWatcher() {
  yield takeEvery(SUBMIT_TRIP_BY_MODEL_ACTION_REQUESTED, submitTripByModelSaga);
}
export default submitTripByModelWatcher;
