import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/USOBConfig";

import {
  USOBVehicleGroupDetailsLoading,
  submitUSOBVehicleGroupDetailsActionDispatcher,
  submitUSOBVehicleGroupDetailsErrorDispatcher,
  SUBMIT_USOB_VEHICLE_GROUP_DETAILS_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postUSOBVehicleGroupDetailsAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.get("/groups/details/" + postData)
    // console.log("result: ", result);
  return result ;
};

function* submitUSOBVehicleGroupDetailsSaga({ postData, page }) {
  try {
    yield put(USOBVehicleGroupDetailsLoading(true));
    const data = yield call(postUSOBVehicleGroupDetailsAPI, postData, page);
    yield put(submitUSOBVehicleGroupDetailsActionDispatcher(data));
    yield put(submitUSOBVehicleGroupDetailsErrorDispatcher({}));
    yield put(USOBVehicleGroupDetailsLoading(false));
  } catch (e) {
    yield put(submitUSOBVehicleGroupDetailsActionDispatcher({}));
    yield put(
      submitUSOBVehicleGroupDetailsErrorDispatcher(
        e.response.data,
        console.log(e.response)
      )
    );
    yield put(USOBVehicleGroupDetailsLoading(false));
  }
}

function* submitUSOBVehicleGroupDetailsWatcher() {
  yield takeEvery(
    SUBMIT_USOB_VEHICLE_GROUP_DETAILS_ACTION_REQUESTED,
    submitUSOBVehicleGroupDetailsSaga
  );
}
export default submitUSOBVehicleGroupDetailsWatcher;
