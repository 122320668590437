import {
  FUEL_EFFICIENCY_LOADING,
  SUBMIT_FUEL_EFFICIENCY_ACTION_DISPATCHER,
  SUBMIT_FUEL_EFFICIENCY_ACTION_REQUESTED,
  SUBMIT_FUEL_EFFICIENCY_ERROR_DISPATCHER,
} from "./Action";

const initialState = {
  successData: {
    dailyAvgFuelEff: [
      [
        "Date",
        "Average",
        { role: "annotation" },
        "Fuel Efficiency",
        { role: "annotation" },
      ],
    ],
    weeklyAvgFuelEff: [
      [
        "Week - Month",
        "Average",
        { role: "annotation" },
        "Fuel Efficiency",
        { role: "annotation" },
      ],
    ],
    minWA: 1,
    maxWA: 1,
    lastThreeMonthAvg: 0,
    siteAvgFuelEff: {
      avg: 0,
      numOfTruckAboveAvg: 0,
      numOfTruckBelowAvg: 0,
    },
    modelWiseFuelEff: [["Model", "Fuel Efficiency", { role: "annotation" }]],
    lastTwelveMonths: [["Month-Year", "Average", { role: "annotation" }]],
    ageBandWiseFuelEff: [["Age Band", "Average", { role: "annotation" }]],
    apiData: {},
  },
  failureData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_FUEL_EFFICIENCY_ACTION_REQUESTED:
      return { ...state, apiLoading: true };
    case SUBMIT_FUEL_EFFICIENCY_ACTION_DISPATCHER:
      let dailyAvgFuelEff = [
        ["Date", "Fuel Efficiency", { role: "annotation" }],
      ];
      let weeklyAvgFuelEff = [
        [
          "Week - Month",
          "Average",
          { role: "annotation" },
          { role: "tooltip", type: "string", p: { html: true } },
          "Fuel Efficiency",
          { role: "annotation" },
        ],
      ];
      let modelWiseFuelEff = [
        ["Model", "Fuel Efficiency", { role: "annotation" }],
      ];
      let lastTwelveMonths = [
        ["Month-Year", "Average", { role: "annotation" }],
      ];
      let ageBandWiseFuelEff = [
        ["Age Band", "Average", { role: "annotation" }],
      ];
      let data = action.payload.data?.success;
      let weekSum = 0;
      let minWA = 1;
      let maxWA = 0;
      data?.daillyAvgFuelEff?.forEach((e, i) => {
        dailyAvgFuelEff.push([
          e?.date,
          parseFloat(e?.avg_fuel_efficiency.toFixed(2)),
          parseFloat(e?.avg_fuel_efficiency.toFixed(2)),
        ]);
      });
      data?.weeklyAvgFuelEff?.forEach((e, i) => {
        if (parseFloat(e?.avg_fuel_efficiency.toFixed(2)) > maxWA) {
          maxWA = parseFloat(e?.avg_fuel_efficiency.toFixed(2));
        }
        if (parseFloat(e?.avg_fuel_efficiency.toFixed(2)) < minWA) {
          minWA = parseFloat(e?.avg_fuel_efficiency.toFixed(2));
        }
        weekSum += parseFloat(e?.avg_fuel_efficiency.toFixed(2));
        const currAvg = weekSum / (i + 1);
        weeklyAvgFuelEff.push([
          e?.week,
          parseFloat(currAvg.toFixed(2)),
          parseFloat(currAvg.toFixed(2)),
          `<div style="padding:9px; width:160px;color: black;"><b style="font-size:15px;font-weight: 900;">Current Average</b><br /><div style="padding-top:7px;font-size:16px;font-weight: lighter !important;">Average: </div><b style="font-size:14px;font-weight: 900;">${parseFloat(
            parseFloat(currAvg.toFixed(2))
          )}</b></div>`,
          parseFloat(e?.avg_fuel_efficiency.toFixed(2)),
          parseFloat(e?.avg_fuel_efficiency.toFixed(2)),
        ]);
      });
      data?.modelWiseFuelEff?.forEach((e) => {
        modelWiseFuelEff.push([
          e?.model,
          parseFloat(e?.avg_fuel_efficiency.toFixed(2)),
          parseFloat(e?.avg_fuel_efficiency.toFixed(2)),
        ]);
      });
      data?.lastTwelveMonths?.forEach((e) => {
        lastTwelveMonths.push([
          e?.date,
          parseFloat(e?.avg_fuel_efficiency.toFixed(2)),
          parseFloat(e?.avg_fuel_efficiency.toFixed(2)),
        ]);
      });
      data?.ageBandWiseFuelEff?.forEach((e) => {
        ageBandWiseFuelEff.push([
          e?.age_band,
          parseFloat(e?.avg_fuel_efficiency.toFixed(2)),
          parseFloat(e?.avg_fuel_efficiency.toFixed(2)),
        ]);
      });
      let truckAboveAvg = parseFloat((data?.siteAvgFuelEff?.numOfTruckAboveAvg / (data?.siteAvgFuelEff?.numOfTruckAboveAvg + data?.siteAvgFuelEff?.numOfTruckBelowAvg)) * 100).toFixed(0);
      let truckBelowAvg = parseFloat((data?.siteAvgFuelEff?.numOfTruckBelowAvg / (data?.siteAvgFuelEff?.numOfTruckAboveAvg + data?.siteAvgFuelEff?.numOfTruckBelowAvg)) * 100).toFixed(0);
      return {
        ...state,
        successData: {
          dailyAvgFuelEff: dailyAvgFuelEff, // || [],
          weeklyAvgFuelEff: weeklyAvgFuelEff,
          siteAvgFuelEff: {
            avg: data?.siteAvgFuelEff?.avg?.toFixed(2) || 0,
            numOfTruckAboveAvg: truckAboveAvg === "NaN" ? 0 : truckAboveAvg,
            numOfTruckBelowAvg: truckBelowAvg === "NaN" ? 0 : truckBelowAvg,
          },
          minWA: parseFloat(minWA.toFixed(2)),
          maxWA: parseFloat(maxWA.toFixed(2)),
          lastThreeMonthAvg: parseFloat(data?.lastThreeMonthAvg).toFixed(2) || 0,
          modelWiseFuelEff: modelWiseFuelEff,
          lastTwelveMonths: lastTwelveMonths,
          ageBandWiseFuelEff: ageBandWiseFuelEff,
          apiData: data,
        },
        loading: false,
      };
    case SUBMIT_FUEL_EFFICIENCY_ERROR_DISPATCHER:
      return {
        ...state,
        failureData: action.payload,
        loading: false,
      };
    case FUEL_EFFICIENCY_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
