import { call, put, takeEvery } from "redux-saga/effects";
import AXIOS_INSTANCE from "../../utils/Config";

import {
  citiesLoading,
  submitCitiesActionDispatcher,
  submitCitiesErrorDispatcher,
  SUBMIT_CITIES_ACTION_REQUESTED,
} from "./Action"; // Path should come here

const postCitiesAPI = async (postData) => {
  const result = await AXIOS_INSTANCE.post("/global?state=ttpl&city=ttpl", {
    ...postData,
  });
  return result;
};

function* submitCitiesSaga({ postData }) {
  try {
    yield put(citiesLoading(true));
    const data = yield call(postCitiesAPI, postData);
    yield put(submitCitiesActionDispatcher(data));
    yield put(submitCitiesErrorDispatcher({}));
    yield put(citiesLoading(false));
  } catch (e) {
    yield put(submitCitiesActionDispatcher({}));
    yield put(submitCitiesErrorDispatcher(e.response.data));
    yield put(citiesLoading(false));
  }
}

function* submitCitiesWatcher() {
  yield takeEvery(SUBMIT_CITIES_ACTION_REQUESTED, submitCitiesSaga);
}
export default submitCitiesWatcher;
